<script setup lang="ts">
import "overlayscrollbars/overlayscrollbars.css"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import { Drawer } from "ant-design-vue"
import { provide, ref, toRefs, useSlots } from "vue"

import { PAGE_DRAWER_DEFAULT_WIDTH } from "../constants"
import { DrawerWidthSetterToken } from "../hooks"
import { providePopupContainer } from "../util"

import { useAdoptable } from "./use-media"

const props = defineProps({
  className: {
    type: String,
    default: "page-drawer"
  },

  loading: {
    type: Boolean,
    default: false
  },

  closeable: {
    type: Boolean,
    default: true
  },

  maskClosable: {
    type: Boolean,
    default: true
  },

  width: {
    type: Number,
    default: () => PAGE_DRAWER_DEFAULT_WIDTH
  },

  customScrollBody: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["onClose", "onOpen"])

const afterOpenChangeAction = (isOpened: boolean) => {
  if (isOpened) {
    emit("onOpen")
  } else {
    emit("onClose")
  }
}
const slots: Record<keyof typeof Drawer.slots, unknown> = useSlots()
const { width } = toRefs(props)
const drawerWidth = ref(width.value)
const setDrawerWidth = (width?: number) => {
  if (width === undefined) {
    drawerWidth.value = PAGE_DRAWER_DEFAULT_WIDTH
  } else {
    drawerWidth.value = width
  }
}
provide(DrawerWidthSetterToken, setDrawerWidth)
const isAdoptable = useAdoptable()
providePopupContainer(() =>
  document.querySelector(".page-drawer .ant-drawer-body div[data-overlayscrollbars-contents]")
)
</script>

<template>
  <Drawer
    :content-wrapper-style="{ boxShadow: 'none' }"
    :style="
      isAdoptable
        ? { borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }
        : { borderBottomLeftRadius: '8px', borderTopLeftRadius: '8px' }
    "
    :footer-style="{ padding: '8px 24px' }"
    :header-style="{ fontSize: '18px' }"
    :class="`page-drawer ${className}`"
    :width="isAdoptable ? '100%' : drawerWidth"
    height="80%"
    :placement="isAdoptable ? 'bottom' : 'right'"
    :z-index="1000"
    :closable="closeable && !loading"
    :mask-closable="maskClosable && !loading"
    @afterOpenChange="afterOpenChangeAction"
  >
    <template v-for="(_, slotname) in slots" #[slotname] :key="_">
      <OverlayScrollbarsComponent
        v-if="slotname === 'default' && !customScrollBody"
        class="ant-drawer-scrollbody"
        defer
      >
        <slot :name="slotname" />
      </OverlayScrollbarsComponent>
      <slot v-else :name="slotname" />
    </template>
  </Drawer>
</template>

<!--suppress CssUnusedSymbol -->
<style>
.ant-drawer-header-title {
  display: inline-flex;
  flex-direction: row-reverse;
}

.ant-drawer-close {
  margin-inline-end: 0 !important;
  margin-inline-start: 12px !important;
  padding: 0;
}

.ant-drawer-scrollbody {
  position: relative;

  max-height: 100%;
  padding: 24px;
}

.page-drawer .ant-drawer-body {
  position: relative;
  overflow: hidden;
  padding: 0;
}

.page-drawer .ant-drawer-title {
  font-size: 18px;
}
</style>
