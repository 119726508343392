<script setup lang="ts">
import { useRouter } from "vue-router"
import { h, watch } from "vue"
import { noop, useToggle } from "@vueuse/core"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { BankAccountEditRouteName, BankAccountRouteName } from "@/router"
import { DeleteConfirm } from "@/package/ui-kit"
import {
  useBankAccountStore,
  BankAccountDescription,
  useBankAccountDetail,
  DETAIL_REQUEST_ERROR_MESSAGE
} from "@/modules/bank-account"

const router = useRouter()
const openEditAction = () =>
  router.push({
    name: BankAccountEditRouteName,
    params: { id: bankAccountId.value }
  })
const openListingAction = () => router.push({ name: BankAccountRouteName })

const bankAccountId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)

const { isLoading, bankAccount, error } = useBankAccountDetail(bankAccountId)

watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)

const store = useBankAccountStore()
const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить запись?",
    onOk: () =>
      store
        .deleteOne(parseInt(bankAccountId.value))
        .then(() => {
          push.success({ message: "Удалено" })
          toggleOpened(false)
        })
        .catch(noop)
  })
}
</script>

<template>
  <APageDrawer v-model:open="isOpened" title="Информация о счете" @onClose="openListingAction">
    <ASpin :spinning="isLoading">
      <BankAccountDescription :bank-account="bankAccount" />
    </ASpin>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :disabled="(bankAccount !== undefined && !bankAccount.editable) || isLoading"
            :icon="h(DeleteOutlined)"
            block
            size="large"
            danger
            @click="deleteAction"
          >
            Удалить
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :icon="h(EditOutlined)"
            block
            type="primary"
            size="large"
            :disabled="(bankAccount !== undefined && !bankAccount.editable) || isLoading"
            @click="openEditAction"
          >
            Редактировать
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
