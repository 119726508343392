import type { CounterpartyDetail } from "../interfaces"
import type { MaybeRef } from "vue"

import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import { useApiError } from "@/package/api-client"

import { detailsGet } from "../api/details.get"

export const useCounterpartiesDetail = (entityId?: MaybeRef<string | number>) => {
  const { data, error, loading, refreshAsync, run } = useRequest<CounterpartyDetail | undefined>(
    (id: string) => (id ? detailsGet(id) : Promise.resolve(undefined)),
    {
      cacheKey: ([id] = []) => `/counterparties/${id}`,
      defaultParams: [get(entityId)]
    }
  )

  return {
    counterparty: data,
    isLoading: loading,
    error: useApiError(error),
    run,
    refreshAsync
  }
}
