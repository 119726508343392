<script setup lang="ts">
import { computed, onMounted } from "vue"
import { useToggle } from "@vueuse/core"

import { usePayerTariffCode } from "../hooks/use-payer-tariff-code"

const {
  payerTariffCode: data,
  payerTariffCodePending: isLoading,
  error,
  getPayerTariffCode: execute
} = usePayerTariffCode()

const errorMessage = computed(() =>
  error.value
    ? "Не удалось загрузить информацию, пожалуйста повторите попытку или обратитесь в поддержку"
    : null
)
const options = computed(() =>
  data.value && errorMessage.value === null
    ? data?.value?.data.map(({ code, description }) => ({
        value: code,
        label: `${code}. ${description}`
      }))
    : []
)

const [isOpened, toggleOpen] = useToggle(false)

onMounted(() => {
  execute()
})
const retryAction = () => {
  toggleOpen()
  setTimeout(execute, 500)
}
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    placeholder="Выберите код тарифа плательщика"
    :loading="isLoading"
    :options="options"
    :status="errorMessage === null ? undefined : 'warning'"
    @dropdownVisibleChange="() => toggleOpen()"
  >
    <template #notFoundContent>
      <AEmpty>
        <template #description> {{ errorMessage }}</template>
        <AButton type="text" @click.stop="retryAction"> Повторить </AButton>
      </AEmpty>
    </template>
  </ASelect>
</template>
