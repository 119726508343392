import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

export const useCentrifugoConnectionToken = () => {
  const { execute } = useAxios("/centrifugo/get", { method: "GET" }, client, {
    immediate: false
  })

  const getConnectionToken = async () => {
    const res = await execute()

    const data = await res.response.value?.data
    return data.token
  }

  return {
    getConnectionToken
  }
}
