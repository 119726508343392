import type { Dayjs } from "dayjs"

export interface FilterForm {
  date: Array<Dayjs>
  bank_account_id: number | null
  counterparty_id?: number | null
  project_id: number | null
  category_id: Array<number | null>
  tags: Array<string>
  actions: Array<string>
  types: Array<string>
}

export const defaultFormFilters: Omit<FilterForm, "date" | "actions" | "types"> = {
  bank_account_id: null,
  counterparty_id: undefined,
  project_id: null,
  category_id: [],
  tags: []
}

export const defaultSeparateFilters: Pick<FilterForm, "date" | "actions" | "types"> = {
  date: [],
  actions: [],
  types: []
}
