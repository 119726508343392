import type { SubscriptionsData } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useGetSubscriptionTypes = () => {
  const { execute, data, isLoading, error } = useAxios<SubscriptionsData>(
    "/subscription/products",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getSubscriptions = async () => {
    return await execute("/subscription/products")
  }

  return {
    subscriptionsData: data,
    getSubscriptions,
    SubscriptionsPending: isLoading,
    SubscriptionsError: useApiError(error)
  }
}
