<script setup lang="ts">
import { gridGutter } from "@/package/ui-kit"
const supportMail = import.meta.env.VITE_SUPPORT_MAIL
</script>

<template>
  <ARow :gutter="gridGutter" :style="{ height: '100%', maxHeight: '100%' }">
    <ACol :xl="{ span: 16, offset: 4 }" :sm="{ span: 18, offset: 3 }" :xs="24">
      <AFlex vertical justify="center" align="center" :style="{ height: '100%' }">
        <ATypographyTitle :level="2" :style="{ textAlign: 'center', marginBottom: '8px' }">
          Есть вопросы?
        </ATypographyTitle>
        <AText variant="lg" :style="{ textAlign: 'center', marginBottom: '8px' }">
          Cвяжитесь с нами <a :href="`mailto:${supportMail}`">{{ supportMail }}</a>
        </AText>
      </AFlex>
    </ACol>
  </ARow>
</template>
