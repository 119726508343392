import { computed } from "vue"

import { useProductsStore, useProductTypes, useUnitTypes } from "@/modules/product"

export const useProductListing = () => {
  const store = useProductsStore()
  const { data: productTypes } = useProductTypes()
  const { data: unitTypes } = useUnitTypes()

  return computed(() =>
    store.listingBuffer.map((entry) => {
      const type = productTypes.value?.find(({ slug }) => slug === entry.type)?.value
      const unit_type = unitTypes.value?.find(({ slug }) => slug === entry.unit_type)?.value

      return {
        ...entry,
        type,
        unit_type
      }
    })
  )
}
