<script setup lang="ts">
import { theme } from "ant-design-vue"
import { computed } from "vue"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons-vue"

import { useAppConfig } from "@/store"

const appConfig = useAppConfig()
const token = theme.useToken()
const btnRadius = computed(() => `${token.token.value.borderRadius}px`)
</script>

<template>
  <ACarousel autoplay :style="{ height: '100%' }" arrows>
    <template #prevArrow>
      <button class="carousel-btn carousel-btn--left" type="button">
        <ArrowLeftOutlined class="carousel-btn__icon" />
      </button>
    </template>
    <template #nextArrow>
      <button type="button" class="carousel-btn carousel-btn--right">
        <ArrowRightOutlined class="carousel-btn__icon" />
      </button>
    </template>
    <div>
      <template v-if="appConfig.isDarkTheme">
        <img class="slick-slide__img" src="./slides/1x1-dark.png" alt="Плюсы использования САМО" />
      </template>
      <template v-else>
        <div class="slide-wrapper slide-wrapper_light">
          <img class="slick-slide__img" src="./slides/1x1.png" alt="Плюсы использования САМО" />
        </div>
      </template>
    </div>
    <div>
      <template v-if="appConfig.isDarkTheme">
        <img class="slick-slide__img" src="./slides/1x1-dark.png" alt="Плюсы использования САМО" />
      </template>
      <template v-else>
        <div class="slide-wrapper slide-wrapper_dark">
          <img class="slick-slide__img" src="./slides/1x2.png" alt="Плюсы использования САМО" />
        </div>
      </template>
    </div>
  </ACarousel>
</template>

<style scoped>
:deep(.slick-list) {
  border-radius: 16px;
}

:deep(.slick-list),
:deep(.slick-track),
:deep(.slick-slider) {
  height: 100% !important;
}

:deep(.slick-track) {
  display: flex;
}

:deep(.slick-slide) {
  text-align: center;
  height: 100%;
  background: v-bind("token.token.value.colorPrimary");
  overflow: hidden;
  display: flex !important;
  user-select: none;
  align-items: center;
  justify-content: center;
}

:deep(.slick-slide) > div {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.slick-slide__img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
}
.carousel-btn:focus,
.carousel-btn:hover {
  transform: scale(1.1);
  box-shadow: none;
  background: v-bind("token.token.value.colorBgElevated");
}

.carousel-btn:active {
  transform: scale(0.9);
  box-shadow: v-bind("token.token.value.boxShadow");
  background: v-bind("token.token.value.colorBgElevated");
}

.carousel-btn {
  transition: v-bind("token.token.value.motionEaseInOut");
  transition-duration: 0.2s;
  z-index: 100;
  background: v-bind("token.token.value.colorBgElevated");
  border-radius: v-bind(btnRadius);
  box-shadow: v-bind("token.token.value.boxShadow");

  top: auto;
  left: auto;
  width: 40px;
  height: 40px;
  bottom: 40px;
}

.carousel-btn--left {
  right: 90px;
}

.carousel-btn--right {
  right: 33px;
}

.carousel-btn__icon {
  font-size: 13px;
  color: v-bind("token.token.value.colorText");
}

.slide-wrapper {
  width: 100%;
  height: 100%;
}

.slide-wrapper_light {
  background-color: v-bind("token.token.value.colorPrimary");
}

.slide-wrapper_dark {
  background-color: var(--dark-image-background);
}
</style>
