import type { ThemeConfig } from "ant-design-vue/es/config-provider/context"

import { theme } from "ant-design-vue"

import { themingDefaults } from "./theming/defaults"
import { themingDark } from "./theming/dark"
import { themingLight } from "./theming/light"

export const createAntTheme = (isDark: boolean): ThemeConfig => {
  const algorithm = isDark ? theme.darkAlgorithm : theme.defaultAlgorithm
  return {
    algorithm,
    token: Object.assign(themingDefaults, isDark ? themingDark : themingLight)
  }
}
