<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref } from "vue"

import { RestoreRequestForm } from "@/modules/auth"
import { loginRoute, registerRoute } from "@/router"

defineProps({
  logoSize: {
    type: Number,
    default: 0
  }
})

const email = ref<string | null>(null)
const router = useRouter()
</script>

<template>
  <AFlex vertical :style="{ flexGrow: 1 }" justify="center">
    <template v-if="email !== null">
      <ATypographyTitle :style="{ marginBottom: '8px', textAlign: 'center' }"
        >Инструкция отправлена
      </ATypographyTitle>
      <AText :style="{ textAlign: 'center' }" variant="lg"
        >Инструкция была отправлена на почту {{ email }}
      </AText>
      <AButton
        type="primary"
        html-type="submit"
        block
        size="large"
        :style="{ marginTop: '32px' }"
        @click="() => router.push({ name: loginRoute })"
      >
        На главную страницу
      </AButton>
    </template>
    <template v-else>
      <ATypographyTitle :style="{ marginBottom: '8px', textAlign: 'center' }"
        >Восстановление доступа
      </ATypographyTitle>
      <AText :style="{ textAlign: 'center' }" variant="lg"
        >Инструкция для восстановления доступа будет отправлена на вашу электронную почту, которая
        была указана при регистрации.
      </AText>
      <RestoreRequestForm
        :style="{ marginTop: '12px' }"
        @success="
          (requestedEmail) => {
            email = requestedEmail
          }
        "
      />
      <div :style="{ textAlign: 'center', marginTop: '8px' }">
        <ATypographyText type="secondary">
          <ALink :to="{ name: loginRoute }">Войдите</ALink>, если у вас уже есть учетная запись или
          <ALink :to="{ name: registerRoute }">зарегистрируйтесь</ALink>, если еще не пользовались
          нашим сервисом
        </ATypographyText>
      </div>
    </template>
  </AFlex>
  <div :style="{ height: `${logoSize}px`, marginTop: '16px' }" />
</template>
