<script setup lang="ts">
import { computed } from "vue"
import { useToggle } from "@vueuse/core"

import { useTurnover } from "../hooks/use-turnover"

const { data, loading: isLoading, error, refreshAsync } = useTurnover()
const errorMessage = computed(() =>
  error.value
    ? "Не удалось загрузить информацию, пожалуйста повторите попытку или обратитесь в поддержку"
    : null
)
const options = computed(() =>
  data.value && errorMessage.value === null
    ? data.value.map(({ id, name }) => ({
        value: id,
        label: name
      }))
    : []
)
const [isOpened, toggleOpen] = useToggle(false)
const retryAction = () => {
  toggleOpen()
  setTimeout(refreshAsync, 500)
}
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    placeholder="Выберите размер ежемесячного оборота"
    :loading="isLoading"
    :options="options"
    :status="errorMessage === null ? undefined : 'warning'"
    @dropdownVisibleChange="() => toggleOpen()"
  >
    <template #notFoundContent>
      <AEmpty>
        <template #description> {{ errorMessage }}</template>
        <AButton type="text" @click.stop="retryAction"> Повторить </AButton>
      </AEmpty>
    </template>
  </ASelect>
</template>
