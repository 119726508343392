<script setup lang="ts">
import { computed, h, onUnmounted, ref } from "vue"
import { DashOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { noop, useInfiniteScroll } from "@vueuse/core"
import { type TableProps } from "ant-design-vue"
import { push } from "notivue"

import {
  CreateBtnActionText,
  CreateBtnActionTextDefault,
  type OperationCategory,
  type OperationCategoryListingElement
} from "@/modules/operation-category"
import type { Counterparty } from "@/modules/counterparty"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useOperationCategoriesStore } from "@/modules/operation-category"

import { useColumns } from "./use-columns"

const emit = defineEmits(["openDetail", "openEdit", "openCreate"])
const isAdoptable = useAdoptable()
const { columns } = useColumns()

const customRow: TableProps["customRow"] = (data: Counterparty) => ({
  onClick: () => {
    emit("openDetail", data.id)
  }
})

const store = useOperationCategoriesStore()

const expandedKeys = ref(new Array<number>())
const expandAll = () => {
  expandedKeys.value = Array.from(store.idList)
}
const shrinkAll = () => {
  expandedKeys.value = []
}
const isExpanded = computed(() => expandedKeys.value.length > 0)
const expandAllToggle = () => {
  if (isExpanded.value) {
    shrinkAll()
  } else {
    expandAll()
  }
}

const tableWrapper = ref<HTMLElement | null>(null)
const stickyConfig = {
  offsetHeader: 0,
  offsetScroll: 0,
  getContainer: () => tableWrapper.value
}

useInfiniteScroll(tableWrapper, () => store.loadNextPart(), {
  distance: 1000,
  interval: 2000,
  canLoadMore: () => !store.isLoadedFull && store.listingApiError === null
})
const deleteAction = (record: OperationCategoryListingElement) => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    centered: true,
    onOk: () => {
      store
        .deleteOperationCategories(record)
        .then(() => {
          push.success({ message: "Удалено" })
        })
        .catch(noop)
    }
  })
}

onUnmounted(store.$reset)
defineExpose({
  expandAll,
  shrinkAll,
  expandAllToggle,
  isExpanded
})
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%' }">
    <ATable
      v-model:expanded-row-keys="expandedKeys"
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="stickyConfig"
      class="custom-table"
      :loading="store.isListingLoading"
      :columns="columns"
      :data-source="store.listingTree"
      :show-header="!isAdoptable"
      :pagination="false"
      row-key="id"
      :custom-row="customRow"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <ADropdown
            :overlay-style="{ zIndex: 100 }"
            placement="bottomRight"
            arrow
            :trigger="['hover']"
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (record as OperationCategory).id)"
                >
                  Редактировать
                </AMenuItem>
                <AMenuItem
                  danger
                  :disabled="store.isDeleting"
                  :icon="h(DeleteOutlined)"
                  @click="() => deleteAction(record as OperationCategoryListingElement)"
                >
                  Удалить
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder
          :create-text="CreateBtnActionText[store.actionType] ?? CreateBtnActionTextDefault"
          show-create-button
          @openCreate="() => emit('openCreate')"
        />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon) {
  border: 1px solid black;
  border-radius: 0;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon:before),
:deep(.ant-table-wrapper .ant-table-row-expand-icon:after) {
  color: black;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}
</style>
