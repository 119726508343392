import type { Profile } from "../interfaces"

import { useRequest } from "vue-request"

import { type ApiSuccessResponse, client } from "@/package/api-client"

export const useProfile = () => {
  const { data, loading, refreshAsync, error } = useRequest<Profile | undefined>(
    () =>
      client
        .get<ApiSuccessResponse<Profile>>("/users/profile")
        .then((response) => response.data.data),
    {
      cacheKey: "profile",
      manual: true
    }
  )

  return {
    error,
    profile: data,
    isLoading: loading,
    refreshAsync
  }
}
