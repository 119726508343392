import { ref } from "vue"

export const useRuleset = () => {
  const ruleset: any = ref({
    name: [{ required: true, message: "Пожалуйста, введите название", whitespace: true }],
    type: [{ required: true, message: "Пожалуйста, введите тип" }],
    comment: [
      {
        type: "string",
        max: 4096,
        message: "Превышено допустимое количество символов"
      }
    ]
  })

  return ruleset
}
