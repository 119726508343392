import type { LoginResponse } from "../interfaces"

import { cleanClient } from "@/package/api-client"

export const refreshPost = (refresh_token: string) =>
  cleanClient
    .post<LoginResponse>("/oauth/token", {
      grant_type: "refresh_token",
      refresh_token
    })
    .then(({ data }) => data)
