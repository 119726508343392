<script setup lang="ts">
import type { TableProps } from "ant-design-vue"
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { h, onBeforeMount, onUnmounted, ref, computed } from "vue"
import { DashOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { noop, useInfiniteScroll } from "@vueuse/core"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import type { Counterparty } from "@/modules/counterparty"
import { useCounterpartiesStore } from "@/modules/counterparty"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useBufferSelection } from "@/package/hooks"

import { useColumns } from "./use-columns"

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["openDetail", "openEdit", "openCreate"])
const { columns } = useColumns()
const isAdoptable = useAdoptable()

const store = useCounterpartiesStore()
const { idList, paginationTotal } = storeToRefs(store)
const {
  isAllSelected,
  selectedCount,
  selectedIdList,
  onSelect,
  onSelectAll,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys
} = useBufferSelection(idList, paginationTotal)
const tableWrapper = ref<HTMLElement | null>(null)
useInfiniteScroll(tableWrapper, () => store.loadNextPart(), {
  distance: 1000,
  interval: 2000,
  canLoadMore: () =>
    !store.isLoadedFull && store.listingApiError === null && !store.isSortingLoading
})

const customRow: TableProps["customRow"] = (data: Counterparty) => ({
  onClick: () => {
    emit("openDetail", data.id)
  }
})
const deleteAction = (id: number) => {
  DeleteConfirm({
    content:
      "Вы действительно хотите безвозвратно удалить запись? Этот контрагент используется в товарах и услугах",
    centered: true,
    onOk: () =>
      store
        .deleteCounterparty(id)
        .then(() => {
          push.success({ message: "Удалено" })
        })
        .catch(noop)
  })
}

const isShowCreateBtn = computed(() => {
  return !props.disabled ? store.isShowBtn : false
})

onBeforeMount(async () => {
  await store.getCounterpartiesSorting()
})

defineExpose({
  unselectAllAction,
  selectedCount,
  isAllSelected,
  toggleAllSelected,
  selectedIdList,
  excludeKeysMode,
  currentModeKeys
})

onUnmounted(store.$reset)
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%', scrollbarGutter: 'stable' }">
    <ATable
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="{
        offsetHeader: 0,
        offsetScroll: 0,
        getContainer: () => tableWrapper
      }"
      class="custom-table"
      :loading="store.isListingLoading"
      :columns="columns"
      :data-source="store.listing"
      :show-header="!isAdoptable"
      :row-selection="{
        columnWidth: 32,
        onChange: onSelect,
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedIdList,
        onSelectAll
      }"
      :pagination="false"
      row-key="id"
      :custom-row="customRow"
      :show-sorter-tooltip="false"
      @change="
        async (pagination: any, filters: any, sorter: SorterResult) => {
          await store.setSortOrder(sorter)
          await store.saveCounterpartiesSorting()
        }
      "
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <ADropdown
            :overlay-style="{ zIndex: 100 }"
            placement="bottomRight"
            arrow
            :trigger="['hover']"
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (record as Counterparty).id)"
                >
                  Редактировать
                </AMenuItem>
                <AMenuItem
                  danger
                  :disabled="store.isDeleting"
                  :icon="h(DeleteOutlined)"
                  @click="() => deleteAction((record as Counterparty).id)"
                >
                  Удалить
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder
          create-text="Создать контрагента"
          :show-create-button="isShowCreateBtn"
          @openCreate="() => emit('openCreate')"
        />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}
</style>
