import type { Dayjs } from "dayjs"

export interface SummaryStatisticsFilter {
  date: Array<Dayjs>
  bankAccountId: string | undefined
  legalEntityId: number | undefined
  projectId: string | undefined
  operationCategories: Array<string | number>
  allCategories: boolean
  selectedMainVariants: Array<keyof SummaryStatistics>
  selectedRevenueVariants: Array<keyof SummaryStatistics>
}

export enum StatisticsDetalization {
  month = "month",
  day = "day"
}

export interface SummaryStatistics {
  income: Array<number | null>
  incomePlan: Array<number | null>
  outlay: Array<number | null>
  outlayPlan: Array<number | null>
  revenue: Array<number | null>
  revenuePlan?: Array<number | null>
  revenueIncrease?: Array<number | null>
  revenuePlanIncrease?: Array<number | null>
  abscissa: Array<number | Date>
}

export type OperationsStatistics = Pick<
  SummaryStatistics,
  "income" | "incomePlan" | "outlayPlan" | "outlay" | "abscissa"
>
export type RevenueStatistics = Pick<
  SummaryStatistics,
  "revenue" | "revenueIncrease" | "revenuePlan" | "revenuePlanIncrease" | "abscissa"
>

export interface SummaryStatisticsResponse extends SummaryStatistics {
  detalization: StatisticsDetalization
}

export interface SaveFiltersData {
  date_from: string | undefined
  date_to: string | undefined
  bank_account_id: number | string | undefined
  project_id: number | string | undefined
  legal_entity_id: number | string | undefined
  selectedMainVariants: Array<keyof SummaryStatistics> | undefined
  selectedRevenueVariants: Array<keyof SummaryStatistics> | undefined
  operationCategories: Array<string | number> | undefined
}
