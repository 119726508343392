import { client } from "@/package/api-client"
import type { OkvedData } from "@/modules/legal-entity"
interface LegalDataResponse {
  success: boolean
  data: OkvedData
}
export const okvedGet = (id: string | number) =>
  client
    .get<LegalDataResponse>("/legal-entities/okved", { params: { id } })
    .then(({ data }) => data.data)
