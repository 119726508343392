<script setup lang="ts">
import { useRouter } from "vue-router"
import { computed } from "vue"
import { TypographyParagraph } from "ant-design-vue"
import { get } from "@vueuse/core"

import { useRegisterComplete } from "@/modules/auth"
import { loginRoute } from "@/router"

defineProps({
  logoSize: {
    type: Number,
    default: 0
  }
})

const router = useRouter()

const { isLoading, errorMessage, dataValidationError } = useRegisterComplete()

const confirmDataError = computed(() => {
  const errorsString = get(dataValidationError)
    ?.map(({ message }) => message)
    .join(", ")
  return errorsString
    ? `Не удалось произвести подтверждение: ${errorsString}. Пожалуйста откройте ссылку повторно или обратитесь в поддержку.`
    : null
})
</script>

<template>
  <AFlex vertical :style="{ flexGrow: 1 }" justify="center">
    <template v-if="isLoading">
      <ASpin size="large" tip="Проверка учетных данных, пожалуйста, оставайтесь на странице." />
    </template>
    <template v-else>
      <template v-if="errorMessage === null && confirmDataError === null">
        <ATypographyTitle :style="{ marginBottom: '8px', textAlign: 'center' }">
          Регистрация завершена
        </ATypographyTitle>
        <AText :style="{ textAlign: 'center' }" variant="lg" :el="TypographyParagraph">
          Поздравляем! Регистрация была успешно завершена. Вы можете авторизоваться и начать
          пользоваться нашим сервисом прямо сейчас.
        </AText>
        {{ confirmDataError }}
      </template>
      <template v-else>
        <ATypographyTitle :style="{ marginBottom: '8px', textAlign: 'center' }"
          >Регистрация не завершена
        </ATypographyTitle>
        <AText :style="{ textAlign: 'center' }" variant="lg" :el="TypographyParagraph"
          >Не удалось подтвердить регистрацию
        </AText>
        <AAffix :offset-top="12">
          <AAlert type="error" class="shake" :message="errorMessage || confirmDataError" show-icon
        /></AAffix>
      </template>
      <AButton
        type="primary"
        html-type="submit"
        block
        size="large"
        :style="{ marginTop: '48px' }"
        @click="() => router.push({ name: loginRoute })"
      >
        На страницу авторизации
      </AButton>
    </template>
  </AFlex>
  <div :style="{ height: `${logoSize}px`, marginTop: '16px' }" />
</template>
