<script setup lang="ts">
import { computed, type PropType } from "vue"
import { theme, TypographyLink } from "ant-design-vue"

defineProps({
  to: {
    type: [String, Object, undefined] as PropType<
      string | { name: string; params?: any } | undefined
    >,

    default: () => undefined
  },

  variant: {
    type: String as PropType<"sm" | "lg" | "xl" | "base">,
    validator: (value: string) => ["sm", "lg", "xl", "base"].includes(value),
    default: "base"
  }
})
defineOptions({
  inheritAttrs: false
})
const token = theme.useToken()
const fontSize = computed(() => ({
  xl: `${token.token.value.fontSizeXL}px`,
  sm: `${token.token.value.fontSizeSM}px`,
  lg: `${token.token.value.fontSizeLG}px`
}))
</script>

<template>
  <TypographyLink
    v-if="to === undefined"
    v-bind="$attrs"
    :class="{
      ['ant-custom-link--xl']: variant === 'xl',
      ['ant-custom-link--sm']: variant === 'sm',
      ['ant-custom-link--lg']: variant === 'lg'
    }"
  >
    <slot />
  </TypographyLink>
  <RouterLink v-else v-slot="{ href, navigate }" custom :to="to">
    <TypographyLink
      :href="href"
      v-bind="$attrs"
      :class="{
        ['ant-custom-link--xl']: variant === 'xl',
        ['ant-custom-link--sm']: variant === 'sm',
        ['ant-custom-link--lg']: variant === 'lg'
      }"
      @click="navigate"
    >
      <slot />
    </TypographyLink>
  </RouterLink>
</template>

<style>
.ant-custom-link--xl {
  font-size: v-bind("fontSize.xl");
}

.ant-custom-link--sm {
  font-size: v-bind("fontSize.sm");
  line-height: v-bind("token.token.value.lineHeightSM");
}

.ant-custom-link--lg {
  font-size: calc(v-bind("fontSize.lg"));
  line-height: v-bind("token.token.value.lineHeightLG");
}
</style>
