import type { SubscriptionEditLink } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useChangeSubscriptionPayment = () => {
  const { execute, data, isLoading, error } = useAxios<SubscriptionEditLink>(
    "/subscription/change-payment",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getChangingPaymentLink = async () => {
    return await execute("/subscription/change-payment")
  }

  return {
    changingPaymentLink: data,
    getChangingPaymentLink,
    ChangingPaymentLinkPending: isLoading,
    ChangingPaymentLinkError: useApiError(error)
  }
}
