import type { TaxpayerCategoryCode } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

interface TaxpayerCategoryCodeResponse {
  success: boolean
  data: Array<TaxpayerCategoryCode>
}

export const useTaxpayerCategoryCode = () => {
  const { data, isLoading, error, execute } = useAxios<TaxpayerCategoryCodeResponse>(
    "/reference/taxpayer-categories",
    { method: "GET" },
    client,
    { immediate: false }
  )
  const getTaxpayerCategoryCode = async () => {
    return await execute("/reference/taxpayer-categories")
  }

  return {
    taxpayerCategoryCode: data,
    taxpayerCategoryCodePending: isLoading,
    error,
    getTaxpayerCategoryCode
  }
}
