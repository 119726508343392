import type { CancelSubscriptionData } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useCancelSubscription = () => {
  const { execute, data, isLoading, error } = useAxios<CancelSubscriptionData>(
    "/subscription/cancel",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const cancelSubscription = async () => {
    return await execute("/subscription/cancel")
  }

  return {
    cancelStatus: data,
    cancelSubscription,
    CancelSubscriptionPending: isLoading,
    CancelSubscriptionError: useApiError(error)
  }
}
