<script setup lang="ts">
import { computed } from "vue"

import { useBpMax } from "@/package/ui-kit"

import { RegistrantType } from "../interfaces"

const RegistrantTypeNames: Record<RegistrantType, string> = {
  [RegistrantType.PhysicalEntity]: "Физическое лицо",
  [RegistrantType.LegalEntity]: "Юридическое лицо"
}
const options = computed(() =>
  Object.values(RegistrantType).map((key) => ({
    label: RegistrantTypeNames[key],
    value: key
  }))
)
const isMobile = useBpMax("xs")
</script>

<template>
  <ARadioGroup
    :style="{ justifyContent: 'center', display: 'flex' }"
    button-style="solid"
    :options="options"
    option-type="button"
    :size="isMobile ? 'small' : 'large'"
  />
</template>
