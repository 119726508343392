import { InfoModal } from "@/package/ui-kit"

export const showRepeatPeriodModal = () =>
  InfoModal({
    title: "Повторение операций",
    content: "Установить повторение для данной операции"
  })

export const showFormatModal = () =>
  InfoModal({
    title: "Поддерживаемые форматы",
    content: "pdf, doc, docx, xls, xlsx, jpeg, png, zip, rar, txt, csv, xml"
  })
