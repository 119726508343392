<script setup lang="ts">
import { Mask } from "maska"
import { computed, useSlots } from "vue"

const props = defineProps({
  showOnFocus: {
    type: Boolean,
    default: false
  },

  placeholder: {
    type: String,
    default: () => "+7 (999) 999-99-99"
  }
})
const phone = defineModel({
  type: String
})
const DEFAULT_VALUE = "+7 ("
const mask = new Mask({ mask: "+7 (###) ###-##-##" })

const phoneVal = computed<string | undefined>({
  get: () => (phone.value ? mask.masked(phone.value) : phone.value),
  set(newValue) {
    phone.value = mask.masked(newValue ?? DEFAULT_VALUE)
  }
})
const onFocus = () => {
  if (props.showOnFocus) {
    if (mask.unmasked(phoneVal.value ?? "").length === 0) {
      phoneVal.value = DEFAULT_VALUE
    }
  }
}
const slots = useSlots()
</script>

<template>
  <AInput v-model="phoneVal" :placeholder="placeholder" @focus="onFocus">
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </AInput>
</template>
