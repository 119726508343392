<script setup lang="ts">
import { type PropType, ref } from "vue"
import { UseElementSize } from "@vueuse/components"
import plural from "plural-ru"

import { renderPlaceholder, useMobileBp } from "@/package/ui-kit"
import type { OperationDetailDisplayTransaction } from "@/modules/operation"
import { formatThousands } from "@/package/util"

defineProps({
  splits: {
    type: [Object, undefined] as PropType<OperationDetailDisplayTransaction[] | undefined>,
    default: () => undefined
  },

  amount: {
    type: Number,
    default: () => undefined
  }
})

const activeKey = ref<Array<string>>([])

const splitsTableColumns = [
  {
    title: "Статья",
    key: "category",
    dataIndex: "category",
    ellipsis: { showTitle: true },
    width: 100,
    customRender: renderPlaceholder
  },
  {
    title: "Контрагент",
    key: "counterparty",
    dataIndex: "counterparty",
    ellipsis: { showTitle: true },
    width: 100,
    customRender: renderPlaceholder
  },
  {
    title: "Проект",
    key: "project",
    dataIndex: "project",
    ellipsis: { showTitle: true },
    width: 100,
    customRender: renderPlaceholder
  },
  {
    title: "Сумма, руб",
    key: "amount",
    dataIndex: "amount",
    ellipsis: { showTitle: true },
    width: 100,
    customRender: renderPlaceholder
  }
]
const isMobile = useMobileBp()
</script>

<template>
  <p style="margin: 0">{{ `${formatThousands(amount)} руб` }}</p>
  <AButton
    v-if="splits && splits?.length > 1"
    type="link"
    style="padding: 0"
    @click="() => (activeKey = activeKey.length > 0 ? [] : ['1'])"
    >{{ activeKey.includes("1") ? "Скрыть" : "Показать" }} разделение суммы ({{ splits.length }}
    {{ plural(splits?.length, "операция", "операции", "операций") }})</AButton
  >
  <ACollapse
    v-if="splits && splits?.length > 1"
    :active-key="activeKey"
    ghost
    class="split-collapse"
  >
    <ACollapsePanel key="1" :show-arrow="false">
      <UseElementSize v-slot="{ width, height }" :height="0" :width="0">
        <ATable
          :columns="splitsTableColumns"
          :data-source="splits"
          :pagination="false"
          :scroll="isMobile ? { x: width, y: height } : undefined"
        />
      </UseElementSize>
    </ACollapsePanel>
  </ACollapse>
</template>

<style>
.split-description-item .ant-collapse .ant-collapse-item .ant-collapse-header {
  display: none;
}
</style>

<style scoped>
:deep(.ant-collapse-header),
:deep(.ant-collapse-content-box) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-descriptions-item-container .ant-collapse-header {
  display: none;
}
</style>
