import { noop } from "@vueuse/core"
import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { LegalEntityInfoFields } from "@/modules/profile"

export const useLegalEntityEdit = () => {
  const { execute, isLoading, error } = useAxios(
    "/data-legal-entity/upsert",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )
  const editProfile = (data: LegalEntityInfoFields, onSuccess: typeof noop) =>
    execute({ data }).then(onSuccess).catch(noop)

  return {
    error: useApiError(error),
    isLoading,
    editProfile
  }
}
