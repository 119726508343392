import { useAxios } from "@vueuse/integrations/useAxios"

import type { OperationCategoryEdit } from "@/modules/operation-category"
import { client, useApiError } from "@/package/api-client"

export const useOperationCategoryEdit = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean; operation_category_id: number },
    { data: OperationCategoryEdit }
  >("/operation-categories", { method: "PATCH" }, client, { immediate: false })

  const transformFormDataToRequest = (data: OperationCategoryEdit): OperationCategoryEdit => ({
    name: data.name,
    type: data.type || null,
    parent_id: data.parent_id || null,
    comment: data.comment || null,
    action_type: data.action_type,
    id: data.id,
    is_taxable: data.is_taxable ?? null
  })

  const editOperationCategory = async (formData: OperationCategoryEdit) => {
    const data = transformFormDataToRequest(formData)
    await execute(`/operation-categories/${formData.id}`, { data })
  }

  return {
    editOperationCategory,
    isEditing: isLoading,
    editError: useApiError(error),
    isEditingFinished: isFinished
  }
}
