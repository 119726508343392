import type { CreateCounterparty } from "src/modules/counterparty"

import { computed, reactive } from "vue"

export const useFormState = () => {
  const formState = reactive<CreateCounterparty>({
    name: "",
    contact_person: "",
    email: "",
    phone: "",
    comment: ""
  })
  const requestData = computed<CreateCounterparty>(() => {
    const { name, contact_person, email, phone, comment } = formState

    return {
      name,
      contact_person,
      email,
      phone,
      comment
    }
  })

  return {
    formState,
    requestData
  }
}
