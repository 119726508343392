import type { Product } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"
import { type MaybeRef } from "vue"

import { client, useApiError, useApiResponseStatus } from "@/package/api-client"
import { SortOrder } from "@/interfaces"

interface ReferenceListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: Product[]
}

export const useProductsList = () => {
  const { execute, isLoading, error, data, response } = useAxios<ReferenceListResponse>(
    "/products",
    { method: "GET" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const listingApiError = useApiError(error)
  const listingResponseStatus = useApiResponseStatus(response, error)

  const requestListing = (
    params: MaybeRef<{
      offset?: number
      limit?: number
      query?: string
      orderBy?: string
      order?: SortOrder
    }>
  ) => execute({ params })

  return {
    listingApiError,
    listingResponseStatus,
    isListingLoading: isLoading,
    productListingResponse: data,
    requestListing
  }
}
