import type { FormValidator } from "./types"

export const isSomeCyrillic = (val: string) => /[а-яА-ЯёЁ]+/gm.test(val)
export const isFullyCyrillic = (val: string) => /^[а-яА-ЯёЁ]+$/gm.test(val)

export const cyrillicValidator: FormValidator = ({ required }, value) =>
  new Promise((resolve, reject) => {
    if (!value && !required) {
      resolve(undefined)
    }
    if (isFullyCyrillic(value)) {
      resolve(undefined)
    } else {
      reject("Введены недопустимые символы")
    }
  })
