import type { Ref } from "vue"
import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { h, ref, watch } from "vue"
import { Flex, TypographyText } from "ant-design-vue"

import { AText, useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import type { OperationListingElement } from "@/modules/operation"
import { ListingElementType } from "@/modules/operation"

const defaultSizes = {
  date: 200,
  bank_account_name: 200,
  type: 200,
  action: 200,
  counterparty_name: 200,
  category_name: 200,
  amount: 200,
  user_action: 50
}
const adoptedColumns = [
  {
    key: "id",
    customRender: ({ record }: { record: OperationListingElement }) =>
      record.elementType === ListingElementType.divider
        ? h(AText, {
            style: { whiteSpace: "nowrap", fontSize: "16px", fontWeight: "700" },
            content: record.date
          })
        : h(Flex, { vertical: true }, () => [
            h("div", { style: { marginTop: "8px" } }, [
              h(Flex, { style: { width: "100%" }, justify: "space-between" }, () => [
                h(TypographyText, {
                  strong: true,
                  ellipsis: true,
                  content: record.bank_account_name
                }),
                h(AText, {
                  class: `operation-amount--${record.action}`,
                  style: {
                    whiteSpace: "nowrap",
                    marginLeft: "8px"
                  },
                  content: record.amount
                })
              ]),
              h(
                Flex,
                { style: { width: "100%", marginTop: "8px" }, justify: "space-between" },
                () => [
                  h(AText, {
                    type: "secondary",
                    ellipsis: true,
                    content: viewValueOrDefault(record.counterparty?.name)
                  }),
                  h(AText, {
                    type: "secondary",
                    style: { whiteSpace: "nowrap", marginLeft: "8px" },
                    content: record.date
                  })
                ]
              )
            ])
          ])
  }
]
const customRender: ColumnsType[0]["customRender"] = ({ value, record }) =>
  record.elementType === ListingElementType.divider ? undefined : viewValueOrDefault(value)

const defaultColumns: ColumnsType<any> = [
  {
    title: "Дата",
    key: "date",
    dataIndex: "date",
    resizable: true,
    width: defaultSizes.date,
    ellipsis: { showTitle: true },
    customRender
  },
  {
    title: "Счет",
    key: "bank_account_name",
    dataIndex: "bank_account_name",
    resizable: true,
    width: defaultSizes.bank_account_name,
    ellipsis: { showTitle: true },
    customRender
  },
  {
    title: "Тип",
    key: "action",
    dataIndex: "action",
    resizable: true,
    width: defaultSizes.action,
    ellipsis: { showTitle: true },
    customRender
  },
  {
    title: "Вид операции",
    key: "type",
    dataIndex: "type",
    resizable: true,
    width: defaultSizes.type,
    ellipsis: { showTitle: true },
    customRender
  },
  {
    title: "Контрагент",
    key: "counterparty_name",
    dataIndex: "counterparty_name",
    resizable: true,
    width: defaultSizes.counterparty_name,
    ellipsis: { showTitle: true },
    customRender
  },
  {
    title: "Статья",
    key: "category_name",
    dataIndex: "category_name",
    resizable: true,
    width: defaultSizes.category_name,
    ellipsis: { showTitle: true },
    customRender
  },
  {
    title: "Сумма, руб",
    key: "amount",
    dataIndex: "amount",
    resizable: false,
    width: defaultSizes.amount,
    colSpan: 2,
    align: "left",
    ellipsis: { showTitle: true },
    customRender
  },
  {
    title: "",
    key: "user_action",
    resizable: false,
    width: 58
  }
]
export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
