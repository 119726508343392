import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { computed, type Ref } from "vue"
import { h, ref, watch } from "vue"
import { Flex } from "ant-design-vue"

import { type LegalEntitySummary, useLegalEntityStore } from "@/modules/legal-entity"
import { AText, renderPlaceholder, viewValueOrDefault, useAdoptable } from "@/package/ui-kit"
import { SortOrder, SortOrderInternal } from "@/interfaces"

const defaultSizes = {
  organization_name: 245,
  itn: 120,
  bic: 100,
  account_number: 245,
  action: 64
}
const adoptedColumns = [
  {
    key: "id",
    customRender: ({ record }: { record: LegalEntitySummary }) =>
      h(Flex, { vertical: true }, () => [
        h(AText, {
          variant: "lg",
          content: viewValueOrDefault(record.organization_name),
          ellipsis: true
        }),
        h(Flex, { style: { marginTop: "8px", gap: "16px" }, wrap: "wrap" }, [
          h(AText, { variant: "lg", type: "secondary", content: "ИНН" }),
          h(AText, {
            variant: "lg",
            content: viewValueOrDefault(record.itn),
            ellipsis: true
          })
        ])
      ])
  }
]
const defaultColumns = (): ColumnsType<any> => {
  const store = useLegalEntityStore()

  return [
    {
      title: "Наименование",
      key: "organization_name",
      dataIndex: "organization_name",
      ellipsis: { showTitle: true },
      width: "max-content",
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder) {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "ИНН",
      key: "itn",
      dataIndex: "itn",
      ellipsis: { showTitle: true },
      width: "max-content",
      customRender: renderPlaceholder
    },
    {
      title: "БИК",
      key: "bic",
      dataIndex: "bic",
      ellipsis: { showTitle: true },
      width: "max-content",
      customRender: renderPlaceholder
    },
    {
      title: "Рассчетный счет",
      key: "account_number",
      dataIndex: "account_number",
      ellipsis: { showTitle: true },
      colSpan: 2,
      align: "left",
      width: "max-content",
      customRender: renderPlaceholder
    },
    {
      title: "",
      key: "action",
      width: defaultSizes.action
    }
  ]
}
export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns()
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
