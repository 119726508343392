<script setup lang="ts">
import type { ApexOptions } from "apexcharts"

import { computed, type PropType, ref, watch } from "vue"
import VueApexChartsComponent from "vue3-apexcharts"
import { theme } from "ant-design-vue"
import { get, set, useDebounceFn, useElementSize } from "@vueuse/core"
import dayjs from "dayjs"

import { APHEX_CHART_LOCALIZATION, useAdoptable } from "@/package/ui-kit"
import { SamoDecimal } from "@/package/util"

import { type RevenueStatistics, StatisticsDetalization } from "../interface"

const props = defineProps({
  data: {
    type: [Object, undefined] as PropType<RevenueStatistics | undefined>,
    default: () => undefined
  },

  showRevenue: {
    type: Boolean,
    default: true
  },

  showRevenueIncrase: {
    type: Boolean,
    default: true
  },

  isLoading: {
    type: Boolean,
    default: false
  },

  errorMessage: {
    type: [String, null] as PropType<string | null>,
    default: null
  },

  detalization: {
    type: String as PropType<StatisticsDetalization>,
    default: StatisticsDetalization.month
  }
})
const isAdoptable = useAdoptable()
const isEmpty = computed(() => {
  let result
  if (props.data === undefined) {
    result = true
  } else {
    const {
      isEmptyRevenue,
      isEmptyRevenuePlan,
      isEmptyRevenueIncrease,
      isEmptyRevenueIncreasePlan
    } = {
      isEmptyRevenue: props.data.revenue.filter(Boolean).length === 0,
      isEmptyRevenuePlan: (props.data.revenuePlan ?? []).filter(Boolean).length === 0,
      isEmptyRevenueIncrease: (props.data.revenueIncrease ?? []).filter(Boolean).length === 0,
      isEmptyRevenueIncreasePlan:
        (props.data.revenuePlanIncrease ?? []).filter(Boolean).length === 0
    }
    result =
      isEmptyRevenue && isEmptyRevenuePlan && isEmptyRevenueIncrease && isEmptyRevenueIncreasePlan
  }

  return result
})
const { token } = theme.useToken()
const seriesNamingDict = computed(() => {
  const result = []
  if (props.showRevenue) {
    result.push("Чистая прибыль")
    result.push("Чистая прибыль, план")
  }
  if (props.showRevenueIncrase) {
    result.push("Чистая прибыль, нараст.")
    result.push("Чистая прибыль, нараст., план")
  }
  return result
})
const series = computed((): ApexOptions["series"] => {
  const result = []
  if (props.data) {
    if (props.showRevenue) {
      result.push(
        {
          name: "ЧП, факт",
          type: "line",
          data: props.data.revenue,
          color: "#0000FF"
        },
        {
          name: "ЧП, план",
          type: "line",
          data: props.data.revenuePlan ?? [],
          color: "#9999f9"
        }
      )
    }
    if (props.showRevenueIncrase) {
      result.push(
        {
          name: "ЧП с нарастающим, факт",
          type: "line",
          data: props.data.revenueIncrease ?? [],
          color: "#6AA84F"
        },
        {
          name: "ЧП с нарастающим, план",
          type: "line",
          data: props.data.revenuePlanIncrease ?? [],
          color: "#81da5a"
        }
      )
    }
  }
  return result
})
const strokeConfig = computed(() => {
  const result = {
    colors: new Array<string>(),
    width: new Array<number>(),
    dashArray: new Array<number>()
  }
  if (props.showRevenue) {
    result.colors.push("#0000FF", "#6767ff")
    result.width.push(2, 2)
    result.dashArray.push(0, 10)
  }
  if (props.showRevenueIncrase) {
    result.colors.push("#6AA84F", "#81da5a")
    result.width.push(2, 2)
    result.dashArray.push(0, 10)
  }
  return result
})
const options = computed(
  (): ApexOptions => ({
    stroke: {
      show: true,
      curve: "smooth",
      colors: get(strokeConfig, "colors"),
      width: get(strokeConfig, "width"),
      dashArray: get(strokeConfig, "dashArray")
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: "Чистая прибыль",
      align: "left",
      offsetY: -5,
      style: {
        fontSize: "18px",
        fontFamily: token.value.fontFamily,
        fontWeight: 600
      }
    },
    grid: {
      show: false
    },
    markers: {
      size: 4
    },
    yaxis: {
      crosshairs: {
        show: false
      },
      tickAmount: 10,
      labels: {
        formatter: (value: number) => SamoDecimal(value, false, true).format()
      }
    },
    xaxis: {
      crosshairs: {
        show: false
      },
      categories: props.data?.abscissa ?? [],
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0
      },
      labels: {
        rotateAlways: true,
        rotate: -45,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm"
        },
        formatter: (_, timestamp: number) =>
          dayjs(timestamp).format(
            props.detalization === StatisticsDetalization.month ? "MMMM" : "D MMMM"
          )
      }
    },
    legend: {
      position: "top",
      horizontalAlign: get(isAdoptable) ? "left" : "right",
      offsetY: get(isAdoptable) ? 0 : -38,
      onItemClick: {
        toggleDataSeries: false
      }
    },
    chart: {
      toolbar: {
        show: false
      },
      ...APHEX_CHART_LOCALIZATION
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
        const seriesName = get(seriesNamingDict, seriesIndex)
        const month = dayjs(w.globals.labels[dataPointIndex]).format(
          props.detalization === StatisticsDetalization.month ? "MMMM" : "D MMMM"
        )
        const value = SamoDecimal(series[seriesIndex][dataPointIndex], false, true).format()

        return `<div class="summary-statistitcs-chart__tooltip">
          <span class="summary-statistitcs-chart__tooltip-title">${seriesName} за ${month}</span>
          <span class="summary-statistitcs-chart__tooltip-value">${value} руб</span>
        </div>`
      }
    }
  })
)

const wrapperRef = ref<HTMLElement | null>(null)
const { width: wrapperWidth, height: wrapperHeight } = useElementSize(wrapperRef)
const chartWidth = computed(() => (get(wrapperWidth) <= 900 ? 900 : "100%"))
const chartHeight = ref(0)
const setupChartHeightByWrapper = useDebounceFn(
  () => set(chartHeight, get(wrapperHeight) - 35),
  500
)

watch(
  [wrapperHeight, isEmpty],
  () => {
    setupChartHeightByWrapper()
  },
  { immediate: true }
)
</script>

<template>
  <div
    ref="wrapperRef"
    class="summary-statistitcs-chart"
    :class="{ 'summary-statistitcs-chart--error': errorMessage !== null }"
  >
    <template v-if="errorMessage === null">
      <template v-if="isEmpty">
        <AEmptyListingPlaceholder class="summary-statistitcs-chart__emptyplaceholder" />
      </template>
      <template v-else>
        <ASpin
          v-if="isLoading || chartHeight === 0"
          size="large"
          class="summary-statistitcs-chart__loader"
        />
        <VueApexChartsComponent
          v-else
          :key="`${chartHeight}-${chartWidth}`"
          type="bar"
          :options="options"
          :series="series"
          :height="chartHeight"
          :width="chartWidth"
        />
      </template>
    </template>
    <template v-else>
      <AAlert type="error" class="shake summary-statistitcs-chart__error" :message="errorMessage" />
    </template>
  </div>
</template>

<style scoped>
.summary-statistitcs-chart {
  overflow: auto;
  padding: 24px 14px 0;
  border-radius: 8px;
  border-color: v-bind("token.colorBorder");
  border-style: solid;
  border-width: 1px;
  min-height: 500px;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
}

.summary-statistitcs-chart--error::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: v-bind("token.colorBgContainerDisabled");
}

.summary-statistitcs-chart__error {
  margin: auto;
}

.summary-statistitcs-chart__loader {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 10px);
  transform: translate(-50%, -50%);
}

.summary-statistitcs-chart__emptyplaceholder {
  width: 100%;
}

:deep(.vue-apexcharts) {
  justify-self: stretch;
  align-self: flex-start;
  flex-grow: 1;
}

:deep(.apexcharts-title-text) {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: v-bind("token.fontFamily") !important;
}

:deep(.apexcharts-menu-item) {
  text-align: left;
}

:deep(.apexcharts-xaxis text) {
  text-transform: capitalize;
}

:deep(.summary-statistitcs-chart__tooltip-title) {
  font-size: 14px;
  line-height: 22px;
  color: v-bind("token.colorTextTertiary");
}

:deep(.summary-statistitcs-chart__tooltip-value) {
  font-size: 14px;
  line-height: 22px;
}

:deep(.summary-statistitcs-chart__tooltip) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
</style>
