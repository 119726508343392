import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { push } from "notivue"

import type { SortingDTO } from "@/interfaces"
import { ApiBadReqResponse, client } from "@/package/api-client"

export const useSaveProductSorting = () => {
  const { execute, isLoading, error } = useAxios<
    { success: string; data: Partial<SortingDTO> },
    { ids: Array<number> }
  >("/parameters/product-sorting", { method: "PATCH" }, client, {
    immediate: false
  })

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  watch(errorMessage, () => {
    if (errorMessage.value !== null) {
      push.error({ message: errorMessage.value })
    }
  })

  const executeSave = async (payload: SortingDTO) => await execute({ data: payload })

  return {
    executeSave,
    isFiltersSaving: isLoading
  }
}
