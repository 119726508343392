import type { OperationFilterParams } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { useApiError, client } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useOperationListDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: Array<number> }>(
    "/operations",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const executeListDelete = async (
    data: SelectListAction<
      string | number,
      {
        filters:
          | null
          | ({
              query?: string
            } & OperationFilterParams)
      }
    >
  ) => await execute({ data })

  return {
    executeListDelete,
    isDeleting: isLoading,
    operationDeleteError: useApiError(error)
  }
}
