<script setup lang="ts">
import type { SubscriptionMessages } from "./interface"

import { watch } from "vue"
import { captureException } from "@sentry/vue"

import { useCentrifugo } from "@/package/hooks"
import { injectProfile } from "@/modules/profile"
import { useCentrifugoConnectionToken, useCentrifugoSubscriptionToken } from "@/modules/auth"

import { useChannelErrorBus } from "./hooks/use-channel-error-bus"
import { useImportBus } from "./hooks/use-import-bus"
import { useOperationsCreateBus } from "./hooks/use-operations-create-bus"
import { useOperationsIntegrationsBus } from "./hooks/use-opertions-integraions-bus"

const { getConnectionToken } = useCentrifugoConnectionToken()
const { getSubscriptionToken } = useCentrifugoSubscriptionToken()
const { connect, cleanup, centrifuge, subscribtion } = useCentrifugo({
  getConnectionToken,
  getSubscriptionToken
})

const profile = injectProfile()

watch(profile, (profile) => {
  if (profile) {
    if (centrifuge.value === undefined) {
      connect(profile.email)
    }
  } else {
    cleanup()
  }
})

const channelErrorBus = useChannelErrorBus()
const importBus = useImportBus()
const operationsCreateBus = useOperationsCreateBus()
const operationsIntegrationsBus = useOperationsIntegrationsBus()

watch(centrifuge, (centrifuge) => {
  if (centrifuge) {
    centrifuge.on("error", () => {
      channelErrorBus.emit("centrifuge")
    })
  }
})

watch(subscribtion, (subscribtion) => {
  if (subscribtion) {
    subscribtion.on("error", () => {
      channelErrorBus.emit("subscribtion")
    })
    subscribtion.on("publication", function (ctx) {
      try {
        const { message, action, event } = JSON.parse(ctx.data.message) as SubscriptionMessages

        switch (action) {
          case "operations:create":
            operationsCreateBus.emit(event, { message })
            break
          case "operations:import":
            importBus.emit(event, { message })
            break
          case "integration:operations:sync":
            operationsIntegrationsBus.emit(event, { message })
            break
        }
      } catch (error) {
        captureException(error, { extra: { message: ctx.data.message } })
      }
    })
  }
})
</script>

<template>
  <slot />
</template>
