<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref } from "vue"
import { useToggle } from "@vueuse/core"

import { EditOperationCategoryForm } from "@/modules/operation-category"
import { ReferencesOperationsCategoriesRouteName } from "@/router"

import CreateTemplateOperationCategoryForm from "../../../modules/operation-category/components/CreateTemplateOperationCategoryForm/CreateTemplateOperationCategoryForm.vue"

const router = useRouter()
const openListingAction = () => router.push({ name: ReferencesOperationsCategoriesRouteName })
const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof EditOperationCategoryForm> | null>(null)
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Создание статей по шаблону"
    @onClose="openListingAction"
  >
    <CreateTemplateOperationCategoryForm ref="formRef" @success="() => toggleOpened(false)" />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="formRef?.isLoading"
            @click="formRef?.submitAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
