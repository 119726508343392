<script setup lang="ts">
import { Button, theme } from "ant-design-vue"
import { useSlots } from "vue"

defineProps({
  success: {
    type: Boolean,
    default: false
  },

  defaultStyling: {
    type: Boolean,
    default: false
  }
})
const slots: Record<keyof typeof Button.slots, unknown> = useSlots()
const { token } = theme.useToken()
</script>

<template>
  <Button
    :class="[defaultStyling ? 'reset-custom' : undefined, success ? 'btn--success' : undefined]"
    :type="success ? 'default' : undefined"
  >
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </Button>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.ant-btn-primary:not(.reset-custom).ant-btn-lg {
  font-weight: 700;
}

.btn--success {
  color: v-bind("token.colorSuccessText");
  border-color: v-bind("token.colorSuccess");
}

.btn--success:hover {
  color: v-bind("token.colorSuccessTextHover");
  border-color: v-bind("token.colorSuccessHover");
}

.btn--success:active {
  color: v-bind("token.colorSuccessTextActive");
  border-color: v-bind("token.colorSuccessActive");
}
</style>
