<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { BankAccountRouteName } from "@/router"
import {
  DETAIL_REQUEST_ERROR_MESSAGE,
  EditBankAccountForm,
  useBankAccountDetail
} from "@/modules/bank-account"

const router = useRouter()
const openListingAction = () => router.push({ name: BankAccountRouteName })
const bankAccountId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof EditBankAccountForm> | null>(null)

const callCloseAction = () => toggleOpened(false)
const { isLoading, bankAccount, error } = useBankAccountDetail(bankAccountId)
watch(bankAccount, (data) => {
  if (data && !data.editable) {
    push.warning({ message: "Редактирование запрещено" })
  }
})
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    :loading="formRef?.isLoading"
    title="Редактирование счета"
    @onClose="openListingAction"
  >
    <ASpin :spinning="isLoading || formRef?.isLoading">
      <EditBankAccountForm
        :id="bankAccountId"
        ref="formRef"
        :detail="bankAccount"
        @success="callCloseAction"
      />
    </ASpin>

    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :disabled="formRef?.isLoading" block size="large" @click="callCloseAction">
            Отмена
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :disabled="(bankAccount !== undefined && !bankAccount.editable) || isLoading"
            :loading="formRef?.isLoading"
            @click="formRef?.submitAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
