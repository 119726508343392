import type { EventBusKey } from "@vueuse/core"
import type { OperationsIntegrationsSync } from "../interface"

import { useEventBus } from "@vueuse/core"

export type IntegrationsEvents = OperationsIntegrationsSync["event"]
export const OperationsIntegrationsBus: EventBusKey<IntegrationsEvents> = Symbol(
  "OperationsIntegrationsBus"
)
export const useOperationsIntegrationsBus = () =>
  useEventBus<IntegrationsEvents, { message: string }>(OperationsIntegrationsBus)
