import { watch } from "vue"
import { notification } from "ant-design-vue"
import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import { tagsGet } from "../api/tags.get"

export const useTags = (query?: string) => {
  const { data, error, loading, refreshAsync, runAsync } = useRequest(tagsGet, {
    cacheKey: (params) => (params ? `/operations/tags/${params[0]}` : "/operations/tags"),
    defaultParams: [get(query)],
    initialData: []
  })

  watch(error, () => {
    if (error.value?.message) {
      notification.error({ message: error.value.message })
    }
  })

  return {
    tags: data,
    loading,
    getTags: runAsync,
    refreshTags: refreshAsync
  }
}
