import dayjs, { Dayjs } from "dayjs"

export const getFilterDatesPresets = (currentDate: Dayjs) => [
  {
    label: "Прошлая неделя",
    value: [dayjs().subtract(1, "week").set("day", 1), dayjs().set("day", 0)]
  },
  {
    label: "Прошлый месяц",
    value: [
      dayjs()
        .month(currentDate.month() - 1)
        .startOf("month"),
      dayjs()
        .month(currentDate.month() - 1)
        .endOf("month")
    ]
  },
  {
    label: "Прошлый квартал",
    value: [
      dayjs()
        .quarter(currentDate.quarter() - 1)
        .startOf("quarter"),
      dayjs()
        .quarter(currentDate.quarter() - 1)
        .endOf("quarter")
    ]
  },
  {
    label: "Прошлый год",
    value: [
      dayjs()
        .year(currentDate.year() - 1)
        .startOf("year"),
      dayjs()
        .year(currentDate.year() - 1)
        .endOf("year")
    ]
  },
  {
    label: "Эта неделя",
    value: [dayjs().startOf("week"), dayjs().endOf("week")]
  },
  {
    label: "Этот месяц",
    value: [dayjs().startOf("month"), dayjs().endOf("month")]
  },
  {
    label: "Этот квартал",
    value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")]
  },
  {
    label: "Этот год",
    value: [dayjs().startOf("year"), dayjs().endOf("year")]
  }
]

export const getFilterMonthPresets = (currentDate: Dayjs) => [
  {
    label: "Прошлый квартал",
    value: [
      dayjs()
        .quarter(currentDate.quarter() - 1)
        .startOf("quarter"),
      dayjs()
        .quarter(currentDate.quarter() - 1)
        .endOf("quarter")
    ]
  },
  {
    label: "Прошлый год",
    value: [
      dayjs()
        .year(currentDate.year() - 1)
        .startOf("year"),
      dayjs()
        .year(currentDate.year() - 1)
        .endOf("year")
    ]
  }
]
