import type { Ref } from "vue"
import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { h, ref, watch } from "vue"
import { Flex } from "ant-design-vue"

import type { BudgetListingRecord } from "@/modules/budget"
import { ALink, AText, useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import { FinancePlanningDetailRouteName } from "@/router"

const defaultSizes = {
  name: 200,
  legalEntity: 200,
  project: 200,
  period: 200,
  comment: 200,
  action: 64
}
const adoptedColumns = [
  {
    key: "id",
    customRender: ({ record }: { record: BudgetListingRecord }) =>
      h(Flex, { vertical: true }, () => [
        h("div", { style: { marginTop: "8px" } }, [
          h(ALink, {
            to: { name: FinancePlanningDetailRouteName, params: { id: record.id } },
            textContent: viewValueOrDefault(record.name),
            style: "font-weight: 700"
          }),
          h(
            Flex,
            {
              style: { width: "100%", marginTop: "8px" },
              justify: "space-between",
              wrap: "wrap"
            },
            () => [
              h(AText, {
                type: "secondary",
                content: viewValueOrDefault(record.legalEntity),
                ellipsis: true
              }),
              h(AText, { type: "secondary", content: viewValueOrDefault(record.period) })
            ]
          )
        ])
      ])
  }
]
const defaultColumns: ColumnsType<any> = [
  {
    title: "Название",
    key: "name",
    dataIndex: "name",
    ellipsis: { showTitle: true },
    width: defaultSizes.name
  },
  {
    title: "Юр. лицо",
    key: "legalEntity",
    dataIndex: "legalEntity",
    ellipsis: { showTitle: true },
    width: defaultSizes.legalEntity
  },
  {
    title: "Проект",
    key: "project",
    dataIndex: "project",
    ellipsis: { showTitle: true },
    width: defaultSizes.project
  },
  {
    title: "Период",
    key: "period",
    dataIndex: "period",
    ellipsis: { showTitle: true },
    width: defaultSizes.period
  },
  {
    title: "Комментарий",
    key: "comment",
    dataIndex: "comment",
    ellipsis: { showTitle: true },
    colSpan: 2,
    align: "left",
    width: defaultSizes.comment
  },
  {
    title: "",
    key: "action",
    width: defaultSizes.action
  }
]
export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
