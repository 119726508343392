import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useOperationCategoriesDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: string }>(
    "/operation-categories",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const executeListDelete = async (data: { ids: Array<number> }) => await execute({ data })

  return {
    executeListDelete,
    isDeleting: isLoading,
    operationCategoriesDeleteError: useApiError(error)
  }
}
