<script setup lang="ts">
import dayjs, { type Dayjs } from "dayjs"
import { computed, h, nextTick, onUnmounted, ref, watch } from "vue"
import { useToggle, get } from "@vueuse/core"
import { CloseOutlined, FilterOutlined } from "@ant-design/icons-vue"

import { useOperationStore } from "@/modules/operation"
import {
  useAdoptable,
  useMobileBp,
  usePopupContainerGetter,
  useSmallDesktopResolution,
  useTableResolution
} from "@/package/ui-kit"
import { injectProfileStatus } from "@/modules/profile"
import { getFilterDatesPresets } from "@/package/util"
import router, { FinanceOperationsFilterRouteName } from "@/router"

import { useSeparateFiltersState } from "../hooks/use-form-state"

const emit = defineEmits(["success"])

const store = useOperationStore()

const { formState, requestData, setupStateFromParams } = useSeparateFiltersState()

const profileStatus = injectProfileStatus()

const isFormFiltersExists = computed(
  () => formState.types?.length > 0 || formState.actions?.length > 0 || formState.date?.length > 0
)

const isSmallDesktop = useSmallDesktopResolution()
const isAdoptable = useAdoptable()

const applyFilters = () =>
  store
    .saveFilters(get(requestData), profileStatus.value.asAdmin)
    .then(() => store.applyFilters(requestData))
    .catch(() => emit("success"))

const currentDates = ref<Array<Dayjs>>([])
const datesApplicable = computed(() => currentDates.value.filter(Boolean).length === 2)

const cancelDateSelect = async () => {
  toggleDatepickerOpened(false)
  await nextTick(() => {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
  })
}
const applyDatePicker = async () => {
  formState.date = currentDates.value
  await cancelDateSelect()
  await applyFilters()
}

const [isDatepickerOpened, toggleDatepickerOpened] = useToggle(false)
const [isDateChanging, toggleIsDateChanging] = useToggle<boolean>(false)
const currentDate = dayjs()
const datePresets = getFilterDatesPresets(currentDate)

const focusRangePicker = () => {
  if (!isDatepickerOpened.value) {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
  }

  if (!isDatepickerOpened.value) {
    toggleDatepickerOpened(true)
  }
}

const blurRangePicker = () => {
  if (!isDateChanging.value) {
    toggleDatepickerOpened(false)
  } else {
    toggleIsDateChanging(false)
  }
}

const handleCalendarChange = (
  dates: Array<Dayjs>,
  dateString: Array<string>,
  info: { range: string }
) => {
  if (!dates) {
    toggleDatepickerOpened(false)
    formState.date = []
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
    applyFilters()
    return
  }

  currentDates.value = dates
  if (info.range === "start" && dates[0]) {
    toggleIsDateChanging(true)
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.focus()
  }

  if (info.range === "end" && dates[1]) {
    toggleIsDateChanging(true)
    nextTick(() =>
      (document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
    )
  }
}

onUnmounted(() => {
  store.isFinishedGettingFilters = false
})

watch(
  () => store.isFinishedGettingFilters,
  () => {
    nextTick(() => setupStateFromParams(store.filters))
  }
)

watch(
  () => store.isFilterExists,
  () => {
    if (isFormFiltersExists.value && !store.isFilterExists) {
      formState.date = []
      formState.types = []
      formState.actions = []
    }
  },
  { immediate: false }
)

const resetFiltersAction = async () => {
  await store.saveFilters(null, profileStatus.value.asAdmin)
  await store.applyFilters(null)
}

const popupContainerGetter = usePopupContainerGetter()
const isMobile = useMobileBp()
const isTableResolution = useTableResolution()
</script>

<template>
  <AForm
    name="operation-separate-filters"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ width: isAdoptable ? '100%' : 'auto' }"
    scroll-to-first-error
  >
    <div
      :style="{
        display: 'flex',
        flexDirection: isTableResolution ? 'column' : 'row',
        gap: '16px',
        alignItems: 'center'
      }"
    >
      <ARangePicker
        :value="formState.date"
        :dropdown-class-name="isMobile ? 'date-range--adoptable' : undefined"
        :open="isDatepickerOpened"
        :get-popup-container="popupContainerGetter"
        :format="['DD-MM-YYYY', 'DD-MM-YYYY']"
        :style="{ width: '100%', minWidth: '340px' }"
        :placeholder="['Начало', 'Конец']"
        popup-class-name="operation-filters-date-picker"
        :presets="isMobile ? [] : datePresets"
        size="large"
        @focus.prevent="focusRangePicker"
        @blur.prevent="blurRangePicker"
        @calendarChange="handleCalendarChange"
      >
        <template #renderExtraFooter>
          <AFlex gap="8" justify="end" :style="{ padding: '8px' }">
            <AButton size="small" type="default" @click="cancelDateSelect">Отмена</AButton>
            <AButton
              size="small"
              type="primary"
              :disabled="!datesApplicable"
              @click="applyDatePicker"
            >
              Применить
            </AButton>
          </AFlex>
        </template>
      </ARangePicker>
      <ASelect
        v-model:value="formState.actions"
        :options="[
          { value: 'incoming', label: 'Доход' },
          { value: 'outgoing', label: 'Расход' },
          { value: 'transfer', label: 'Перевод' }
        ]"
        show-arrow
        placeholder="Тип"
        mode="multiple"
        size="large"
        :style="{ width: isSmallDesktop ? '100%' : '200px', minWidth: '200px' }"
        :max-tag-count="1"
        @change="applyFilters"
      />
      <div
        :style="{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: isSmallDesktop ? 'flex-start' : 'center',
          gap: '8px',
          width: '100%'
        }"
      >
        <ASelect
          v-model:value="formState.types"
          :options="[
            { value: 'factual', label: 'Фактические' },
            { value: 'planned', label: 'Плановые' }
          ]"
          show-arrow
          placeholder="Вид"
          mode="multiple"
          size="large"
          :style="{ width: '100%', minWidth: '200px' }"
          :max-tag-count="1"
          @change="applyFilters"
        />

        <div style="position: relative; max-width: 40px">
          <AButton
            size="large"
            :type="store.isFilterExists ? 'primary' : 'default'"
            :icon="h(FilterOutlined)"
            @click="() => router.push({ name: FinanceOperationsFilterRouteName })"
          />

          <AButton
            v-if="!isAdoptable && Object.values(store.filters).length > 0"
            type="default"
            size="small"
            :icon="h(CloseOutlined, { class: 'close-icon' })"
            class="close-button"
            @click="resetFiltersAction"
          />
        </div>
      </div>
    </div>
  </AForm>
</template>

<style>
.operation-filters-date-picker
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.operation-filters-date-picker
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.operation-filters-date-picker .ant-picker-panels > *:last-child {
  display: none !important;
}
</style>
