import type { Breakpoint } from "ant-design-vue/es/_util/responsiveObserve"

import { responsiveArray } from "ant-design-vue/es/_util/responsiveObserve"
import { Grid } from "ant-design-vue"
import { createSharedComposable, useWindowSize } from "@vueuse/core"
import { computed, inject, ref, watch } from "vue"

export const useBreakpoint = createSharedComposable(() => {
  const screens = Grid.useBreakpoint()
  const result = ref<Breakpoint>(responsiveArray[0])
  watch(screens, () => {
    result.value = responsiveArray.find((bp) => screens.value[bp]) ?? "xs"
  })

  return result
})

export const useBpMin = (requestedBp: Breakpoint) => {
  const currentBp = useBreakpoint()
  const requestedBpId = responsiveArray.indexOf(requestedBp)
  const currentBpId = computed(() => responsiveArray.indexOf(currentBp.value))

  const result = ref(requestedBpId >= currentBpId.value)
  watch(currentBp, () => {
    result.value = requestedBpId >= currentBpId.value
  })
  return result
}
export const useBpMax = (requestedBp: Breakpoint) => {
  const currentBp = useBreakpoint()
  const requestedBpId = responsiveArray.indexOf(requestedBp)
  const currentBpId = computed(() => responsiveArray.indexOf(currentBp.value))

  const result = ref(requestedBpId <= currentBpId.value)
  watch(currentBp, () => {
    result.value = requestedBpId <= currentBpId.value
  })
  return result
}

export const HScreen = "calc(var(--vh, 1vh) * 100)"
export const useVh = createSharedComposable(() => {
  const { height } = useWindowSize()

  const vh = computed(() => `${height.value * 0.01}px`)
  const vhVariableProvider = computed(() => ({ "--vh": vh.value }))

  return {
    vhVariableProvider,
    vh
  }
})

export const DrawerWidthSetterToken = Symbol("SetDrawerWidthFn")
export const usePageDrawerWidthSetter = () =>
  inject<(v?: number) => void>(DrawerWidthSetterToken, () => {
    throw new Error("Provider not found")
  })
