<script setup lang="ts">
import { useRouter } from "vue-router"
import { set, useToggle } from "@vueuse/core"
import { computed, h, onUnmounted, type Ref, watch } from "vue"
import { CloseOutlined } from "@ant-design/icons-vue"
import { theme } from "ant-design-vue"
import { push } from "notivue"
import { useRouteParams } from "@vueuse/router"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"

import { FinanceOperationsRouteName } from "@/router"
import {
  DETAIL_REQUEST_ERROR_MESSAGE,
  OperationAction,
  type OperationCreatRequest,
  OperationElementSource,
  useCreateOperationTabs,
  useOperationDetail,
  useOperationEdit,
  useOperationFormStateProvider,
  useOperationStore
} from "@/modules/operation"
import { ApiBadReqResponse, ApiForbiddenResponse } from "@/package/api-client"
import { APageDrawer, MAX_Z_INDEX } from "@/package/ui-kit"

const router = useRouter()
const openListingAction = () => router.push({ name: FinanceOperationsRouteName })

const [isOpened, toggleOpened] = useToggle(true)
const closeAction = () => toggleOpened(false)

const store = useOperationStore()

const resetAction = useOperationFormStateProvider()

const operationId = useRouteParams<string>("id")

const { current, submitAction, tabList, setFormRef } = useCreateOperationTabs()

const { operation, isLoading, error } = useOperationDetail(operationId.value)

watch(operation, (operation) => {
  if (operation) {
    set(current, operation.action)
    resetAction(operation)
  }
})
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)
const { token } = theme.useToken()

const { editOperation, isEditing, isEditingFinished } = useOperationEdit()
const editOperationAction = async (formData: Ref<OperationCreatRequest>) => {
  try {
    await editOperation(operationId.value, formData)
    switch (current.value) {
      case OperationAction.incoming:
        push.success({ message: "Доход зафиксирован!" })
        break
      case OperationAction.outgoing:
        push.success({ message: "Расход зафиксирован!" })
        break
      case OperationAction.transfer:
        push.success({ message: "Перевод зафиксирован!" })
        break
    }
    toggleOpened(false)
    await store.updateBuffer()
  } catch (e) {
    isEditingFinished.value = false
    if (e instanceof ApiBadReqResponse || e instanceof ApiForbiddenResponse) {
      push.error({ message: e.message })
    } else {
      switch (current.value) {
        case OperationAction.incoming:
          push.error({ message: "Не удается зафискировать доход, обратитесь в поддержку" })
          break
        case OperationAction.outgoing:
          push.error({ message: "Не удается зафискировать расход, обратитесь в поддержку" })
          break
        case OperationAction.transfer:
          push.error({ message: "Не удается зафискировать перевод, обратитесь в поддержку" })
          break
      }
    }
  }
}

const isEditingOperation = computed(() => isEditingFinished.value || isEditing.value)

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    custom-scroll-body
    :closeable="false"
    :body-style="{
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '--body-color': token.colorBgBase,
      '--zIndex': MAX_Z_INDEX
    }"
    @onClose="openListingAction"
  >
    <AFlex
      :style="{
        padding: '24px 24px 8px',
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: token.colorBgBase,
        zIndex: 'var(--zIndex)'
      }"
    >
      <ATypographyTitle :level="4" :style="{ margin: 0 }">Редактирование операции</ATypographyTitle>
      <AButton
        :style="{ position: 'absolute', right: '16px', top: '24px' }"
        type="link"
        class="ant-drawer-close"
        :icon="h(CloseOutlined)"
        @click="closeAction"
      />
    </AFlex>
    <template v-if="isLoading">
      <div class="operation-edit-spin-container">
        <ASpin />
      </div>
    </template>
    <template v-else>
      <ATabs v-model:active-key="current" class="operation-type-tabs" destroy-inactive-tab-pane>
        <ATabPane v-for="option in tabList" :key="option.key" :tab="option.tab">
          <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
            <component
              :is="option.form"
              :ref="(el: any) => setFormRef(option.key, el)"
              validate-immediate
              :repeat-disabled="
                operation?.is_repeat ||
                operation?.repeat_parent_id !== null ||
                operation.source === OperationElementSource.import
              "
              @submit="editOperationAction"
            />
          </OverlayScrollbarsComponent>
        </ATabPane>
      </ATabs>
    </template>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block :disabled="isLoading" size="large" @click="() => toggleOpened(false)"
            >Отмена</AButton
          >
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            :disabled="isLoading"
            type="primary"
            size="large"
            :loading="isEditingOperation"
            @click="submitAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped>
.operation-type-tabs {
  overflow: hidden;
}
.operation-type-tabs:deep(.ant-tabs-tab:first-child) {
  margin-left: 24px;
}

.operation-type-tabs:deep(.ant-tabs-content) {
  overflow: hidden;
  max-height: 100%;
}

.operation-type-tabs:deep(.ant-tabs-nav) {
  z-index: var(--zIndex);
  background-color: var(--body-color);
}

.operation-edit-spin-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
