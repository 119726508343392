<script setup lang="ts">
import { useToggle } from "@vueuse/core"

import { viewValueOrDefault } from "../../util"

defineProps({
  content: {
    type: String,
    require: false,
    default: null
  },

  rows: {
    type: Number,
    default: 1
  }
})

const [ellipsisActive, toggleEllipsis] = useToggle(true)
const [showButton, toggleShowButton] = useToggle(false)
</script>

<template>
  <div style="{display: flex; flex-wrap: 'wrap';}">
    <ATypographyParagraph
      :ellipsis="
        ellipsisActive && {
          rows,
          onEllipsis: () => {
            toggleShowButton(true)
          }
        }
      "
      :content="viewValueOrDefault(content)"
      :style="{ fontSize: '16px', marginBottom: '0' }"
    />
    <AButton v-if="showButton" type="link" style="padding: 0" @click="() => toggleEllipsis()">{{
      ellipsisActive ? "Показать целиком" : "Скрыть"
    }}</AButton>
  </div>
</template>
