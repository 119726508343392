<script setup lang="ts">
import { ref } from "vue"

const checked = ref<boolean>(false)
</script>

<template>
  <ASwitch v-model:checked="checked" />
</template>

<style scoped></style>
