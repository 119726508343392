<script setup lang="ts">
import type { Key } from "ant-design-vue/es/_util/type"

import { type Form, Modal, Tree } from "ant-design-vue"
import { push } from "notivue"
import { h, onMounted, ref } from "vue"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"

import { useForm } from "@/package/hooks"
import { isValidateError } from "@/package/util"
import { useOperationCategoriesStore } from "@/modules/operation-category"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import { useFormState } from "./use-form-state"
import { useRuleset } from "./use-ruleset"
import { useTemplateOperationCategoryStore } from "./store"

const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const store = useTemplateOperationCategoryStore()
const operationStore = useOperationCategoriesStore()

const { validate, validateInfos } = useForm(formState, ruleset)

const formRef = ref<typeof Form | null>(null)
const checkedKeys = ref<string[] | Key[]>([])

const mapCategoriesToTreeData = (categories: any, parentKey = ""): any => {
  return categories.map((category: any) => {
    const key = `${parentKey}${parentKey ? "-" : ""}${category.id}`
    return {
      title: category.name,
      key: key,
      children: mapCategoriesToTreeData(category.children || [], key)
    }
  })
}

const getIdsFromArray = (array: any) => {
  const regex = /-(\d+)$/

  return array.map((str: string) => {
    const match = str.match(regex)
    return match ? match[1] : str
  })
}

const confirmModalAction = (selectedId: number) => {
  const selectedTreeData = store.data.find(({ id }) => id === selectedId)?.categories || []
  const content = h(
    OverlayScrollbarsComponent,
    {
      style: {
        maxHeight: "500px"
      }
    },
    [
      h(Tree, {
        checkable: true,
        selectedKeys: checkedKeys.value,
        "onUpdate:checkedKeys": (newCheckedKeys) => (checkedKeys.value = newCheckedKeys),
        treeData: mapCategoriesToTreeData(selectedTreeData),
        style: { marginRight: "10px" }
      })
    ]
  )
  Modal.confirm({
    title: "Будут созданы следующие статьи:",
    content: content,
    centered: true,
    onOk: () => {
      const arr = getIdsFromArray(checkedKeys.value).map((el: string) => Number(el))

      return store
        .createFromTemplate(arr)
        .then(() => {
          push.success({
            message: "Статья создана"
          })
          emit("success")
          operationStore.searchListing(undefined)
        })
        .catch((error) => {
          push.error({
            message: store?.createFromTemplateError?.message ?? DEFAULT_REQUEST_ERROR_MESSAGE
          })
          return Promise.reject(error)
        })
    },
    okText: "Подтвердить",
    cancelText: "Отмена"
  })
}

const submitAction = async () => {
  try {
    await validate()
    if (requestData.value) {
      confirmModalAction(requestData.value as number)
    }
  } catch (err) {
    console.log(err)
    if (isValidateError(err)) {
      formRef.value?.scrollToField(err.errorFields[0].name, {
        block: "start",
        boundary: (parent: HTMLElement) => {
          parent.focus()
          return parent.classList.contains("ant-row")
        }
      })
    }
  }
}

onMounted(async () => {
  await store.getTemplateOptions()
})

defineExpose({
  submitAction
})
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-operation-categories-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
  >
    <AFormItem
      label="Шаблон"
      name="template"
      v-bind="validateInfos?.template"
      :style="{ marginTop: '16px' }"
    >
      <ASelect
        v-model:value="formState.template"
        :options="store.options"
        size="large"
        placeholder="Выберите шаблон"
      />
    </AFormItem>
  </AForm>
</template>
