import type { FormTransaction } from "../interface"

import type { FormValidator } from "@/package/validators"
import { SamoDecimal } from "@/package/util"

export const useOperationSplit = () => {
  const columns = [
    {
      title: "Статья",
      key: "operation_category_id",
      width: 145
    },
    {
      title: "Контрагент",
      key: "counterparty",
      width: 145
    },
    {
      title: "Проекты",
      key: "project",
      width: 145
    },
    {
      title: "Сумма",
      key: "amount",
      width: 145
    },
    {
      title: "",
      width: 72,
      align: "center",
      key: "action"
    }
  ]

  return { columns }
}
export const createDefaultTransaction = () =>
  Object.create({
    amount: undefined,
    project_id: undefined,
    counterparty_id: undefined,
    operation_category_id: undefined
  })
export const createTransactionsAmountValidator: (amount: string | undefined) => FormValidator = (
  amountSrc
) => {
  const validator: FormValidator = (rule, value: Array<FormTransaction>) =>
    new Promise((resolve, reject) => {
      if (amountSrc && value.length > 0) {
        const amount = SamoDecimal(amountSrc)
        const currentAmount = value.reduce(
          (res, currentValue) => res.add(currentValue.amount ? currentValue.amount : 0),
          SamoDecimal(0)
        )
        const tail = amount.subtract(currentAmount).value
        if (tail !== 0) {
          if (tail > 0) {
            reject({ action: "Распределите  еще:", amount: tail })
          } else if (tail < 0) {
            reject({ action: "Уменьшите на:", amount: Math.abs(tail) })
          }
        } else {
          resolve()
        }
      } else {
        resolve()
      }
    })
  return validator
}
