import { defineStore } from "pinia"

import { createAntTheme } from "@/package/ui-kit"
interface AppConfig {
  isDarkTheme: boolean
}

export const useAppConfig = defineStore("AppConfig", {
  state: (): AppConfig => ({
    isDarkTheme: false
  }),
  actions: {
    toggleTheme() {
      this.isDarkTheme = !this.isDarkTheme
    }
  },
  getters: {
    theme: (state) => createAntTheme(state.isDarkTheme)
  }
})
