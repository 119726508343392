import type { UserDetail } from "../interface"

import { type MaybeRef } from "vue"
import { get } from "@vueuse/core"
import { useRequest } from "vue-request"

import { useApiError } from "@/package/api-client"

import { userDetailGet } from "../api/user-detail.get"

export const useUserDetail = (userId: MaybeRef<number | string>) => {
  const { data, error, loading, runAsync, refreshAsync } = useRequest<UserDetail | undefined>(
    (id) => (id ? userDetailGet(id) : Promise.resolve(undefined)),
    {
      cacheKey: ([id] = []) => `/admin/user/${id}`,
      defaultParams: [get(userId)]
    }
  )

  return {
    userDetailRefresh: refreshAsync,
    userDetailResponse: data,
    requestUserDetail: runAsync,
    isUserDetailLoading: loading,
    userDetailError: useApiError(error)
  }
}
