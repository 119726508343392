import type { DocumentCode } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

interface DocumentCodeResponse {
  success: boolean
  data: Array<DocumentCode>
}

export const useDocumentCode = () => {
  const { data, isLoading, error, execute } = useAxios<DocumentCodeResponse>(
    "/reference/document-codes",
    { method: "GET" },
    client,
    { immediate: false }
  )
  const getDocumentCode = async () => {
    return await execute("/reference/document-codes")
  }

  return { documentCode: data, documentCodePending: isLoading, error, getDocumentCode }
}
