<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, onUnmounted, type PropType, ref } from "vue"
import { noop, useDebounceFn } from "@vueuse/core"
import { onMounted, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import type { CounterpartyDetail } from "@/modules/counterparty"
import { useCounterpartiesStore } from "@/modules/counterparty"
import { useForm } from "@/package/hooks"
import { isValidateError } from "@/package/util"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import { useRuleset } from "./use-ruleset"
import { useFormState } from "./use-form-state"

const props = defineProps({
  counterparty: {
    type: [Object, undefined] as PropType<CounterpartyDetail | undefined>,
    default: () => undefined
  }
})
const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const resetAction = useDebounceFn(() => {
  if (props.counterparty) {
    Object.assign(formState, props.counterparty)
  }
}, 100)
watch(() => props.counterparty, resetAction)
onMounted(resetAction)

const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const counterpartyStore = useCounterpartiesStore()
const { editError, isEditing, isEditingFinished } = storeToRefs(counterpartyStore)

watch([editError, isEditingFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isEditingFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<typeof Form | null>(null)

const submitAction = async () => {
  try {
    await validate()
    if (props.counterparty) {
      await counterpartyStore.editCounterparty(requestData.value)
    } else {
      throw new Error("Cannot save counterparty")
    }
    push.success({
      message: "Контрагент изменен"
    })
    emit("success")
    await counterpartyStore.updateBufferAfterEdit(requestData.value)
  } catch (err) {
    if (isValidateError(err)) {
      formRef.value?.scrollToField(err.errorFields[0].name, {
        block: "start",
        boundary: (parent: HTMLElement) => {
          parent.focus()
          return parent.classList.contains("ant-row")
        }
      })
    }
  }
}

defineExpose({
  submitAction,
  isLoading: computed(() => isEditing.value || isValidating.value || isEditingFinished.value),
  resetAction
})

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-counterparty-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem
      label="Название"
      name="name"
      v-bind="validateInfos?.name"
      :style="{ marginTop: '16px' }"
    >
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>

    <AFormItem label="Контактное лицо" name="contact_person" v-bind="validateInfos?.contact_person">
      <AInput
        v-model="formState.contact_person"
        size="large"
        placeholder="Введите контактное лицо"
      />
    </AFormItem>

    <AFormItem label="Телефон" name="phone" v-bind="validateInfos?.phone">
      <APhoneInput
        v-model="formState.phone"
        size="large"
        @blur="() => validate('phone', { trigger: 'blur' }).catch(noop)"
      />
    </AFormItem>

    <AFormItem label="E-mail" name="email" v-bind="validateInfos?.email">
      <AInput
        v-model="formState.email"
        size="large"
        placeholder="Введите e-mail"
        @change="
          () => {
            if (validateInfos?.email.validateStatus === 'error') {
              validate('email', { trigger: 'blur' }).catch(noop)
            }
          }
        "
        @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
      />
    </AFormItem>

    <AFormItem label="Комментарий" name="comment" v-bind="validateInfos?.comment">
      <ATextarea v-model:value="formState.comment" size="large" placeholder="Введите комментарий" />
    </AFormItem>
  </AForm>
</template>
