import type { OperationCreatRequest } from "../interface"
import type { MaybeRef } from "vue"

import { useAxios } from "@vueuse/integrations/useAxios"
import { get } from "@vueuse/core"

import { client } from "@/package/api-client"

export const useOperationCreate = () => {
  const { data, error, execute, isLoading, isFinished } = useAxios(
    "/operations",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const createOperation = (requestData: MaybeRef<OperationCreatRequest>) =>
    execute({ data: get(requestData) })

  return {
    data,
    error,
    isCreating: isLoading,
    createOperation,
    isCreateFinished: isFinished
  }
}
