import type { SubscriptionEditLink } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const usePaySubscription = () => {
  const { execute, data, isLoading, error } = useAxios<SubscriptionEditLink>(
    "/subscription/url",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getSubscriptionPaymentLink = async (tariffId: number) => {
    return await execute({
      url: "/subscription/url",
      params: { tariff_id: tariffId }
    })
  }

  return {
    paymentLink: data,
    getSubscriptionPaymentLink,
    paymentLinkPending: isLoading,
    SubscriptionPaymentError: useApiError(error)
  }
}
