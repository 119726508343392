import type {
  CreateIncomeOperationForm,
  CreateOutcomeOperationForm,
  CreateTransferOperationForm,
  FormTransaction
} from "./interface"

export const DRAWER_SPLIT_WDITH = 710
export const DEFAULT_FORM_STATE = {
  id: undefined,
  amount: undefined,
  date: undefined,
  bank_account_from_id: undefined,
  tags: [],
  purpose: undefined,
  transactions: [],
  files: [],

  //income, outcome
  type: undefined,
  is_repeat: false,
  repeat_period: undefined,
  repeat_finish_at: undefined,

  //transfer
  bank_account_to_id: undefined
}
export const DEFAULT_TRANSACTION_STATE: Partial<FormTransaction> = {
  income_operation_category_id: undefined,
  outcome_operation_category_id: undefined,
  project_id: undefined,
  counterparty_id: undefined
}

export const INCOMING_FORM_DEFAULT_STATE: Partial<CreateIncomeOperationForm> = {
  amount: undefined,
  date: undefined,
  bank_account_from_id: undefined,
  is_repeat: false,
  purpose: undefined,
  repeat_finish_at: undefined,
  repeat_period: undefined,
  tags: [],
  transactions: [],
  type: undefined
}
export const OUTGOING_FORM_DEFAULT_STATE: Partial<CreateOutcomeOperationForm> = {
  amount: undefined,
  date: undefined,
  bank_account_from_id: undefined,
  is_repeat: false,
  purpose: undefined,
  repeat_finish_at: undefined,
  repeat_period: undefined,
  tags: [],
  transactions: [],
  type: undefined
}
export const TRANSFER_FORM_DEFAULT_STATE: Partial<CreateTransferOperationForm> = {
  amount: undefined,
  date: undefined,
  tags: undefined,
  purpose: undefined,
  bank_account_from_id: undefined,
  bank_account_to_id: undefined
}
