import type { Ref } from "vue"

import { get, noop, set } from "@vueuse/core"
import { readonly, ref } from "vue"

export const useListBufferApi = <T extends { id: Id }, Id = number>(buffer: Ref<Array<T>>) => {
  const bufferRewrite = (items: Array<T>) => {
    set(buffer, items)
  }
  const bufferConcat = (items: Array<T> | T) => {
    set(buffer, get(buffer).concat(items))
  }
  const bufferFilterOne = (excludedId: Id) => {
    set(
      buffer,
      get(buffer).filter(({ id }) => id !== excludedId)
    )
  }
  const bufferFilterOneConcat = (excludedId: Id, items: Array<T> | T) => {
    set(
      buffer,
      get(buffer)
        .filter(({ id }) => id !== excludedId)
        .concat(items)
    )
  }
  const bufferFilterList = (excludedIdList: Array<Id>) => {
    set(
      buffer,
      get(buffer).filter(({ id }) => !excludedIdList.includes(id))
    )
  }
  const bufferFilterListConcat = (excludedIdList: Array<Id>, items: Array<T> | T) => {
    set(
      buffer,
      get(buffer)
        .filter(({ id }) => !excludedIdList.includes(id))
        .concat(items)
    )
  }

  const bufferFilterListConcatReverse = (excludedIdList: Array<Id>, items: Array<T>) => {
    set(buffer, items.concat(get(buffer).filter(({ id }) => !excludedIdList.includes(id))))
  }
  const bufferUnshift = (items: Array<T> | T) => {
    if (Array.isArray(items)) {
      get(buffer).unshift(...items)
    } else {
      get(buffer).unshift(items)
    }
  }
  const bufferReplaceByFirst = (updatedId: number, items: Array<T>) => {
    if (items.length > 0) {
      get(buffer)[updatedId] = items[0]
    }
  }
  const bufferReplace = (updatedId: number, item: T) => {
    get(buffer)[updatedId] = item
  }

  const updateBufferFn = ref<(items: Array<T>) => void>(noop)
  const updateBufferFnPublic: (items: Array<T>) => void = (items: Array<T>) => {
    updateBufferFn.value(items)
  }
  const setUpdateFn = (fn: (items: Array<T>) => void) => {
    set(updateBufferFn, fn)
  }

  const bufferRO = readonly(buffer)
  return {
    bufferRO,
    bufferRewrite,
    bufferConcat,
    bufferFilterOne,
    bufferFilterOneConcat,
    bufferFilterList,
    bufferFilterListConcat,
    bufferFilterListConcatReverse,
    bufferUnshift,
    bufferReplace,
    bufferReplaceByFirst,
    updateBufferFn: updateBufferFnPublic,
    setUpdateFn
  }
}
