import type { ProjectDetail } from "../interfaces"
import type { MaybeRef } from "vue"

import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import { useApiError } from "@/package/api-client"

import { projectGet } from "../api/project.get"

export const useProjectDetail = (entityId?: MaybeRef<string | number>) => {
  const { data, error, loading, refreshAsync, run } = useRequest<ProjectDetail | undefined>(
    (id) => (id ? projectGet(id) : Promise.resolve(undefined)),
    {
      cacheKey: ([id] = []) => `/projects/${id}`,
      defaultParams: [get(entityId)]
    }
  )

  return {
    project: data,
    isLoading: loading,
    error: useApiError(error),
    run,
    refreshAsync
  }
}
