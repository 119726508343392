import type { Budget, BudgetDetailEntry, BudgetListingElement } from "./interface"
import type { Dayjs } from "dayjs"

import { defineStore } from "pinia"
import { computed, reactive, ref } from "vue"
import { noop, useDebounceFn } from "@vueuse/core"
import dayjs from "dayjs"

import { formatThousands } from "@/package/util"

import { useBudgetList } from "./hooks/use-budget-listing"

export const useBudgetDetailStore = defineStore("budgetDetailStore", () => {
  const budget = reactive<Budget>({
    budget: {
      name: "",
      id: 0,
      from: "",
      to: "",
      project: { id: null, name: null },
      bankAccount: { id: null, name: null }
    },
    columns: [],
    details: []
  })
  const filters = reactive({
    factual: true,
    declined: true,
    percentage: true
  })

  const filtersNumberInactive = computed(() => Object.values(filters).filter((val) => !val).length)

  const dateFilter = ref<Array<Dayjs> | null>([])
  const dateParams = computed(() => {
    if (dateFilter?.value?.length) {
      return {
        from: dateFilter.value[0]?.format("YYYY-MM-DD"),
        to: dateFilter.value[1]?.format("YYYY-MM-DD")
      }
    } else {
      return null
    }
  })

  const convertToTreeElement = (entry: BudgetDetailEntry) => {
    const transformedEntry: BudgetListingElement = {
      category_name: entry.category.name,
      id: entry.category.id,
      children: null
    }

    if (entry.periods) {
      entry.periods.forEach((record) => {
        transformedEntry[`${record.column_slug}-factual`] =
          +record.summary.factual === 0 ? 0 : formatThousands(record.summary.factual)
        transformedEntry[`${record.column_slug}-target`] =
          +record.summary.target === 0 ? 0 : formatThousands(record.summary.target)
        transformedEntry[`${record.column_slug}-absolut_value`] =
          record.summary.difference.absolut_value
        transformedEntry[`${record.column_slug}-percents_value`] =
          record.summary.difference.percents_value + "%"
      })
    }

    if (entry.childrens && entry.childrens[0]) {
      transformedEntry.children = entry.childrens.map(convertToTreeElement)
    }

    return transformedEntry
  }
  const listingTree = computed(() => {
    return budget.details.map(convertToTreeElement)
  })

  const getIds = (acc: Array<number>, val: BudgetListingElement) => {
    acc.push(val.id as number)
    if (val.children) {
      ;(val.children as BudgetListingElement[]).forEach((child: BudgetListingElement) => {
        getIds(acc, child as BudgetListingElement)
      })
    }

    return acc
  }

  const idList = computed(() => {
    return listingTree.value.reduce(getIds, [])
  })

  const budgetUpdate = (budgetData: Budget) => {
    Object.assign(budget, budgetData)
  }

  const { executeListing, isListingLoading, error } = useBudgetList()

  const getListing = useDebounceFn(
    async (params: { id: number }, updateBuffer: (newItems: Budget) => void) => {
      const listResponse = await executeListing({
        ...params,
        to: dateParams.value?.to,
        from: dateParams.value?.from
      })
      updateBuffer(listResponse.data!.value!.data)
    },
    100
  )

  async function getBudget(id: number) {
    await getListing({ id }, budgetUpdate)
      .then(() => {
        if (dateFilter?.value?.length === 0) {
          dateFilter.value = [
            dayjs(budget.budget.from, "DD-MM-YYYY"),
            dayjs(budget.budget.to, "DD-MM-YYYY")
          ]
        }
      })
      .catch(noop)
  }

  function $reset() {
    Object.assign(budget, {
      budget: { name: "", id: 0, from: "", to: "" },
      columns: [],
      details: []
    })
    dateFilter.value = []
  }

  return {
    error,
    filters,
    filtersNumberInactive,
    budget,
    listingTree,
    isListingLoading,
    getBudget,
    dateFilter,
    idList,
    $reset
  }
})
