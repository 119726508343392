import type { ProductCreate, ProductCreateForm } from "src/modules/product"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import { parseThousands } from "@/package/util"

export const useProductCreate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: ProductCreate }
  >("/products", { method: "POST" }, client, { immediate: false })

  const transformFormDataToRequest = (data: ProductCreateForm): ProductCreate => {
    return {
      name: data.name || null,
      cost: data.cost ? +parseThousands(data.cost) : null,
      type: data.type || null,
      article: data.article || null,
      self_cost: data.self_cost ? +parseThousands(data.self_cost) : null,
      tax_type: data.tax_type || null,
      nds_type: data.nds_type || null,
      unit_type: data.unit_type || null,
      description: data.description || null,
      counterparty_id: data.counterparty_id || null,
      quantity: data.quantity
    }
  }

  const createProduct = async (formData: ProductCreateForm) => {
    const data = transformFormDataToRequest(formData)
    await execute({ data })
  }

  return {
    createProduct,
    isCreating: isLoading,
    createError: useApiError(error),
    isCreateFinished: isFinished
  }
}
