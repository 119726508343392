import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError, useApiResponseStatus } from "@/package/api-client"
import type { Project } from "@/modules/project"
import { SortOrder } from "@/interfaces"

interface ProjectListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: Project[]
}

export const useProjectsList = () => {
  const { execute, isLoading, error, data, response } = useAxios<ProjectListResponse>(
    "/projects",
    { method: "GET" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const listingApiError = useApiError(error)
  const listingResponseStatus = useApiResponseStatus(response, error)

  const requestListing = async (params: {
    offset?: number
    limit?: number
    query?: string
    orderBy?: string
    order?: SortOrder
  }) => await execute({ params })

  return {
    listingApiError,
    listingResponseStatus,
    projectListingResponse: data,
    isListingLoading: isLoading,
    requestListing
  }
}
