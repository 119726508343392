import type { ColumnType } from "ant-design-vue/lib/table"
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { inject, provide } from "vue"

import { SortOrder } from "@/interfaces"

const PopupCountainerGetter = Symbol("PopupCountainerGetter")
export const providePopupContainer = (getter: () => Element | null) =>
  provide(PopupCountainerGetter, getter)

export const usePopupContainerGetter = (getContainer?: () => HTMLElement | null) =>
  getContainer ? getContainer : inject(PopupCountainerGetter, () => document.body)

export const viewValueOrDefault = <T>(v: T) => (v ? v : "—")

interface CustomRenderProps<RecordType = unknown> {
  value: any
  text: any
  record: RecordType
  index: number
  renderIndex: number
  column: ColumnType<RecordType>
}
export const renderPlaceholder = ({ value }: Pick<CustomRenderProps, "value">) =>
  viewValueOrDefault(value)

export const getOrderBySorter = (sorterRes: SorterResult) => {
  let res = null
  if (sorterRes) {
    if (sorterRes.order === "ascend") {
      res = SortOrder.ASC
    } else if (sorterRes.order === "descend") {
      res = SortOrder.DESC
    }
  }
  return res
}
