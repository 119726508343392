<script setup lang="ts">
import { useToggle } from "@vueuse/core"

import { OperationType, OperationTypeDict } from "@/modules/operation"

const [isOpened, toggleOpen] = useToggle(false)

const options = (Array.from(Object.keys(OperationType)) as Array<keyof typeof OperationType>).map(
  (key) => ({
    label: OperationTypeDict[key],
    value: OperationType[key]
  })
)
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    :options="options"
    placeholder="Выберите вид операции"
    @dropdownVisibleChange="toggleOpen"
    @focus="() => toggleOpen(true)"
  />
</template>
