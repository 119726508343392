import { useAxios } from "@vueuse/integrations/useAxios"

import type { Counterparty } from "@/modules/counterparty"
import { client, useApiError, useApiResponseStatus } from "@/package/api-client"
import { SortOrder } from "@/interfaces"

interface ReferenceListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: Counterparty[]
}

export const useCounterpartiesList = () => {
  const { execute, isLoading, error, data, response } = useAxios<ReferenceListResponse>(
    "/counterparties/list",
    { method: "GET" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const listingApiError = useApiError(error)
  const listingResponseStatus = useApiResponseStatus(response, error)
  const executeListing = async (params: {
    offset?: number
    limit?: number
    query?: string
    orderBy?: string
    order?: SortOrder
  }) => await execute({ params })

  return {
    data,
    executeListing,
    listingResponseStatus,
    isListingLoading: isLoading,
    listingApiError
  }
}
