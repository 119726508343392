<script setup lang="ts">
import Icon from "@ant-design/icons-vue"
import { computed } from "vue"
import { TransitionFade } from "@morev/vue-transitions"
import { get } from "@vueuse/core"

import Logo from "@/assets/icons/logo--light.svg"
import LogoShort from "@/assets/icons/logo-short.svg"
import { ProfileStatus, injectProfileStatus } from "@/modules/profile"
import { useAdoptable, useMobileBp, HScreen } from "@/package/ui-kit"

import AppMenu from "./AppMenu.vue"

const closed = defineModel({ type: Boolean })

const profileStatus = injectProfileStatus()
const isAdoptable = useAdoptable()
const isMobile = useMobileBp()

const siderStyling = computed(() => ({
  ...(isAdoptable.value
    ? {
        height: `calc(${HScreen} - 56px)`,
        position: "fixed",
        bottom: 0
      }
    : {
        top: 0,
        position: "sticky",
        height: ""
      }),
  padding: get(isMobile) ? "32px 16px" : get(isAdoptable) ? "16px 16px 32px" : "16px 16px 48px",
  left: 0,
  zIndex: 1000,
  maxHeight: HScreen,
  flexGrow: 1
}))
const iconStyling = {
  padding: "0 24px"
}
</script>

<template>
  <ALayoutSider
    v-model:collapsed="closed"
    :class="isAdoptable ? 'mobile-menu' : undefined"
    :style="siderStyling"
    :collapsed-width="isAdoptable ? 0 : undefined"
    :width="isMobile ? '100%' : 292"
    theme="dark"
    :collapsible="isAdoptable ? undefined : true"
    :trigger="isAdoptable ? null : undefined"
  >
    <AFlex :justify="isMobile || closed ? 'center' : 'space-between'">
      <template v-if="profileStatus.status === ProfileStatus.UNCONFIRMED">
        <TransitionFade
          :duration="{ enter: 1000, leave: 0 }"
          :delay="isAdoptable ? { enter: 100, leave: 0 } : undefined"
        >
          <Icon v-if="closed">
            <template #component>
              <LogoShort v-if="closed" :height="40" />
            </template>
          </Icon>
          <Icon v-else :style="iconStyling">
            <template #component>
              <Logo :height="40" />
            </template>
          </Icon>
        </TransitionFade>
      </template>
      <template v-else>
        <RouterLink to="/" :style="{ alignSelf: 'center' }">
          <TransitionFade
            :duration="{ enter: 1000, leave: 0 }"
            :delay="isAdoptable ? { enter: 100, leave: 0 } : undefined"
          >
            <Icon v-if="closed">
              <template #component>
                <LogoShort v-if="closed" :height="40" />
              </template>
            </Icon>
            <Icon v-else :style="iconStyling">
              <template #component>
                <Logo :height="40" />
              </template>
            </Icon>
          </TransitionFade>
        </RouterLink>
      </template>
    </AFlex>
    <AppMenu v-model="closed" :style="{ marginTop: '32px', flexGrow: 1 }" />
  </ALayoutSider>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
:deep(.ant-layout-sider-children) {
  display: flex;
  flex-direction: column;
}
.mobile-menu.ant-layout-sider-collapsed {
  padding: 0 !important;
}
:deep(.ant-layout-sider-trigger) {
  left: 0;
}
</style>
