<script setup lang="ts">
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons-vue"

const props = defineProps({
  list: {
    type: Array<any>,
    default: null
  },

  checked: {
    type: Boolean,
    default: true
  }
})
</script>

<template>
  <ul class="list">
    <li v-for="(listItem, index) in list" :key="index" class="list-item">
      <div>
        <CheckCircleOutlined
          v-if="props.checked"
          :style="{ marginRight: '12px', color: '#52c41a' }"
        />
        <CloseCircleOutlined
          v-else
          class="list-icon"
          :style="{ marginRight: '12px', color: '#FF4D4F' }"
        />
      </div>
      <ATypographyText>{{ listItem }}</ATypographyText>
    </li>
  </ul>
</template>

<style scoped>
.list {
  margin: 0;
  padding: 0;
}

.list-item {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
</style>
