import { useAxios } from "@vueuse/integrations/useAxios"

import { type ApiSuccessResponse, client, useApiError } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useBankAccountListDelete = () => {
  const { execute, isLoading, error } = useAxios<ApiSuccessResponse<undefined>>(
    "/bank-accounts",
    { method: "DELETE" },
    client,
    { immediate: false }
  )

  const requestDeleteBankAccountList = (
    data: SelectListAction<number | string, { query?: string }>
  ) => execute({ data })

  return {
    requestDeleteBankAccountList,
    isListDeleting: isLoading,
    bankAccountDeleteListError: useApiError(error)
  }
}
