import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { OperationBankIntegrationEdit } from "@/modules/operation"

export const useBankIntegrationDelete = () => {
  const { execute, data, isLoading, error } = useAxios<OperationBankIntegrationEdit>(
    "/integrations/banks/integration/{integration_id}",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const deleteBankIntegration = async (integration_id: number) => {
    return await execute(`/integrations/banks/integration/${integration_id}`)
  }

  return {
    deleteRequestData: data,
    deleteBankIntegration,
    deleteBankDataPending: isLoading,
    deleteBankDataError: useApiError(error)
  }
}
