import { computed } from "vue"

import type { BudgetListingRecord } from "@/modules/budget"
import { useBudgetStore } from "@/modules/budget"

export const useBudgetListing = () => {
  const store = useBudgetStore()

  return computed((): BudgetListingRecord[] =>
    store.listingBuffer.map((listingElement) => ({
      id: listingElement.id,
      name: listingElement.name,
      comment: listingElement.comment,
      period: `${listingElement.from} - ${listingElement.to}`,
      project: listingElement.project.name,
      legalEntity: listingElement.legal_entity.organization_name
    }))
  )
}
