import type { NotificationType } from "notivue"

import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons-vue"
import { h } from "vue"

export const NotificationIcon = ({ type, ...props }: { type: NotificationType }) => {
  let resultIcon
  switch (type) {
    case "promise-resolve":
    case "success":
      resultIcon = CheckCircleFilled
      break
    case "promise":
    case "warning":
    case "info":
      resultIcon = InfoCircleFilled
      break
    case "promise-reject":
    case "error":
      resultIcon = CloseCircleFilled
      break
  }
  return h(resultIcon, { ...props })
}
