<script setup lang="ts">
import { get, useToggle } from "@vueuse/core"
import { useRouter } from "vue-router"
import { theme } from "ant-design-vue"
import { computed, onMounted, ref, watch } from "vue"
import { useRouteQuery } from "@vueuse/router"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import plural from "plural-ru"
import { push } from "notivue"

import { APageDrawer, MAX_Z_INDEX } from "@/package/ui-kit"
import { FinanceOperationsRouteName } from "@/router"
import { EditProductForm } from "@/modules/product"
import { OperationEditListForm, useOperationListEdit, useOperationStore } from "@/modules/operation"

const router = useRouter()

const operationIds = useRouteQuery<Array<string>>("idList")
const excludeMode = useRouteQuery<string, boolean>("exclude", undefined, {
  transform: (v) => Boolean(Number(v))
})

const openListingAction = () => router.push({ name: FinanceOperationsRouteName })

const { editListOperation, error } = useOperationListEdit()
watch(error, (value) => {
  if (value && value.message) {
    push.error(value.message)
  }
})
const store = useOperationStore()

const [isOpened, toggleOpened] = useToggle(true)
const { token } = theme.useToken()
const formRef = ref<InstanceType<typeof EditProductForm> | null>(null)
const title = computed(() =>
  get(excludeMode)
    ? `Изменения всех${get(operationIds)?.length > 0 ? ` кроме ${get(operationIds)?.length}` : ""} ${plural(get(operationIds)?.length, "операции", "операций")}`
    : `Изменения ${get(operationIds)?.length} ${plural(get(operationIds)?.length, "операции", "операций")}`
)

const submitAction = async (edit: Record<string, string | number>) => {
  if (get(excludeMode)) {
    await editListOperation({
      exclude_ids: get(operationIds).map(Number),
      edit,
      filters: {
        query: store.searchQuery,
        ...store.filters
      }
    })
  } else {
    await editListOperation({ include_ids: get(operationIds).map(Number), edit })
  }
  push.success({ message: "Изменения внесены" })
  toggleOpened(false)
  await store.updateBuffer()
}
onMounted(() => {
  if (get(operationIds) === undefined || get(excludeMode) === undefined) {
    toggleOpened(false)
  }
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    :title="title"
    custom-scroll-body
    :body-style="{
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '--body-color': token.colorBgBase,
      '--zIndex': MAX_Z_INDEX
    }"
    @onClose="openListingAction"
  >
    <OverlayScrollbarsComponent :style="{ maxHeight: '100%', flexGrow: 1 }">
      <OperationEditListForm ref="formRef" @submit="submitAction" />
    </OverlayScrollbarsComponent>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block type="primary" size="large" @click="formRef?.submitAction">
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
