import axios, { type AxiosInterceptorOptions } from "axios"

import { setupJwtInterceptor, setupJwtRefresh } from "./setup-jwt-interceptor"
import { setupErrorInterceptor } from "./setup-error-interceptor"

export const client = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})
setupJwtInterceptor(client)
setupErrorInterceptor(client)
setupJwtRefresh(client)
export const setupClientInterceptor = (
  onFulfilled?: (value: any) => any | Promise<any>,
  onRejected?: (error: any) => any,
  options?: AxiosInterceptorOptions
) => {
  client.interceptors.response.use(onFulfilled, onRejected, options)
}
export const cleanClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
})
