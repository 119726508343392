<script setup lang="ts">
import { FormItem, theme } from "ant-design-vue"
import { useSlots } from "vue"

const { token } = theme.useToken()
const slots: Record<keyof typeof FormItem.slots, unknown> = useSlots()
</script>

<template>
  <FormItem>
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </FormItem>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.ant-form-item {
  margin-bottom: 28px;
}

:deep(.ant-form-item-label) > label {
  height: 22px;
}

:deep(.ant-form-item-label) {
  padding-bottom: 0;
  height: 22px;
}

:deep(.ant-form-item-explain-error) {
  line-height: 22px;
}

.ant-form-item.ant-form-item-has-error :deep(.ant-input[type="password"]:hover) {
  border-color: v-bind("token.colorErrorBorderHover");
}
.ant-form-item.ant-form-item-has-error :deep(.ant-input[type="password"]) {
  border-color: v-bind("token.colorError");
  box-shadow: none !important;
}
</style>
