import { ref } from "vue"

import { digitsExcludeValidator, phoneNumberValidator } from "@/package/validators"

export const useRuleset = () => {
  const ruleset: any = ref({
    email: [{ type: "email", message: "Пожалуйста, введите e-mail", trigger: "blur" }],
    phone: [{ asyncValidator: phoneNumberValidator, trigger: "blur" }],
    contact_person: [
      {
        asyncValidator: digitsExcludeValidator
      }
    ],
    name: [{ required: true, message: "Пожалуйста, введите название", whitespace: true }],
    comment: [
      {
        type: "string",
        max: 4096,
        message: "Превышено допустимое количество символов"
      }
    ]
  })

  return ruleset
}
