<script setup lang="ts">
import type { SummaryStatisticsFilter } from "../interface"

import { computed, h, nextTick, type PropType, ref } from "vue"
import dayjs, { type Dayjs } from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { get, set, useToggle } from "@vueuse/core"
import { CloseOutlined, FilterOutlined } from "@ant-design/icons-vue"

import { OperationCategoryTreeSelect } from "@/modules/operation-category"
import { gridGutter, useAdoptable, useMobileBp, useLargeTabletResolution } from "@/package/ui-kit"
import { getFilterDatesPresets } from "@/package/util"

import StatisticFiltersForm from "./StatisticFiltersForm.vue"

const formState = defineModel({
  type: Object as PropType<Partial<SummaryStatisticsFilter>>,
  default: () => ({
    date: [],
    legalEntityId: undefined,
    bankAccountId: undefined,
    operationCategories: [],
    allCategories: false,
    projectId: undefined
  })
})

dayjs.extend(quarterOfYear)
const filtersEmpty = computed(
  () =>
    (!formState.value.date || formState.value.date?.length === 0) &&
    !formState.value.bankAccountId &&
    !formState.value.projectId &&
    !formState.value.legalEntityId &&
    (!formState.value.operationCategories || formState.value.operationCategories.length === 0) &&
    (!formState.value.selectedMainVariants || formState.value.selectedMainVariants.length === 2) &&
    (!formState.value.selectedRevenueVariants ||
      formState.value.selectedRevenueVariants.length === 2)
)
const backupDateState = ref<[Dayjs, Dayjs] | []>([])
const currentDates = ref<Array<Dayjs>>([])

const [isDatepickerOpened, toggleDatepickerOpened] = useToggle(false)
const [isDateChanging, toggleIsDateChanging] = useToggle<boolean>(false)
const isTabletResolution = useLargeTabletResolution()

const currentDate = dayjs()
const datePresets = getFilterDatesPresets(currentDate)
const cancelDateSelect = () => {
  backupDateState.value = []
  toggleDatepickerOpened(false)
  nextTick(() => {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
  })
}
const applyDatePicker = () => {
  formState.value.date = currentDates.value
  cancelDateSelect()
}

const focusRangePicker = () => {
  if (!isDatepickerOpened.value && !isDateChanging.value) {
    toggleDatepickerOpened(true)
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
  }
}

const blurRangePicker = () => {
  if (!isDateChanging.value && isDatepickerOpened.value) {
    toggleDatepickerOpened(false)
  } else {
    toggleIsDateChanging(false)
  }
}

const handleCalendarChange = (
  dates: Array<Dayjs>,
  dateString: Array<string>,
  info: { range: string }
) => {
  if (!dates) {
    toggleDatepickerOpened(false)
    formState.value.date = []
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
    return
  }

  currentDates.value = dates
  if (info.range === "start" && dates[0]) {
    toggleIsDateChanging(true)
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.focus()
  }

  if (info.range === "end" && dates[1]) {
    toggleIsDateChanging(true)
    nextTick(() =>
      (document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
    )
  }
}

const datesApplicable = computed(() => currentDates.value.filter(Boolean).length === 2)

const isAdoptable = useAdoptable()
const isMobile = useMobileBp()
const [showFilterPanel, toggleShowFilterPanel] = useToggle(false)

const operationCategoriesModel = computed({
  set(v: Array<string | number> | undefined) {
    const state = get(formState)
    state.operationCategories = v
    set(formState, state)
  },
  get() {
    let result = undefined
    if (!get(formState).allCategories) {
      result = get(formState).operationCategories
    }
    return result
  }
})

const updateStateFromMobileForm = (newState: Partial<SummaryStatisticsFilter>) => {
  formState.value = { ...newState }
  toggleShowFilterPanel(false)
}

const resetFilters = () => {
  formState.value = {
    date: [],
    legalEntityId: undefined,
    bankAccountId: undefined,
    operationCategories: [],
    allCategories: false,
    projectId: undefined,
    selectedMainVariants: ["income", "outlay"],
    selectedRevenueVariants: ["revenueIncrease", "revenue"]
  }
}

const cancelMobileForm = () => {
  toggleShowFilterPanel(false)
}
</script>

<template>
  <ARow
    :gutter="gridGutter"
    :wrap="false"
    :justify="isAdoptable ? 'space-between' : 'start'"
    :style="{
      flexDirection: isTabletResolution ? 'column' : 'row',
      gap: isTabletResolution ? '24px' : 0
    }"
  >
    <ACol :flex="isTabletResolution ? null : '0 1 324px'">
      <ARangePicker
        :value="formState.date"
        :open="isDatepickerOpened"
        :presets="isMobile ? [] : datePresets"
        :format="['DD-MM-YYYY', 'DD-MM-YYYY']"
        :style="{ width: '100%', maxWidth: '300px' }"
        :placeholder="['Начало', 'Конец']"
        popup-class-name="operation-filters-date-picker"
        :dropdown-class-name="isAdoptable ? 'date-range--adoptable' : undefined"
        size="large"
        @calendarChange="handleCalendarChange"
        @focus.prevent="focusRangePicker"
        @blur.prevent="blurRangePicker"
      >
        <template #renderExtraFooter>
          <AFlex gap="8" justify="end" :style="{ padding: '8px' }">
            <AButton size="small" type="default" @click="cancelDateSelect">Отмена</AButton>
            <AButton
              size="small"
              type="primary"
              :disabled="!datesApplicable"
              @click="applyDatePicker"
            >
              Применить
            </AButton>
          </AFlex>
        </template>
      </ARangePicker>
    </ACol>
    <ACol>
      <AFlex :gap="8" :style="{ width: '100%', maxWidth: '300px' }">
        <OperationCategoryTreeSelect
          v-model="operationCategoriesModel"
          hide-link
          placeholder="Выберите cтатьи"
          size="large"
          :input-style="{ width: '252px' }"
          :max-tag-count="2"
        />
      </AFlex>
    </ACol>
    <ACol style="display: flex">
      <div style="position: relative; max-width: 200px">
        <AButton
          size="large"
          :type="filtersEmpty ? 'default' : 'primary'"
          :icon="h(FilterOutlined)"
          @click="() => toggleShowFilterPanel(true)"
          >Фильтры</AButton
        >

        <AButton
          v-if="!filtersEmpty"
          type="default"
          size="small"
          :icon="h(CloseOutlined, { class: 'close-icon' })"
          class="close-button"
          @click="resetFilters"
        />
      </div>
      <StatisticFiltersForm
        v-if="showFilterPanel"
        :form-state-prop="formState"
        :show-filter-panel="showFilterPanel"
        @submit="updateStateFromMobileForm"
        @cancel="cancelMobileForm"
      />
    </ACol>
  </ARow>
</template>

<style scoped>
.operation-category-select:deep(.ant-spin-text) {
  transform: translateY(-50%);
}
.operation-category-select {
  @media (max-width: 1240px) {
    max-width: 276px;
  }

  max-width: 100%;
  width: 100%;
}

.close-button {
  margin-left: auto;
  border-radius: 50px !important;
  border: 2px solid black;
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  z-index: 1000;
  right: -8px;
  top: -5px;
}
</style>

<style>
.close-icon svg {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -13px;
  right: -5px;
}

.statistics-filters-date-picker
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.statistics-filters-date-picker
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.statistics-filters-date-picker .ant-picker-panels > *:last-child {
  display: none !important;
}
</style>
