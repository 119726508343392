import { reactive } from "vue"

import {
  bikValidator,
  type FormValidator,
  innValidator,
  validateCorrespondentAccount,
  validateRs
} from "@/package/validators"
import type { BankAccount } from "@/modules/bank-account"

export const useRuleset = (formState: Pick<BankAccount, "bic">) => {
  const paymentAccountValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      const [result, error] = validateRs(value, formState.bic)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })

  const correspondentAccountValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      const [result, error] = validateCorrespondentAccount(value, formState.bic)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })

  return reactive({
    itn: [{ required: true, asyncValidator: innValidator }],
    organization_name: [{ required: true, message: "Необходимо ввести наименование организации" }],
    head_fullname: [
      { required: true, message: "Пожалуйста, введите ФИО руководителя", whitespace: true }
    ],
    okved_id: [{ required: true, message: "Необходимо выбрать ОКВЭД" }],
    legal_address: [{ required: true, message: "Необходимо ввести адрес", whitespace: true }],
    monthly_turnover_rate: [{ required: true, message: "Необходимо выбрать сумму" }],
    bic: [{ required: true, asyncValidator: bikValidator }],
    account_number: [
      { required: true, message: "Данные банка не получены" },
      { required: true, asyncValidator: paymentAccountValidator }
    ],
    bank_name: [{ required: true, message: "Данные банка не получены" }],
    bank_account_id: [{ required: true, message: "Пожалуйста, выберите  счет", trigger: "submit" }],
    correspondent_account_number: [
      { required: true, message: "Данные банка не получены" },
      { required: true, asyncValidator: correspondentAccountValidator }
    ]
  })
}

export const searchConnectedFields = [
  "itn",
  "organization_name",
  "head_fullname",
  "okved_id",
  "legal_address"
]
