<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useToggle } from "@vueuse/core"

import { FinanceOperationsRouteName } from "@/router"
import { OperationFiltersForm } from "@/modules/operation"

const router = useRouter()
const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof OperationFiltersForm> | null>(null)
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Фильтры"
    @onClose="() => router.push({ name: FinanceOperationsRouteName })"
  >
    <OperationFiltersForm ref="formRef" @success="() => toggleOpened(false)" />
    <template #footer>
      <ARow :gutter="8">
        <ACol :span="12">
          <AButton size="large" block type="default" @click="() => formRef?.resetFilters()"
            >Очистить</AButton
          >
        </ACol>
        <ACol :span="12">
          <AButton size="large" block type="primary" @click="() => formRef?.applyFilters()"
            >Применить</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
