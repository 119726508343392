import type { OperationConfirmBank } from "../../../interface"

import { computed, reactive } from "vue"

export const useFormState = () => {
  const formState = reactive<OperationConfirmBank>({
    code: null
  })
  const requestData = computed<OperationConfirmBank>(() => {
    const { code } = formState

    return {
      code
    }
  })

  return {
    formState,
    requestData
  }
}
