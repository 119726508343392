<script setup lang="ts">
import { useToggle } from "@vueuse/core"

const options = [
  {
    value: true,
    label: "Да"
  },
  {
    value: false,
    label: "Нет"
  }
]

const [isOpened, toggleOpen] = useToggle(false)
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    :options="options"
    :status="undefined"
    @dropdownVisibleChange="() => toggleOpen()"
    @focus="() => toggleOpen(true)"
  />
</template>
