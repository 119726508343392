import type { ProductDetail } from "../interfaces"
import type { MaybeRef } from "vue"

import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import { type ApiSuccessResponse, client, useApiError } from "@/package/api-client"

export const useProductDetail = (entityId?: MaybeRef<string | number>) => {
  const { data, error, loading, refreshAsync, run } = useRequest<ProductDetail | undefined>(
    (id) =>
      id
        ? client
            .get<ApiSuccessResponse<ProductDetail>>(`/products/${id}`)
            .then(({ data }) => data.data)
        : Promise.resolve(undefined),
    {
      defaultParams: [get(entityId)]
    }
  )

  return {
    product: data,
    isLoading: loading,
    error: useApiError(error),
    run,
    refreshAsync
  }
}
