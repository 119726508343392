import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { CreateProject } from "@/modules/project"

export const useProjectCreate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: CreateProject }
  >("/projects", { method: "POST" }, client, { immediate: false })

  const transformFormDataToRequest = (data: CreateProject): CreateProject => ({
    name: data.name || null
  })

  const createProject = async (formData: CreateProject) => {
    const data = transformFormDataToRequest(formData)
    await execute({ data })
  }

  return {
    createProject,
    isCreating: isLoading,
    createError: useApiError(error),
    isCreateFinished: isFinished
  }
}
