import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useProjectsListDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: Array<string> }>(
    "/projects",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const requestProejctListDelete = (data: SelectListAction<number | string, { query?: string }>) =>
    execute({ data })

  return {
    requestProejctListDelete,
    isListDeleting: isLoading,
    projectDeleteListError: useApiError(error)
  }
}
