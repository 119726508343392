import { type IPorfileStatus, ProfileStatus } from "@/modules/profile"

export const PROFILE = Symbol("ProfileInjectToken")
export const PROFILE_REFRESH = Symbol("ProfileRefreshInjectToken")
export const PROFILE_RESET = Symbol("ProfileResetInjectToken")
export const PROFILE_STATUS = Symbol("ProfileStatusInjectToken")
export const DECREMENT_ATTEMPTS_COUNT = Symbol("ProfileAttemptsDecrementInjectToken")
export const PROFILE_LOADING = Symbol("ProfileLoadingInjectToken")

export const defaultProfile: IPorfileStatus = {
  status: ProfileStatus.UNKNOWN,
  deltedAt: null,
  attempts: 0,
  asAdmin: false,
  authAsUser: false
}
