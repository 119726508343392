<script setup lang="ts">
import type { CurrentSubscription } from "../../../subscription"

import { computed, type PropType } from "vue"
import dayjs from "dayjs"

import { injectProfile, injectProfileLoading } from "@/modules/profile"
import router, { PhysSubscriptionRouteName, Subscription } from "@/router"

import ProfileWrapper from "../ProfileWrapper/ProfileWrapper.vue"

const props = defineProps({
  subscription: {
    type: Object as PropType<CurrentSubscription>,
    default: null
  },

  subscriptionLoading: {
    type: Boolean,
    default: false
  }
})

defineEmits(["editProfile"])

const profile = injectProfile()
const isLoading = injectProfileLoading()
defineOptions({
  inheritAttrs: true
})

const subscriptionName = computed(() =>
  props.subscription?.product.name === "PRO" ? "Подписка ПРО" : "Подписка ФРИ"
)

const subscriptionHandler = () => {
  props.subscription?.product.name === "PRO"
    ? router.push({ name: PhysSubscriptionRouteName })
    : router.push({ name: Subscription })
}

const SubscriptionFinish = computed(() => {
  if (profile?.value?.is_cancel_subscription || !props.subscription?.card_number) {
    return (
      "Закончится " + dayjs(props.subscription?.subscription?.finish_at).utc().format("DD.MM.YYYY")
    )
  }
  return (
    "Будет продлена " +
    dayjs(props.subscription?.payment?.next_payment_date).utc().format("DD.MM.YYYY")
  )
})
</script>

<template>
  <ASpin :spinning="isLoading || props.subscriptionLoading">
    <ProfileWrapper title="Подписка">
      <div class="subscription-wrapper">
        <ATypographyText strong :style="{ fontSize: '16px' }">
          {{ subscriptionName }}</ATypographyText
        >
      </div>
      <AButton type="primary" size="large" block @click="subscriptionHandler">
        {{ props.subscription?.product.name === "PRO" ? "Настроить" : "Изменить" }}
      </AButton>
      <template #extra>
        <ATypographyText v-if="props.subscription?.product.name === 'PRO'" type="secondary">{{
          SubscriptionFinish
        }}</ATypographyText>
      </template>
    </ProfileWrapper>
  </ASpin>
</template>

<style scoped>
.subscription-wrapper {
  padding: 16px 12px 14px;
  border: 1px solid #7ab4ff;
  border-radius: 6px;
  margin-bottom: 24px;
}
</style>
