import type {
  BaseCategoryListingElement,
  OperationCategory,
  OperationCategoryEdit,
  OperationCategoryListingElement
} from "../interfaces"

import { defineStore } from "pinia"
import { computed, type MaybeRef, readonly, type Ref, ref, watch } from "vue"
import { get, set } from "@vueuse/core"
import { push } from "notivue"
import { HttpStatusCode } from "axios"

import {
  useOperationCategoriesDelete,
  useOperationCategoriesList,
  useOperationCategoryCreate,
  useOperationCategoryDetail,
  useOperationCategoryEdit
} from "@/modules/operation-category"
import { useListBufferApi, usePagination } from "@/package/hooks"

const idListMapper = (list: Ref<Array<OperationCategory>>): any => {
  const valueMapper = (list: Array<OperationCategory>): Array<number> =>
    list.flatMap((value) => [value.id].concat(valueMapper(value.children)))

  return valueMapper(list.value)
}
const convertToTreeElement = (entry: OperationCategory): OperationCategoryListingElement => ({
  id: entry.id,
  name: entry.name,
  key: entry.id,
  parent_id: entry.parent_id,
  children:
    entry.children && entry.children[0] ? entry.children.map(convertToTreeElement) : undefined
})

export const useOperationCategoriesStore = defineStore("operationCategoriesStore", () => {
  const { pagination, setPagination } = usePagination()

  const listingBuffer = ref(new Array<OperationCategory>())
  const idList = computed(() => idListMapper(listingBuffer))
  const listingTree = computed(() => listingBuffer.value.map(convertToTreeElement))

  const searchQuery = ref<string>()
  const searchQueryRO = readonly(searchQuery)
  const actionType = ref("income")

  const addNestedBufferItem = (
    bufferPart: MaybeRef<OperationCategory[]>,
    newEntry: OperationCategory
  ) => {
    get(bufferPart).forEach((entry) => {
      if (entry.id === newEntry.parent_id) {
        if (entry.children) {
          entry.children.push(newEntry)
        } else {
          entry.children = [newEntry]
        }
      } else {
        if (entry.children) {
          addNestedBufferItem(entry.children, newEntry)
        }
      }
    })
  }
  const excludeNestedBufferItem = (
    bufferPart: MaybeRef<OperationCategory[]>,
    deletedRecord: Pick<BaseCategoryListingElement, "id" | "parent_id">
  ) => {
    get(bufferPart).forEach((entry) => {
      if (deletedRecord.parent_id === entry.id) {
        const filteredChildren = entry.children.filter(({ id }) => id !== deletedRecord.id)
        entry.children.splice(0, entry.children.length, ...filteredChildren)
      } else {
        if (entry.children) {
          excludeNestedBufferItem(entry.children, deletedRecord)
        }
      }
    })
  }
  const actualizeBuffer = (
    bufferPart: MaybeRef<OperationCategory[]>,
    editEntry: OperationCategoryEdit
  ): OperationCategory | undefined => {
    for (const entry of get(bufferPart)) {
      if (entry.id === editEntry.id) {
        if (entry.parent_id === editEntry.parent_id) {
          entry.type = editEntry.type ?? ""
          entry.name = editEntry.name
          entry.action_type = editEntry.action_type
          entry.comment = editEntry.action_type
        } else {
          const transferEntry: OperationCategory = {
            id: entry.id,
            name: editEntry.name,
            type: editEntry.type ?? "",
            comment: editEntry.comment ?? "",
            action_type: editEntry.action_type,
            parent_id: editEntry.parent_id,
            children: entry.children
          }

          const filteredBufferPart = get(bufferPart).filter(({ id }) => id !== entry.id)

          get(bufferPart).splice(0, get(bufferPart, "length"), ...filteredBufferPart)
          return transferEntry
        }
      } else {
        if (entry.children) {
          const transferEntry = actualizeBuffer(entry.children, editEntry)
          if (transferEntry) return transferEntry
        }
      }
    }
  }

  const { bufferConcat, setUpdateFn, updateBufferFn, bufferUnshift, bufferRewrite } =
    useListBufferApi(listingBuffer)
  const {
    requestListing,
    isListingLoading,
    listingResponse,
    listingApiError,
    listingResponseStatus
  } = useOperationCategoriesList()

  const isResourceEmpty = computed(() => get(listingResponseStatus) === HttpStatusCode.NoContent)
  const isLoadedFull = computed(() => {
    const total = get(pagination)?.total
    return (total !== null && total <= get(listingBuffer).length) || get(isResourceEmpty)
  })
  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
    }
  })
  watch(listingResponse, (listing) => {
    if (listing && listing.data.length > 0) {
      setPagination(listing.pagination)
      updateBufferFn(listing.data)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })

  const loadNextPart = async () => {
    setUpdateFn(bufferConcat)
    await requestListing({
      offset: get(listingBuffer, "length"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      action_type: get(actionType)
    })
  }

  const excludeBufferItemFromRoot = async (
    deletedRecord: Pick<BaseCategoryListingElement, "id">
  ) => {
    if (isLoadedFull.value) {
      const filteredBuffer = listingBuffer.value.filter(({ id }) => id !== deletedRecord.id)
      listingBuffer.value.splice(0, listingBuffer.value.length, ...filteredBuffer)
    } else {
      const filteredBuffer = listingBuffer.value.filter(({ id }) => id !== deletedRecord.id)
      listingBuffer.value.splice(0, listingBuffer.value.length, ...filteredBuffer)
      setUpdateFn(bufferConcat)
      await requestListing({
        offset: get(listingBuffer, "length"),
        limit: 1,
        query: get(searchQuery) || undefined,
        action_type: get(actionType)
      })
    }
  }

  const { createOperationCategories, isCreating, createError, isCreateFinished } =
    useOperationCategoryCreate()

  const { runAsync: getDetail, loading: isDetailLoading } = useOperationCategoryDetail()

  const updateBufferAfterCreate = async (operation_category_id: number) => {
    const newRecord = await getDetail(operation_category_id)
    const operationCategory = {
      ...newRecord!,
      children: [],
      type: newRecord?.type ?? "",
      comment: newRecord?.comment ?? ""
    }
    if (operationCategory.parent_id) {
      addNestedBufferItem(listingBuffer, operationCategory)
    } else {
      bufferUnshift(operationCategory)
    }
    return operation_category_id
  }

  const { executeListDelete, isDeleting, operationCategoriesDeleteError } =
    useOperationCategoriesDelete()

  const deleteOperationCategories = async ({
    id: deletedId,
    parent_id
  }: Pick<BaseCategoryListingElement, "id" | "parent_id">) => {
    const ids: Array<number> = [deletedId]

    const addToDeleteIds = (bufferPart: OperationCategory[]) => {
      bufferPart.forEach((entry) => {
        ids.push(entry.id)
        if (entry.children) {
          addToDeleteIds(entry.children)
        }
      })
    }
    const getIdsToDelete = (bufferPart: MaybeRef<OperationCategory[]>) => {
      for (const entry of get(bufferPart)) {
        if (entry.id === deletedId) {
          if (entry.children) {
            addToDeleteIds(entry.children)
          }
        } else {
          if (entry.children) {
            getIdsToDelete(entry.children)
          }
        }
      }
    }

    getIdsToDelete(listingBuffer)
    await executeListDelete({ ids })
    if (parent_id) {
      excludeNestedBufferItem(listingBuffer, { id: deletedId, parent_id })
    } else {
      await excludeBufferItemFromRoot({ id: deletedId })
    }
  }

  const { editOperationCategory, editError, isEditingFinished, isEditing } =
    useOperationCategoryEdit()

  const updateBufferAfterEdit = (editEntry: OperationCategoryEdit) => {
    const transferEntry = actualizeBuffer(listingBuffer, editEntry)

    if (transferEntry) {
      addNestedBufferItem(listingBuffer, transferEntry)
    }
  }

  const searchListing = async (query: MaybeRef<string> | undefined) => {
    set(searchQuery, get(query))
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      action_type: get(actionType)
    })
  }

  const changeActionType = async (slug: MaybeRef<string>) => {
    if (actionType.value !== slug) {
      set(searchQuery, undefined)
      set(actionType, get(slug))
      setPagination()
      setUpdateFn(bufferRewrite)
      await requestListing({
        offset: get(pagination, "offset"),
        limit: get(pagination, "limit"),
        query: get(searchQuery) || undefined,
        action_type: get(actionType)
      })
    }
  }

  const $reset = () => {
    set(listingBuffer, [])
    setPagination()
    set(searchQuery, undefined)
  }

  return {
    isResourceEmpty,

    searchQuery: searchQueryRO,
    actionType,
    pagination,

    listingApiError,
    isLoadedFull,
    listingBuffer,
    idList,
    listingTree,
    isListingLoading,
    loadNextPart,
    searchListing,
    changeActionType,

    createOperationCategories,
    isCreating,
    createError,
    isCreateFinished,
    updateBufferAfterCreate,
    editOperationCategory,
    isEditing,
    editError,
    isEditingFinished,
    updateBufferAfterEdit,
    isDetailLoading,

    deleteOperationCategories,
    isDeleting,
    operationCategoriesDeleteError,
    $reset
  }
})
