<script setup lang="ts">
import { computed } from "vue"

import { RepeatPeriod, RepeatPeriodNaming } from "@/interfaces"

const options = computed(() =>
  (Object.keys(RepeatPeriod) as Array<keyof typeof RepeatPeriod>).map((key) => ({
    value: RepeatPeriod[key],
    label: RepeatPeriodNaming[key]
  }))
)
</script>

<template>
  <ASelect :options="options" />
</template>
