<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import {
  EditCounterpartyForm,
  useCounterpartiesDetail,
  DETAIL_REQUEST_ERROR_MESSAGE
} from "@/modules/counterparty"
import { CounterpartiesReferencesRouteName } from "@/router"

const router = useRouter()
const counterpartyId = useRouteParams<string | undefined>("id")
const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof EditCounterpartyForm> | null>(null)

const { counterparty, error } = useCounterpartiesDetail(counterpartyId.value)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await router.push({ name: CounterpartiesReferencesRouteName })
    }
  },
  { immediate: true }
)
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Редактирование контрагента"
    @onClose="() => router.push({ name: CounterpartiesReferencesRouteName })"
  >
    <EditCounterpartyForm
      ref="formRef"
      :counterparty="counterparty"
      @success="() => toggleOpened(false)"
    />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="formRef?.isLoading"
            @click="formRef?.submitAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
