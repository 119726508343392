<script setup lang="ts">
import { computed, type PropType } from "vue"
import { Mask } from "maska"

import { type CounterpartyDetail } from "@/modules/counterparty"
import { viewValueOrDefault } from "@/package/ui-kit"

const props = defineProps({
  counterparty: {
    type: [Object, undefined] as PropType<CounterpartyDetail | undefined>,
    default: () => undefined
  }
})

const mask = new Mask({ mask: "+7 (###) ###-##-##" })

const maskedPhone = computed(() =>
  props.counterparty?.phone ? mask.masked(props.counterparty?.phone) : null
)
</script>

<template>
  <ADescriptions layout="vertical" :column="24">
    <ADescriptionsItem :span="24" label="Название">{{
      viewValueOrDefault(counterparty?.name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Контактное лицо">{{
      viewValueOrDefault(counterparty?.contact_person)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Телефон">{{
      viewValueOrDefault(maskedPhone)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="E-mail">{{
      viewValueOrDefault(counterparty?.email)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Комментарий" :content-style="{ whiteSpace: 'normal' }">{{
      viewValueOrDefault(counterparty?.comment)
    }}</ADescriptionsItem>
  </ADescriptions>
</template>
