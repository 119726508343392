<script setup lang="ts">
import { useRouter } from "vue-router"
import { useToggle } from "@vueuse/core"
import { computed, onUnmounted, type Ref, ref, watch } from "vue"
import { storeToRefs } from "pinia"
import { push } from "notivue"

import type { BudgetFormInterface } from "@/modules/budget"
import { FinancePlanningRouteName } from "@/router"
import { useBudgetStore, BudgetForm } from "@/modules/budget"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

const router = useRouter()
const openListingAction = () => router.push({ name: FinancePlanningRouteName })

const [isOpened, toggleOpened] = useToggle(true)

const store = useBudgetStore()
const { createError, isCreating, isCreateFinished } = storeToRefs(store)

const formRef = ref<InstanceType<typeof BudgetForm> | null>(null)

const submitAction = () => formRef.value?.submitAction()

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const createPlanning = async (data: Ref<BudgetFormInterface>) => {
  await store.createBudget(data.value)
  toggleOpened(false)
  await store.updateBufferAfterCreate()
}

const isCreatingButtonDisabled = computed(() => isCreating.value || isCreateFinished.value)

onUnmounted(() => {
  isCreateFinished.value = false
})
</script>

<template>
  <APageDrawer v-model:open="isOpened" title="Создание бюджета" @onClose="openListingAction">
    <BudgetForm ref="formRef" @submit="createPlanning" />

    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="isCreatingButtonDisabled"
            @click="submitAction"
            >Создать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
