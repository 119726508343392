import type { IPorfileStatus, Profile } from "../../interfaces"

import { inject, ref, type ShallowRef, shallowRef } from "vue"
import { noop } from "@vueuse/core"

import { ProfileStatus } from "../../interfaces"

import {
  DECREMENT_ATTEMPTS_COUNT,
  PROFILE,
  PROFILE_LOADING,
  PROFILE_REFRESH,
  PROFILE_RESET,
  PROFILE_STATUS
} from "./constants"

export const injectProfile = () => inject(PROFILE, ref<undefined | Profile>())
export const injectAttemptDecrement = () => inject(DECREMENT_ATTEMPTS_COUNT, noop)
export const injectProfileRefresh = () =>
  inject<() => Promise<undefined | Profile>>(PROFILE_REFRESH, () => Promise.resolve(undefined))
export const injectProfileReset = () => inject<() => void>(PROFILE_RESET, noop)
export const injectProfileStatus = () =>
  inject<ShallowRef<IPorfileStatus>>(
    PROFILE_STATUS,
    shallowRef({
      attempts: null,
      status: ProfileStatus.UNKNOWN,
      deltedAt: null,
      asAdmin: false,
      authAsUser: false
    })
  )
export const injectProfileLoading = () => inject(PROFILE_LOADING, ref(false))
