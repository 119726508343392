import { createNotivue } from "notivue"

export const pushNotificatonsPlugin = createNotivue({
  position: "top-right",
  pauseOnHover: true,
  pauseOnTouch: true,
  pauseOnTabChange: true,
  notifications: {
    global: {
      duration: 3000
    }
  }
})
