<script setup lang="ts">
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue"
import { useRouter } from "vue-router"
import { ref, h, computed, watch } from "vue"
import { noop, watchThrottled } from "@vueuse/core"
import { push } from "notivue"
import { TransitionFade, TransitionSlide } from "@morev/vue-transitions"
import { storeToRefs } from "pinia"

import { CounterpartyListing, useCounterpartiesStore } from "@/modules/counterparty"
import {
  CounterpartiesReferencesCreateRouteName,
  CounterpartiesReferencesDetailRouteName,
  CounterpartiesReferencesEditRouteName
} from "@/router"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { ApiNotFoundResponse } from "@/package/api-client"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { injectProfile, useProfileSubscription } from "@/modules/profile"

const profile = injectProfile()
const isSubscriptionExpired = computed(() => useProfileSubscription(profile?.value).value)

const listingRef = ref<InstanceType<typeof CounterpartyListing> | null>(null)
const allChecked = computed({
  get: () => listingRef.value?.isAllSelected,
  set: (value) => {
    listingRef.value?.toggleAllSelected(value)
  }
})

const store = useCounterpartiesStore()

const { counterpartyDeleteListError, counterpartyDeleteError } = storeToRefs(store)

const searchQuery = ref<string>("")
watchThrottled(
  searchQuery,
  (value) => {
    store.searchListing(value)
  },
  { throttle: 1000, leading: false }
)

const summary = computed(() => {
  let result = null
  if (
    listingRef.value &&
    listingRef.value.selectedCount > 0 &&
    !(store.listingApiError?.status === ApiNotFoundResponse.STATUS)
  ) {
    result = `Выбрано ${listingRef.value.selectedCount} из ${store.paginationTotal}`
  }
  return result
})

watch(counterpartyDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(counterpartyDeleteListError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const deleteAction = () => {
  DeleteConfirm({
    content:
      "Вы действительно хотите безвозвратно удалить выбранные записи? Этот контрагент используется в товарах и услугах",
    centered: true,
    onOk: () => {
      if (listingRef.value !== null) {
        let resCb: (args: any) => Promise<void> = () => Promise.resolve()
        if (listingRef.value) {
          if (listingRef.value.excludeKeysMode) {
            resCb = store.deleteByExcludedIdList
          } else {
            resCb = store.deleteBySelectedIdList
          }
        }
        return resCb(listingRef.value.currentModeKeys)
          .then(() => {
            push.success({ message: "Удалено" })
          })
          .catch(noop)
          .finally(listingRef.value?.unselectAllAction)
      }
    }
  })
}

const router = useRouter()
const openCreateAction = () => router.push({ name: CounterpartiesReferencesCreateRouteName })
const openDetailAction = (id: string | number) =>
  router.push({
    name: CounterpartiesReferencesDetailRouteName,
    params: { id }
  })
const openEditAction = (id: string | number) =>
  router.push({ name: CounterpartiesReferencesEditRouteName, params: { id } })

const isAdoptable = useAdoptable()
</script>

<template>
  <ARow
    :gutter="[16, 0]"
    justify="space-between"
    :style="{ marginTop: '32px', alignItems: 'center' }"
  >
    <ACol :xs="isAdoptable ? 0 : undefined">
      <AButton
        default-styling
        size="large"
        type="primary"
        :disabled="isSubscriptionExpired"
        @click="openCreateAction"
      >
        Добавить
      </AButton>
    </ACol>
    <ACol :xs="24" :md="12" :lg="8">
      <AInputSearch
        v-model:value="searchQuery"
        size="large"
        placeholder="Название, контактное лицо, телефон"
        :loading="store.isListingLoading && searchQuery.length > 0"
        @search="store.searchListing"
      />
    </ACol>
  </ARow>
  <ARow :gutter="[16, 0]" :style="{ marginTop: isAdoptable ? '32px' : '16px' }">
    <ACol :xs="0" :md="24" :style="{ minHeight: '32px' }">
      <TransitionFade>
        <ASpace v-if="summary !== null" :size="16">
          <div>{{ summary }}</div>
          <AButton type="text" danger :icon="h(DeleteOutlined)" @click="deleteAction">
            Удалить
          </AButton>
        </ASpace>
      </TransitionFade>
    </ACol>
    <ACol :xs="24" :md="0">
      <AFlex :gap="16" justify="space-between">
        <ACheckbox v-model:checked="allChecked" :indeterminate="summary !== null && !allChecked">
          Выбрать все
        </ACheckbox>

        <TransitionSlide>
          <div v-if="summary !== null">{{ summary }}</div>
        </TransitionSlide>
      </AFlex>
    </ACol>
  </ARow>
  <CounterpartyListing
    ref="listingRef"
    :style="{ marginTop: '16px', flexGrow: 1 }"
    :disabled="isSubscriptionExpired"
    @openDetail="openDetailAction"
    @openCreate="openCreateAction"
    @openEdit="openEditAction"
  />
  <RouterView />
  <AListingControlPanel v-if="isAdoptable">
    <AButton size="large" type="primary" :icon="h(PlusOutlined)" @click="openCreateAction" />
    <AButton
      v-if="summary !== null"
      type="default"
      size="large"
      danger
      :icon="h(DeleteOutlined)"
      @click="deleteAction"
    >
      Удалить
    </AButton>
  </AListingControlPanel>
</template>
