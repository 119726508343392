import { Modal } from "ant-design-vue"
import { h } from "vue"
import { ExclamationCircleOutlined } from "@ant-design/icons-vue"

export const copyModal = (onOk: () => unknown) => {
  Modal.confirm({
    autoFocusButton: null,
    maskClosable: true,
    class: "ant-modal-confirm--custom",
    title: "Копирование записи",
    content: "Подтвердите копирование записи",
    okText: "Подтвердить",
    cancelText: "Отмена",
    icon: h(ExclamationCircleOutlined),
    centered: true,
    cancelButtonProps: { block: true },
    okButtonProps: { block: true },
    onOk
  })
}
