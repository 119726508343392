<script setup lang="ts">
import { useRouter } from "vue-router"
import { useToggle } from "@vueuse/core"
import { computed, h, onUnmounted } from "vue"
import { CloseOutlined } from "@ant-design/icons-vue"
import { theme } from "ant-design-vue"
import { push } from "notivue"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"

import { FinanceOperationsRouteName } from "@/router"
import {
  OperationAction,
  type OperationCreatRequest,
  useOperationFormStateProvider,
  useCreateOperationTabs,
  useOperationStore,
  useOperationCreate
} from "@/modules/operation"
import { ApiBadReqResponse, ApiForbiddenResponse } from "@/package/api-client"
import { APageDrawer, MAX_Z_INDEX } from "@/package/ui-kit"

const router = useRouter()
const openListingAction = () => router.push({ name: FinanceOperationsRouteName })

const [isOpened, toggleOpened] = useToggle(true)
const closeAction = () => toggleOpened(false)

const store = useOperationStore()

useOperationFormStateProvider()
const { current, submitAction, tabList, setFormRef } = useCreateOperationTabs()

const { token } = theme.useToken()

const { createOperation, isCreating, isCreateFinished } = useOperationCreate()
const createOperationAction = async (formData: OperationCreatRequest) => {
  try {
    await createOperation(formData)
    switch (current.value) {
      case OperationAction.incoming:
        push.success({ message: "Доход зафиксирован!" })
        break
      case OperationAction.outgoing:
        push.success({ message: "Расход зафиксирован!" })
        break
      case OperationAction.transfer:
        push.success({ message: "Перевод зафиксирован!" })
        break
    }
    toggleOpened(false)
    await store.updateBuffer()
  } catch (e) {
    isCreateFinished.value = false
    if (e instanceof ApiBadReqResponse || e instanceof ApiForbiddenResponse) {
      push.error({ message: e.message })
    } else {
      switch (current.value) {
        case OperationAction.incoming:
          push.error({ message: "Не удается зафискировать доход, обратитесь в поддержку" })
          break
        case OperationAction.outgoing:
          push.error({ message: "Не удается зафискировать расход, обратитесь в поддержку" })
          break
        case OperationAction.transfer:
          push.error({ message: "Не удается зафискировать перевод, обратитесь в поддержку" })
          break
      }
      isCreateFinished.value = false
    }
  }
}

const isCreatingOperation = computed(() => isCreateFinished.value || isCreating.value)

onUnmounted(() => {
  isCreateFinished.value = false
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    custom-scroll-body
    :closeable="false"
    :body-style="{
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '--body-color': token.colorBgBase,
      '--zIndex': MAX_Z_INDEX
    }"
    @onClose="openListingAction"
  >
    <AFlex
      :style="{
        padding: '24px 24px 8px',
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: token.colorBgBase,
        zIndex: 'var(--zIndex)'
      }"
    >
      <ATypographyTitle :level="4" :style="{ margin: 0 }">Создание операции</ATypographyTitle>
      <AButton
        :style="{ position: 'absolute', right: '16px', top: '24px' }"
        type="link"
        class="ant-drawer-close"
        :icon="h(CloseOutlined)"
        @click="closeAction"
      />
    </AFlex>
    <ATabs v-model:active-key="current" class="operation-type-tabs" destroy-inactive-tab-pane>
      <ATabPane v-for="option in tabList" :key="option.key" :tab="option.tab">
        <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
          <component
            :is="option.form"
            :ref="(el: any) => setFormRef(option.key, el)"
            disable-split
            clean-files-on-change
            @submit="createOperationAction"
          />
        </OverlayScrollbarsComponent>
      </ATabPane>
    </ATabs>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="isCreatingOperation"
            @click="submitAction"
            >Создать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped>
.operation-type-tabs {
  overflow: hidden;
}
.operation-type-tabs:deep(.ant-tabs-tab:first-child) {
  margin-left: 24px;
}

.operation-type-tabs:deep(.ant-tabs-content) {
  overflow: hidden;
  max-height: 100%;
}
.operation-type-tabs:deep(.ant-tabs-nav) {
  z-index: var(--zIndex);
  background-color: var(--body-color);
}
</style>
