import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { push } from "notivue"

import type { SortingDTO } from "@/interfaces"
import { ApiBadReqResponse, client } from "@/package/api-client"

export const useGetCounterpartiesSorting = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: string; data: Partial<SortingDTO> },
    { ids: Array<number> }
  >("/parameters/counterparties-sorting", { method: "GET" }, client, {
    immediate: false
  })

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  watch(errorMessage, () => {
    if (errorMessage.value !== null) {
      push.error({ message: errorMessage.value })
    }
  })

  const getSorting = async () => {
    const response = await execute()
    return response.data.value?.data
  }

  return {
    getSorting,
    isSortingLoading: isLoading,
    isSortingLoaded: isFinished
  }
}
