<script setup lang="ts">
import type { PropType } from "vue"

import { vMaska } from "maska"
import { theme } from "ant-design-vue"

import { formatThousands, parseThousands } from "@/package/util"
defineProps({
  hideAddon: {
    type: Boolean,
    default: () => false
  }
})
const valueModel = defineModel({
  type: [String, Number] as PropType<string | number>
})
const priceMaskOptions = {
  preProcess: parseThousands,
  postProcess: formatThousands
}

const { token } = theme.useToken()
</script>

<template>
  <AInput
    v-model="valueModel"
    v-maska:[priceMaskOptions]
    data-maska="0.99"
    data-maska-tokens="0:\d:multiple|9:\d:optional"
    :addon-after="hideAddon ? undefined : 'руб'"
  />
</template>

<style scoped>
:deep(.ant-input-group-addon) {
  background-color: v-bind("token.colorBgBase");
}
</style>
