import type { FormValidator } from "./types"

export function validateBik(
  bik?: string | number,
  required?: boolean | null | undefined
): [
  boolean,
  {
    code: number
    message: string
  } | null
] {
  let error = null
  let result = false
  if (typeof bik === "number") {
    bik = bik.toString()
  } else if (typeof bik !== "string") {
    bik = ""
  }
  if (required && !bik.length) {
    error = {
      code: 1,
      message: "Пожалуйста, введите БИК"
    }
  } else if (/[^0-9]/.test(bik)) {
    error = {
      code: 2,
      message: "БИК может состоять только из цифр"
    }
  } else if (bik.length && bik.length !== 9) {
    error = {
      code: 3,
      message: "Бик должен содержать 9 символов"
    }
  } else {
    result = true
  }
  return [result, error]
}

export const bikValidator: FormValidator = (_, value: string) =>
  new Promise((resolve, reject) => {
    const [result, error] = validateBik(value, _.required)
    if (!result) {
      reject(error?.message)
    } else {
      resolve(undefined)
    }
  })
