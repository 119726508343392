import type { CheckPromocode } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useValidatePromocode = () => {
  const { execute, data, isLoading, error } = useAxios<CheckPromocode>(
    "/promocode",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const validatePromocode = async (promocode: string) => {
    const payload = {
      promocode
    }
    return await execute("/promocode", { data: payload })
  }

  return {
    validationData: data,
    validatePromocode,
    isValidating: isLoading,
    validationError: useApiError(error)
  }
}
