<script setup lang="ts">
import { useRouter } from "vue-router"
import { onMounted, watch, ref, computed } from "vue"
import { useToggle } from "@vueuse/core"
import { push } from "notivue"
import dayjs from "dayjs"

import { PhysProfileRouteName } from "@/router"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { injectProfile, injectProfileRefresh, SubscriptionBadge } from "@/modules/profile"
import {
  SubscriptionPeriod,
  useGetCurrentSubscription,
  useCancelSubscription,
  useChangeSubscriptionPayment
} from "@/modules/subscription"

const profile = injectProfile()
const refreshAsync = injectProfileRefresh()

const router = useRouter()

const [isOpened, toggleOpened] = useToggle(true)

const {
  subscriptionCurrentData,
  getCurrentSubscription,
  SubscriptionCurrentPending,
  SubscriptionCurrentError
} = useGetCurrentSubscription()

const { changingPaymentLink, getChangingPaymentLink } = useChangeSubscriptionPayment()

const { cancelSubscription, CancelSubscriptionError } = useCancelSubscription()

onMounted(async () => {
  await getCurrentSubscription()
})

watch(SubscriptionCurrentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(CancelSubscriptionError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const getSubscriptionPeriod = (period: SubscriptionPeriod) => {
  switch (period) {
    case SubscriptionPeriod.Trial:
      return "14 дней"
    case SubscriptionPeriod.OneMonth:
      return "1 месяц"
    case SubscriptionPeriod.ThreeMonths:
      return "3 месяца"
    case SubscriptionPeriod.SixMonths:
      return "6 месяцев"
    case SubscriptionPeriod.TwelveMonths:
      return "12 месяцев"
    default:
      return "Безлимитный период"
  }
}
const openConfirm = ref(false)
const openConfirmModal = () => {
  openConfirm.value = true
}

const cancelSubscriptionHandler = async () => {
  await cancelSubscription()
  openConfirm.value = false
  toggleOpened(false)
  await refreshAsync()
  push.success({ message: "Подписка отменена" })
}

const changePayment = async () => {
  await getChangingPaymentLink()

  const width = 850
  const height = 550

  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2

  window.open(
    changingPaymentLink.value?.data?.card as string,
    "change-payment-modal",
    `width=${width},height=${height},left=${left},top=${top}`
  )
}

const SubscriptionFinishText = computed(() => {
  if (
    profile?.value?.is_cancel_subscription ||
    !subscriptionCurrentData?.value?.data?.card_number
  ) {
    return "Закончится"
  }
  return "Будет продлена"
})
const SubscriptionFinish = computed(() => {
  if (
    profile?.value?.is_cancel_subscription ||
    !subscriptionCurrentData?.value?.data?.card_number
  ) {
    return dayjs(subscriptionCurrentData?.value?.data?.subscription?.finish_at)
      .utc()
      .format("DD.MM.YYYY")
  }
  return dayjs(subscriptionCurrentData?.value?.data?.payment?.next_payment_date)
    .utc()
    .format("DD.MM.YYYY")
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Управление подпиской"
    @onClose="() => router.push({ name: PhysProfileRouteName })"
    ><ASkeleton v-if="SubscriptionCurrentPending" active :paragraph="{ rows: 8 }" />
    <template v-else>
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>Текущая подписка</ATypographyText
        ><AFlex align="center"
          ><SubscriptionBadge
            v-if="subscriptionCurrentData?.data?.product?.name === 'PRO'"
            :style="{ marginRight: '8px' }"
            :subscription="subscriptionCurrentData?.data?.product?.name"
          /><ATypographyText class="details-info">{{
            getSubscriptionPeriod(
              subscriptionCurrentData?.data?.tariff?.period as SubscriptionPeriod
            )
          }}</ATypographyText></AFlex
        >
      </AFlex>
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>{{ SubscriptionFinishText }}</ATypographyText
        ><AFlex align="center"
          ><ATypographyText class="details-info"> {{ SubscriptionFinish }}</ATypographyText></AFlex
        >
      </AFlex>
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>Сумма списания</ATypographyText
        ><AFlex align="center"
          ><ATypographyText class="details-info">{{
            subscriptionCurrentData?.data?.tariff?.cost
              ? Number(subscriptionCurrentData?.data?.tariff?.cost) / 100
              : "—"
          }}</ATypographyText></AFlex
        >
      </AFlex>
      <div class="divider" />
      <AFlex class="details-row" justify="space-between" align="center">
        <ATypographyText>Способ оплаты</ATypographyText
        ><AFlex align="center"
          ><ATypographyText class="details-info">{{
            subscriptionCurrentData?.data?.card_number ?? "—"
          }}</ATypographyText></AFlex
        >
      </AFlex>
      <AButton
        :style="{ marginBottom: '24px' }"
        size="large"
        type="default"
        block
        disabled
        @click="changePayment"
        >Сменить способ оплаты</AButton
      >
      <template v-if="!profile?.is_cancel_subscription">
        <div class="divider" />
        <AButton class="cancel-button" type="link" danger @click="openConfirmModal"
          >Отменить подписку</AButton
        >
        <ATypographyText type="secondary"
          >При отмене подписки ПРО, подписка ФРИ будет подключена автоматически</ATypographyText
        >
      </template>
    </template>
  </APageDrawer>
  <AModal v-model:open="openConfirm" :footer="null">
    <ATypographyTitle :style="{ margin: 0 }" :level="3">
      Вы уверены, что хотите отключить подписку ПРО?
    </ATypographyTitle>
    <ATypographyTitle :style="{ marginTop: '16px' }" :level="5">
      Функции ПРО будут доступны до
      {{ dayjs(subscriptionCurrentData?.data?.subscription?.finish_at).utc().format("DD.MM.YYYY") }}
    </ATypographyTitle>
    <ARow :gutter="[24, 24]">
      <ACol :xs="12" :xl="12">
        <AButton
          block
          type="primary"
          size="large"
          :style="{ marginTop: '20px' }"
          @click="cancelSubscriptionHandler"
        >
          Да
        </AButton>
      </ACol>
      <ACol :xs="12" :xl="12">
        <AButton block size="large" :style="{ marginTop: '20px' }" @click="openConfirm = false">
          Отмена
        </AButton>
      </ACol>
    </ARow>
  </AModal>
</template>

<style scoped>
.details-row {
  margin-bottom: 16px;
}

.details-info {
  font-size: 16px;
}

.divider {
  height: 1px;
  background: #f0f0f0;
  width: 100%;
  margin-bottom: 16px;
}

.cancel-button {
  display: block;
  position: relative;
  margin: 0 auto 12px;
}

.cancel-button:after {
  content: "";
  width: calc(100% - 30px);
  height: 1px;
  background: #ff7875;
  position: absolute;
  bottom: 9px;
  left: 15px;
}
</style>
