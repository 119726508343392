import type { ImportEvents } from "../../../centrifugo/hooks/use-import-bus"
import type { OperationsCreateEvents } from "../../../centrifugo/hooks/use-operations-create-bus"

import { defineStore } from "pinia"
import { computed, type MaybeRef, ref, watch } from "vue"
import { get, noop, set } from "@vueuse/core"
import { push } from "notivue"
import { HttpStatusCode } from "axios"

import { useListBufferApi, usePagination } from "@/package/hooks"
import type { OperationListElementDTO } from "@/modules/operation"

import { useOperationRestore } from "../../hooks/use-operation-restore"
import { useOperationTrashBinDelete } from "../../hooks/use-operation-trash-bin-delete"
import { useOperationTrashBinList } from "../../hooks/use-operation-trash-bin-list"

export const useOperationTrashBinStore = defineStore("OperationTrashBin", () => {
  const { pagination, paginationTotal, setPagination, decrementTotal } = usePagination()
  const listingBuffer = ref(new Array<OperationListElementDTO>())
  const transactionsCount = computed(() => {
    let res = 0
    for (let i = 0; i < listingBuffer.value.length; i++) {
      res++
      if ((get(listingBuffer, i).transactions ?? []).length > 1) {
        res += (get(listingBuffer, i).transactions ?? []).length
      }
    }
    return res
  })
  const operationsIdList = computed(() => get(listingBuffer).map(({ id }) => id))

  const getBufferLength = () => get(listingBuffer, "length")

  const {
    bufferRO,
    updateBufferFn,
    setUpdateFn,
    bufferConcat,
    bufferRewrite,
    bufferFilterOneConcat
  } = useListBufferApi(listingBuffer)
  const {
    requestListing,
    isListingLoading,
    operationListingResponse,
    listingApiError,
    responseStatus
  } = useOperationTrashBinList()

  const isResourceEmpty = computed(
    () => get(responseStatus) !== null && get(responseStatus) === HttpStatusCode.NoContent
  )
  const isLoadedFull = computed(() => {
    const total = get(pagination)?.total
    return (total !== null && total <= get(listingBuffer).length) || get(isResourceEmpty)
  })

  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
    }
  })
  watch(operationListingResponse, (listing) => {
    if (listing && listing.data.length > 0) {
      setPagination(listing.pagination)
      updateBufferFn(listing.data)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })

  const loadNextPart = async () => {
    setUpdateFn(bufferConcat)
    await requestListing({
      offset: get(listingBuffer, "length"),
      limit: get(pagination, "limit")
    })
  }
  const updateBuffer = async () => {
    const bufferLengthBeforeRewrite = getBufferLength()

    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: 0,
      limit: 100
    }).catch(noop)

    setUpdateFn(bufferConcat)
    while (getBufferLength() < bufferLengthBeforeRewrite && getBufferLength() !== 0) {
      const limit =
        bufferLengthBeforeRewrite - getBufferLength() > 100
          ? 100
          : bufferLengthBeforeRewrite - getBufferLength()

      await requestListing({
        offset: getBufferLength(),
        limit
      }).catch(noop)
    }
  }

  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: 0,
      limit: get(listingBuffer, "length")
    })
  }

  const { executeListRestore, operationRestoreError, isRestoring } = useOperationRestore()

  const restoreByExcludedIdList = async (idList: MaybeRef<Array<number>>) => {
    await executeListRestore({ exclude_ids: get(idList) })
    await reloadBuffer().catch(noop)
  }
  const restoreBySelectedIdList = async (idList: MaybeRef<Array<number>>) => {
    await executeListRestore({ include_ids: get(idList) })
    await reloadBuffer().catch(noop)
  }

  const restoreOperation = async (ID: MaybeRef<number>) => {
    const id = get(ID)
    if (isLoadedFull.value) {
      await executeListRestore({ include_ids: [id] })
      bufferFilterOneConcat(id, [])
      decrementTotal()
    } else {
      await executeListRestore({ include_ids: [id] })
      setUpdateFn(bufferFilterOneConcat.bind(null, id))
      await requestListing({
        offset: getBufferLength(),
        limit: 1
      })
    }
  }

  const { executeListTrashBinDelete, operationDeleteError, isDeleting } =
    useOperationTrashBinDelete()
  const deleteByExcludedIdList = async (idList: MaybeRef<Array<number>>) => {
    await executeListTrashBinDelete({ exclude_ids: get(idList) })
    await reloadBuffer().catch(noop)
  }
  const deleteBySelectedIdList = async (idList: MaybeRef<Array<number>>) => {
    await executeListTrashBinDelete({ include_ids: get(idList) })
    await reloadBuffer().catch(noop)
  }

  const deleteOperation = async (ID: MaybeRef<number>) => {
    const id = get(ID)
    if (isLoadedFull.value) {
      await executeListTrashBinDelete({ include_ids: [id] })
      bufferFilterOneConcat(id, [])
      decrementTotal()
    } else {
      await executeListTrashBinDelete({ include_ids: [id] })
      setUpdateFn(bufferFilterOneConcat.bind(null, id))
      await requestListing({
        offset: getBufferLength(),
        limit: 1
      })
    }
  }

  const $reset = () => {
    setPagination()
    set(listingBuffer, [])
  }

  const busEventsHandler = async (
    successMessage: string | null,
    event: ImportEvents | OperationsCreateEvents
  ) => {
    switch (event) {
      case "error":
        break
      case "success":
        if (successMessage !== null) {
          push.success({ message: successMessage })
        }
        await updateBuffer()
    }
  }

  return {
    isLoadedFull,
    isResourceEmpty,
    listingApiError,
    paginationTotal,
    pagination,
    isListingLoading,
    transactionsCount,
    operationsIdList,
    listing: bufferRO,
    restoreByExcludedIdList,
    restoreBySelectedIdList,
    restoreOperation,
    operationRestoreError,
    isRestoring,
    deleteByExcludedIdList,
    deleteBySelectedIdList,
    deleteOperation,
    operationDeleteError,
    isDeleting,
    loadNextPart,
    updateBuffer,
    busEventsHandler,
    $reset
  }
})
