<script setup lang="ts">
import { useRouter } from "vue-router"
import { computed, h, onUnmounted, ref, watch } from "vue"
import { CloseCircleOutlined } from "@ant-design/icons-vue"
import { noop, watchThrottled } from "@vueuse/core"
import { TransitionFade, TransitionSlide } from "@morev/vue-transitions"
import plural from "plural-ru"
import { push } from "notivue"

import { UserListing, useUserStore } from "@/modules/admin"
import { AdminUserDetailRouteName, AdminUserEditRouteName } from "@/router"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"

import UserFilters from "../../../modules/admin/components/UserListing/ui/UserFilters.vue"

const store = useUserStore()
onUnmounted(store.$reset)

const searchQuery = ref<string>("")
watchThrottled(
  searchQuery,
  (value) => {
    store.requestSearch(value)
  },
  { throttle: 1000, leading: false }
)

const listingRef = ref<InstanceType<typeof UserListing> | null>(null)
const allChecked = computed({
  get: () => listingRef.value?.isAllSelected,
  set: (value) => {
    listingRef.value?.toggleAllSelected(value)
  }
})
const summary = computed(() => {
  let result = null
  if (listingRef.value && listingRef.value.selectedCount > 0) {
    result = `Выбрано ${listingRef.value.selectedCount} из ${store.paginationTotal}`
  }
  return result
})

const blockListAction = () => {
  DeleteConfirm({
    title: "Блокировка пользователя",
    content: "Вы действительно хотите заблокировать всех выбранных пользователей?",
    okText: "Заблокировать",
    centered: true,
    onOk: () => {
      if (listingRef.value !== null) {
        let resCb: (args: any) => Promise<void> = () => Promise.resolve()
        if (listingRef.value) {
          if (listingRef.value.excludeKeysMode) {
            resCb = store.blockByExcludedIdList
          } else {
            resCb = store.blockBySelectedIdList
          }
        }
        return resCb(listingRef.value.currentModeKeys)
          .then(() => {
            push.success({ message: "Пользователи заблокированы" })
            listingRef.value?.unselectAllAction()
          })
          .catch(noop)
      }
    }
  })
}
watch(
  () => store.batchBlockErrorMessage,
  (error) => {
    if (error) {
      push.error({ message: error })
    }
  }
)

const router = useRouter()
const openDetailAction = (id: string | number) =>
  router.push({ name: AdminUserDetailRouteName, params: { id } })
const openEditAction = (id: string | number) =>
  router.push({ name: AdminUserEditRouteName, params: { id } })
const isAdoptable = useAdoptable()
</script>

<template>
  <ARow
    :gutter="[16, 0]"
    justify="space-between"
    :style="{ marginTop: '32px', alignItems: 'center' }"
  >
    <UserFilters />

    <AInputSearch
      v-model:value="searchQuery"
      size="large"
      placeholder="Фамилия, имя, e-mail"
      :loading="store.isListLoading && searchQuery.length > 0"
      style="width: 400px; padding-bottom: 16px"
      @search="store.requestSearch"
    />
  </ARow>

  <ARow :gutter="[16, 0]">
    <ACol :xs="0" :md="24" :style="{ minHeight: '32px' }">
      <TransitionFade>
        <ASpace v-if="summary !== null" :size="16">
          <div>{{ summary }}</div>
          <AButton type="text" danger :icon="h(CloseCircleOutlined)" @click="blockListAction">
            {{
              plural(
                listingRef?.selectedCount ?? 0,
                "Заблокировать пользователя",
                "Заблокировать пользователей"
              )
            }}
          </AButton>
        </ASpace>
      </TransitionFade>
    </ACol>
    <ACol :xs="24" :md="0">
      <AFlex :gap="16" justify="space-between">
        <ACheckbox v-model:checked="allChecked" :indeterminate="summary !== null && !allChecked">
          Выбрать все
        </ACheckbox>

        <TransitionSlide>
          <div v-if="summary !== null">{{ summary }}</div>
        </TransitionSlide>
      </AFlex>
    </ACol>
  </ARow>

  <UserListing
    ref="listingRef"
    :style="{ marginTop: '16px', flexGrow: 1 }"
    @openDetail="openDetailAction"
    @openEdit="openEditAction"
  />

  <RouterView />

  <AListingControlPanel v-if="isAdoptable">
    <AButton
      v-if="summary !== null"
      type="default"
      size="large"
      danger
      :icon="h(CloseCircleOutlined)"
      @click="blockListAction"
    >
      Заблокировать
    </AButton>
  </AListingControlPanel>
</template>
