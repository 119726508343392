import { useAxios } from "@vueuse/integrations/useAxios"

import { useApiError, client } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useOperationTrashBinDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: Array<number> }>(
    "/operations/trash",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const executeListTrashBinDelete = async (data: SelectListAction<string | number>) =>
    await execute({ data })

  return {
    executeListTrashBinDelete,
    isDeleting: isLoading,
    operationDeleteError: useApiError(error)
  }
}
