import { computed, reactive } from "vue"

import type { LegalEntityCreate } from "@/modules/legal-entity"
interface CreateFormState extends Omit<LegalEntityCreate, "monthly_turnover_rate" | "okved_id"> {
  monthly_turnover_rate?: number
  okved_id?: number
  bank_account_id?: number
}

export const useFormState = () => {
  const formState = reactive<CreateFormState>({
    itn: "",
    organization_name: "",
    legal_address: "",
    okved_id: undefined,
    okved_code: "",
    head_fullname: "",
    bank_account_id: undefined,
    bic: "",
    bank_name: "",
    account_number: "",
    correspondent_account_number: "",
    monthly_turnover_rate: undefined
  })
  const requestData = computed<LegalEntityCreate>(() => {
    const {
      itn,
      organization_name,
      legal_address,
      okved_id,
      okved_code,
      head_fullname,
      bic,
      bank_name,
      account_number,
      bank_account_id,
      correspondent_account_number,
      monthly_turnover_rate
    } = formState

    return {
      itn,
      organization_name,
      legal_address,
      okved_id: okved_id ? okved_id : 0,
      okved_code,
      head_fullname,
      bic,
      bank_name,
      account_number,
      bank_account_id,
      correspondent_account_number,
      monthly_turnover_rate: monthly_turnover_rate ? monthly_turnover_rate : 0
    }
  })

  return {
    formState,
    requestData
  }
}
