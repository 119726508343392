import { useRequest } from "vue-request"

import { okvedGet } from "../api/okved.get"

export const useOkvedDetail = () => {
  const { data, error, run, loading } = useRequest(okvedGet, {
    cacheKey(args) {
      let result = "/legal-entities/okved/"
      if (args) {
        const [id] = args
        result += id
      }
      return result
    },
    manual: true
  })
  const getDetail = (id: number | string) => run(id)
  return { okvedDetail: data, isLoading: loading, error, getDetail }
}
