import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { computed, type Ref } from "vue"
import { h, ref, watch } from "vue"
import { Flex, TypographyText } from "ant-design-vue"

import { AText, renderPlaceholder, useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import { type Product, useProductsStore } from "@/modules/product"
import { SortOrder, SortOrderInternal } from "@/interfaces"

const defaultSizes = {
  article: 100,
  type: 80,
  name: 205,
  counterparty: 214,
  unit_type: 90,
  self_cost: 180,
  cost: 180,
  description: 394,
  action: 64
}
const adoptedColumns = [
  {
    key: "id",
    customRender: ({ record }: { record: Product }) =>
      h(Flex, { vertical: true }, () => [
        h(Flex, { style: { width: "100%" }, justify: "space-between", wrap: "wrap" }, () => [
          h(TypographyText, {
            strong: true,
            ellipsis: true,
            content: viewValueOrDefault(record.name)
          }),
          h(TypographyText, { strong: true, content: viewValueOrDefault(record.cost) })
        ]),
        h(AText, {
          type: "secondary",
          style: { marginTop: "8px" },
          content: viewValueOrDefault(record.type)
        })
      ])
  }
]
const defaultColumns = (): ColumnsType<any> => {
  const store = useProductsStore()

  return [
    {
      title: "Артикул",
      key: "article",
      dataIndex: "article",
      ellipsis: { showTitle: true },
      width: defaultSizes.article,
      customRender: renderPlaceholder
    },
    {
      title: "Тип",
      key: "type",
      dataIndex: "type",
      ellipsis: { showTitle: true },
      width: defaultSizes.type,
      customRender: renderPlaceholder
    },
    {
      title: "Название",
      key: "name",
      dataIndex: "name",
      ellipsis: { showTitle: true },
      width: defaultSizes.name,
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder) {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Контрагент",
      key: "counterparty",
      dataIndex: ["counterparty", "name"],
      ellipsis: { showTitle: true },
      width: defaultSizes.counterparty,
      customRender: renderPlaceholder
    },
    {
      title: "Ед.изм.",
      key: "unit_type",
      dataIndex: "unit_type",
      ellipsis: { showTitle: true },
      width: defaultSizes.unit_type,
      customRender: renderPlaceholder
    },
    {
      title: "Себестоимость, руб",
      key: "self_cost",
      dataIndex: "self_cost",
      ellipsis: { showTitle: true },
      width: defaultSizes.self_cost,
      customRender: renderPlaceholder
    },
    {
      title: "Стоимость, руб",
      key: "cost",
      dataIndex: "cost",
      ellipsis: { showTitle: true },
      width: defaultSizes.cost,
      customRender: renderPlaceholder
    },
    {
      title: "Описание",
      key: "description",
      dataIndex: "description",
      ellipsis: { showTitle: true },
      width: defaultSizes.description,
      colSpan: 2,
      align: "left",
      customRender: renderPlaceholder
    },
    {
      title: "",
      key: "action",
      width: defaultSizes.action
    }
  ]
}
export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns()
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
