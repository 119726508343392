import type { JwtPayload } from "jwt-decode"

export enum UserRole {
  Manager = "manager",
  Admin = "admin",
  ServiceManager = "service_manager"
}
export interface Profile {
  id?: number
  first_name: string
  last_name: string
  middle_name?: string | null
  email: string
  email_verified_at: string
  phone: string
  role: UserRole
  storage: {
    usage_mb: string
  }
  budget_count: string
  report_count: string
  operations_count: string
  is_trial: boolean
  days_left: number | null
  subscription: string
  is_cancel_subscription?: boolean
}
export type EditableProfile = Omit<
  Profile,
  | "role"
  | "storage"
  | "subscription"
  | "report_count"
  | "operations_count"
  | "budget_count"
  | "email_verified_at"
  | "is_trial"
  | "days_left"
>

export interface ChangePasswordRequest {
  current_password: string
  new_password: string
  new_password_confirmation: string
}

export interface ConfirmChangingPasswordRequest {
  code: string
}

export enum ProfileStatus {
  UNKNOWN,
  ACTIVE,
  UNCONFIRMED
}
export interface IPorfileStatus {
  attempts: number | null
  status: ProfileStatus | undefined
  deltedAt: null | Date
  asAdmin: boolean
  authAsUser: boolean | undefined
}

export interface UnavailableProfileErrorPayload {
  attempts: null
  data: Profile
  deletion_date: number
}

export interface JwtPayloadWithScopes extends JwtPayload {
  scopes: Array<string>
}

export const ProfileRolesMap = {
  [UserRole.Manager]: "Менеджер",
  [UserRole.Admin]: "Администратор",
  [UserRole.ServiceManager]: "Руководитель сервиса"
}

export interface LegalEntityInfo {
  birth_date: string
  kbk: string
  phone: string
  head_fullname: string
  email: string
  organization_name: string
  kpp: string
  gender: string
  citizenship_code: string
  oktmo: string
  document_type_code: string
  document_series: string
  document_number: string
  taxpayer_category_code: string
  payer_tariff_code: string
}

export interface LegalEntityInfoFields {
  birth_date: string | null
  gender: string | undefined
  citizenship_code: string | undefined
  kbk: string
  kpp: string
  ogrnip: string
  oktmo: string
  document_type_code: string | undefined
  document_series: string
  document_number: string
  taxpayer_category_code: string | undefined
  payer_tariff_code: string | undefined
  phone: string
  head_fullname: string
  email: string
  organization_name: string
}

export interface LegalEntityRequestData {
  data: {
    legal_entity: {
      organization_name: string
      head_fullname: string
    }
    info: {
      birth_date: string
      gender: number
      citizenship_code: string
      phone: string
      email: string
      kbk: string
      kpp: string
      ogrnip: string
      oktmo: string
      document_type_code: string
      document_series: string
      document_number: string
      taxpayer_category_code: string
      payer_tariff_code: string
    }
  }
}
