import { useAxios } from "@vueuse/integrations/useAxios"

import type { CreateCounterparty } from "@/modules/counterparty"
import { client, useApiError } from "@/package/api-client"
import { parseNumber } from "@/package/util"

export const UseCounterpartiesCreate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: CreateCounterparty }
  >("/counterparties/create", { method: "POST" }, client, { immediate: false })

  const transformFormDataToRequest = (data: CreateCounterparty): CreateCounterparty => ({
    name: data.name || null,
    contact_person: data.contact_person || null,
    phone: data.phone ? parseInt(parseNumber(data.phone)).toString() : null,
    email: data.email || null,
    comment: data.comment || null
  })

  const createCounterparty = async (formData: CreateCounterparty) => {
    const data = transformFormDataToRequest(formData)
    await execute({ data })
  }

  return {
    createError: useApiError(error),
    createCounterparty,
    isCreating: isLoading,
    isCreateFinished: isFinished
  }
}
