<script setup lang="ts">
import type { BankType } from "../OperationBanksForm/interface"
import type { PropType } from "vue/dist/vue"

import { h, defineProps, computed, ref, watch } from "vue"
import { RetweetOutlined, RightOutlined } from "@ant-design/icons-vue"
import { useRouter } from "vue-router"
import { Form, theme } from "ant-design-vue"
import { useToggle } from "@vueuse/core"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import { push } from "notivue"

import SberIcon from "@/assets/icons/sbank.svg"
import AlphaIcon from "@/assets/icons/abank.svg"
import AlphaIconLegal from "@/assets/icons/abank_legal.svg"
import TIcon from "@/assets/icons/tbank.svg"
import { FinanceOperationsRouteName } from "@/router"
import { useOperationImportStore } from "@/modules/operation"
import { useChannelErrorBus, useImportBus } from "@/modules/centrifugo"
import { AButton } from "@/package/ui-kit"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { neverHandler } from "@/package/util"

import OperationConnectBankForm from "../OperationBanksForm/ui/OperationConnectBankForm/OperationConnectBankForm.vue"
import OperationSyncBankForm from "../OperationBanksForm/ui/OperationSyncBankForm/OperationSyncBankForm.vue"
import { useBankIntegrationSync } from "../../hooks/use-bank-integration-sync"
import { useBankIntegrationDelete } from "../../hooks/use-bank-integration-delete"

const props = defineProps({
  isConnected: {
    type: Boolean,
    required: true
  },

  bankName: {
    type: String,
    default: ""
  },

  variant: {
    type: String as PropType<BankType | undefined>,
    required: true
  },

  authType: {
    type: String,
    default: ""
  },

  integrationId: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(["refetch"])
const router = useRouter()
const openListingAction = () => router.push({ name: FinanceOperationsRouteName })

const [isFormOpen, toggleFormOpen] = useToggle(false)
const [isConnectionFormOpen, toggleConnectionFormOpen] = useToggle(false)

const store = useOperationImportStore()
const importBus = useImportBus()
const channelErrorBus = useChannelErrorBus()

channelErrorBus.on(() => {
  store.toggleImportStatus(false)
})
importBus.on((event) => {
  store.toggleImportStatus(false)
  if (event === "success") {
    openListingAction()
  }
})

const formRef = ref<InstanceType<typeof OperationConnectBankForm> | null>(null)
const syncFormRef = ref<typeof Form | null>(null)
const connectBankAccount = () => {
  formRef.value?.submitAction()
}

const icon = computed(() => {
  if (!props.variant) {
    throw new Error("variant is undefined")
  }

  switch (props.variant) {
    case "sber":
      return SberIcon
    case "alfabank_individual":
      return AlphaIcon
    case "alfabank_legal":
      return AlphaIconLegal
    case "t":
      return TIcon
    default:
      neverHandler(props.variant)
      return null
  }
})

const formTitle = computed(() => `Подключение ${props.bankName}`)

const bankSyncTitle = computed(() => `Синхронизация ${props.bankName}`)

const openForm = () => {
  toggleFormOpen(true)
}

const closeForm = () => {
  toggleFormOpen(false)
}

const openConnectionForm = () => {
  toggleConnectionFormOpen(true)
}

const closeConnectionForm = () => {
  toggleConnectionFormOpen(false)
}

const { token } = theme.useToken()

const { syncBankIntegration, syncBankDataError } = useBankIntegrationSync()
const { deleteBankIntegration, deleteBankDataError } = useBankIntegrationDelete()

const syncHandler = async () => {
  await syncBankIntegration(props.integrationId as number)

  push.success({ message: "Синхронизация включена" })
}

watch(syncBankDataError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(deleteBankDataError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const disableBank = async () => {
  await deleteBankIntegration(props?.integrationId as number)
  push.success({ message: "Синхронизация отключена" })
  closeConnectionForm()
  emit("refetch")
}
</script>

<template>
  <AFlex :style="{ marginTop: '16px' }" justify="space-between" align="center">
    <AFlex>
      <component :is="icon" :style="{ marginRight: '8px', width: '40px', height: '40px' }" />
      <AFlex vertical>
        <AText variant="lg">{{ props.bankName }}</AText>
        <AText v-if="isConnected" :style="{ color: token.colorSuccessText }">Подключен</AText>
        <AText v-else :style="{ color: token.colorErrorText }">Отключен</AText>
      </AFlex>
    </AFlex>

    <div v-if="isConnected">
      <AButton
        size="large"
        type="text"
        :icon="h(RetweetOutlined)"
        :style="{ color: token.colorPrimary, marginRight: '10px' }"
        @click="syncHandler"
      />
      <AButton size="large" type="text" :icon="h(RightOutlined)" @click="openConnectionForm" />
    </div>

    <AButton v-else @click="openForm">Подключить</AButton>
  </AFlex>
  <APageDrawer
    v-if="isFormOpen"
    v-model:open="isFormOpen"
    :title="formTitle"
    class-name="bank-account-drawer"
    :footer-style="{
      minHeight: '57px'
    }"
  >
    <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
      <OperationConnectBankForm
        ref="formRef"
        :form-title="formTitle"
        :bank-type="props.variant as BankType"
        :bank-name="props.bankName"
      />
    </OverlayScrollbarsComponent>

    <template #footer>
      <ARow :gutter="[8, 8]">
        <template v-if="authType !== 'url'">
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block @click="closeForm">Отмена</AButton>
          </ACol>
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block type="primary" @click="connectBankAccount"
              >Подключить</AButton
            >
          </ACol>
        </template>
      </ARow>
    </template>
  </APageDrawer>
  <APageDrawer
    v-if="isConnectionFormOpen"
    v-model:open="isConnectionFormOpen"
    :title="bankSyncTitle"
    class-name="bank-account-drawer"
  >
    <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
      <OperationSyncBankForm
        ref="syncFormRef"
        :auth-type="props.authType"
        :integration-id="props.integrationId"
      />
    </OverlayScrollbarsComponent>

    <template #footer>
      <ARow :gutter="[8, 8]">
        <AButton size="large" block @click="disableBank">Отключить синхронизацию</AButton>
      </ARow>
    </template>
  </APageDrawer>
</template>

<style scoped></style>
