<script setup lang="ts">
import { UserEmailStatus, UserEmailStatusDict } from "../../../interface"

const options = (
  Object.keys(UserEmailStatus).filter(
    (k) => typeof UserEmailStatus[k as any] === "number"
  ) as Array<keyof typeof UserEmailStatus>
).map((key) => ({
  value: UserEmailStatus[key],
  label: UserEmailStatusDict[UserEmailStatus[key]]
}))
</script>

<template>
  <ASelect :options="options" placeholder="Выберите статус e-mail пользователя" />
</template>
