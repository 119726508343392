<script setup lang="ts">
import type { BankAccountSummary } from "../interfaces"

import { computed } from "vue"
import { type PropType } from "vue"

import { viewValueOrDefault } from "@/package/ui-kit"

const props = defineProps({
  bankAccount: {
    type: [Object, undefined] as PropType<BankAccountSummary | undefined>,
    default: () => undefined
  }
})

const bankAccountBalance = computed(() =>
  props.bankAccount?.balance === undefined ? "—" : props.bankAccount?.balance
)
</script>

<template>
  <ADescriptions layout="vertical" :column="24">
    <ADescriptionsItem :span="24" label="Название">{{
      viewValueOrDefault(bankAccount?.name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Юридическое лицо">{{
      viewValueOrDefault(bankAccount?.legal_entity.name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="БИК">{{
      viewValueOrDefault(bankAccount?.bic)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Банк">{{
      viewValueOrDefault(bankAccount?.bank_name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Корреспондентский счет">{{
      viewValueOrDefault(bankAccount?.correspondent_account_number)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Расчетный счет">{{
      viewValueOrDefault(bankAccount?.account_number)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Статус">
      <ATag :color="bankAccount?.active ? 'success' : 'error'">{{
        viewValueOrDefault(bankAccount?.active ? "Активен" : "Не активен")
      }}</ATag>
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Баланс">{{ bankAccountBalance }}</ADescriptionsItem>
  </ADescriptions>
</template>
