import type { OperationCategoryDetail } from "../interfaces"

import { computed, type MaybeRef, watch } from "vue"

import { useOperationCategoryDetail } from "./use-operation-category"

export const useOperationCategoryDetailView = (categoryId: MaybeRef<string | number>) => {
  const {
    data,
    error: detailDataError,
    loading: detailLoading
  } = useOperationCategoryDetail(categoryId)

  const {
    data: parentData,
    run,
    error: parentDataError,
    loading: parentLoading
  } = useOperationCategoryDetail(undefined, true)
  watch(data, (detailData) => {
    if (detailData && detailData.parent_id) {
      run(detailData.parent_id)
    }
  })

  const error = computed(() => detailDataError.value || parentDataError.value)
  const isLoading = computed(() => detailLoading.value || parentLoading.value)
  const detailView = computed(() => {
    let result: OperationCategoryDetail | undefined = undefined
    if (data.value) {
      result = {
        ...data.value,
        type: data.value.type ?? "",
        comment: data.value?.comment ?? "",
        parentName: parentData.value?.name ?? ""
      }
    }
    return result
  })

  return {
    detailView,
    error,
    isLoading
  }
}
