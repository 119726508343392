import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useProductsListDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: Array<number> }>(
    "/products",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const requestListDelete = async (data: SelectListAction<number | string, { query?: string }>) =>
    await execute({ data })

  return {
    requestListDelete,
    isListDeleting: isLoading,
    productDeleteListError: useApiError(error)
  }
}
