import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useOperationCreateFromTemplate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<{
    success: boolean
    operation_category_id: number
  }>("/operation-category-templates/apply", { method: "POST" }, client, { immediate: false })

  const createFromTemplate = async (array: number[]) => {
    await execute({ data: { ids: array } })
  }

  return {
    createFromTemplate,
    isCreatingFromTemplate: isLoading,
    createFromTemplateError: useApiError(error),
    isCreatingFromTemplateFinished: isFinished
  }
}
