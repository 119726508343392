import type { UserDetail } from "../interface"

import { client } from "@/package/api-client"

interface UserDetailRespone {
  success: boolean
  data: UserDetail
}

export const userDetailGet = (user_id: string | number) =>
  client
    .get<UserDetailRespone>("/admin/user", { params: { user_id } })
    .then(({ data }) => data.data)
