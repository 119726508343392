<script setup lang="ts">
import { inject, watch } from "vue"
import { ABILITY_TOKEN } from "@casl/vue"
import { AbilityBuilder, type AbilityTuple, type MatchConditions, PureAbility } from "@casl/ability"
import { useRouter } from "vue-router"

import { FinanceStatistics } from "@/router"
import { injectProfile, useProfileSubscription, UserRole } from "@/modules/profile"

const emit = defineEmits(["adminAuthorized", "userAuthorized"])

const ability = inject<PureAbility>(ABILITY_TOKEN)
const profile = injectProfile()
const router = useRouter()

watch(
  profile,
  async (profile) => {
    const { can, rules, cannot } = new AbilityBuilder<PureAbility<AbilityTuple, MatchConditions>>(
      PureAbility
    )

    if (ability !== undefined && profile !== undefined) {
      if (
        (profile.role === UserRole.Admin || profile.role === UserRole.ServiceManager) &&
        ability.cannot("admin", "app")
      ) {
        can("admin", "app")
        ability?.update(rules)
        emit("adminAuthorized")
      } else if (profile.role === UserRole.Manager) {
        const isSubscriptionExpired = useProfileSubscription(profile)
        if (isSubscriptionExpired.value) {
          const noAccessRoutes = ["FinancePlanningSection", "FinanceReportsPage"]
          if (
            router.currentRoute.value.name &&
            noAccessRoutes.includes(router.currentRoute.value.name.toString())
          ) {
            router.push({ name: FinanceStatistics })
            return
          }
        }
        cannot("admin", "app")
        ability?.update(rules)
        emit("userAuthorized")
      }
    }
  },
  { deep: true }
)
</script>

<template>
  <slot />
</template>
