import type { LoginResponse } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, h } from "vue"
import { noop } from "@vueuse/core"

import { ApiBadReqResponse, ApiForbiddenResponse, client, isApiError } from "@/package/api-client"

export const useLogin = () => {
  const { execute, error, isLoading, data } = useAxios<LoginResponse>(
    "/oauth/token",
    {
      method: "POST"
    },
    client,
    { immediate: false }
  )

  const login = ({ email, password }: { email: string; password: string }) => {
    execute({
      data: {
        username: email,
        password,
        grant_type: "password"
      }
    }).catch(noop)
  }

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      } else if (error.value instanceof ApiForbiddenResponse) {
        result = h("div", { style: { display: "flex", flexDirection: "column" } }, [
          h("span", [
            "На вашем аккаунте была замечена подозрительная активность, в связи с чем он был заблокирован."
          ]),
          h("span", [
            "Пожалуйста, свяжитесь с нами ",
            h("a", { href: "mailto:hello@samo.business" }, ["hello@samo.business"]),
            " для восстановления доступа."
          ])
        ])
      } else if (isApiError(error.value)) {
        const message =
          typeof error.value.response?.data === "object" &&
          error.value.response?.data &&
          "message" in error.value.response.data
            ? error.value.response?.data["message"]
            : undefined

        switch (error.value.response?.status) {
          case 401:
          case 400:
          case 403:
            if (typeof message === "string") {
              result = message
            } else {
              result = "Неверный e-mail или пароль."
            }
            break
          default:
            result = "Неизвестная ошибка, пожалуйста повторите позже или обратитесь в поддержку."
        }
      } else {
        result = "Неизвестная ошибка, пожалуйста повторите позже или обратитесь в поддержку."
      }
    }
    return result
  })
  return {
    isLoading,
    login,
    errorMessage,
    data
  }
}
