import type { App, Plugin } from "vue"
import type { Router } from "vue-router"

import * as Sentry from "@sentry/vue"

const sentryAbleEnv = ["pubprev", "prod"]
export const sentryPlugin: Plugin = {
  install: (app: App, { router }: { router: Router }) => {
    if (sentryAbleEnv.includes(import.meta.env.VITE_APP_ENV)) {
      Sentry.init({
        environment: import.meta.env.VITE_APP_ENV,
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration({
            router
          }),
          Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true
          })
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0
      })
    }
  }
}
