<script setup lang="ts">
import { UserStatus, UserStatusDict } from "../../../interface"

const options = (
  Object.keys(UserStatus).filter((k) => typeof UserStatus[k as any] === "number") as Array<
    keyof typeof UserStatus
  >
)
  .filter((key) => UserStatus[key] !== UserStatus.block)
  .map((key) => ({
    value: UserStatus[key],
    label: UserStatusDict[UserStatus[key]]
  }))
</script>

<template>
  <ASelect :options="options" placeholder="Выберите статус пользователя" />
</template>
