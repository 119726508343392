import { computed, reactive } from "vue"

export const useFormState = () => {
  const formState = reactive<{ template?: number }>({ template: undefined })
  const requestData = computed<number | undefined>(() => {
    const { template } = formState

    return template
  })

  return {
    formState,
    requestData
  }
}
