import { defineComponent, inject, provide, readonly, shallowRef, watch } from "vue"
import { type RouteMeta, useRoute } from "vue-router"
import { noop } from "@vueuse/core"

const CURRENT_ROUTE_META_SETTER = Symbol("CURRENT_ROUTE_META_SETTER")
export const injectRouteMetaSetter = () =>
  inject<(meta?: RouteMeta) => void>(CURRENT_ROUTE_META_SETTER, noop)

const CURRENT_ROUTE_BREADCRUMB_SETTER = Symbol("CURRENT_ROUTE_BREADCRUMB_SETTER")
export const injectRouteBcSetter = () =>
  inject<(breadcrumb?: RouteMeta["breadcrumb"]) => void>(CURRENT_ROUTE_BREADCRUMB_SETTER, noop)

const CURRENT_ROUTE_META = Symbol("CURRENT_ROUTE_META")
export const injectRouteMeta = () => inject(CURRENT_ROUTE_META, shallowRef<RouteMeta>())

export const useCurrentRoute = () => {
  const setCurrentRoute = injectRouteMetaSetter()
  const currentRouteMeta = injectRouteMeta()

  const currentRoute = useRoute()
  watch(currentRoute, () => {
    setCurrentRoute(undefined)
  })

  return { currentRoute, currentRouteMeta }
}

export const CurrentRouteProvider = defineComponent({
  name: "CurrentRouteProvider",

  setup(_, { slots }) {
    const currentRouteMeta = shallowRef<RouteMeta>()
    provide(CURRENT_ROUTE_META_SETTER, (meta?: RouteMeta) => {
      currentRouteMeta.value = meta
    })
    provide(CURRENT_ROUTE_BREADCRUMB_SETTER, (bc?: RouteMeta["breadcrumb"]) => {
      currentRouteMeta.value = {
        ...currentRouteMeta.value,
        breadcrumb: bc
      }
    })
    provide(CURRENT_ROUTE_META, readonly(currentRouteMeta))

    return slots?.default
  }
})
