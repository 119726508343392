import type { BudgetListingColumn } from "./interface"

import { computed } from "vue"

import { APHEX_CHART_LOCALIZATION } from "@/package/ui-kit"

import { useBudgetDetailStore } from "./store"

export const useColumns = () => {
  const store = useBudgetDetailStore()

  const getDateTitle = (slug: string) => {
    const [month, year] = slug.split("-")
    return `${APHEX_CHART_LOCALIZATION.locales[0].options.months[+month - 1]} ${year}`
  }

  const columns = computed(() => {
    const columnsData: BudgetListingColumn[] = [
      {
        title: "Общий бюджет",
        key: "category_name",
        dataIndex: "category_name",
        width: 300,
        fixed: true
      }
    ]
    store.budget.columns.forEach(({ slug }) => {
      const column = {
        title: slug === "summary" ? "Итог" : getDateTitle(slug),
        width: 600,
        children: [
          {
            title: "План",
            key: `${slug}-target`,
            dataIndex: `${slug}-target`,
            width: 150
          }
        ]
      }

      if (store.filters.factual) {
        column.children.push({
          title: "Факт",
          key: `${slug}-factual`,
          dataIndex: `${slug}-factual`,
          width: 150
        })
      }

      if (store.filters.declined) {
        column.children.push({
          title: "Откл.",
          key: `${slug}-absolut_value`,
          dataIndex: `${slug}-absolut_value`,
          width: 150
        })
      }

      if (store.filters.percentage) {
        column.children.push({
          title: "%",
          key: `${slug}-percents_value`,
          dataIndex: `${slug}-percents_value`,
          width: 150
        })
      }

      columnsData.push(column)
    })

    return columnsData
  })

  return {
    columns
  }
}
