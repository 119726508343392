<script setup lang="ts">
import { useRouter } from "vue-router"
import { h, watch } from "vue"
import { get, noop, useToggle } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  LoginOutlined
} from "@ant-design/icons-vue"

import { AdminUserEditRouteName, AdminUserRouteName, FinanceStatistics } from "@/router"
import { UserDescription, UserStatus, useUserDetail, useUserStore } from "@/modules/admin"
import { APageDrawer, DeleteConfirm } from "@/package/ui-kit"
import {
  backupAccessToken,
  backupRefreshToken,
  setAccessToken,
  setRefreshToken
} from "@/package/api-client"
import { useProfile, UserRole } from "@/modules/profile"

import { DETAIL_USER_REQUEST_ERROR_MESSAGE } from "../../../modules/admin/constants"
import { useLoginUserAs } from "../../../modules/admin/components/UserListing/hooks/use-login-user-as"

const router = useRouter()
const userId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)
const { loginAs, data: loginData } = useLoginUserAs()
const { refreshAsync, profile } = useProfile()

const callCloseAction = () => toggleOpened(false)
const { userDetailResponse, isUserDetailLoading, userDetailError } = useUserDetail(userId)

watch(
  userDetailError,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_USER_REQUEST_ERROR_MESSAGE })
      await router.push({ name: AdminUserRouteName })
    }
  },
  { immediate: true }
)

const loginAsAction = async () => {
  await loginAs(+userId.value)
  backupAccessToken()
  backupRefreshToken()
  setAccessToken(loginData.value.access)
  setRefreshToken(loginData.value.refresh)
  await refreshAsync()
  await router.push({ name: FinanceStatistics })
}
const openEditAction = () =>
  router.push({ name: AdminUserEditRouteName, params: { id: get(userId) } })
const store = useUserStore()
const blockAction = () => {
  DeleteConfirm({
    title: "Блокировка пользователя",
    content: "Вы действительно хотите заблокировать пользователя?",
    okText: "Заблокировать",
    onOk: () =>
      store
        .blockOne(userId)
        .then(() => {
          push.success({ message: "Пользователь заблокирован" })
          router.push({ name: AdminUserRouteName, params: { id: get(userId) } })
        })
        .catch(noop)
  })
}
const activateAction = () => {
  DeleteConfirm({
    title: "Активация пользователя",
    content: "Вы действительно хотите активировать пользователя?",
    okText: "Активировать",
    icon: h(CheckCircleOutlined),
    onOk: () =>
      store
        .activateOne(userId)
        .then(() => {
          push.success({ message: "Пользователь активирован" })
          router.push({ name: AdminUserRouteName, params: { id: get(userId) } })
        })
        .catch(noop)
  })
}
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    :loading="isUserDetailLoading"
    title="Информация о пользователе"
    @onClose="() => router.push({ name: AdminUserRouteName })"
  >
    <AButton
      size="large"
      type="primary"
      :icon="h(LoginOutlined)"
      :style="{ marginBottom: '32px' }"
      :disabled="
        userDetailResponse?.role !== UserRole.Manager ||
        userDetailResponse?.status !== UserStatus.active
      "
      block
      @click="loginAsAction"
    >
      Войти под пользователем
    </AButton>
    <ASpin :spinning="isUserDetailLoading">
      <UserDescription :user="userDetailResponse" @success="callCloseAction" />
    </ASpin>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <template v-if="isUserDetailLoading || userDetailResponse === undefined">
            <AFlex justify="center" align="center" :style="{ height: '100%' }">
              <ASpin size="small" />
            </AFlex>
          </template>
          <template v-else>
            <AButton
              v-if="userDetailResponse.status === UserStatus.active"
              danger
              :disabled="
                isUserDetailLoading || profile?.role !== UserRole.Admin || profile.id === +userId
              "
              block
              size="large"
              :icon="h(CloseCircleOutlined)"
              @click="blockAction"
            >
              Заблокировать польз.
            </AButton>
            <AButton
              v-else
              :disabled="isUserDetailLoading || profile?.role !== UserRole.Admin"
              block
              success
              size="large"
              :icon="h(CheckCircleOutlined)"
              @click="activateAction"
            >
              Разблокировать польз.
            </AButton>
          </template>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :disabled="isUserDetailLoading"
            :loading="isUserDetailLoading"
            :icon="h(EditOutlined)"
            @click="openEditAction"
          >
            Редактировать
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
