import type { LegalEntityDetail } from "../interfaces"
import type { MaybeRef } from "vue"

import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import { useApiError } from "@/package/api-client"

import { legalEntityOneGet } from "../api/legal-entity-one.get"

export const useLegalEntityDetail = (entityId?: MaybeRef<string | number>) => {
  const { data, error, loading, refreshAsync, run, runAsync } = useRequest<
    LegalEntityDetail | undefined
  >((id) => (id ? legalEntityOneGet(id) : Promise.resolve(undefined)), {
    cacheKey: ([id] = []) => `/legal-entities/${id}`,
    defaultParams: [get(entityId)]
  })

  return {
    profile: data,
    isLoading: loading,
    error: useApiError(error),
    requestLegalEntityDetail: run,
    getLegalEntityDetail: runAsync,
    refreshAsync
  }
}
