<script setup lang="ts">
import type { TableProps } from "ant-design-vue"
import type { VueNode } from "ant-design-vue/lib/_util/type"
import type { TableRowSelection } from "ant-design-vue/es/table/interface"

import { computed, h, onUnmounted, ref } from "vue"
import { get, noop, useInfiniteScroll } from "@vueuse/core"
import { push } from "notivue"
import { CopyOutlined, DashOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { storeToRefs } from "pinia"

import {
  type OperationListingElement,
  type GeneralListingElement,
  useOperationStore
} from "@/modules/operation"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { ListingElementType } from "@/modules/operation"
import { useBufferSelection } from "@/package/hooks"

import { useOperationListView, useOpertaionIcons, useColumns } from "../hooks"
import { copyModal } from "../util"

import TextOrTagCell from "./TextOrTagCell.vue"

const emit = defineEmits(["openDetail", "openEdit", "openCreate"])

const { columns, handleResizeColumn } = useColumns()
const store = useOperationStore()
const { operationsIdList, paginationTotal } = storeToRefs(store)
const {
  isAllSelected,
  selectedCount,
  selectedIdList,
  onSelect,
  onSelectAll,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys
} = useBufferSelection(operationsIdList, paginationTotal)

const selectedOperationsSum = computed(() => {
  return store.listing.reduce((accum: number, current) => {
    if (selectedIdList.value.includes(current.id)) {
      return current.action === "incoming" || current.action === "transfer"
        ? accum + +current.amount
        : accum - +current.amount
    } else {
      return accum
    }
  }, 0)
})

const rowSelectConfig = computed(
  (): TableRowSelection => ({
    columnWidth: 35,
    onChange: (keys) => {
      const selecetableKeys = (keys as Array<number>).filter(
        (el) => el && store.operationsIdList.includes(el)
      )
      onSelect(selecetableKeys)
    },
    onSelectAll: () => {
      onSelectAll(!get(isAllSelected))
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: get(selectedIdList),
    renderCell: (
      value: boolean,
      record: GeneralListingElement,
      index: number,
      originNode: VueNode
    ) => {
      if (record.elementType === ListingElementType.operation) {
        return originNode
      }
      return null
    }
  })
)
const deleteAction = (id: number) => {
  DeleteConfirm({
    content:
      "Вы действительно хотите безвозвратно удалить запись? Будут удалены все вложенные объекты",
    centered: true,
    onOk: () =>
      store
        .deleteOperation(id)
        .then(() => {
          push.success({ message: "Удалено" })
        })
        .catch(noop)
  })
}

const copyAction = (id: number) => {
  copyModal(() =>
    store
      .copyOperation(id)
      .then(() => {
        push.success({ message: "Операция скопирована" })
      })
      .catch(noop)
  )
}

defineExpose({
  isAllSelected,
  selectedCount,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys,
  selectedOperationsSum
})

const isAdoptable = useAdoptable()
const operationCellMap = useOpertaionIcons()

const tableWrapper = ref<HTMLElement | null>(null)
useInfiniteScroll(tableWrapper, store.loadNextPart, {
  distance: 1000,
  interval: 2000,
  canLoadMore: () =>
    !store.isLoadedFull && store.listingApiError === null && !store.isListingLoading
})

const customRow: TableProps["customRow"] = (data: any) => {
  return {
    onClick: () => {
      if (data.elementType === ListingElementType.operation) {
        emit("openDetail", data.id)
      }
    }
  }
}
onUnmounted(store.$reset)

const formattedList = useOperationListView()
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%' }">
    <ATable
      size="middle"
      :sticky="{ offsetHeader: 0, offsetScroll: 0, getContainer: () => tableWrapper }"
      class="custom-table"
      :loading="store.isListingLoading"
      :columns="columns"
      :data-source="formattedList"
      :show-header="!isAdoptable"
      :pagination="false"
      row-key="id"
      :row-selection="rowSelectConfig"
      :custom-row="customRow"
      @resizeColumn="handleResizeColumn"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'date' && record.elementType === ListingElementType.divider">
          <span class="divider-cell-date">{{ record.date }}</span>
        </template>
        <template v-if="column.key === 'action'">
          <component
            :is="operationCellMap[record.action as keyof typeof operationCellMap]"
            :class="`operation-amount--${record.action}`"
          />
        </template>
        <template v-if="column.key === 'amount'">
          <AText :class="`operation-amount--${record.action}`">
            {{ record.amount }}
          </AText>
        </template>
        <template
          v-if="
            column.key === 'counterparty_name' &&
            record.elementType === ListingElementType.operation
          "
        >
          <TextOrTagCell :count="record.counterparty?.count" :name="record.counterparty?.name" />
        </template>
        <template
          v-if="
            column.key === 'category_name' && record.elementType === ListingElementType.operation
          "
        >
          <TextOrTagCell :count="record.category?.count" :name="record.category?.name" />
        </template>
        <template
          v-if="column.key === 'user_action' && record.elementType === ListingElementType.operation"
        >
          <ADropdown
            :overlay-style="{ zIndex: 100 }"
            placement="bottomRight"
            arrow
            :trigger="['hover']"
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (record as OperationListingElement).id)"
                >
                  Редактировать
                </AMenuItem>
                <AMenuItem
                  :icon="h(CopyOutlined)"
                  @click="() => copyAction((record as OperationListingElement).id)"
                >
                  Копировать
                </AMenuItem>
                <AMenuItem
                  danger
                  :disabled="store.isDeleting"
                  :icon="h(DeleteOutlined)"
                  @click="() => deleteAction((record as OperationListingElement).id)"
                >
                  Удалить
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder
          create-text="Создать операцию"
          :show-create-button="store.isResourceEmpty || !store.pagination.total"
          @openCreate="() => emit('openCreate')"
        />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon) {
  border: 1px solid black;
  border-radius: 0;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon:before),
:deep(.ant-table-wrapper .ant-table-row-expand-icon:after) {
  color: black;
}

.divider-cell-date {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  display: block;
}

:deep(.operation-amount--incoming) {
  color: var(--incoming-operation-color);
}

:deep(.operation-amount--outgoing) {
  color: var(--outgoing-operation-color);
}

:deep(.operation-amount--transfer) {
  color: var(--transfer-operation-color);
}
</style>
