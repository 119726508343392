import type { Dayjs } from "dayjs"

import { RepeatPeriod } from "@/interfaces"

export enum OperationAction {
  incoming = "incoming",
  outgoing = "outgoing",
  transfer = "transfer"
}

export const OperationActionDict = {
  [OperationAction.incoming]: "Доход",
  [OperationAction.outgoing]: "Расход",
  [OperationAction.transfer]: "Перевод"
}

export enum OperationType {
  factual = "factual",
  planned = "planned"
}

export const OperationTypeDict: Record<keyof typeof OperationType, string> = {
  factual: "Фактическая",
  planned: "Плановая"
}

export interface OperationTransaction {
  id?: number
  amount: number
  operation_category_id?: number
  counterparty_id?: number
  project_id?: number
}

export interface OperationCreatRequest {
  //all operations
  action: OperationAction
  amount: number
  date: Dayjs
  bank_account_from_id: number
  tags?: Array<string>
  purpose?: string
  transactions: Array<OperationTransaction>
  files: Array<number>

  // income, outcome
  type?: OperationType
  is_repeat?: boolean
  repeat_period?: RepeatPeriod
  repeat_finish_at?: Dayjs

  // transfer
  bank_account_to_id?: number | null
}

export enum ListingElementType {
  operation = "operation",
  transaction = "transaction",
  divider = "divider"
}

export enum OperationElementSource {
  import = "import",
  manual = "manual",
  copy = "copy"
}

export interface OperationTransactionListDTO {
  id: number
  counterparty_name: string
  category_name: string
  amount: string
}

export interface OperationListElementDTO {
  id: number
  date: string
  bank_name: string
  bank_account_name: string
  action: OperationAction
  type: OperationType
  counterparty: {
    count: number
    name: string
  } | null
  category?: {
    count: number
    name: string
  } | null
  amount: string
  source: OperationElementSource
  transactions: Array<OperationTransactionListDTO>
}

export interface OperationListingTransactionElement extends OperationTransactionListDTO {
  key: number
  action: OperationAction
  elementType: string
}

export interface GeneralListingElement {
  date: string
  elementType: ListingElementType
}

export interface OperationListingElement extends GeneralListingElement {
  id: number
  bank_account_name: string
  type: string
  action: OperationAction
  counterparty?: {
    count: number
    name: string
  }
  category?: {
    count: number
    name: string
  }
  amount: string
  children: Array<OperationListingTransactionElement>
}

export interface OperationFilterParams {
  date_from?: string
  date_to?: string
  bank_account_ids?: Array<number | null>
  counterparty_ids?: Array<number | null>
  project_ids?: Array<number | null>
  category_ids?: Array<number | null>
  tags?: Array<string>
  actions?: Array<string>
  types?: Array<string>
}

export interface OperationDetailTransactionDTO {
  id?: number
  amount?: number
  counterparty?: {
    id: number
    name: string
  } | null
  category?: {
    id: number
    name: string
  } | null
  project?: {
    id: number
    name: string
  } | null
}

export interface OperationDetailDTO {
  id: number
  //all operations
  action: OperationAction
  amount: number
  date: string
  bank_account_from: {
    name: string
    id: number
  }
  tags?: Array<string>
  purpose?: string
  transactions: Array<OperationDetailTransactionDTO>
  files: Array<{
    id: number
    file_name: string
    file_ext: string
    file_type: string
    file_size: string
  }>

  // income, outcome
  type?: OperationType
  is_repeat?: boolean
  repeat_period?: { slug: RepeatPeriod; name: string }
  repeat_finish_at?: string
  repeat_parent_id: null | number
  source: OperationElementSource

  // transfer
  bank_account_to?: {
    name: string
    id: number
  } | null
}

export interface OperationEditRequest extends OperationCreatRequest {}

export interface OperationDetailDisplayTransaction {
  id?: number
  counterparty?: string
  category?: string
  project?: string
  amount?: number
}

export interface ReferencesList {
  ids: Array<number>
  nameList: Array<string>
}

export interface OperationDisplayData {
  splits: Array<OperationDetailDisplayTransaction>
  counterparties: ReferencesList
  categories: ReferencesList
  projects: ReferencesList
}

export interface OperationBankIntegration {
  success: boolean
  data: {
    id: number
    bank_type: number
    is_active: boolean
    is_connected: boolean
    last_sync_date: number
    filter_date: string
    available_bank_accounts: Array<AvailableBankAccounts>
  }
}

export interface OperationBankAuth {
  success: boolean
  data: {
    user_bank_integration_id: number
  }
}

export interface AvailableBankAccounts {
  name: string
  number: string
  is_connected: boolean
}

export interface OperationBankIntegrationEdit {
  success: boolean
}

export interface BankData {
  id: number
  type: string
  name: string
  is_connected: boolean
  user_integration_id: number
  auth_type: string
}
