<script setup lang="ts">
import { useRouter } from "vue-router"
import { h, watch } from "vue"
import { get, noop, useToggle } from "@vueuse/core"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { notification } from "ant-design-vue"
import { push } from "notivue"

import { FinanceOperationsEditRouteName, FinanceOperationsRouteName } from "@/router"
import { DeleteConfirm } from "@/package/ui-kit"
import {
  useOperationDetail,
  OperationDescription,
  useOperationStore,
  DETAIL_REQUEST_ERROR_MESSAGE
} from "@/modules/operation"

const router = useRouter()
const openListingAction = () => router.push({ name: FinanceOperationsRouteName })
const operationId = useRouteParams<string>("id")

const store = useOperationStore()

const openEditAction = () =>
  router.push({
    name: FinanceOperationsEditRouteName,
    params: { id: operationId.value }
  })

const [isOpened, toggleOpened] = useToggle(true)

const { operation, isLoading, error } = useOperationDetail(operationId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)
const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    centered: true,
    onOk: () => {
      if (operation.value) {
        store
          .deleteOperation(+get(operationId))
          .then(() => {
            notification.success({ message: "Удалено" })
            toggleOpened(false)
          })
          .catch(noop)
      } else {
        throw new Error("logic error")
      }
    }
  })
}
</script>

<template>
  <APageDrawer v-model:open="isOpened" title="Информация об операции" @onClose="openListingAction">
    <ASpin :spinning="isLoading">
      <OperationDescription :detail="operation" />
    </ASpin>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :icon="h(DeleteOutlined)" block size="large" danger @click="deleteAction">
            Удалить
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :icon="h(EditOutlined)"
            block
            type="primary"
            size="large"
            @click="openEditAction"
          >
            Редактировать
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
