<script setup lang="ts">
import { computed, reactive } from "vue"
import { TransitionFade } from "@morev/vue-transitions"
import { noop } from "@vueuse/core"
import { CloseCircleFilled } from "@ant-design/icons-vue"
import { HttpStatusCode } from "axios"

import { useForm } from "@/package/hooks"

import { usePasswordResetRequest } from "../hooks/use-password-reset-request"

const emit = defineEmits(["success"])
const { apiError, apiStatus, isLoading, createPasswordResetRequest } = usePasswordResetRequest()
const formState = reactive({
  email: ""
})
const ruleset = computed(() => ({
  email: [{ type: "email", required: true, message: "Пожалуйста, введите e-mail", trigger: "blur" }]
}))
const { validateInfos, validate } = useForm(formState, ruleset)

const onSubmit = () =>
  validate()
    .then(() => createPasswordResetRequest(formState.email, () => emit("success")))
    .catch(noop)
const contactEmail = import.meta.env.VITE_SUPPORT_MAIL
</script>

<template>
  <AFlex vertical>
    <div :style="{ minHeight: '40px' }">
      <TransitionFade>
        <template v-if="apiError !== null">
          <template v-if="apiStatus === HttpStatusCode.Forbidden">
            <AAffix :offset-top="12">
              <AAlert class="shake" type="error" show-icon>
                <template #icon>
                  <CloseCircleFilled
                    :style="{
                      fontSize: '14px',
                      alignSelf: 'baseline',
                      transform: 'translateY(5px)'
                    }"
                  />
                </template>
                <template #description>
                  Доступ в систему ограничен, пожалуйста свяжитесь с технической поддержкой
                  <a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>
                </template>
              </AAlert>
            </AAffix>
          </template>
          <template v-else-if="apiError.message !== null">
            <AAffix :offset-top="12">
              <AAlert class="shake" :message="apiError.message" type="error" show-icon />
            </AAffix>
          </template>
        </template>
      </TransitionFade>
    </div>
    <AForm
      v-enter-to-tab
      :style="{ marginTop: '12px' }"
      :model="formState"
      name="restore-request"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      hide-required-mark
      @submit.prevent="onSubmit"
    >
      <AFormItem label="Ваш E-mail" name="email" v-bind="validateInfos?.email">
        <AInput
          v-model.lowercase.trim="formState.email"
          size="large"
          placeholder="Введите e-mail"
          @change="
            () => {
              if (validateInfos?.email.validateStatus === 'error') {
                validate('email', { trigger: 'blur' }).catch(noop)
              }
            }
          "
          @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
        />
      </AFormItem>

      <AButton
        type="primary"
        html-type="submit"
        block
        :style="{ marginTop: '32px' }"
        size="large"
        :loading="isLoading"
        >Восстановить доступ
      </AButton>
    </AForm>
  </AFlex>
</template>
