<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, onUnmounted, type PropType, ref } from "vue"
import { useDebounceFn } from "@vueuse/core"
import { onMounted, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import type { ProjectDetail } from "@/modules/project"
import { useProjectsStore } from "@/modules/project"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import { useRuleset } from "./use-ruleset"
import { useFormState } from "./use-form-state"

const props = defineProps({
  project: {
    type: [Object, undefined] as PropType<ProjectDetail | undefined>,
    default: () => undefined
  }
})
const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const resetAction = useDebounceFn(() => {
  if (props.project) {
    Object.assign(formState, props.project)
  }
}, 100)
watch(() => props.project, resetAction)
onMounted(resetAction)

const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const projectStore = useProjectsStore()
const { editError, isEditing, isEditingFinished } = storeToRefs(projectStore)

const formRef = ref<typeof Form | null>(null)

const submitAction = async () => {
  try {
    await validate()
    if (props.project) {
      await projectStore.editProject(requestData.value)
    } else {
      throw new Error("Cannot save project")
    }
    push.success({
      message: "Проект изменен"
    })
    emit("success")
    await projectStore.updateBufferAfterEdit(requestData.value)
  } catch (err) {
    if (isValidateError(err)) {
      formRef.value?.scrollToField(err.errorFields[0].name, {
        block: "start",
        boundary: (parent: HTMLElement) => {
          parent.focus()
          return parent.classList.contains("ant-row")
        }
      })
    }
  }
}

watch([editError, isEditingFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isEditingFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

defineExpose({
  submitAction,
  isLoading: computed(() => isEditing.value || isEditingFinished.value || isValidating.value),
  resetAction
})

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-project-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem
      label="Название"
      name="name"
      v-bind="validateInfos?.name"
      :style="{ marginTop: '16px' }"
    >
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
  </AForm>
</template>
