import type { PayerTariffCode } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

interface PayerTariffCodeResponse {
  success: boolean
  data: Array<PayerTariffCode>
}

export const usePayerTariffCode = () => {
  const { data, isLoading, error, execute } = useAxios<PayerTariffCodeResponse>(
    "/reference/payer-tariff-codes",
    { method: "GET" },
    client,
    { immediate: false }
  )
  const getPayerTariffCode = async () => {
    return await execute("/reference/payer-tariff-codes")
  }

  return { payerTariffCode: data, payerTariffCodePending: isLoading, error, getPayerTariffCode }
}
