import type { validateOptions } from "ant-design-vue/es/form/useForm"

import { reactive, ref, watch } from "vue"

import type { LegalEntity, LegalEntityData } from "@/modules/legal-entity"

export const autocompletePlaceholder = "Автозаполнение данных"

interface usedLegalEntityState
  extends Pick<
    LegalEntity,
    "itn" | "legal_address" | "okved_code" | "organization_name" | "head_fullname"
  > {
  okved_id?: number
}

type DisabledFields = Record<keyof usedLegalEntityState, boolean>
export const useLegalEntityAutocomplete = (
  legalEntity: usedLegalEntityState,
  defaultDisabled = true,
  validate?: (names?: string | Array<string>, option?: validateOptions) => Promise<unknown>
) => {
  const selectedEntity = ref<LegalEntityData | null>(null)
  const disabledFields = reactive<DisabledFields>({
    legal_address: defaultDisabled,
    itn: defaultDisabled,
    okved_code: defaultDisabled,
    okved_id: defaultDisabled,
    organization_name: defaultDisabled,
    head_fullname: defaultDisabled
  })
  watch(selectedEntity, async (entity) => {
    if (entity === null) {
      disabledFields.legal_address = defaultDisabled
      disabledFields.head_fullname = defaultDisabled
      disabledFields.okved_id = defaultDisabled
    } else {
      legalEntity.itn = entity.itn
      legalEntity.organization_name = entity.organization_name ?? null
      legalEntity.legal_address = entity.legal_address ?? null
      legalEntity.head_fullname = entity.head_fullname ?? null
      legalEntity.okved_code = entity.okved_code ?? null
      legalEntity.okved_id = entity.okved_id ?? null

      disabledFields.legal_address = false
      disabledFields.head_fullname = false
      disabledFields.okved_id = false
      if (validate !== undefined) {
        setTimeout(async () => {
          await validate(["organization_name", "head_fullname", "itn", "okved_id", "legal_address"])
        }, 0)
      }
    }
  })
  return {
    selectedEntity,
    disabledFields
  }
}
