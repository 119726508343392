import type { Directive } from "vue"

const tabableInputTypes = ["password", "text"]
const getInputs = (el: HTMLElement) => {
  const allElementsQuery = el.querySelectorAll(
    "input, button, a, textarea, select, audio, video, [contenteditable]"
  )
  const allElements = new Array<Element>()
  allElementsQuery.forEach((e) => {
    const r = e as HTMLElement
    const type = r.getAttribute("type")

    if (
      !(r as HTMLTextAreaElement).disabled &&
      !r.hidden &&
      r.offsetParent &&
      r.tabIndex >= 0 &&
      ((r.tagName === "INPUT" && type && tabableInputTypes.includes(type)) ||
        r.tagName === "TEXTAREA" ||
        r.tagName === "SELECT")
    ) {
      allElements.push(r)
    }
  })

  return allElements
}
const keydownHandler = (el: HTMLFormElement, e: KeyboardEvent) => {
  const { ctrlKey, code, altKey, shiftKey } = e
  const target = e.target as HTMLElement
  if (
    (code === "Enter" || code === "NumpadEnter") &&
    !ctrlKey &&
    !altKey &&
    !shiftKey &&
    target &&
    target.tagName !== "TEXTAREA"
  ) {
    const allElements = getInputs(el)
    const currentIndex = allElements.indexOf(target)
    if (currentIndex !== -1) {
      e.preventDefault()
      const targetIndex = (currentIndex + 1) % allElements.length
      const nextElement = allElements[targetIndex] as HTMLElement
      const currentElement = allElements[currentIndex] as HTMLElement
      if (currentElement.tagName === "BUTTON" && currentElement.getAttribute("type") === "submit") {
        currentElement.click()
      } else if (targetIndex === 0) {
        el.requestSubmit()
      } else {
        nextElement.focus()
      }
    }
  }
}
export const enterToTab: Directive = {
  mounted: (el) => {
    el.addEventListener("keydown", keydownHandler.bind(null, el))
  },
  unmounted: (el) => {
    el.addEventListener("keydown", keydownHandler)
  }
}
