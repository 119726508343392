<script setup lang="ts">
import type { NotivueItem } from "notivue"

import { computed, h, type PropType, ref, watch } from "vue"
import { CloseOutlined } from "@ant-design/icons-vue"
import { theme } from "ant-design-vue"
import { vElementHover } from "@vueuse/components"
import { useWindowFocus } from "@vueuse/core"

import { NotificationIcon } from "./NotificationIcon"
import { NotificationTitle } from "./NotificationTitle"
import ProgressTimeout from "./ProgressTimeout.vue"

defineProps({
  item: {
    type: Object as PropType<
      NotivueItem<{
        retry?: {
          onClick?: () => unknown
        }
      }>
    >,

    required: true
  }
})
const { token } = theme.useToken()
const borderRadius = computed(() => `${token.value.borderRadius}px`)
const progressBar = ref<InstanceType<typeof ProgressTimeout> | null>(null)
const onHover = (hovered: boolean) => {
  if (hovered) {
    progressBar.value?.pauseInterval()
  } else {
    progressBar.value?.startInterval()
  }
}
const windodFocused = useWindowFocus()
watch(windodFocused, (focused) => {
  if (focused) {
    progressBar.value?.startInterval()
  } else {
    progressBar.value?.pauseInterval()
  }
})
</script>

<template>
  <div
    v-element-hover="onHover"
    class="notification"
    :class="{
      'notification--success': item.type === 'success',
      'notification--info': item.type === 'info',
      'notification--promise': item.type === 'promise',
      'notification--reject': item.type === 'promise-reject',
      'notification--resolve': item.type === 'promise-resolve',
      'notification--error': item.type === 'error',
      'notification--warning': item.type === 'warning'
    }"
  >
    <NotificationIcon
      :type="item.type"
      class="notification__icon"
      :class="{
        'notification__icon--success': item.type === 'success',
        'notification__icon--info': item.type === 'info',
        'notification__icon--promise': item.type === 'promise',
        'notification__icon--reject': item.type === 'promise-reject',
        'notification__icon--resolve': item.type === 'promise-resolve',
        'notification__icon--error': item.type === 'error',
        'notification__icon--warning': item.type === 'warning'
      }"
    />

    <NotificationTitle class="notification__title" :title="item.title" :type="item.type" />

    <ATypographyText class="notification__messsage">{{ item.message }}</ATypographyText>

    <AFlex v-if="item.props.retry" class="notification__control-panel">
      <AButton
        v-if="item.props.retry"
        size="small"
        type="primary"
        @click="
          async () => {
            try {
              await item.props.retry?.onClick?.call(null)
            } catch (e) {
              //TODO: настроить вывод ошибки на пвтор
            } finally {
              item.destroy()
            }
          }
        "
      >
        Повторить
      </AButton>
    </AFlex>

    <AButton
      class="notification__close-btn"
      type="text"
      :icon="h(CloseOutlined)"
      @click="item.destroy"
    />

    <ProgressTimeout
      v-if="item.duration && item.duration !== Infinity"
      ref="progressBar"
      class="notification__progress"
      :class="{
        'notification__progress--success': item.type === 'success',
        'notification__progress--info': item.type === 'info',
        'notification__progress--promise': item.type === 'promise',
        'notification__progress--reject': item.type === 'promise-reject',
        'notification__progress--resolve': item.type === 'promise-resolve',
        'notification__progress--error': item.type === 'error',
        'notification__progress--warning': item.type === 'warning'
      }"
      :duration="item.duration"
      @timeUp="item.destroy"
    />
  </div>
</template>

<style scoped lang="scss">
.notification {
  width: 100%;
  min-height: 88px;
  background-color: v-bind("token.colorBgBase");
  border-radius: v-bind(borderRadius);
  padding: 15px 10px 26px;
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(auto, 300px) auto;
  grid-template-rows: auto auto;
  gap: 0 16px;
  border-width: 1px;
  border-style: solid;
  max-width: 100%;

  &--resolve,
  &--success {
    border-color: v-bind("token.colorSuccess");
  }

  &--promise,
  &--info {
    border-color: v-bind("token.colorInfo");
  }

  &--reject,
  &--error {
    border-color: v-bind("token.colorError");
  }

  &--warning {
    border-color: v-bind("token.colorWarning");
  }
}

.notification__icon {
  align-self: center;
  padding: 2px;
  grid-column: 1/2;
  grid-row: 1/-1;

  :deep(svg) {
    width: 20px;
    height: 20px;
  }

  &--resolve,
  &--success {
    color: v-bind("token.colorSuccess");
  }

  &--promise,
  &--info {
    color: v-bind("token.colorInfo");
  }

  &--reject,
  &--error {
    color: v-bind("token.colorError");
  }

  &--warning {
    color: v-bind("token.colorWarning");
  }
}

.notification__title {
  grid-column: 2/3;
  grid-row: 1/2;
}

.notification__messsage {
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification__close-btn {
  align-self: center;
  grid-column: 3/4;
  grid-row: 1/-1;
}

.notification__control-panel {
  margin-top: 5px;
  grid-column: 2/-1;
  grid-row: 3/4;
}

.notification__progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &--resolve,
  &--success {
    color: v-bind("token.colorSuccess");
  }

  &--promise,
  &--info {
    color: v-bind("token.colorInfo");
  }

  &--reject,
  &--error {
    color: v-bind("token.colorError");
  }

  &--warning {
    color: v-bind("token.colorWarning");
  }
}
</style>
