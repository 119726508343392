import type { MaybeRef } from "vue"
import type { LegalEntityData } from "./use-form-state"

import { ref } from "vue"
import { get, set, useToggle } from "@vueuse/core"

import { type RegisterPhysicalEntity, physicalEntityRegisterPost } from "@/modules/physical-entity"
import { legalEntityRegisterPost } from "@/modules/legal-entity"
import { ApiBadReqResponse } from "@/package/api-client"
import { useRequestFieldErrors } from "@/package/hooks"

import { RegistrantType } from "../interfaces"

export const useRegister = (
  personType: MaybeRef<RegistrantType>,
  physicalEntity: MaybeRef<RegisterPhysicalEntity>,
  legalEntity: MaybeRef<LegalEntityData>
) => {
  const [isRegisterLoading, toggleLoading] = useToggle(false)
  const registerError = ref<string | null>(null)
  const { fieldErrors, cleanFieldError, resetFieldErrors, setFieldErrors } = useRequestFieldErrors<
    RegisterPhysicalEntity | LegalEntityData
  >()

  const registerRequest = async (onSuccess: () => unknown) => {
    toggleLoading(true)
    set(registerError, null)
    resetFieldErrors()

    try {
      if (get(personType) === RegistrantType.PhysicalEntity) {
        await physicalEntityRegisterPost(get(physicalEntity))
      } else if (get(personType) === RegistrantType.LegalEntity) {
        await legalEntityRegisterPost(get(legalEntity))
      }
      onSuccess()
    } catch (e) {
      if (e instanceof ApiBadReqResponse) {
        if (e.errorList) {
          setFieldErrors(e.errorList)
          throw {
            errorFields: Object.entries(e.errorList).map(([key, value]) => ({
              name: key,
              errors: value
            }))
          }
        } else {
          set(registerError, e.message)
        }
      } else {
        registerError.value = "Неопределенная ошибка, повторите запрос или обратитесь в поддержку."
      }
    } finally {
      toggleLoading(false)
    }
  }

  return {
    registerRequest,
    isRegisterLoading,
    registerError,
    fieldErrors,
    cleanFieldError
  }
}
