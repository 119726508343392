import type { ReportListElement, ReportsFilters } from "./interfaces"

import { defineStore } from "pinia"
import { computed, nextTick, reactive, ref } from "vue"
import dayjs from "dayjs"

import { reportsTypesGet } from "./api/reports.get"
import { useGetReportsFilter, useReportDownload, useSaveReportsFilters } from "./hooks"

export const useReportsStore = defineStore("reports", () => {
  const formatListing = ref<Array<ReportListElement>>([])

  const reportsFilters = reactive<ReportsFilters>({
    dateFilter: [],
    projectsFilter: null,
    legalEntityFilter: null
  })

  const formattedFilters = computed(() => {
    return {
      date_from: reportsFilters.dateFilter[0]?.format("DD-MM-YYYY"),
      date_to: reportsFilters.dateFilter[1]?.format("DD-MM-YYYY"),
      project_ids: reportsFilters.projectsFilter,
      legal_entity_id: reportsFilters.legalEntityFilter
    }
  })

  const getFormatListing = async () => {
    formatListing.value = await reportsTypesGet()
  }

  const { executeDownload, downloadReportError, isLoadingFile } = useReportDownload()

  const downloadReport = async (format: string) => {
    await executeDownload({
      type: format,
      ...formattedFilters.value
    })
  }

  const { getFilters, isGettingFilters } = useGetReportsFilter()

  const isFiltersLoaded = ref(false)
  const getReportFilters = async () => {
    const response = await getFilters()

    reportsFilters.dateFilter =
      response?.date_to && response.date_from
        ? [dayjs(response?.date_from, "DD-MM-YYYY"), dayjs(response?.date_to, "DD-MM-YYYY")]
        : []
    reportsFilters.projectsFilter = response?.project_ids || []
    reportsFilters.legalEntityFilter = response?.legal_entity_id || null
    await nextTick(() => (isFiltersLoaded.value = true))
  }

  const { executeSave } = useSaveReportsFilters()
  const saveReportFilters = async () => {
    const payload = {
      date_from: reportsFilters.dateFilter?.[0]?.format("DD-MM-YYYY") || null,
      date_to: reportsFilters.dateFilter?.[1]?.format("DD-MM-YYYY") || null,
      project_ids: reportsFilters.projectsFilter ?? [],
      legal_entity_id: reportsFilters.legalEntityFilter
    }
    await executeSave(payload)
  }

  const $reset = () => {
    formatListing.value = []
    Object.assign(reportsFilters, { dateFilter: [], projectsFilter: [], legalEntityFilter: null })
    isFiltersLoaded.value = false
  }

  return {
    formatListing,
    reportsFilters,
    downloadReportError,
    isGettingFilters,
    isFiltersLoaded,
    isLoadingFile,
    downloadReport,
    getReportFilters,
    saveReportFilters,
    getFormatListing,
    $reset
  }
})
