<script setup lang="ts">
import { computed, watch } from "vue"
import dayjs from "dayjs"
import { useAxios } from "@vueuse/integrations/useAxios"
import { push } from "notivue"
import { HttpStatusCode } from "axios"
import { get, noop } from "@vueuse/core"

import { gridGutter } from "@/package/ui-kit"
import { client, useApiError } from "@/package/api-client"
import {
  injectAttemptDecrement,
  injectProfile,
  injectProfileRefresh,
  injectProfileStatus
} from "@/modules/profile"
import { useRegisterComplete } from "@/modules/auth"

const supportMail = import.meta.env.VITE_SUPPORT_MAIL
const status = injectProfileStatus()
const profile = injectProfile()
const profileRefresh = injectProfileRefresh()
const decrementAttempt = injectAttemptDecrement()

const { execute, error } = useAxios(
  "/security/resend-email-verification-request",
  { method: "POST" },
  client,
  {
    immediate: false
  }
)
const apiError = useApiError(error)
watch(apiError, (err) => {
  if (err && err.message) {
    push.error({ message: err.message, duration: Infinity })
  }
})
const resendAction = () => {
  execute()
    .then(() => {
      push.success({ message: "Сообщение отправлено на email" })
      decrementAttempt()
    })
    .catch(noop)
}

const isRestoreAvailable = computed(
  () =>
    (get(apiError) === null || get(apiError)?.status !== HttpStatusCode.BadRequest) &&
    (get(status)?.attempts ?? 0) > 0
)
const alertMessage = computed(
  () =>
    `Неподтвержденный аккаунт будет удален ${get(status).deltedAt ? dayjs(get(status).deltedAt).format("DD.MM.YYYY") : ""}`
)
const { errorMessage: completeError, completeResult } = useRegisterComplete()
watch(
  completeError,
  (message) => {
    if (message) {
      push.error({ message })
    }
  },
  { immediate: true }
)
watch(completeResult, (result) => {
  if (result && result.success) {
    profileRefresh()
  }
})
</script>

<template>
  <ARow :gutter="gridGutter" :style="{ height: '100%', maxHeight: '100%' }">
    <ACol :xl="{ span: 16, offset: 4 }" :sm="{ span: 18, offset: 3 }" :xs="24">
      <AFlex vertical justify="center" align="center" :style="{ height: '100%' }">
        <ATypographyTitle :level="2" :style="{ textAlign: 'center', marginBottom: '8px' }">
          Пожалуйста, завершите регистрацию
        </ATypographyTitle>
        <AText variant="lg" :style="{ textAlign: 'center', marginBottom: '8px' }">
          На вашу почту {{ profile?.email }} было отправлено письмо для завершения регистрации.
          Пожалуйста проверьте вашу почту.
        </AText>
        <AAlert show-icon :message="alertMessage" :style="{ marginBottom: '24px' }" />
        <AFlex :style="{ marginBottom: '24px' }" wrap="wrap" justify="center">
          <AText variant="lg"> Не пришло письмо?&nbsp;</AText>
          <ATooltip
            :key="isRestoreAvailable"
            :open="isRestoreAvailable ? false : undefined"
            title="Превышен лимит запросов на отправку e-mail. Пожалуйста, свяжитесь с поддержкой"
          >
            <ALink :disabled="!isRestoreAvailable" variant="lg" @click="resendAction">
              Отправить повторно
            </ALink>
          </ATooltip>
        </AFlex>
        <ATypographyParagraph
          type="secondary"
          :style="{ marginBottom: '10px', textAlign: 'center' }"
        >
          Если у вас возникли проблемы с регистрацией, свяжитесь с поддержкой
        </ATypographyParagraph>
        <ALink :href="`mailto:${supportMail}`">{{ supportMail }}</ALink>
      </AFlex>
    </ACol>
  </ARow>
</template>
