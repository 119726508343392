<template>
  <svg class="divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416 2" fill="none">
    <path
      d="M1 1H415"
      stroke="url(#paint0_linear_176_5056)"
      stroke-opacity="0.16"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_176_5056"
        x1="1"
        y1="1.50058"
        x2="415"
        y2="1.49908"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0" />
        <stop offset="0.49" />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped>
.divider {
  height: 2px;
  display: flex;
  justify-content: center;
  max-width: 100%;
}
</style>
