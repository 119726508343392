<script setup lang="ts">
import type { FormTransaction, OperationSplitError } from "../interface"

import { vElementSize } from "@vueuse/components"
import { computed, h, type PropType, ref } from "vue"
import { DeleteOutlined, QuestionCircleOutlined, ApartmentOutlined } from "@ant-design/icons-vue"
import { TransitionSlide } from "@morev/vue-transitions"
import { Divider, theme } from "ant-design-vue"

import { OperationCategorySelect } from "@/modules/operation-category"
import { CounterpartiesSelect } from "@/modules/counterparty"
import { ProjectSelect } from "@/modules/project"
import { AButton, InfoModal, useMobileBp, usePopupContainerGetter } from "@/package/ui-kit"
import { SamoDecimal } from "@/package/util"
import { OperationAction } from "@/modules/operation"

import { createDefaultTransaction, useOperationSplit } from "../hooks"

const props = defineProps({
  validateStatus: {
    type: String,
    default: "",
    validator: (value: string) => ["success", "warning", "error", "validating", ""].includes(value)
  },

  required: {
    type: Boolean,
    default: false
  },

  autoLink: {
    type: Boolean,
    default: false
  },

  help: {
    type: Array as PropType<Array<Array<OperationSplitError>>>,
    default: () => []
  },

  disabled: {
    type: Boolean,
    default: false
  },

  disabledText: {
    type: String,
    default: () => undefined
  },

  amount: {
    type: String,
    default: "0"
  },

  operationAction: {
    type: String,
    required: true,

    validator: (value: string) =>
      value === OperationAction.outgoing || value === OperationAction.incoming
  }
})

const transations = defineModel({
  type: Object as PropType<Array<Partial<FormTransaction>>>,
  default: []
})
const isSplitted = computed(() => transations.value.length >= 2)

const deletable = computed(() => transations.value.length > 2)
const disabled = computed(() => props.disabled || SamoDecimal(props.amount).value <= 0)

const halfSplitAnnotation = computed(() => (deletable.value ? null : "Разбить сумму пополам"))
const splitCallButtonText = computed(() =>
  isSplitted.value ? "Отменить разделение" : "Разделить сумму"
)
const toggleSplitAction = () => {
  if (transations.value.length > 0) {
    transations.value = []
  } else {
    transations.value = [createDefaultTransaction(), createDefaultTransaction()]
  }
}
const halfSplitAction = () => {
  if (transations.value && transations.value.length === 2) {
    const amount = SamoDecimal(props.amount)
    const half = amount.divide(2)
    const anotherHalf = amount.subtract(half)

    transations.value[0].amount = Math.max(half.value, anotherHalf.value).toString()
    transations.value[1].amount = Math.min(half.value, anotherHalf.value).toString()
  } else {
    throw new Error("half split unavailable")
  }
}
const deleteTransactionAction = (index: number) => {
  transations.value?.splice(index, 1)
}
const addSplitRowAction = () => {
  transations.value = transations.value?.concat(createDefaultTransaction())
}

const splitResult = computed(() => {
  const result = (transations.value ?? []).reduce(
    (res, { amount }) => res.add(amount ? amount : 0),
    SamoDecimal(0)
  )

  return result.value === 0 ? 0 : result.format()
})

const showAnnotationModal = () =>
  InfoModal({ content: "Настройка разделения для данной операции", title: "Разделение суммы" })
const { columns } = useOperationSplit()
const { token } = theme.useToken()
const popupContainerGetter = usePopupContainerGetter()

const splitRef = ref<HTMLElement | null>(null)
const sizeListSrc = ref([145, 145, 145, 176])
const sizeList = computed(() => sizeListSrc.value.map((v) => `${v}px`))
const DELETE_BTN_SIZE = 40
const onChange = () => {
  const tableHeader = splitRef.value?.querySelector(".ant-table-thead tr")
  if (tableHeader) {
    const res = Array.from(sizeListSrc.value)
    const headerColumns = tableHeader?.children
    const colsList = Array.from(headerColumns)

    for (let i = 0; i < colsList.length; i++) {
      const colpos = parseInt(colsList[i].getAttribute("colstart") ?? "")
      const width = colsList[i].clientWidth
      if (i === colsList.length - 1) {
        res[res.length - 1] += width - DELETE_BTN_SIZE
      } else {
        res[colpos] = width
      }
    }
    sizeListSrc.value = res
  }
}
const isQuery = useMobileBp()
</script>

<template>
  <div ref="splitRef" class="split-amount">
    <AFlex justify="space-between">
      <AButton
        class="split-amount__btn"
        :disabled="disabled"
        size="small"
        type="link"
        @click="toggleSplitAction"
      >
        {{ splitCallButtonText }}
      </AButton>
      <AButton
        size="small"
        type="text"
        :icon="h(QuestionCircleOutlined)"
        :style="{ color: token.colorPrimary }"
        @click="showAnnotationModal"
      />
    </AFlex>
    <TransitionSlide
      group
      tag="div"
      :delay="{ enter: 500, leave: 0 }"
      :duration="{ enter: 1000, leave: 0 }"
    >
      <TransitionSlide
        v-if="isSplitted"
        group
        tag="div"
        class="split-amount__table-wrapper"
        @afterEnter="onChange"
      >
        <template v-if="isQuery">
          <ASpace :size="0" direction="vertical" :style="{ width: '100%' }">
            <template #split>
              <Divider />
            </template>
            <AFlex v-for="(transation, key) in transations" :key="key" vertical>
              <AFlex justify="space-between" align="center">
                <AText variant="lg">Разделение {{ key + 1 }}</AText>
                <AButton
                  :disabled="!deletable"
                  size="large"
                  type="primary"
                  danger
                  :icon="h(DeleteOutlined)"
                  :style="{ width: `${DELETE_BTN_SIZE}px` }"
                  @click="() => deleteTransactionAction(key)"
                />
              </AFlex>
              <ASpace :style="{ marginTop: '16px' }" :size="8" direction="vertical">
                <template v-if="operationAction === OperationAction.incoming">
                  <OperationCategorySelect
                    v-model="transation.income_operation_category_id"
                    hide-link
                    :is-select-options-reset="false"
                    action-type="income"
                    :get-popup-container="popupContainerGetter"
                    :style="{ width: '100%' }"
                  />
                </template>
                <template v-else>
                  <OperationCategorySelect
                    v-model="transation.outcome_operation_category_id"
                    action-type="expense"
                    hide-link
                    :is-select-options-reset="false"
                    :get-popup-container="popupContainerGetter"
                    :style="{ width: '100%' }"
                  />
                </template>
                <CounterpartiesSelect
                  v-model:selectedId="transation.counterparty_id"
                  hide-link
                  :is-select-options-reset="false"
                  placeholder="Выберите контрагента"
                  :get-popup-container="popupContainerGetter"
                  :style="{ width: '100%' }"
                />
                <ProjectSelect
                  v-model="transation.project_id"
                  hide-link
                  :is-select-options-reset="false"
                  placeholder="Выберите проект"
                  :get-popup-container="popupContainerGetter"
                  :style="{ width: '100%' }"
                />
                <AInputPrice
                  v-model="transation.amount"
                  placeholder="Введите сумму"
                  hide-addon
                  size="large"
                  :style="{ width: '100%' }"
                />
              </ASpace>
            </AFlex>
          </ASpace>
          <Divider />
        </template>
        <template v-else>
          <ATable v-element-size="onChange" class="split-amount__table" :columns="columns">
            <template #headerCell="{ column, title }">
              <template v-if="column.key === 'action'">
                <ATooltip
                  :title="halfSplitAnnotation"
                  :get-popup-container="popupContainerGetter"
                  placement="bottom"
                  :color="token.colorBgBase"
                  :overlay-inner-style="{ color: token.colorText }"
                  :disabled="deletable"
                  @click="halfSplitAction"
                >
                  <AButton
                    :icon="h(ApartmentOutlined)"
                    size="small"
                    :style="{ margin: '0 auto' }"
                    :disabled="deletable"
                  />
                </ATooltip>
              </template>
              <template v-else>{{ title }}</template>
            </template>
          </ATable>
          <template v-for="(transation, key) in transations" :key="key">
            <AInputGroup compact class="split-amount__item">
              <template v-if="operationAction === OperationAction.incoming">
                <OperationCategorySelect
                  v-model="transation.income_operation_category_id"
                  hide-link
                  :is-select-options-reset="false"
                  action-type="income"
                  :get-popup-container="popupContainerGetter"
                  :style="{ width: sizeList[0] }"
                />
              </template>
              <template v-else>
                <OperationCategorySelect
                  v-model="transation.outcome_operation_category_id"
                  hide-link
                  :is-select-options-reset="false"
                  action-type="expense"
                  :get-popup-container="popupContainerGetter"
                  :style="{ width: sizeList[0] }"
                />
              </template>
              <CounterpartiesSelect
                v-model:selectedId="transation.counterparty_id"
                hide-link
                :is-select-options-reset="false"
                placeholder="Выберите контрагента"
                :style="{ width: sizeList[1] }"
                :get-popup-container="popupContainerGetter"
              />
              <ProjectSelect
                v-model="transation.project_id"
                hide-link
                :is-select-options-reset="false"
                placeholder="Выберите проект"
                :style="{ width: sizeList[2] }"
                :get-popup-container="popupContainerGetter"
              />
              <AInputPrice
                v-model="transation.amount"
                placeholder="Введите сумму"
                hide-addon
                size="large"
                :style="{ width: sizeList[3] }"
              />
              <AButton
                :disabled="!deletable"
                size="large"
                type="primary"
                danger
                :icon="h(DeleteOutlined)"
                :style="{ width: `${DELETE_BTN_SIZE}px` }"
                @click="() => deleteTransactionAction(key)"
              />
            </AInputGroup>
          </template>
        </template>
        <div class="split-amount__result">
          <AButton class="split-amount__btn" size="small" type="text" @click="addSplitRowAction">
            Разделить еще
          </AButton>
          <div>Итого:</div>
          <div class="split-amount__result-amount">{{ splitResult }}</div>
        </div>
        <template v-if="validateStatus === 'error' || validateStatus === 'validating'">
          <template v-for="(helpInfos, key) in help" :key="key">
            <template v-for="helpInfo in helpInfos" :key="helpInfo.action">
              <div class="slpit-amount__error">
                <div>{{ helpInfo.action }}</div>
                <div class="split-amount__amount-error">{{ helpInfo.amount }}</div>
              </div>
            </template>
          </template>
        </template>
      </TransitionSlide>
    </TransitionSlide>
  </div>
</template>

<style scoped>
.split-amount {
  padding-bottom: 16px;
}

.split-amount__item:not(:first-child) {
  margin-top: 10px;
}

.split-amount__table-wrapper {
  margin-top: 8px;
}

.split-amount__table :deep(.ant-table-tbody) {
  display: none;
}

.split-amount__btn {
  padding-left: 0;
  padding-right: 0;
}

.split-amount__amount-error,
.split-amount__result-amount {
  text-align: right;
}

.split-amount__result {
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto 1fr minmax(193px, auto);
  gap: 8px;
  justify-items: end;
}

.slpit-amount__error {
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr minmax(193px, auto);
  gap: 8px;
  justify-items: end;
  color: v-bind("token.colorErrorText");
}

@media screen and (max-width: 435px) {
  .split-amount__result {
    grid-template-columns: auto 1fr minmax(100px, auto);
  }

  .slpit-amount__error {
    grid-template-columns: 1fr minmax(100px, auto);
  }
}
</style>
