import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useLegalEntityListDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: Array<string> }>(
    "/legal-entities/batch-delete",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const requestLegalEntityListDelete = (
    data: SelectListAction<number | string, { query?: string }>
  ) => execute({ data })

  return {
    legalEntityListDeleteError: useApiError(error),
    requestLegalEntityListDelete,
    isLegalEntityListDeleting: isLoading
  }
}
