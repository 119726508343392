import type { OperationConnectBank } from "../../../interface"

import { computed, reactive } from "vue"

export const useFormState = () => {
  const formState = reactive<OperationConnectBank>({
    login: "",
    password: ""
  })
  const requestData = computed<OperationConnectBank>(() => {
    const { login, password } = formState

    return {
      login,
      password
    }
  })

  return {
    formState,
    requestData
  }
}
