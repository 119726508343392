import { h } from "vue"
import { ArrowDownOutlined, ArrowUpOutlined, SwapOutlined } from "@ant-design/icons-vue"

export const useOpertaionIcons = () => {
  return {
    incoming: h(ArrowDownOutlined),
    outgoing: h(ArrowUpOutlined),
    transfer: h(SwapOutlined)
  }
}
