import { computed, type Ref } from "vue"

import { createMinValidator, cyrillicValidator, phoneNumberValidator } from "@/package/validators"
import type { EditableProfile } from "@/modules/profile"

export const useRuleset = (formState: EditableProfile, emptyPatronymic: Ref<boolean>) => {
  const ruleSet = computed(() => ({
    last_name: [
      {
        required: true,
        message: "Необходимо ввести фамилию"
      },
      {
        asyncValidator: cyrillicValidator
      },
      {
        asyncValidator: createMinValidator(2)
      }
    ],
    first_name: [
      {
        required: true,
        message: "Необходимо ввести имя"
      },
      {
        asyncValidator: cyrillicValidator
      },
      {
        asyncValidator: createMinValidator(2)
      }
    ],
    middle_name: [
      {
        required: !emptyPatronymic.value,
        message: "Необходимо ввести отчество"
      },
      {
        asyncValidator: cyrillicValidator
      },
      {
        asyncValidator: createMinValidator(2)
      }
    ],
    email: [{ type: "email", required: true, message: "Необходимо ввести email" }],
    phone: [{ required: true, asyncValidator: phoneNumberValidator, trigger: "blur" }]
  }))

  return ruleSet
}
