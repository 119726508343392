import type { ColumnsType } from "ant-design-vue/es/table"

const defaultColumns: ColumnsType<any> = [
  {
    title: "Название",
    key: "formatName",
    dataIndex: "formatName",
    ellipsis: { showTitle: true },
    colSpan: 2,
    align: "left"
  }
]
export const useColumns = () => {
  return {
    columns: defaultColumns
  }
}
