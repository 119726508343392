import type { ItemType } from "ant-design-vue"
import type { PureAbility } from "@casl/ability"

import { inject, type ShallowRef, type VNode } from "vue"
import { computed, h } from "vue"
import { BarsOutlined, RocketOutlined, UserOutlined } from "@ant-design/icons-vue"
import { RouterLink } from "vue-router"
import { ABILITY_TOKEN } from "@casl/vue"

import RoubleIcon from "@/assets/icons/rouble.svg"
import {
  AdminLogsRouteName,
  AdminReferencesRouteName,
  AdminRouteName,
  AdminUserRouteName,
  BankAccountRouteName,
  FinanceOperationsRouteName,
  FinancePlanningRouteName,
  FinanceReportsPage,
  FinanceRouteName,
  FinanceStatistics,
  LegalProfileRouteName,
  PhysProfileRouteName,
  ProfileRouteName,
  ReferencesRouteName
} from "@/router"
import type { IPorfileStatus } from "@/modules/profile"
import { ProfileStatus } from "@/modules/profile"

const getItem = (
  label: VNode | string,
  key: string,
  disabled = false,
  icon?: VNode,
  children?: ItemType[],
  type?: "group"
): ItemType =>
  ({
    disabled,
    key,
    icon,
    children,
    label,
    type
  }) as ItemType
export const useMenuItems = (profileStatus: ShallowRef<IPorfileStatus>, isExpired = false) => {
  const ability = inject<PureAbility>(ABILITY_TOKEN)

  return computed(() => {
    const isUnconfirmed = profileStatus.value.status === ProfileStatus.UNCONFIRMED
    const isDisabled = computed(() => isExpired || isUnconfirmed)
    let result = new Array<ItemType>()
    if (profileStatus.value.status !== ProfileStatus.UNKNOWN) {
      if (ability?.can("admin", "app") && profileStatus.value.asAdmin) {
        result = [
          getItem("Админ. панель", AdminRouteName, isUnconfirmed, h(RocketOutlined), [
            getItem(
              h(RouterLink, { to: { name: AdminUserRouteName } }, () => "Пользователи"),
              AdminUserRouteName,
              isUnconfirmed
            ),
            getItem("Административные справочники", AdminReferencesRouteName, true),
            getItem("Лог", AdminLogsRouteName, true)
          ])
        ]
      } else {
        result = [
          getItem("Профиль", ProfileRouteName, isUnconfirmed, h(UserOutlined), [
            getItem(
              h(RouterLink, { to: "/profile/phys" }, () => "Пользователь"),
              PhysProfileRouteName,
              isUnconfirmed
            ),
            getItem(
              h(
                RouterLink,
                { to: "/profile/legal", class: isDisabled.value ? "item-disabled" : "" },
                () => "Организации"
              ),
              LegalProfileRouteName,
              isDisabled.value
            )
          ]),
          getItem(
            "Управление финансами",
            FinanceRouteName,
            isUnconfirmed,
            h(RoubleIcon, { width: "1em", height: "1em" }),
            [
              getItem(
                h(RouterLink, { to: { name: FinanceStatistics } }, () => "Статистика"),
                FinanceStatistics,
                isUnconfirmed
              ),
              getItem(
                h(RouterLink, { to: { name: FinanceOperationsRouteName } }, () => "Операции"),
                FinanceOperationsRouteName,
                isUnconfirmed
              ),
              getItem(
                h(
                  RouterLink,
                  {
                    to: { name: FinancePlanningRouteName },
                    class: isDisabled.value ? "item-disabled" : ""
                  },
                  () => "Планирование"
                ),
                FinancePlanningRouteName,
                isDisabled.value
              ),
              getItem(
                h(
                  RouterLink,
                  {
                    to: { name: FinanceReportsPage },
                    class: isDisabled.value ? "item-disabled" : ""
                  },
                  () => "Отчеты"
                ) as any,
                FinanceReportsPage,
                isDisabled.value
              )
            ]
          ),
          getItem(
            isUnconfirmed
              ? h("div", {}, "Справочники")
              : h(
                  RouterLink,
                  { to: isExpired ? "/references/bank-account" : "/references" },
                  () => "Справочники"
                ),
            isExpired ? BankAccountRouteName : ReferencesRouteName,
            isUnconfirmed,
            h(BarsOutlined)
          )
        ]
      }
    }
    return result
  })
}
