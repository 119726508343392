import { validateBik } from "./bik"

export function validateRs(
  rs?: string | number,
  bik?: string | number,
  required?: boolean | null | undefined
): [
  boolean,
  {
    code: number
    message: string
  } | null
] {
  let result = false
  let error = null
  const [validBik] = validateBik(bik)
  if (typeof rs === "number") {
    rs = rs.toString()
  } else if (typeof rs !== "string") {
    rs = ""
  }
  if (required && !rs.length) {
    error = {
      code: 1,
      message: "Пожалуйста, введите расчетный счет"
    }
  } else if (validBik && bik && rs.length) {
    if (/[^0-9]/.test(rs)) {
      error = {
        code: 2,
        message: "Р/С может состоять только из цифр"
      }
    } else if (rs.length !== 20) {
      error = {
        code: 3,
        message: "Расчетный счет должен содержать 20 символов"
      }
    } else {
      const bikRs = bik.toString().slice(-3) + rs
      let checksum = 0
      const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1]
      for (const i in coefficients) {
        checksum += coefficients[i] * (+bikRs[i] % 10)
      }
      if (checksum % 10 === 0) {
        result = true
      } else {
        error = {
          code: 4,
          message: "Бик или Р/С указаны неверно"
        }
      }
    }
  } else {
    if (rs.length) {
      error = {
        code: 4,
        message: "Бик или Р/С указаны неверно"
      }
    }
  }
  return [result, error]
}
