<script setup lang="ts">
import { useToggle } from "@vueuse/core"
import { useRouter } from "vue-router"

import { RestoreForm } from "@/modules/auth"
import { loginRoute } from "@/router"

defineProps({
  logoSize: {
    type: Number,
    default: 0
  }
})

const router = useRouter()
const [isSuccess, toggleSuccess] = useToggle(false)
</script>

<template>
  <AFlex vertical :style="{ flexGrow: 1 }" justify="center">
    <ATypographyTitle :style="{ marginBottom: '8px', textAlign: 'center' }"
      >Создание пароля
    </ATypographyTitle>
    <AText :style="{ textAlign: 'center' }" variant="lg"
      >Пожалуйста, придумайте пароль длиной не менее 8 символов
    </AText>
    <template v-if="isSuccess">
      <AAlert
        :style="{ marginTop: '24px' }"
        show-icon
        type="success"
        message="Пароль успешно изменен"
      />
      <AButton
        type="primary"
        html-type="submit"
        block
        size="large"
        :style="{ marginTop: '32px' }"
        @click="() => router.push({ name: loginRoute })"
      >
        Перейти на страницу авторизации
      </AButton>
    </template>
    <template v-else>
      <RestoreForm :style="{ marginTop: '12px' }" @success="() => toggleSuccess(true)" />
    </template>
  </AFlex>
  <div :style="{ height: `${logoSize}px`, marginTop: '16px' }" />
</template>
