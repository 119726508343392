<script setup lang="ts">
import type { Dayjs } from "dayjs"

import { computed, nextTick, onMounted, type PropType, ref } from "vue"
import { get, set, useToggle } from "@vueuse/core"

import { BankAccountSelect } from "@/modules/bank-account"
import { OperationCategoryTreeSelect } from "@/modules/operation-category"
import { APageDrawer, useAdoptable } from "@/package/ui-kit"
import { ProjectSelect } from "@/modules/project"
import type { SummaryStatisticsFilter } from "@/modules/statistics"
import { LegalEntitySelect } from "@/modules/legal-entity"

const props = defineProps({
  showFilterPanel: {
    type: Boolean,
    default: false
  },

  formStateProp: {
    type: Object as PropType<Partial<SummaryStatisticsFilter>>,

    default: () => ({
      date: [],
      bankAccountId: undefined,
      operationCategories: [],
      allCategories: false,
      projectId: undefined
    })
  }
})

const emit = defineEmits(["submit", "cancel"])

const isAdoptable = useAdoptable()

const backupDateState = ref<Array<Dayjs>>([])
const currentDates = ref<Array<Dayjs>>([])
const [isDatepickerOpened, toggleDatepickerOpened] = useToggle(false)
const [isDateChanging, toggleIsDateChanging] = useToggle<boolean>(false)
const formState = ref<Partial<SummaryStatisticsFilter>>({})

const closeDatePicker = async () => {
  backupDateState.value = []
  toggleDatepickerOpened(false)
  await nextTick(() => {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
  })
}

const applyDatePicker = async () => {
  formState.value.date = currentDates.value
  await closeDatePicker()
}

const cancelDateSelect = () => {
  formState.value.date = backupDateState.value
  closeDatePicker()
}

const focusRangePicker = () => {
  if (!isDatepickerOpened.value) {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
  }

  if (!isDatepickerOpened.value && formState.value.date) {
    backupDateState.value = formState.value.date
    toggleDatepickerOpened(true)
  }
}

const blurRangePicker = () => {
  if (!isDateChanging.value) {
    toggleDatepickerOpened(false)
  } else {
    toggleIsDateChanging(false)
  }
}

const handleCalendarChange = (
  dates: Array<Dayjs>,
  dateString: Array<string>,
  info: { range: string }
) => {
  if (!dates) {
    toggleDatepickerOpened(false)
    Object.assign(currentDates.value, [null, null])
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
    return
  }

  currentDates.value = dates
  if (info.range === "start" && dates[0]) {
    toggleIsDateChanging(true)
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.focus()
  }

  if (info.range === "end" && dates[1]) {
    toggleIsDateChanging(true)
    nextTick(() =>
      (document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
    )
  }
}

onMounted(() => {
  formState.value = Object.assign({}, props.formStateProp)
})

const operationCategoriesModel = computed({
  set(v: Array<string | number> | undefined) {
    const state = get(formState)
    state.operationCategories = v
    set(formState, state)
  },
  get() {
    let result = undefined
    if (!get(formState).allCategories) {
      result = get(formState).operationCategories
    }
    return result
  }
})
</script>

<template>
  <APageDrawer :open="showFilterPanel" title="Фильтры статистики" @close="emit('cancel')">
    <AForm
      name="statistics-filter-form"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      scroll-to-first-error
    >
      <AFormItem v-if="isAdoptable" label="Период" name="dateFilter">
        <ARangePicker
          style="width: 100%"
          :value="formState.date"
          :format="['DD-MM-YYYY', 'DD-MM-YYYY']"
          :open="isDatepickerOpened"
          :placeholder="['Начало', 'Конец']"
          popup-class-name="operation-filters-date-picker"
          size="large"
          @focus.prevent="focusRangePicker"
          @calendarChange="handleCalendarChange"
          @blur="blurRangePicker"
        >
          <template #renderExtraFooter>
            <AFlex gap="8" justify="end" :style="{ padding: '8px' }">
              <AButton size="small" type="default" @click="cancelDateSelect">Отмена</AButton>
              <AButton size="small" type="primary" @click="applyDatePicker"> Применить </AButton>
            </AFlex>
          </template>
        </ARangePicker>
      </AFormItem>
      <AFormItem label="Расчетный счет" name="bankAccountId">
        <BankAccountSelect
          v-model="formState.bankAccountId"
          placeholder="Выберите счет"
          :style="{ width: '100%' }"
          hide-link
        />
      </AFormItem>
      <AFormItem label="Проект" name="projectId">
        <ProjectSelect
          v-model="formState.projectId"
          placeholder="Выберите проект"
          :style="{ width: '100%' }"
          hide-link
        />
      </AFormItem>
      <AFormItem v-if="isAdoptable" label="Статьи доходов/расходов" name="operationCategoriesModel">
        <OperationCategoryTreeSelect
          v-model="operationCategoriesModel"
          hide-link
          placeholder="Выберите cтатьи"
          size="large"
          :style="{ width: '100%' }"
          :max-tag-count="1"
        />
      </AFormItem>
      <AFormItem label="Юридическое лицо" name="legalEntityId">
        <LegalEntitySelect
          v-model="formState.legalEntityId"
          hide-link
          placeholder="Выберите юр.лицо"
          size="large"
          :style="{ width: '100%' }"
        />
      </AFormItem>
      <AFormItem
        v-if="isAdoptable"
        label="Отображать на графике доходов/расходов:"
        name="selectedMainVariants"
      >
        <ACheckboxGroup
          v-model:value="formState.selectedMainVariants"
          :options="[
            { label: 'Доходы', value: 'income' },
            { label: 'Расходы', value: 'outlay' }
          ]"
        />
      </AFormItem>
      <AFormItem
        v-if="isAdoptable"
        label="Отображать на графике чистой прибыли:"
        name="selectedRevenueVariants"
      >
        <ACheckboxGroup
          :value="formState.selectedRevenueVariants"
          style="flex-direction: column"
          :options="[
            { label: 'Чистая прибыль', value: 'revenue' },
            { label: 'Чистая прибыль с нарастающим итогом', value: 'revenueIncrease' }
          ]"
        />
      </AFormItem>
    </AForm>
    <template #footer>
      <ARow :gutter="8">
        <ACol :span="12">
          <AButton size="large" block type="default" @click="emit('cancel')">Отменить</AButton>
        </ACol>
        <ACol :span="12">
          <AButton size="large" block type="primary" @click="emit('submit', formState)"
            >Применить</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>

<style scoped>
.operation-category-select:deep(.ant-spin-text) {
  transform: translateY(-50%);
}
.operation-category-select {
  width: 100%;
}
</style>
