import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router"

import { SystemRoutes } from "./routes/system"
import { AuthSection, authPageNames } from "./routes/auth"
import { completeRegistrationRoute } from "./routes/auth/constants"
import { AppSection } from "./routes/app"
import { appRoot } from "./routes/app/constants"

export const routes: RouteRecordRaw[] = [...AuthSection, ...AppSection, ...SystemRoutes]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export const useUserAuth = (isAuthenticatedCb?: () => Promise<boolean> | boolean) => {
  router.beforeEach(async (to) => {
    if (isAuthenticatedCb) {
      const isAuthenticated = await isAuthenticatedCb()

      if (!isAuthenticated && typeof to.name === "string" && !authPageNames.includes(to.name)) {
        window.location.href = "https://xn--80aygj.xn--p1ai/"
        return false
      } else if (
        isAuthenticated &&
        typeof to.name === "string" &&
        authPageNames.includes(to.name)
      ) {
        const query = to.name === completeRegistrationRoute ? to.query : {}
        return { name: appRoot, query }
      }
    }
  })
}

export default router

export * from "./routes/app/constants"
export * from "./routes/auth/constants"
export * from "./providers"
