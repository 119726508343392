import type { FormValidator } from "./types"

export const digitsIncludeValidator: FormValidator = (rule, value) =>
  new Promise((resolve, reject) => {
    if (!rule.required && value.length === 0) {
      resolve(undefined)
      return
    }
    if (!value.match(/^\d+$/)) {
      reject("Значение должно состоять только из цифр")
    } else {
      resolve(undefined)
    }
  })
