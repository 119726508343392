<script setup lang="ts">
import { push } from "notivue"
import { onMounted } from "vue"
import { get } from "@vueuse/core"

import { CounterpartiesSelect } from "@/modules/counterparty"
import { useOperationStore } from "@/modules/operation"
import { BankAccountSelect } from "@/modules/bank-account"
import { OperationCategoryTreeSelect } from "@/modules/operation-category"
import { usePopupContainerGetter } from "@/package/ui-kit"
import { ProjectSelect } from "@/modules/project"
import { injectProfileStatus } from "@/modules/profile"

import { useFormState } from "../hooks/use-form-state"
import TagsSelect from "../../TagsSelect.vue"

const emit = defineEmits(["success"])

const store = useOperationStore()

const { formState, requestData, setupStateFromParams } = useFormState()

const profileStatus = injectProfileStatus()

const applyFilters = () =>
  store
    .saveFilters(get(requestData), profileStatus.value.asAdmin)
    .then(() => store.applyFilters(requestData))
    .then(() => {
      push.success({
        message: "Фильтры применены"
      })
      emit("success")
    })
    .catch(() => emit("success"))

const resetFilters = () =>
  store
    .saveFilters(null, profileStatus.value.asAdmin)
    .then(() => store.applyFilters(null))
    .then(() => {
      setupStateFromParams(store.filters)
      push.success({
        message: "Фильтры сброшены"
      })
      emit("success")
    })
    .catch(() => emit("success"))

onMounted(() => {
  setupStateFromParams(store.filters)
})
defineExpose({ applyFilters, resetFilters })
const popupContainerGetter = usePopupContainerGetter()
</script>

<template>
  <AForm
    name="create-product-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    @submit.prevent="applyFilters"
  >
    <AFormItem label="Счет" name="bank_account_id">
      <BankAccountSelect
        v-model="formState.bank_account_id"
        :get-popup-container="popupContainerGetter"
        hide-link
        placeholder="Выберите счет"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Статья дохода/расхода" name="category_id">
      <OperationCategoryTreeSelect
        v-model="formState.category_id"
        hide-link
        placeholder="Выберите статью"
        mode="multiple"
        size="large"
        :max-tag-count="2"
      />
    </AFormItem>
    <AFormItem label="Проект" name="project_id">
      <ProjectSelect
        v-model="formState.project_id"
        :get-popup-container="popupContainerGetter"
        hide-link
        has-none-option
        placeholder="Выберите проект"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Контрагент" name="counterparty_id">
      <CounterpartiesSelect
        v-model:selectedId="formState.counterparty_id"
        :get-popup-container="popupContainerGetter"
        hide-link
        has-none-option
        placeholder="Выберите контрагента"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Теги" name="tags">
      <TagsSelect
        v-model="formState.tags"
        :get-popup-container="popupContainerGetter"
        placeholder="Выберите теги"
        mode="multiple"
        size="large"
      />
    </AFormItem>
  </AForm>
</template>

<style scoped lang="scss">
:deep(.ant-checkbox-group) {
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
</style>
