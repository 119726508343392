<script setup lang="ts">
import locale from "ant-design-vue/es/locale/ru_RU"
import Icon from "@ant-design/icons-vue"
import { RouterView } from "vue-router"
import { App, ConfigProvider, FloatButton } from "ant-design-vue"
import { useRouteQuery } from "@vueuse/router"

import { PushNotificationOutput } from "@/package/ui-kit"
import { CurrentRouteProvider } from "@/router"
import { useAppConfig } from "@/store"
import Sun from "@/assets/icons/sun.svg"
import Moon from "@/assets/icons/moon.svg"
import { AppAuthProvider } from "@/modules/auth"
import { CentrifugoProvider } from "@/modules/centrifugo"

const appConfig = useAppConfig()
const themingTest = useRouteQuery("themingTest")
</script>

<template>
  <CurrentRouteProvider>
    <AppAuthProvider>
      <CentrifugoProvider>
        <ConfigProvider :locale="locale" :theme="appConfig.theme">
          <App
            :style="{
              height: '100%',
              width: '100%'
            }"
          >
            <RouterView />
          </App>
          <FloatButton
            v-if="themingTest !== undefined"
            :style="{ top: '70px' }"
            @click="
              () => {
                appConfig.toggleTheme()
                throw new Error('TEST SENTRY ERROR')
              }
            "
          >
            <template #icon>
              <Icon>
                <template #component>
                  <Sun v-if="appConfig.isDarkTheme" :width="18" :height="18" />
                  <Moon v-else :width="18" :height="18" />
                </template>
              </Icon>
            </template>
          </FloatButton>
        </ConfigProvider>
        <PushNotificationOutput />
      </CentrifugoProvider>
    </AppAuthProvider>
  </CurrentRouteProvider>
</template>
