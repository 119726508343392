import type { RouteRecordRaw } from "vue-router"

import SupportPage from "@/views/SupportPage.vue"

import { SupportSection } from "./constants"

export const SupportRoute: RouteRecordRaw[] = [
  {
    path: "/support",
    name: SupportSection,
    component: SupportPage,
    meta: {
      breadcrumb: {
        disabled: true,
        name: "Техническая поддержка"
      }
    }
  }
]
