import type { ImportFormField } from "./interfaces"

import { defineStore } from "pinia"
import { computed, reactive, ref } from "vue"
import { useToggle } from "@vueuse/core"

import { useFileImport } from "./hooks"

export const useOperationImportStore = defineStore("operationImport", () => {
  const requiredFields = [
    { id: 1, operationKey: { label: "Счет", value: "bank_account_id" }, value: null },
    { id: 2, operationKey: { label: "Дата операции", value: "date" }, value: null },
    { id: 3, operationKey: { label: "Сумма", value: "amount" }, value: null }
  ]

  const fieldNameSelectOptionsList = ref([
    { label: "Дата операции", value: "date" },
    { label: "Вид операции", value: "type" },
    { label: "Сумма", value: "amount" },
    { label: "Тип операции", value: "action" },
    { label: "Статья", value: "operation_category" },
    { label: "Контрагент", value: "counterparty" },
    { label: "Назначение", value: "purpose" },
    { label: "Проект", value: "project" }
  ])

  const formFields = reactive<Array<ImportFormField>>([...requiredFields])
  const files = ref<{ id: number; name: string; url: string }[]>([])
  const excelColumnOptionList = ref<Array<{ label: string; value: string }>>([])

  const filteredFieldNameOptions = computed(() => {
    return fieldNameSelectOptionsList.value.filter(
      (option) => !formFields.find((data) => data.operationKey?.value === option.value)
    )
  })

  const filteredExcelColumnOptions = computed(() => {
    return excelColumnOptionList.value.filter(
      (option) => !formFields.find((data) => data.value === option.value)
    )
  })

  const canAddMoreFields = computed(() => {
    return (
      filteredExcelColumnOptions.value.length !== 0 &&
      formFields.length - 1 < excelColumnOptionList.value.length
    )
  })

  const deleteField = (idToDelete: number) => {
    formFields.splice(0, formFields.length, ...formFields.filter(({ id }) => id !== idToDelete))
  }

  const addField = () => {
    formFields.push({
      id: formFields!.at(-1)!.id + 1,
      operationKey: null,
      value: ""
    })
  }

  const requestData = computed(() => {
    const payload: Record<string, string> = {}

    formFields.forEach((field) => {
      payload[field.operationKey!.value] = field.value!
    })

    return payload
  })

  const setFileId = (newFieldId: number, newFileName: string, fileUrl: string) => {
    files.value = [{ id: newFieldId, name: newFileName, url: fileUrl }]
  }

  const removeFile = () => {
    files.value = []
    formFields.splice(0, formFields.length, ...requiredFields)
    excelColumnOptionList.value = []
  }

  const setExcelColumnOptions = (optionList: Array<string>) => {
    excelColumnOptionList.value = optionList.map((option) => ({ label: option, value: option }))
    formFields.forEach((item) => {
      if (item.operationKey?.value !== "bank_account_id") {
        if (item.value && !optionList.includes(item.value)) {
          item.value = ""
        }
      }
    })
  }

  const { fileImport } = useFileImport()
  const [isFileImporting, toggleImportStatus] = useToggle(false)

  const importOperations = async () => {
    toggleImportStatus(true)

    try {
      await fileImport(files.value[0].id, requestData.value)
    } catch (e) {
      toggleImportStatus(false)
    }
  }

  const $reset = () => {
    files.value = []
    formFields.splice(
      0,
      formFields.length,
      ...requiredFields.map((field) => ({ ...field, value: null }))
    )
    excelColumnOptionList.value = []
  }

  return {
    formFields,
    excelColumnOptionList,
    filteredFieldNameOptions,
    filteredExcelColumnOptions,
    canAddMoreFields,
    deleteField,
    addField,
    setFileId,
    removeFile,
    setExcelColumnOptions,
    requestData,
    toggleImportStatus,
    importOperations,
    files,
    isFileImporting,
    $reset
  }
})
