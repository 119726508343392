<script setup lang="ts">
import { useToggle } from "@vueuse/core"
import { computed, watch } from "vue"

import { useAdoptable, useVh } from "@/package/ui-kit"

import LayoutContent from "./Layout/LayoutContent.vue"
import LayoutHeader from "./LayoutHeader.vue"
import AppSidebar from "./Sidebar/AppSidebar.vue"
import { useLayoutVariant } from "./hooks"

const isAdoptable = useAdoptable()
const [isCollapsed, toggleCollapsed] = useToggle(false)
watch(
  isAdoptable,
  () => {
    toggleCollapsed(isAdoptable.value)
  },
  { immediate: true }
)
const { isContentLayout } = useLayoutVariant()
const { vhVariableProvider } = useVh()

const layoutStyling = computed(() => ({
  width: "100%",
  minHeight: "100%",
  ...(isContentLayout.value ? {} : { maxHeight: "100%", overflow: "hidden" }),
  ...vhVariableProvider.value
}))
</script>

<template>
  <ALayout :style="layoutStyling">
    <AppSidebar v-model="isCollapsed" />
    <ALayout>
      <LayoutHeader v-model="isCollapsed" />
      <LayoutContent />
    </ALayout>
  </ALayout>
</template>
