<script setup lang="ts">
import { computed, onMounted, watch } from "vue"
import { useEventBus } from "@vueuse/core"
import { Mask } from "maska"
import { push } from "notivue"

import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { injectProfileLoading, useProfile } from "@/modules/profile"

import ProfileWrapper from "../ProfileWrapper/ProfileWrapper.vue"
import { useLegalEntity } from "../../hooks/use-legal-entity"

const props = defineProps({
  subscriptionLoading: {
    type: Boolean,
    default: false
  }
})

defineEmits(["editProfile"])

const { on } = useEventBus("profile-updated")

const { refreshAsync } = useProfile()

const { legalEntityData, getLegalEntityData, legalEntityDataPending, LegalEntityDataError } =
  useLegalEntity()

onMounted(async () => {
  await getLegalEntityData()
  on(() => {
    getLegalEntityData()
    refreshAsync()
  })
})

watch(LegalEntityDataError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const isLoading = injectProfileLoading()
defineOptions({
  inheritAttrs: true
})

const mask = new Mask({ mask: "+7 (###) ###-##-##" })

const phone = computed(() => mask.masked(legalEntityData?.value?.data?.info?.phone || ""))
</script>

<template>
  <ASpin
    wrapper-class-name="a-spin-nested"
    :spinning="isLoading || props.subscriptionLoading || legalEntityDataPending"
  >
    <ProfileWrapper
      title="Данные ИП"
      editable
      :style="{ height: '100%' }"
      @editProfileData="() => $emit('editProfile')"
    >
      <ADescriptions layout="vertical" v-bind="$attrs" :column="1">
        <ADescriptionsItem label="Название">
          {{ legalEntityData?.data?.legal_entity?.organization_name || "—" }}</ADescriptionsItem
        >
        <ADescriptionsItem label="Контактное лицо">{{
          legalEntityData?.data?.legal_entity?.head_fullname || "—"
        }}</ADescriptionsItem>
        <ADescriptionsItem label="E-mail">{{
          legalEntityData?.data?.info?.email || "—"
        }}</ADescriptionsItem>
        <ADescriptionsItem label="Номер телефона">{{ phone || "—" }}</ADescriptionsItem>
      </ADescriptions>
    </ProfileWrapper>
  </ASpin>
</template>

<style scoped>
.avatar {
  margin-right: 32px;
  width: 140px;
  height: 140px;
  background: linear-gradient(260.01deg, #969696 15%, #363635 85%);
  border-radius: 50%;
  flex-shrink: 0;
}

.avatar-inner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  font-size: 24px;
  color: #ffffff;
}

.a-spin-nested {
  height: 100%;
}

:deep(.ant-spin-container) {
  height: 100%;
}
</style>
