import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { LegalEntityCreate } from "@/modules/legal-entity"

export const useLegalEntityEdit = () => {
  const { execute, isLoading, error, isFinished } = useAxios(
    "/legal-entities/update",
    { method: "POST" },
    client,
    { immediate: false }
  )

  const editLegalEntity = (id: number | string, data: LegalEntityCreate) =>
    execute({ params: { id }, data })

  return {
    editLegalEntity,
    legalEntityEditError: useApiError(error),
    isLegalEntityEditing: isLoading,
    isEditingFinished: isFinished
  }
}
