<script setup lang="ts">
import { type PropType, type VNode } from "vue"
import { theme } from "ant-design-vue"

defineProps({
  el: {
    type: [String, Object] as PropType<string | VNode>,
    default: () => "div"
  },

  disabled: {
    type: Boolean,
    default: false
  }
})
const { token } = theme.useToken()
defineOptions({
  inheritAttrs: false
})
</script>

<template>
  <div class="ant-fake-option-wrapper">
    <component
      :is="el"
      class="ant-fake-option"
      :class="{ 'ant-fake-option--disabled': disabled }"
      v-bind="$attrs"
    >
      <slot />
    </component>
  </div>
</template>

<style scoped>
.ant-fake-option-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}
:deep(.ant-fake-option:not(.ant-fake-option--disabled)):hover {
  background: v-bind("token.controlItemBgHover");
}
:deep(.ant-fake-option) {
  display: flex;
  padding: 5px 12px;
  width: 100%;
}
</style>
