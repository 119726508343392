import type { LegalEntityData } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

interface LegalDataResponse {
  success: boolean
  data: Array<LegalEntityData>
}

export const useLegalDataSearch = () => {
  const { data, isLoading, error, execute } = useAxios<LegalDataResponse>(
    "/legal-entities/find-legal-data",
    { method: "GET" },
    client,
    { immediate: false }
  )
  const executeSearch = (query: string = "") =>
    query.length ? execute({ params: { query, count: 20 } }) : Promise.resolve()
  return { searchResult: data, isLoading, error, executeSearch }
}
