import type { TemplateOption } from "../../interfaces"

import { defineStore } from "pinia"
import { computed } from "vue"

import { useOperationCategoryTemplateOptions } from "../../hooks/use-operation-category-template-options"
import { useOperationCreateFromTemplate } from "../../hooks/use-operation-create-from-template"

export const useTemplateOperationCategoryStore = defineStore(
  "templateOperationCategoryStore",
  () => {
    const { optionsResponse, getTemplateOptions } = useOperationCategoryTemplateOptions()

    const data = computed<TemplateOption[]>(() => optionsResponse?.value?.data ?? [])

    const options = computed(() =>
      data.value.map(({ name, id }: TemplateOption) => ({
        label: name,
        value: id
      }))
    )

    const { createFromTemplate, createFromTemplateError } = useOperationCreateFromTemplate()

    return {
      data,
      optionsResponse,
      options,
      getTemplateOptions,
      createFromTemplateError,
      createFromTemplate
    }
  }
)
