<script setup lang="ts">
import { useRoute } from "vue-router"

import { injectProfileStatus, ProfileStatus, useProfile } from "@/modules/profile"
import { useAdoptable } from "@/package/ui-kit"
import router, { AdminUserRouteName } from "@/router"
import { useBackupTokens } from "@/package/api-client"

import { useLayoutVariant } from "../hooks"

import BreadcumbsList from "./BreadcumbsList.vue"
import UnconfirmedError from "./UnconfirmedError.vue"

const profileStatus = injectProfileStatus()
const isAdoptable = useAdoptable()
const { isContentLayout } = useLayoutVariant()
const { refreshAsync } = useProfile()

const logoutToAdmin = async () => {
  useBackupTokens()
  await refreshAsync()
  await router.push({ name: AdminUserRouteName })
}

const route = useRoute()
</script>

<template>
  <ALayoutContent
    :style="{
      padding: isAdoptable ? '24px 40px' : '24px 48px 24px 24px',
      display: 'flex',
      flexDirection: 'column',
      ...(isContentLayout
        ? {}
        : { maxHeight: '100%', overflow: route.name === 'Subscription' ? 'auto' : 'hidden' })
    }"
  >
    <AAlert
      v-if="profileStatus.authAsUser"
      type="warning"
      message="Вы авторизованы как пользователь:"
      style="position: fixed; top: 10px; right: 10px; z-index: 99999"
    >
      <template #action>
        <AButton type="link" @click="logoutToAdmin">Отключиться</AButton>
      </template>
    </AAlert>
    <UnconfirmedError v-if="profileStatus.status === ProfileStatus.UNCONFIRMED" />
    <template v-else-if="profileStatus.status === ProfileStatus.ACTIVE">
      <BreadcumbsList :style="{ maringBottom: '32px' }" />
      <RouterView />
    </template>
    <AFlex v-else :style="{ height: '100%' }" justify="center" align="center">
      <ASpin size="large" tip="подготовка рабочей области" />
    </AFlex>
  </ALayoutContent>
</template>
