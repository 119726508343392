import { computed, reactive } from "vue"

import type { OperationCategoryCreate } from "@/modules/operation-category"

export const useFormState = () => {
  const formState = reactive<OperationCategoryCreate>({
    name: "",
    type: null,
    comment: null,
    action_type: "",
    parent_id: null,
    is_taxable: null
  })
  const requestData = computed<OperationCategoryCreate>(() => {
    const { name, comment, parent_id, action_type, type, is_taxable } = formState

    return {
      name,
      comment,
      parent_id,
      action_type,
      type,
      is_taxable
    }
  })

  return {
    formState,
    requestData
  }
}
