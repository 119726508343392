import type { CreateIncomeOperationForm, FormState } from "../interface"
import type { OperationDetailDTO } from "../../../interface"

import { computed, type MaybeRef, provide, reactive, watch } from "vue"
import dayjs from "dayjs"
import { get } from "@vueuse/core"

import { usePageDrawerWidthSetter } from "@/package/ui-kit"

import { OperationAction } from "../../../interface"
import { DEFAULT_FORM_STATE, DEFAULT_TRANSACTION_STATE, DRAWER_SPLIT_WDITH } from "../constants"

export const FormStateToken = Symbol("CreateOperationFormState")
export const DefaultTransactionToken = Symbol("DefaultTransactionToken")

export const useOperationFormStateProvider = (
  state: MaybeRef<typeof DEFAULT_FORM_STATE> = DEFAULT_FORM_STATE,
  transaction: MaybeRef<typeof DEFAULT_TRANSACTION_STATE> = DEFAULT_TRANSACTION_STATE
) => {
  const formState = reactive<FormState>(Object.assign({}, get(state)))
  provide(FormStateToken, formState)

  const defaultTransaction = reactive(Object.assign({}, get(transaction)))
  provide(DefaultTransactionToken, defaultTransaction)

  const resetAction = (operation: Partial<OperationDetailDTO>) => {
    if (operation.transactions?.length === 1) {
      const transaction = operation.transactions[0]
      Object.assign(defaultTransaction, {
        id: transaction.id,
        amount: transaction.amount,
        income_operation_category_id:
          operation.action === OperationAction.incoming ? transaction.category?.id : undefined,
        outcome_operation_category_id:
          operation.action === OperationAction.outgoing ? transaction.category?.id : undefined,
        counterparty_id: transaction.counterparty?.id,
        project_id: transaction.project?.id
      })
    }

    const formData: FormState = {
      type: operation.type,
      bank_account_from_id: operation.bank_account_from?.id,
      tags: operation.tags ?? [],
      amount: operation.amount?.toString(),
      transactions:
        operation.transactions && operation.transactions?.length > 1
          ? operation.transactions.map((transaction) => {
              return {
                id: transaction?.id,
                amount: transaction.amount?.toString() ?? "",
                income_operation_category_id:
                  operation.action === OperationAction.incoming
                    ? transaction.category?.id
                    : undefined,
                outcome_operation_category_id:
                  operation.action === OperationAction.outgoing
                    ? transaction.category?.id
                    : undefined,
                counterparty_id: transaction.counterparty?.id,
                project_id: transaction.project?.id
              }
            })
          : [],
      date: operation.date ? dayjs(operation.date, "DD-MM-YYYY") : undefined,
      purpose: operation.purpose,
      bank_account_to_id: operation.bank_account_to?.id,
      files: operation.files?.map(({ id, file_size, file_type, file_name, file_ext }) => ({
        id: id,
        size: file_size,
        type: file_type,
        ext: file_ext,
        name: file_name
      }))
    }

    if (operation.is_repeat) {
      formData.is_repeat = operation.is_repeat
      formData.repeat_period = operation.repeat_period?.slug
      formData.repeat_finish_at = dayjs(operation.repeat_finish_at, "DD-MM-YYYY")
    }

    Object.assign(formState, formData)
  }

  return resetAction
}

export const useAmountSplit = (
  formState: Partial<Pick<CreateIncomeOperationForm, "transactions">>
) => {
  const setDrawerWidth = usePageDrawerWidthSetter()
  const isSplited = computed(
    () => formState.transactions !== undefined && formState.transactions.length >= 2
  )
  watch(
    isSplited,
    (splited) => {
      setDrawerWidth(splited ? DRAWER_SPLIT_WDITH : undefined)
    },
    { immediate: true }
  )
  return { isSplited }
}
