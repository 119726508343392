import { useAxios } from "@vueuse/integrations/useAxios"

import type { EditCounterparty } from "@/modules/counterparty"
import { client, useApiError } from "@/package/api-client"
import { parseNumber } from "@/package/util"

export const UseCounterpartiesEdit = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: EditCounterparty }
  >("/counterparties/update", { method: "POST" }, client, { immediate: false })

  const transformFormDataToRequest = (data: EditCounterparty): EditCounterparty => ({
    id: data.id,
    name: data.name || null,
    contact_person: data.contact_person || null,
    phone: data.phone ? parseInt(parseNumber(data.phone)).toString() : null,
    email: data.email || null,
    comment: data.comment || null
  })
  const editCounterparty = async (formData: EditCounterparty) => {
    const data = transformFormDataToRequest(formData)
    await execute({ data })
  }
  return {
    editError: useApiError(error),
    editCounterparty,
    isEditing: isLoading,
    isEditingFinished: isFinished
  }
}
