<script setup lang="ts">
import { Input } from "ant-design-vue"
import { useSlots } from "vue"

import { parseNumber } from "@/package/util"

const [input, modifiers] = defineModel({
  type: String,
  set(v) {
    if (modifiers.numbered) {
      return parseNumber(v)
    } else if (modifiers.lowercase) {
      return v.toLowerCase()
    } else {
      return v
    }
  }
})
const slots = useSlots()
</script>

<template>
  <Input v-model:value="input">
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </Input>
</template>
