import { computed, reactive } from "vue"

import type { EditCounterparty } from "@/modules/counterparty"

export const useFormState = () => {
  const formState = reactive<EditCounterparty>({
    id: 0,
    name: "",
    contact_person: "",
    email: "",
    phone: "",
    comment: ""
  })
  const requestData = computed<EditCounterparty>(() => {
    const { id, name, contact_person, email, phone, comment } = formState

    return {
      id,
      name,
      contact_person,
      email,
      phone,
      comment
    }
  })

  return {
    formState,
    requestData
  }
}
