<script setup lang="ts">
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue"
import { useRouter } from "vue-router"
import { ref, h, computed, watch } from "vue"
import { noop, watchThrottled } from "@vueuse/core"
import { push } from "notivue"
import { TransitionFade, TransitionSlide } from "@morev/vue-transitions"
import { storeToRefs } from "pinia"

import { FinancePlanningCreateRouteName, FinancePlanningEditRouteName } from "@/router"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useBudgetStore, BudgetListing } from "@/modules/budget"
import { ApiNotFoundResponse } from "@/package/api-client"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { SubscriptionReminder } from "@/modules/subscription-reminder"

const router = useRouter()

const isAdoptable = useAdoptable()
const listingRef = ref<InstanceType<typeof BudgetListing> | null>(null)
const allChecked = computed({
  get: () => listingRef.value?.isAllSelected,
  set: (value) => {
    listingRef.value?.toggleAllSelected(value)
  }
})

const store = useBudgetStore()

const { budgetDeleteError, budgetDeleteListError } = storeToRefs(store)

const searchQuery = ref("")
watchThrottled(
  searchQuery,
  (value) => {
    store.searchListing(value)
  },
  { throttle: 1000, leading: false }
)

const summary = computed(() => {
  let result = null
  if (
    listingRef.value?.selectedCount &&
    listingRef.value?.selectedCount > 0 &&
    !(store.listingApiError?.status === ApiNotFoundResponse.STATUS)
  ) {
    result = `Выбрано ${listingRef.value?.selectedCount} из ${store.paginationTotal}`
  }
  return result
})

watch(budgetDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(budgetDeleteListError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    centered: true,
    onOk: () => {
      if (listingRef.value !== null) {
        let resCb: (args: any) => Promise<void> = () => Promise.resolve()
        if (listingRef.value) {
          if (listingRef.value.excludeKeysMode) {
            resCb = store.deleteByExcludedIdList
          } else {
            resCb = store.deleteBySelectedIdList
          }
        }
        return resCb(listingRef.value.currentModeKeys)
          .then(() => {
            push.success({ message: "Удалено" })
          })
          .catch(noop)
          .finally(listingRef.value?.unselectAllAction)
      }
    }
  })
}
const openCreateAction = () => router.push({ name: FinancePlanningCreateRouteName })

const copyAction = (id: number) => {
  store
    .copyBudget(id)
    .then(() => {
      push.success({ message: "Скопировано" })
      store.updateBufferAfterCreate()
    })
    .catch(noop)
}

const openEditAction = (id: string | number) =>
  router.push({ name: FinancePlanningEditRouteName, params: { id } })
</script>

<template>
  <SubscriptionReminder :style="{ marginTop: '32px', marginBottom: '0' }" />
  <ARow
    :gutter="[16, 0]"
    justify="space-between"
    :style="{ marginTop: '32px', alignItems: 'center' }"
  >
    <ACol :span="isAdoptable ? 0 : undefined">
      <AButton default-styling size="large" type="primary" @click="openCreateAction">
        Создать
      </AButton>
    </ACol>
    <ACol :xs="24" :md="12" :lg="8">
      <AInputSearch
        v-model:value="searchQuery"
        size="large"
        placeholder="Название, юридическое лицо"
        :loading="store.isListingLoading && searchQuery.length > 0"
        @search="store.searchListing"
      />
    </ACol>
  </ARow>
  <ARow :gutter="[16, 0]" :style="{ marginTop: isAdoptable ? '32px' : '16px' }">
    <ACol :xs="0" :md="24" :style="{ minHeight: '32px' }">
      <TransitionFade>
        <ASpace v-if="summary !== null" :size="16">
          <div>{{ summary }}</div>
          <AButton type="text" danger :icon="h(DeleteOutlined)" @click="deleteAction">
            Удалить
          </AButton>
        </ASpace>
      </TransitionFade>
    </ACol>
    <ACol :xs="24" :md="0">
      <AFlex :gap="16" justify="space-between">
        <ACheckbox v-model:checked="allChecked" :indeterminate="summary !== null && !allChecked">
          Выбрать все
        </ACheckbox>

        <TransitionSlide>
          <div v-if="summary !== null">{{ summary }}</div>
        </TransitionSlide>
      </AFlex>
    </ACol>
  </ARow>
  <BudgetListing
    ref="listingRef"
    :style="{ marginTop: '16px', flexGrow: 1 }"
    @openCreate="openCreateAction"
    @copyAction="copyAction"
    @openEdit="openEditAction"
  />
  <RouterView />
  <AListingControlPanel v-if="isAdoptable">
    <AButton size="large" type="primary" :icon="h(PlusOutlined)" @click="openCreateAction" />
    <AButton
      v-if="summary !== null"
      type="default"
      size="large"
      danger
      :icon="h(DeleteOutlined)"
      @click="deleteAction"
    >
      Удалить
    </AButton>
  </AListingControlPanel>
</template>
