import type { AxiosInstance } from "axios"

import createAuthRefreshInterceptor from "axios-auth-refresh"

import {
  clearAccess,
  clearRefresh,
  getAccessToken,
  getRefresh,
  setAccessToken,
  setRefreshToken
} from "./token"

const getAccessTokenString = (tkn: string) => `Bearer ${tkn}`
export const setupJwtInterceptor = (client: AxiosInstance) => {
  client.interceptors.request.use(
    (config) => {
      const token = getAccessToken()
      if (token) {
        config.headers.Authorization = getAccessTokenString(token)
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

// eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
let refresher = (refreshToken: string): Promise<{ token: string; refresh: string } | null> =>
  Promise.resolve(null)
let refreshFailedCb: () => Promise<unknown> | unknown = () => Promise.resolve(null)
export const onRefreshFailed = (fn: () => Promise<unknown> | unknown) => {
  refreshFailedCb = fn
}
export const setRefresher = (
  fn: (refreshToken: string) => Promise<{ token: string; refresh: string } | null>
) => {
  refresher = fn
}
export const setupJwtRefresh = (client: AxiosInstance) => {
  const refreshAuthLogic = async (failedRequest: any) => {
    const refreshToken = getRefresh()
    const refreshed = refreshToken ? await refresher(refreshToken) : null
    let result
    if (refreshed === null) {
      clearAccess()
      clearRefresh()
      await refreshFailedCb()
      result = Promise.reject(failedRequest)
    } else {
      const { token, refresh } = refreshed
      setAccessToken(token)
      setRefreshToken(refresh)
      failedRequest.response.config.headers["Authorization"] = getAccessTokenString(token)
      result = Promise.resolve()
    }
    return result
  }

  createAuthRefreshInterceptor(client, refreshAuthLogic)
}
