<script setup lang="ts">
import { computed, h } from "vue"
import { BellOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons-vue"
import { theme } from "ant-design-vue"

import { injectProfile, injectProfileLoading, ProfileSummary } from "@/modules/profile"
import { useAdoptable } from "@/package/ui-kit"

const sidebarCollapsed = defineModel({
  type: Boolean
})

const profile = injectProfile()
const isLoading = injectProfileLoading()

const isAdoptable = useAdoptable()
const token = theme.useToken()
const headerStyling = computed(() => ({
  ...(isAdoptable.value
    ? {
        padding: "8px 40px",
        justifyContent: "flex-end"
      }
    : {
        justifyContent: "space-between",
        padding: "8px 48px 8px 24px"
      }),
  position: "sticky",
  display: "flex",
  top: 0,
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  zIndex: "100",
  height: "56px",
  backgroundColor: token.token.value.colorBgBase,
  borderBottomColor: token.token.value.colorBorder
}))
const sidebarToggleAction = () => {
  sidebarCollapsed.value = !sidebarCollapsed.value
}
</script>

<template>
  <ALayoutHeader :style="headerStyling">
    <template v-if="!isAdoptable">
      <ASpin :spinning="isLoading">
        <ProfileSummary :profile="profile" />
      </ASpin>
    </template>
    <template v-else>
      <AButton
        size="large"
        :icon="sidebarCollapsed ? h(MenuOutlined) : h(CloseOutlined)"
        type="primary"
        :style="{ marginRight: 'auto' }"
        @click="sidebarToggleAction"
      />
    </template>
    <APopconfirm placement="bottomRight" :show-cancel="false">
      <template #title>
        <p style="margin: 0; white-space: nowrap">Уведомлений нет</p>
      </template>
      <template #okButton></template>
      <AButton type="text" size="large" :icon="h(BellOutlined)" />
    </APopconfirm>
    <template v-if="isAdoptable">
      <ASpin :spinning="isLoading">
        <ProfileSummary :profile="profile" short :style="{ marginLeft: '8px' }" />
      </ASpin>
    </template>
  </ALayoutHeader>
</template>
