import { validateBik } from "./bik"

export function validateCorrespondentAccount(
  ks?: string | number,
  bik?: string | number
): [
  boolean,
  {
    code: number
    message: string
  } | null
] {
  let result = false
  let error = null
  const [validBik, bikError] = validateBik(bik)
  if (validBik && bik) {
    if (typeof ks === "number") {
      ks = ks.toString()
    } else if (typeof ks !== "string") {
      ks = ""
    }
    if (!ks.length) {
      error = { code: 1, message: "К/С пуст" }
    } else if (/[^0-9]/.test(ks)) {
      error = { code: 2, message: "К/С может состоять только из цифр" }
    } else if (ks.length !== 20) {
      error = {
        code: 3,
        message: "К/С может состоять только из 20 цифр"
      }
    } else {
      const bikKs = "0" + bik.toString().slice(4, 6) + ks
      let checksum = 0
      const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1]
      for (const i in coefficients) {
        checksum += coefficients[i] * (parseInt(bikKs[i]) % 10)
      }
      if (checksum % 10 === 0) {
        result = true
      } else {
        error = {
          code: 4,
          message: "Неправильное контрольное число"
        }
      }
    }
  } else {
    error = bikError
  }
  return [result, error]
}
