import { useAxios } from "@vueuse/integrations/useAxios"

import { useApiError, client } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useOperationRestore = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: Array<number> }>(
    "/operations/trash/restore",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const executeListRestore = async (data: SelectListAction<string | number>) =>
    await execute({ data })

  return {
    executeListRestore,
    isRestoring: isLoading,
    operationRestoreError: useApiError(error)
  }
}
