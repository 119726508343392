import type { Ref } from "vue"
import type { RegisterForm } from "../interfaces"

import { computed } from "vue"

import type { FormValidator } from "@/package/validators"
import {
  bikValidator,
  createMinValidator,
  cyrillicValidator,
  passwordValidator,
  phoneNumberValidator,
  trueValidator,
  validateCorrespondentAccount,
  validateRs
} from "@/package/validators"

import { RegistrantType } from "../interfaces"

export const useRuleset = (
  formState: RegisterForm,
  emptyPatronymic: Ref<boolean>,
  personType: Ref<RegistrantType>
) => {
  const confirmValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      if (formState.password && formState.password === value) {
        resolve(undefined)
      } else {
        reject("Пароли не совпадают")
      }
    })

  const legalEntityAutocompleteValidator: FormValidator = ({ message }, value: string) =>
    new Promise((resolve, reject) => {
      if (formState.itn && !value) {
        reject(message)
      } else {
        resolve(undefined)
      }
    })

  const paymentAccountValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      const [result, error] = validateRs(value, formState.bic)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })

  const correspondentAccountValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      if (!formState.bic) {
        resolve(undefined)
      }
      const [result, error] = validateCorrespondentAccount(value, formState.bic)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })
  return computed(() => {
    let result: any = {
      last_name: [
        {
          required: true,
          message: "Пожалуйста, введите фамилию"
        },
        {
          asyncValidator: cyrillicValidator
        },
        {
          asyncValidator: createMinValidator(2, "Фамилия должна содержать не менее 2 символов")
        }
      ],
      first_name: [
        {
          required: true,
          message: "Пожалуйста, введите имя"
        },
        {
          asyncValidator: cyrillicValidator
        },
        {
          asyncValidator: createMinValidator(2, "Имя  должно содержать не менее 2 символов")
        }
      ],
      middle_name: emptyPatronymic.value
        ? []
        : [
            {
              required: true,
              message: "Пожалуйста, введите отчество"
            },
            {
              asyncValidator: cyrillicValidator
            },
            {
              asyncValidator: createMinValidator(
                2,
                "Отчество  должно содержать не менее 2 символов"
              )
            }
          ],
      email: [
        { type: "email", required: true, message: "Пожалуйста, введите e-mail", trigger: "blur" }
      ],
      password_confirmation: [{ required: true, asyncValidator: confirmValidator }],
      accept: [
        {
          asyncValidator: trueValidator,
          message: "Пожалуйста, подтвердите правила пользования и политику"
        }
      ],
      password: [
        { required: true, message: "Пожалуйста, введите пароль", trigger: "blur" },
        { required: true, asyncValidator: passwordValidator }
      ],
      phone: [{ required: true, asyncValidator: phoneNumberValidator, trigger: "blur" }],
      promocode: []
    }

    if (personType.value === RegistrantType.LegalEntity) {
      const legalEntityRules = {
        itn: [
          {
            required: true,
            message: "Данные об организации не получены"
          }
        ],
        organization_name: [
          {
            required: true,
            message: "Данные об организации не получены"
          }
        ],
        legal_address: [
          {
            required: true,
            message: "Пожалуйста, укажите юридический адрес"
          }
        ],
        head_fullname: [
          {
            required: true,
            message: "Пожалуйста, введите ФИО руководителя"
          }
        ],
        okved_id: [
          {
            asyncValidator: legalEntityAutocompleteValidator,
            message: "Пожалуйста, укажите ОКВЭД"
          },
          {
            required: true,
            message: "Пожалуйста, укажите ОКВЭД",
            trigger: "blur"
          }
        ],
        monthly_turnover_rate: [
          { required: true, message: "Пожалуйста укажите ежемесячный оборот" }
        ]
      }
      const bankAccountRules = {
        correspondent_account_number: [
          {
            required: true,
            message: "Данные банка не получены"
          },
          { required: true, asyncValidator: correspondentAccountValidator }
        ],
        bank_name: [
          {
            required: true,
            message: "Данные банка не получены"
          }
        ],
        bic: [{ required: true, asyncValidator: bikValidator }],
        account_number: [
          { required: true, message: "Пожалуйста, введите расчетный счет" },
          { required: true, asyncValidator: paymentAccountValidator }
        ]
      }

      result = Object.assign(result, legalEntityRules, bankAccountRules)
    }

    return result
  })
}
