export const parseNumber = (str: string) => {
  let result = ""
  for (const char of str) {
    const resChar = parseInt(char)
    if (!isNaN(resChar)) {
      result += `${char}`
    }
  }
  return result
}
export const parseFloatNumber = (str: string) => parseFloat(parseNumber(str))

export const formatThousands = (val?: string | number | null) => {
  if (!val && val !== 0) return ""
  const formattingValue = typeof val === "number" ? val.toString() : val
  const [integer, decimal] = formattingValue.split(".")
  const integerFormatted = integer.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  return decimal || formattingValue.indexOf(".") !== -1
    ? [integerFormatted, decimal].join(".")
    : integerFormatted
}

export const parseThousands = (val: string | number) => {
  const parsingValue = typeof val === "number" ? val.toString() : val
  return parsingValue.replace(/[ ]/g, "")
}
