import { noop } from "@vueuse/core"
import { useAxios } from "@vueuse/integrations/useAxios"

import type { EditableProfile } from "@/modules/profile"
import { client, useApiError } from "@/package/api-client"

export const useProfileEdit = () => {
  const { execute, isLoading, error } = useAxios("/users/update", { method: "POST" }, client, {
    immediate: false
  })
  const editProfile = (data: Omit<EditableProfile, "phone" | "email">, onSuccess: typeof noop) =>
    execute({ data }).then(onSuccess).catch(noop)

  return {
    error: useApiError(error),
    isLoading,
    editProfile
  }
}
