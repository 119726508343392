<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { noop } from "@vueuse/core"
import { push } from "notivue"
import { ref, watch, computed, onUnmounted } from "vue"
import { storeToRefs } from "pinia"

import { useCounterpartiesStore } from "@/modules/counterparty"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import { useFormState } from "./use-form-state"
import { useRuleset } from "./use-ruleset"

const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const counterpartiesStore = useCounterpartiesStore()
const { createError, isCreating, isCreateFinished, sortUsed } = storeToRefs(counterpartiesStore)

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<typeof Form | null>(null)

const submitAction = () =>
  validate()
    .then(() => counterpartiesStore.createCounterparty(requestData.value))
    .then(async () => {
      const id = await counterpartiesStore.updateBufferAfterCreate()
      if (id || sortUsed.value) {
        emit("success", id)
        push.success({
          message: "Контрагент создан"
        })
      } else {
        push.error({
          message: "Контрагент не создан, сообщите в поддержку"
        })
      }
    })
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isValidating.value || isCreateFinished.value)
})

onUnmounted(() => {
  isCreateFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="create-counterparty-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem
      label="Название"
      name="name"
      v-bind="validateInfos?.name"
      :style="{ marginTop: '16px' }"
    >
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
    <AFormItem label="Контактное лицо" name="contact_person" v-bind="validateInfos?.contact_person">
      <AInput
        v-model="formState.contact_person"
        size="large"
        placeholder="Введите контактное лицо"
      />
    </AFormItem>
    <AFormItem label="Телефон" name="phone" v-bind="validateInfos?.phone">
      <APhoneInput
        v-model="formState.phone"
        size="large"
        @blur="() => validate('phone', { trigger: 'blur' }).catch(noop)"
      />
    </AFormItem>
    <AFormItem label="E-mail" name="email" v-bind="validateInfos?.email">
      <AInput
        v-model="formState.email"
        size="large"
        placeholder="Введите e-mail"
        @change="
          () => {
            if (validateInfos?.email.validateStatus === 'error') {
              validate('email', { trigger: 'blur' }).catch(noop)
            }
          }
        "
        @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
      />
    </AFormItem>
    <AFormItem label="Комментарий" name="comment" v-bind="validateInfos?.comment">
      <ATextarea v-model:value="formState.comment" size="large" placeholder="Введите комментарий" />
    </AFormItem>
  </AForm>
</template>
