<script setup lang="ts">
import type { OkvedData } from "../interfaces"

import { computed, ref, watch } from "vue"
import { watchDebounced } from "@vueuse/core"

import { useOkvedSearch } from "../hooks/use-okved-search"
import { useOkvedDetail } from "../hooks/use-okved-detail"

const okved_id = defineModel("okved_id", {
  type: Number
})
const okved_code = defineModel("okved_code", {
  type: String
})

const { okvedList, executeSearch, isLoading } = useOkvedSearch()
const options = computed(() =>
  (okvedList.value?.data ?? []).map((dto) => ({
    value: `${dto.code} ${dto.name}`,
    data: dto
  }))
)

const { okvedDetail, getDetail } = useOkvedDetail()
watch(
  okved_id,
  (outerId) => {
    if (outerId) {
      getDetail(outerId)
    }
  },
  { immediate: true }
)

const query = ref("")
watchDebounced(query, executeSearch, { debounce: 500 })

const selectedItem = ref<OkvedData | null>(null)
const selectedQuery = computed(() => {
  let result = null
  if (selectedItem.value) {
    result = `${selectedItem.value.code} ${selectedItem.value.name}`
  }
  return result
})
const resetAction = () => {
  if (selectedQuery.value && query.value !== selectedQuery.value) {
    query.value = selectedQuery.value
  }
}
watch(okvedDetail, (okved) => {
  if (okved) {
    okved_code.value = okved.code
    selectedItem.value = okved
    resetAction()
  }
})

const selectAction = (_: string, { data }: { data: OkvedData }) => {
  okved_id.value = data.id
  selectedItem.value = data
}
</script>

<template>
  <AAutoComplete
    v-model:value="query"
    placeholder="Поиск по (ОКВЭД)"
    size="large"
    :options="options"
    :loading="isLoading"
    @select="selectAction"
    @blur="resetAction"
  />
</template>
