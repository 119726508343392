<script setup lang="ts">
import { computed } from "vue"

import { useUserRoles } from "../../../hooks/use-user-roles"

const { userRolesResponse } = useUserRoles()

const options = computed(() =>
  userRolesResponse.value?.map((role) => ({ label: role.name, value: role.id }))
)
</script>

<template>
  <ASelect :options="options" placeholder="Выберите роль пользователя" />
</template>
