<script setup lang="ts">
import { computed, reactive, watch } from "vue"
import { Form } from "ant-design-vue"
import { TransitionFade } from "@morev/vue-transitions"
import { noop } from "@vueuse/core"

import { setAccessToken, setRefreshToken, setSessionMode } from "@/package/api-client"
import { restoreRequestRoute } from "@/router"
import { useForm } from "@/package/hooks"
import { createMaxValidator, createMinValidator } from "@/package/validators"

import { useLogin } from "../hooks/use-login"
defineProps({
  loading: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["success"])
const formState = reactive({
  email: "",
  password: "",
  remember: true
})
const ruleset = computed(() => ({
  email: [
    { type: "email", required: true, message: "Пожалуйста, введите e-mail", trigger: "blur" }
  ],
  password: [
    {
      required: true,
      message: "Пожалуйста, введите пароль"
    },
    { asyncValidator: createMinValidator(8, "Пароль должен содержать не менее 8 символов") },
    { asyncValidator: createMaxValidator(24, "Пароль должен содержать не более 24 символов") }
  ]
}))
const { validate, validateInfos } = useForm(formState, ruleset)
const { login, isLoading, errorMessage, data } = useLogin()
const submitAction = () =>
  validate()
    .then(() => login(formState))
    .catch(noop)

watch(data, () => {
  const accessToken = data.value?.access_token
  const refreshToken = data.value?.refresh_token
  if (accessToken !== undefined && refreshToken !== undefined) {
    setSessionMode(formState.remember === false)
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)
    emit("success")
  }
})
</script>

<template>
  <AFlex vertical>
    <div :style="{ minHeight: '42px' }">
      <TransitionFade :easing="{ enter: '.2s', leave: '.5s' }">
        <AAffix v-if="errorMessage !== null" :offset-top="12">
          <AAlert class="shake" :message="errorMessage" type="error" show-icon />
        </AAffix>
      </TransitionFade>
    </div>
    <Form
      v-enter-to-tab
      :model="formState"
      name="login"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      hide-required-mark
      :style="{ marginTop: '8px' }"
      @submit.prevent="submitAction"
    >
      <AFormItem label="E-mail" v-bind="validateInfos?.email" name="email">
        <AInput
          v-model.lowercase.trim="formState.email"
          size="large"
          placeholder="Введите e-mail"
          @change="
            () => {
              if (validateInfos?.email.validateStatus === 'error') {
                validate('email', { trigger: 'blur' }).catch(noop)
              }
            }
          "
          @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
        />
      </AFormItem>

      <AFormItem label="Пароль" v-bind="validateInfos?.password" name="password">
        <AInputPassword
          v-model:value="formState.password"
          size="large"
          placeholder="Введите пароль"
        />
      </AFormItem>

      <AFlex justify="space-between" :style="{ marginTop: '36px' }">
        <ACheckbox v-model:checked="formState.remember">Запомнить меня</ACheckbox>
        <ALink :to="{ name: restoreRequestRoute }"> Забыли пароль?</ALink>
      </AFlex>

      <AButton
        type="primary"
        html-type="submit"
        block
        :style="{ marginTop: '56px' }"
        size="large"
        :loading="isLoading || loading"
        >Войти
      </AButton>
    </Form>
  </AFlex>
</template>
