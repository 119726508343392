import { computed, reactive } from "vue"

import type { OperationCategoryEdit } from "@/modules/operation-category"

export const useFormState = () => {
  const formState = reactive<OperationCategoryEdit>({
    id: 0,
    name: "",
    type: null,
    comment: null,
    action_type: "",
    parent_id: null,
    is_taxable: null
  })
  const requestData = computed<OperationCategoryEdit>(() => {
    const { id, name, comment, parent_id, action_type, type, is_taxable } = formState

    return {
      id,
      name,
      comment,
      parent_id,
      action_type,
      type,
      is_taxable
    }
  })

  return {
    formState,
    requestData
  }
}
