import { useAxios } from "@vueuse/integrations/useAxios"

import type { ApiSuccessMessage } from "@/package/api-client"
import { client, useApiError } from "@/package/api-client"

export const usePaySubscriptionApprove = () => {
  const { execute, data, isLoading, error } = useAxios<ApiSuccessMessage>(
    "/subscription/approve-payment",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const requestSubscriptionApprove = async (orderId: string) => {
    return await execute({
      data: { order_id: orderId }
    })
  }

  return {
    subscriptionApprove: data,
    requestSubscriptionApprove,
    subscriptionPending: isLoading,
    SubscriptionApproveError: useApiError(error)
  }
}
