import { useAxios } from "@vueuse/integrations/useAxios"
import { type MaybeRef } from "vue"
import { get } from "@vueuse/core"

import type { OperationCategory } from "@/modules/operation-category"
import { client, useApiError, useApiResponseStatus } from "@/package/api-client"

interface ReferenceListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: OperationCategory[]
}

export const useOperationCategoriesList = () => {
  const { execute, isLoading, error, data, response } = useAxios<ReferenceListResponse>(
    "/operation-categories",
    { method: "GET" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const listingApiError = useApiError(error)
  const listingResponseStatus = useApiResponseStatus(response, error)
  const requestListing = (
    params: MaybeRef<{
      offset?: number
      limit?: number
      action_type?: string
      query?: string
    }>
  ) => execute({ params: get(params) })

  return {
    listingApiError,
    listingResponseStatus,
    isListingLoading: isLoading,
    listingResponse: data,
    requestListing
  }
}
