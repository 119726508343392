<script setup lang="ts">
import type { MenuProps } from "ant-design-vue"

import { h, computed } from "vue"
import { RouterLink, useRoute } from "vue-router"

import { SubscriptionReminder } from "@/modules/subscription-reminder"
import {
  BankAccountRouteName,
  CounterpartiesReferencesRouteName,
  ReferencesOperationsCategoriesRouteName,
  ReferencesProductsRouteName,
  ReferencesProjectsRouteName
} from "@/router"
import { injectProfile, useProfileSubscription } from "@/modules/profile"

const profile = injectProfile()
const isSubscriptionExpired = computed(() => useProfileSubscription(profile?.value).value)

const items: MenuProps["items"] = [
  {
    label: h(
      RouterLink,
      {
        to: "/references/counterparties",
        class: isSubscriptionExpired.value ? "custom-menu-item-disabled" : ""
      },
      () => "Контрагенты"
    ),
    key: CounterpartiesReferencesRouteName,
    disabled: isSubscriptionExpired.value
  },
  {
    label: h(RouterLink, { to: "/references/bank-account" }, () => "Счета"),
    key: BankAccountRouteName
  },
  {
    label: h(RouterLink, { to: "/references/projects" }, () => "Проекты"),
    key: ReferencesProjectsRouteName
  },
  {
    label: h(
      RouterLink,
      { to: "/references/operation-categories" },
      () => "Статьи доходов и расходов"
    ),
    key: ReferencesOperationsCategoriesRouteName
  },
  {
    label: h(
      RouterLink,
      {
        to: "/references/products",
        class: isSubscriptionExpired.value ? "custom-menu-item-disabled" : ""
      },
      () => "Товары и услуги"
    ),
    key: ReferencesProductsRouteName,
    disabled: isSubscriptionExpired.value
  }
]
const route = useRoute()

const current = computed(() => route.matched.map(({ name }) => name))
</script>

<template>
  <AAlert
    v-if="isSubscriptionExpired"
    :style="{ marginTop: '20px', alignItems: 'center' }"
    type="error"
    message="Разделы «Контрагенты» и «Товары и услуги» доступны в подписке «Про»"
  />
  <SubscriptionReminder :style="{ marginTop: '32px', marginBottom: '0' }" />
  <AMenu
    class="custom-menu"
    :selected-keys="current"
    :items="items"
    mode="horizontal"
    :style="{ marginTop: '8px' }"
  />
  <RouterView />
</template>

<style scoped lang="scss">
.custom-menu:deep(.ant-menu-item:first-child) {
  padding-left: 0 !important;

  &::after {
    left: 0;
  }
}
.custom-menu:deep(.ant-menu-item:nth-last-child(2)) {
  padding-right: 0 !important;
  &::after {
    right: 0;
  }
}

:deep(.custom-menu-item-disabled) {
  pointer-events: none;
}
</style>
