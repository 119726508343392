import { computed } from "vue"

import {
  createMaxValidator,
  digitsIncludeValidator,
  phoneNumberValidator
} from "@/package/validators"

// eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
export const useRuleset = () => {
  const ruleSet = computed(() => ({
    kbk: [
      {
        asyncValidator: digitsIncludeValidator
      },
      {
        asyncValidator: createMaxValidator(20)
      }
    ],
    kpp: [
      {
        asyncValidator: digitsIncludeValidator
      },
      {
        asyncValidator: createMaxValidator(9)
      }
    ],
    ogrnip: [
      {
        asyncValidator: digitsIncludeValidator
      },
      {
        asyncValidator: createMaxValidator(15)
      }
    ],
    phone: [{ asyncValidator: phoneNumberValidator, trigger: "blur" }]
  }))

  return ruleSet
}
