<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { push } from "notivue"
import { storeToRefs } from "pinia"
import { computed, onUnmounted, ref, watch } from "vue"

import { useProjectsStore } from "@/modules/project"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import { useFormState } from "./use-form-state"
import { useRuleset } from "./use-ruleset"

const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const projectsStore = useProjectsStore()
const { createError, isCreating, isCreateFinished } = storeToRefs(projectsStore)

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<typeof Form | null>(null)

const submitAction = () =>
  validate()
    .then(() => projectsStore.createProject(requestData.value))
    .then(async () => {
      push.success({
        message: "Проект создан"
      })
      const id = await projectsStore.updateBufferAfterCreate()
      if (id || projectsStore.sortUsed) {
        emit("success", id)
      } else {
        push.error({
          message: "Проект не создан, сообщите в поддержку"
        })
      }
    })
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isCreateFinished.value || isValidating.value)
})

onUnmounted(() => {
  isCreateFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="create-counterparty-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem
      label="Название"
      name="name"
      v-bind="validateInfos?.name"
      :style="{ marginTop: '16px' }"
    >
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
  </AForm>
</template>
