import { noop } from "@vueuse/core"
import { useAxios } from "@vueuse/integrations/useAxios"

import type { ConfirmChangingPasswordRequest } from "@/modules/profile"
import { client, useApiError } from "@/package/api-client"

export const useConfirmChangingPassword = () => {
  const { execute, isLoading, error } = useAxios(
    "/users/confirm-password-change",
    { method: "POST" },
    client,
    { immediate: false }
  )
  const confirmChangingPassword = (data: ConfirmChangingPasswordRequest, onSuccess: typeof noop) =>
    execute({ data }).then(onSuccess).catch(noop)

  return {
    error: useApiError(error),
    isLoading,
    confirmChangingPassword
  }
}
