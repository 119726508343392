<script setup lang="ts">
import { useRouter } from "vue-router"
import { onMounted, ref } from "vue"
import { useEventBus, useToggle } from "@vueuse/core"

import { PhysProfileRouteName } from "@/router"

import { useLegalEntity } from "../../../modules/profile/hooks/use-legal-entity"
import EntrepreneurEdit from "../../../modules/profile/components/EntrepreneurEdit/EntrepreneurEdit.vue"

const { legalEntityData, getLegalEntityData, legalEntityDataPending } = useLegalEntity()

const router = useRouter()

const { emit } = useEventBus("profile-updated")

const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof EntrepreneurEdit> | null>(null)

const onSuccess = async () => {
  toggleOpened(false)
  emit()
}

onMounted(async () => {
  await getLegalEntityData()
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Редактирование данных ИП"
    class-name="entrepreneur-drawer"
    @onClose="() => router.push({ name: PhysProfileRouteName })"
  >
    <EntrepreneurEdit
      ref="formRef"
      :profile="legalEntityData"
      :is-loading="legalEntityDataPending"
      @success="onSuccess"
    />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block type="primary" size="large" @click="formRef?.submitAction">
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
