import { useAxios } from "@vueuse/integrations/useAxios"
import { ref } from "vue"

import { client, useApiError } from "@/package/api-client"

export function useFileUpload() {
  const progress = ref(0)
  const { execute, isLoading, error, data } = useAxios(
    "/storage/upload-attachment",
    {
      method: "POST",
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        if (progressEvent?.total) {
          progress.value = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
        }
      }
    },
    client,
    {
      immediate: false
    }
  )

  const upload = async (fileList: File[]) => {
    const fmData = new FormData()

    Array.from(fileList).forEach((file: File) => fmData.append("files[]", file))

    try {
      await execute({ data: fmData })
    } catch (e) {
      console.log(e)
    }
  }
  const uploadError = useApiError(error, "Что-то пошло не так")

  return {
    loadedData: data,
    upload,
    isLoading,
    uploadError,
    progress
  }
}
