<script setup lang="ts">
import { Checkbox } from "ant-design-vue"
import { useSlots } from "vue"
const slots = useSlots()
</script>

<template>
  <Checkbox>
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </Checkbox>
</template>

<style scoped>
:deep(.ant-checkbox) {
  align-self: baseline;
  transform: translateY(2px);
}
</style>
