import { computed, watch } from "vue"
import { noop, watchDebounced } from "@vueuse/core"
import { Form } from "ant-design-vue"

import type { BankAccount } from "@/modules/bank-account"
import { ApiNotFoundResponse } from "@/package/api-client"

import { useBankDataSearch } from "./use-bank-data-search"

export const useBankAutocomplete = (
  state: Pick<BankAccount, "bic" | "bank_name" | "correspondent_account_number" | "account_number">,
  validate: ReturnType<typeof Form.useForm>["validate"]
) => {
  const { data, executeSearch, error } = useBankDataSearch()
  watch(data, (bankData) => {
    if (bankData) {
      state.bank_name = bankData.bank_name
      state.correspondent_account_number = bankData.correspondent_account
    }
  })

  watchDebounced(
    () => state.bic,
    async (value) => {
      if (value) {
        await validate("bic")
          .then(() => executeSearch(state.bic))
          .then(() =>
            validate(["account_number", "bank_name", "correspondent_account_number"], {
              validateFirst: true
            })
          )
          .catch(noop)
      } else {
        state.bank_name = ""
        state.correspondent_account_number = ""
      }
    },
    { debounce: 500 }
  )
  const errorMessage = computed(() => {
    let result: string | null = null
    if (error.value) {
      if (error.value instanceof ApiNotFoundResponse) {
        result = error.value.message
      } else {
        result = "Не удалось распознать банк по БИК"
      }
    }
    return result
  })
  return {
    bankDataRequestErrorMessage: errorMessage
  }
}
