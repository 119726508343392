<script setup lang="ts">
import { computed } from "vue"
import { useToggle } from "@vueuse/core"

import { useCategoryTypes } from "@/modules/operation-category"

const { data, refreshAsync, error, loading } = useCategoryTypes()
const options = computed(() =>
  (data.value ?? []).map(({ slug, value }) => ({
    value: slug,
    label: value
  }))
)
const [isOpened, toggleOpen] = useToggle(false)
const retryAction = async () => {
  toggleOpen()
  await refreshAsync()
}
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    placeholder="Выберите тип"
    :loading="loading"
    :options="options"
    :status="error ? 'warning' : undefined"
    @dropdownVisibleChange="() => toggleOpen()"
    @focus="() => toggleOpen(true)"
  >
    <template #notFoundContent>
      <AEmpty>
        <template #description> Не удалось загрузить категории </template>
        <AButton type="text" @click.stop="retryAction"> Повторить </AButton>
      </AEmpty>
    </template>
  </ASelect>
</template>

<style scoped>
:deep(.ant-select-selection-search-input) {
  cursor: pointer !important;
}
</style>
