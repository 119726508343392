import { ref } from "vue"

export const useRuleset = () => {
  const ruleset: any = ref({
    article: [],
    type: [{ required: true, message: "Пожалуйста, выберите тип" }],
    name: [{ required: true, message: "Пожалуйста, введите название", whitespace: true }],
    description: [
      {
        type: "string",
        max: 4096,
        message: "Превышено допустимое количество символов"
      }
    ],
    counterparty_id: [],
    tax_type: [],
    nds_type: [],
    unit_type: [],
    self_cost: [],
    cost: []
  })

  return ruleset
}
