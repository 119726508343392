import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError, useApiResponseStatus } from "@/package/api-client"
import { SortOrder } from "@/interfaces"
import type { LegalEntitySummary } from "@/modules/legal-entity"

interface LegalEntityResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: LegalEntitySummary[]
}

export const useLegalEntityListing = () => {
  const { execute, data, error, isLoading, response } = useAxios<LegalEntityResponse>(
    "/legal-entities/list",
    { method: "GET" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const listingApiError = useApiError(error)
  const listingResponseStatus = useApiResponseStatus(response, error)

  const listingLoad = (params: {
    limit: number
    offset: number
    query?: string
    orderBy?: string | null
    order?: SortOrder | null
  }) => execute({ params })

  return {
    data,
    listingLoad,
    listingResponseStatus,
    listingApiError,
    isListingLoading: isLoading
  }
}
