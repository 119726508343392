<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useToggle } from "@vueuse/core"

import { CreateProductForm } from "@/modules/product"
import { ReferencesProductsRouteName } from "@/router"

const router = useRouter()
const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof CreateProductForm> | null>(null)
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Создание товара/услуги"
    @onClose="() => router.push({ name: ReferencesProductsRouteName })"
  >
    <CreateProductForm ref="formRef" @success="() => toggleOpened(false)" />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            size="large"
            block
            type="primary"
            :loading="formRef?.isCreating"
            @click="() => formRef?.submitAction()"
            >Создать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
