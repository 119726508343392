/* eslint-disable vue/require-name-property */
import { defineComponent, type PropType, type VNode } from "vue"

export const VNodeRender = defineComponent({
  props: {
    node: {
      type: Object as PropType<VNode>,
      required: true
    }
  },

  render() {
    return this.node
  }
})
