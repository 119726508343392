<script setup lang="ts">
import type { EditableProfile, Profile } from "../../interfaces"

import { computed, onMounted, type PropType, reactive, ref, watch } from "vue"
import { Form } from "ant-design-vue"
import { noop, useDebounceFn } from "@vueuse/core"
import { push } from "notivue"

import { gridGutter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import { useProfileEdit } from "../../hooks/use-profile-edit"

import { useRuleset } from "./use-ruleset"

const props = defineProps({
  profile: {
    type: [Object, undefined] as PropType<Profile | undefined>,
    default: () => undefined
  }
})
const emit = defineEmits(["success"])
const emptyPatronymic = ref(false)

const formRef = ref<typeof Form | null>(null)

const formState = reactive<EditableProfile>({
  first_name: "",
  last_name: "",
  middle_name: "",
  email: "",
  phone: ""
})
const requestData = computed(() => {
  // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
  const { phone, email, ...dto } = formState
  return dto
})
const resetAction = useDebounceFn(() => {
  if (props.profile) {
    emptyPatronymic.value = props.profile.middle_name === null || props.profile.middle_name === ""
    Object.assign(formState, props.profile)
  }
}, 100)
onMounted(resetAction)
watch(() => props.profile, resetAction)

const ruleSet = useRuleset(formState, emptyPatronymic)
const { validate, validateInfos, clearValidate } = useForm(formState, ruleSet, {
  watchValidateOptions: { validateFirst: true }
})
watch(emptyPatronymic, (val) => {
  if (val) {
    clearValidate("middle_name")
    formState.middle_name = null
  }
})
const { editProfile, error, isLoading } = useProfileEdit()
watch(error, (err) => {
  if (err) {
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
const submitAction = () =>
  validate()
    .then(() =>
      editProfile(requestData.value, () => {
        push.success({ message: "Профиль обновлен." })
        emit("success")
      })
    )
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({ isLoading, resetAction, submitAction })
</script>

<template>
  <Form
    ref="formRef"
    name="edit-profile"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    hide-required-mark
    @submit.prevent="submitAction"
  >
    <AFormItem label="Фамилия" name="last_name" v-bind="validateInfos?.last_name">
      <AInput v-model.trim="formState.last_name" size="large" :default-value="profile?.last_name" />
    </AFormItem>
    <AFormItem label="Имя" name="first_name" v-bind="validateInfos?.first_name">
      <AInput
        v-model.trim="formState.first_name"
        size="large"
        :default-value="profile?.first_name"
      />
    </AFormItem>
    <AFormItem
      label="Отчество"
      name="middle_name"
      validate-first
      v-bind="validateInfos?.middle_name"
    >
      <ARow :gutter="gridGutter" :wrap="false">
        <ACol flex="auto">
          <AInput
            v-model.trim="formState.middle_name"
            size="large"
            placeholder="Введите отчество"
            :disabled="emptyPatronymic"
          />
        </ACol>
        <ACol :style="{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }">
          <AFormItemRest>
            <ACheckbox v-model:checked="emptyPatronymic" :style="{ whiteSpace: 'nowrap' }">
              Нет отчества
            </ACheckbox>
          </AFormItemRest>
        </ACol>
      </ARow>
    </AFormItem>
    <AFormItem label="E-mail" name="email" v-bind="validateInfos?.email">
      <AInput v-model="formState.email" size="large" disabled :default-value="profile?.email" />
    </AFormItem>
    <AFormItem label="Номер телефона" name="phone" v-bind="validateInfos?.phone">
      <APhoneInput
        v-model="formState.phone"
        size="large"
        disabled
        :default-value="profile?.phone"
        @blur="() => validate('phone', { trigger: 'blur' }).catch(noop)"
      />
    </AFormItem>
  </Form>
</template>
