import { useAxios } from "@vueuse/integrations/useAxios"
import { computed } from "vue"
import { get } from "@vueuse/core"

import { client, useApiError } from "@/package/api-client"

export interface OperationCategoryVariant {
  id: number
  name: number
  children: Array<OperationCategoryVariant>
}

interface Response {
  success: boolean
  data: Array<OperationCategoryVariant>
}

export const useOperationCategoryVariantsTree = () => {
  const { execute, isLoading, error, data } = useAxios<Response>(
    "/operation-categories/variants/as-tree",
    { method: "GET" },
    client,
    { immediate: false }
  )

  const requestListing = (params?: {
    offset?: number
    limit?: number
    query?: string
    action_type?: string
    operation_category_id?: number | string
  }) => execute({ params })

  const listing = computed(() => get(data)?.data ?? [])
  const unwrapChildrens = (
    arr: Array<OperationCategoryVariant>
  ): Array<Pick<OperationCategoryVariant, "id" | "name">> =>
    arr.flatMap(({ children, id, name }) => [{ id, name }].concat(unwrapChildrens(children)))
  const flatList = computed(() =>
    unwrapChildrens(get(listing)).reduce((res, v) => {
      res.set(v.id, v)
      return res
    }, new Map())
  )

  return {
    listing,
    flatList,
    requestListing,
    isListingLoading: isLoading,
    error: useApiError(error)
  }
}
