<script setup lang="ts">
import Icon from "@ant-design/icons-vue"
import { computed } from "vue"

import { gridGutter, useBpMax, useBpMin, useVh, HScreen } from "@/package/ui-kit"
import Logo from "@/assets/icons/logo.svg"
import LogoLight from "@/assets/icons/logo--light.svg"
import { useAppConfig } from "@/store"

import InfoSlider from "./InfoSlider.vue"

const app = useAppConfig()
const isMobile = useBpMax("xs")
const isLarge = useBpMin("xxl")
const logoSize = computed(() => (isLarge.value ? 56 : 40))
const { vhVariableProvider } = useVh()
</script>

<template>
  <ALayout
    :style="{
      width: '100%',
      minHeight: '100%',
      ...vhVariableProvider
    }"
  >
    <ALayoutContent
      :style="{
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        padding: isMobile ? '0 24px' : '0 40px'
      }"
    >
      <ARow :gutter="gridGutter" :style="{ flexGrow: 1 }">
        <ACol
          :xs="24"
          :xl="12"
          :style="{
            paddingTop: '24px',
            paddingBottom: '24px',
            display: 'flex',
            flexDirection: 'column'
          }"
        >
          <ARow :gutter="gridGutter">
            <ACol :span="24" :style="{ display: 'inline-flex', marginBottom: '16px' }" :flex="0">
              <Icon :style="{ alignSelf: isMobile ? 'center' : 'flex-start' }">
                <template #component>
                  <LogoLight v-if="app.isDarkTheme" :height="logoSize" />
                  <Logo v-else :height="logoSize" />
                </template>
              </Icon>
            </ACol>
          </ARow>
          <ARow :style="{ flexGrow: 1 }" :gutter="gridGutter">
            <ACol
              :sm="{ span: 20, offset: 2 }"
              :md="{ span: 16, offset: 4 }"
              :xs="24"
              :style="{ display: 'flex', flexDirection: 'column' }"
            >
              <RouterView :logo-size="logoSize" />
            </ACol>
          </ARow>
        </ACol>
        <ACol
          :xs="0"
          :xl="12"
          :style="{
            maxHeight: HScreen,
            position: 'sticky',
            top: 0,
            paddingTop: '24px',
            paddingBottom: '24px'
          }"
        >
          <InfoSlider />
        </ACol>
      </ARow>
    </ALayoutContent>
  </ALayout>
</template>
