import { useAxios } from "@vueuse/integrations/useAxios"
import { computed } from "vue"
import { noop } from "@vueuse/core"

import { ApiBadReqResponse, ApiNotFoundResponse, client } from "@/package/api-client"

export interface PasswordResetRequest {
  email: string
  password: string
  password_confirmation: string
  token: string
}

export const usePasswordReset = () => {
  const { execute, error, isLoading } = useAxios(
    "/security/reset-password",
    {
      method: "POST"
    },
    client,
    {
      immediate: false
    }
  )

  const passwordPost = (data: PasswordResetRequest, onSuccess: typeof noop) =>
    execute({ data }).then(onSuccess).catch(noop)

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse || error.value instanceof ApiNotFoundResponse) {
        result = error.value.message
      } else {
        result = "Неизвестная ошибка, пожалуйста повторите позже или обратитесь в поддержку."
      }
    }
    return result
  })

  return {
    passwordPost,
    isLoading,
    errorMessage
  }
}
