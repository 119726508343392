import { type MaybeRef } from "vue"
import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import type { OperationCategoryEdit } from "@/modules/operation-category"
import { useApiError } from "@/package/api-client"

import { operationCategoryDetailGet } from "../api/operation-category-detail.get"

export const useOperationCategoryDetail = (id?: MaybeRef<string | number>, manual = false) => {
  const { error, loading, run, cancel, data, refresh, refreshAsync, runAsync } = useRequest<
    OperationCategoryEdit | undefined
  >((id?: string | number) => (id ? operationCategoryDetailGet(id) : Promise.resolve(undefined)), {
    defaultParams: [get(id)],
    cacheTime: 0,
    manual
  })
  return {
    error: useApiError(error),
    loading,
    run,
    cancel,
    data,
    refresh,
    refreshAsync,
    runAsync
  }
}
