import type { SaveReportsFilters } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { push } from "notivue"

import { ApiBadReqResponse, client } from "@/package/api-client"

export const useGetReportsFilter = () => {
  const { execute, isLoading, error } = useAxios<
    { success: string; data: Partial<SaveReportsFilters> },
    { ids: Array<number> }
  >("/parameters/reports-filters", { method: "GET" }, client, {
    immediate: false
  })

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  watch(errorMessage, () => {
    if (errorMessage.value !== null) {
      push.error({ message: errorMessage.value })
    }
  })

  const getFilters = async () => {
    const response = await execute()
    return response.data.value?.data
  }

  return {
    getFilters,
    isGettingFilters: isLoading
  }
}
