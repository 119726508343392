<script setup lang="ts">
import type { UserDetail } from "../../../interface"

import { type PropType, ref, watch } from "vue"
import { Form } from "ant-design-vue"
import { get, noop, set } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"

import { useForm } from "@/package/hooks"
import { useProfile, UserRole } from "@/modules/profile"

import { useRuleset } from "../hooks"

import UserStatusSelect from "./UserStatusSelect.vue"
import UserEmailStatusSelect from "./UserEmailStatusSelect.vue"
import UserPasswordStatusSelect from "./UserPasswordStatusSelect.vue"
import UserRoleSelect from "./UserRoleSelect.vue"

const props = defineProps({
  user: {
    type: [Object, undefined] as PropType<UserDetail | undefined>,
    default: () => undefined
  }
})

const emit = defineEmits(["submit"])

const userId = useRouteParams<string>("id")

const formState = ref<Partial<UserDetail>>({})
watch(
  () => props.user,
  (newValue) => {
    set(formState, newValue ?? {})
  }
)

const ruleset = useRuleset()
const { profile } = useProfile()
const { validate, validateInfos } = useForm(formState, ruleset)
const submitAction = () =>
  validate()
    .then(() => emit("submit", get(formState)))
    .catch(noop)

defineExpose({ submitAction })
</script>

<template>
  <Form
    name="admin-edit-user-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
  >
    <AFormItem label="Фамилия" name="last_name" v-bind="validateInfos?.last_name">
      <AInput v-model="formState.last_name" size="large" placeholder="Введите фамилию" />
    </AFormItem>
    <AFormItem label="Имя" name="first_name" v-bind="validateInfos?.first_name">
      <AInput v-model="formState.first_name" size="large" placeholder="Введите имя" />
    </AFormItem>
    <AFormItem label="Отчество" name="middle_name" v-bind="validateInfos?.middle_name">
      <AInput v-model="formState.middle_name" size="large" placeholder="Введите отчество" />
    </AFormItem>
    <AFormItem label="E-mail" name="email" v-bind="validateInfos?.email">
      <AInput
        v-model="formState.email"
        size="large"
        placeholder="Введите e-mail"
        @change="
          () => {
            if (validateInfos?.email.validateStatus === 'error') {
              validate('email', { trigger: 'blur' }).catch(noop)
            }
          }
        "
        @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
      />
    </AFormItem>
    <AFormItem label="Роль" name="role" v-bind="validateInfos?.role">
      <UserRoleSelect
        v-model:value="formState.role"
        size="large"
        :disabled="profile?.role !== UserRole.Admin || profile.id === +userId"
      />
    </AFormItem>
    <AFormItem label="Статус" name="status" v-bind="validateInfos?.status">
      <UserStatusSelect v-model:value="formState.status" size="large" />
    </AFormItem>
    <AFormItem
      label="Статус подтверждения почты"
      name="status_email"
      v-bind="validateInfos?.status_email"
    >
      <UserEmailStatusSelect v-model:value="formState.status_email" size="large" />
    </AFormItem>
    <AFormItem
      label="Статус блокировки пароля"
      name="status_email"
      v-bind="validateInfos?.status_pass"
    >
      <UserPasswordStatusSelect v-model:value="formState.status_pass" size="large" />
    </AFormItem>
  </Form>
</template>
