import { computed, type MaybeRef } from "vue"
import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import { useApiError } from "@/package/api-client"

import { detailGet } from "../api/detail.get"

export const useBudgetDetail = (id: MaybeRef<string | number>) => {
  const { data, error, loading, refreshAsync, run } = useRequest(
    (id) => (id ? detailGet(id) : Promise.resolve(undefined)),
    {
      cacheKey: (params) => (params ? `/budgets/${params[0]}` : "/budgets/"),
      defaultParams: [get(id)]
    }
  )

  return {
    data: computed(() => get(data)?.data?.data ?? null),
    isDetailLoading: loading,
    error: useApiError(error),
    run,
    refreshAsync
  }
}
