import { useRequest } from "vue-request"

import { type ApiSuccessResponse, client } from "@/package/api-client"
import type { BackendEnumValue } from "@/interfaces"

export const useProductTypes = () =>
  useRequest<Array<BackendEnumValue>>(
    () =>
      client
        .get<ApiSuccessResponse<Array<BackendEnumValue>>>("/products/product-types")
        .then((response) => response.data.data),
    {
      cacheKey: "/products/product-types",
      staleTime: -1
    }
  )
