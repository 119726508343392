import type { ComputedRef, MaybeRef, Ref } from "vue"

import { computed, readonly, ref } from "vue"
import { get, set } from "@vueuse/core"

export const useBufferSelection = (
  idBuffer: Ref<Array<number>>,
  totalCount: ComputedRef<number | null>
) => {
  const excludeKeysMode = ref(false)
  const selectedKeys = ref(new Array<number>())
  const excludedKeys = ref(new Array<number>())
  const onSelect = (keys: Array<number>) => {
    if (get(excludeKeysMode)) {
      set(
        excludedKeys,
        get(idBuffer).filter((id) => !keys.includes(id))
      )
    } else {
      set(selectedKeys, keys)
    }
  }

  const unselectAllAction = () => {
    set(excludeKeysMode, false)
    set(selectedKeys, [])
    set(excludedKeys, [])
  }
  const selectAllAction = () => {
    set(excludeKeysMode, true)
    set(selectedKeys, [])
    set(excludedKeys, [])
  }
  const toggleAllSelected = (status?: MaybeRef<boolean>) => {
    const selectAll = status === undefined ? !get(excludeKeysMode) : get(status)
    if (selectAll) {
      selectAllAction()
    } else {
      unselectAllAction()
    }
  }

  const isAllSelected = readonly(excludeKeysMode)
  const selectedIdList = computed(() => {
    let result
    if (get(excludeKeysMode)) {
      result = get(idBuffer).filter((id) => !get(excludedKeys).includes(id))
    } else {
      result = get(selectedKeys)
    }
    return result
  })
  const selectedCount = computed(() => {
    let result = 0
    if (get(excludeKeysMode)) {
      const total = get(totalCount) ?? 0
      const totalExcluded = get(excludedKeys, "length")
      result = total - totalExcluded
    } else {
      result = get(selectedKeys, "length")
    }
    return result
  })
  const onSelectAll = (status: boolean) => {
    setTimeout(toggleAllSelected.bind(null, status), 0)
  }

  const currentModeKeys = computed(() =>
    get(excludeKeysMode) ? get(excludedKeys) : get(selectedKeys)
  )
  return {
    isAllSelected,
    selectedIdList,
    selectedCount,
    onSelect,
    onSelectAll,
    toggleAllSelected,
    unselectAllAction,
    selectAllAction,
    excludeKeysMode,
    currentModeKeys
  }
}
