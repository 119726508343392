import type { BankAccountCreate } from "../../interfaces"

import { computed } from "vue"

import type { FormValidator } from "@/package/validators"
import { bikValidator, validateCorrespondentAccount, validateRs } from "@/package/validators"

export const useRuleset = (formState: Pick<BankAccountCreate, "bic">) => {
  const paymentAccountValidator: FormValidator = (rules, value: string) =>
    new Promise((resolve, reject) => {
      const [result, error] = validateRs(value, formState.bic, rules.required)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })

  const correspondentAccountValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      const [result, error] = validateCorrespondentAccount(value, formState.bic)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })

  const ruleset: any = computed(() => ({
    name: [{ required: true, message: "Пожалуйста, введите название", whitespace: true }],
    correspondent_account_number: [
      { message: "Данные банка не получены" },
      { asyncValidator: correspondentAccountValidator }
    ],
    bank_name: [{ message: "Данные банка не получены" }],
    bic: [{ asyncValidator: bikValidator }],
    account_number: [{ asyncValidator: paymentAccountValidator }],
    is_active: [{ required: true, message: "Пожалуйста, выберите  статус", trigger: "submit" }]
  }))

  return ruleset
}
