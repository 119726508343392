import { useAxios } from "@vueuse/integrations/useAxios"
import { computed } from "vue"

import { ApiBadReqResponse, type ApiSuccessResponse, client } from "@/package/api-client"
import { UserStatus } from "@/modules/admin"

interface ChangeStatusRequest {
  user_id: number | string
  status: UserStatus
}
export const useUserChangeStatus = () => {
  const { execute, isLoading, error } = useAxios<
    ApiSuccessResponse<undefined>,
    { data: ChangeStatusRequest }
  >("/admin/change-status", { method: "POST" }, client, { immediate: false })

  const cahngeStatusErrorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  const changeStatus = (data: ChangeStatusRequest) => execute({ data })

  return {
    changeStatus,
    isStatusChanging: isLoading,
    cahngeStatusErrorMessage
  }
}
