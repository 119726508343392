import { computed, reactive } from "vue"

import type { ProjectEdit } from "@/modules/project"

export const useFormState = () => {
  const formState = reactive<ProjectEdit>({
    id: 0,
    name: ""
  })
  const requestData = computed<ProjectEdit>(() => {
    const { id, name } = formState

    return {
      id,
      name
    }
  })

  return {
    formState,
    requestData
  }
}
