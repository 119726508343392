import { useAxios } from "@vueuse/integrations/useAxios"
import { computed } from "vue"

import { ApiBadReqResponse, client } from "@/package/api-client"

interface BanksInformation {
  success: boolean
  data: BankData
}

interface BankData {
  id: number
  type: string
  name: string
  is_connected: boolean
  user_integration_id: number
  auth_type: string
}

export const useBanksList = () => {
  const { execute, isLoading, error, data } = useAxios<BanksInformation | any>(
    "/integrations/banks",
    { method: "GET" },
    client,
    { immediate: false }
  )

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  const requestBanksList = async () => await execute()

  return {
    banksListResponse: data,
    requestBanksList,
    isListLoading: isLoading,
    listingLoadingErrorMessage: errorMessage,
    listingError: error
  }
}
