import type { BankAccountCreate } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { type ApiSuccessResponse, client, useApiError } from "@/package/api-client"

export const useBankAccountEdit = () => {
  const { execute, isLoading, error, isFinished } = useAxios<ApiSuccessResponse<undefined>>(
    { method: "PATCH" },
    client
  )

  const editBankAccount = (id: number | string, data: BankAccountCreate) =>
    execute(`/bank-accounts/${id}`, { data })

  return {
    editBankAccount,
    isEditing: isLoading,
    editError: useApiError(error),
    isEditingFinished: isFinished
  }
}
