<script setup lang="ts">
import { computed } from "vue"
import { useDebounceFn, useToggle } from "@vueuse/core"

import { useTags } from "../hooks"

const selectedId = defineModel({
  type: Array
})

const [isOpened, toggleOpen] = useToggle(false)

const { getTags, tags, loading } = useTags()

const handleSearch = useDebounceFn(
  async (searchQuery: string) => getTags(searchQuery ? searchQuery : undefined),
  1000
)

const options = computed(() => tags.value?.map((tag) => ({ value: tag, label: tag })) ?? [])
</script>

<template>
  <ASelect
    v-model:value="selectedId"
    :open="isOpened"
    placeholder="Выберите статью"
    size="large"
    show-search
    virtual
    :filter-option="false"
    :options="options"
    :loading="loading"
    @search="handleSearch"
    @dropdownVisibleChange="() => toggleOpen()"
  >
    <template #tagRender="{ label }">
      <ATag color="blue">{{ label }}</ATag>
    </template>
  </ASelect>
</template>
