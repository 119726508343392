import { ContentLayout } from "@/layout"
import LoginPage from "@/views/auth/LoginPage.vue"
import RestorePage from "@/views/auth/RestorePage.vue"
import RegistrationPage from "@/views/auth/RegistrationPage.vue"
import CompleteRegistrationPage from "@/views/auth/CompleteRegistrationPage.vue"
import RestoreRequestPage from "@/views/auth/RestoreRequestPage.vue"

import {
  completeRegistrationRoute,
  loginRoute,
  registerRoute,
  registerRoutePromo,
  restoreRequestRoute,
  restoreRoute
} from "./constants"

export const authPageNames = [
  loginRoute,
  registerRoute,
  registerRoutePromo,
  completeRegistrationRoute,
  restoreRequestRoute,
  restoreRoute
]

export const AuthSection = [
  {
    path: "/auth",
    component: ContentLayout,
    redirect: "/auth/login",
    children: [
      {
        name: loginRoute,
        path: "login",
        component: LoginPage
      },
      {
        path: "register",
        name: registerRoute,
        component: RegistrationPage
      },
      {
        path: "register/:promoCode",
        name: registerRoutePromo,
        component: RegistrationPage
      },
      {
        path: "confirm-email",
        name: completeRegistrationRoute,
        component: CompleteRegistrationPage
      },
      {
        path: "restore",
        name: restoreRequestRoute,
        component: RestoreRequestPage
      },
      {
        path: "reset-password",
        name: restoreRoute,
        component: RestorePage
      }
    ]
  }
]
