import IMask, { type FactoryArg, type InputElement, type InputMask, type UpdateOpts } from "imask"

type AllowedElements = InputElement & { maskRef?: InputMask }
type DirectiveMaskElement<Opts extends FactoryArg> = InputElement & { maskRef?: InputMask<Opts> }
type EventCb = (v: InputMask<Record<string, unknown>>) => unknown

export type MaskOpts = FactoryArg & {
  elGetter?: (el: HTMLElement) => DirectiveMaskElement<any> | null
  onComplete?: EventCb
  onAccept?: EventCb
}

export const vInputMask = {
  beforeMount: <Opts extends FactoryArg>(
    el: DirectiveMaskElement<Opts>,
    binding: { value: MaskOpts }
  ) => {
    const resEl = binding.value.elGetter ? binding.value.elGetter(el) : el
    if (binding.value) {
      initMask(resEl, binding.value)
    }
  },
  updated: <Opts extends FactoryArg>(
    el: DirectiveMaskElement<Opts>,
    binding: { value: MaskOpts }
  ) => {
    const resEl = binding.value.elGetter ? binding.value.elGetter(el) : el
    if (binding.value) {
      if (resEl && resEl.maskRef) {
        resEl.maskRef.updateOptions(binding.value as UpdateOpts<Opts>)
        if (resEl.value !== resEl.maskRef.value) resEl.maskRef._onChange()
      } else initMask(binding.value.elGetter ? binding.value.elGetter(el) : el, binding.value)
    } else {
      destroyMask(resEl)
    }
  },
  unmounted: <Opts extends FactoryArg>(el: DirectiveMaskElement<Opts>) => {
    if (el.maskRef) {
      el.maskRef.destroy()
      delete el.maskRef
    }
  }
}

function destroyMask(el: any) {
  if (el.maskRef) {
    el.maskRef.destroy()
    delete el.maskRef
  }
}

function initMask(el: AllowedElements | null, opts: Omit<MaskOpts, "elGetter">) {
  const { onComplete, onAccept, ...options } = opts
  if (el !== null) {
    el.maskRef = IMask(el, options)
      .on("accept", () => {
        if (el.maskRef) {
          if (onAccept) {
            onAccept(el.maskRef)
          }
        }
      })
      .on("complete", () => {
        if (el.maskRef) {
          if (onComplete) {
            onComplete(el.maskRef)
          }
        }
      })
  }
}
