<script setup lang="ts">
import { Descriptions, theme } from "ant-design-vue"
import { useSlots } from "vue"

const { token } = theme.useToken()
const slots: Record<keyof typeof Descriptions.slots, unknown> = useSlots()
</script>

<template>
  <Descriptions
    :colon="false"
    :label-style="{ color: token.colorTextSecondary }"
    :content-style="{
      fontSize: `${token.fontSizeLG}px`,
      lineHeight: token.lineHeightLG,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
      whiteSpace: 'nowrap'
    }"
  >
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </Descriptions>
</template>

<style scoped>
:deep(.ant-descriptions-item) {
  padding: 0 !important;
}

:deep(.ant-descriptions-row:not(:last-child) .ant-descriptions-item) {
  padding-bottom: 4px !important;
  border: none;
}

:deep(.ant-descriptions-header) {
  margin-bottom: 24px;
}

:deep(.ant-descriptions-row:nth-child(even):not(:last-child)) {
  border-bottom: 9px solid transparent;
}
</style>
