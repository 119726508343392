import { computed, reactive } from "vue"

import type { ProductCreateForm } from "@/modules/product"

export const useFormState = () => {
  const formState = reactive<ProductCreateForm>({
    article: null,
    type: null,
    name: null,
    description: null,
    counterparty_id: null,
    tax_type: null,
    nds_type: null,
    unit_type: null,
    self_cost: null,
    cost: null,
    quantity: null
  })
  const requestData = computed((): ProductCreateForm => {
    const {
      name,
      article,
      type,
      description,
      counterparty_id,
      tax_type,
      nds_type,
      unit_type,
      self_cost,
      cost,
      quantity
    } = formState

    return {
      quantity,
      name,
      article,
      type,
      description,
      counterparty_id,
      tax_type,
      nds_type,
      unit_type,
      self_cost,
      cost
    }
  })

  return {
    formState,
    requestData
  }
}
