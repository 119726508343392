import type { OkvedData } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

interface LegalDataResponse {
  success: boolean
  data: Array<OkvedData>
}

export const useOkvedSearch = () => {
  const { data, isLoading, error, execute } = useAxios<LegalDataResponse>(
    "/legal-entities/find-okveds",
    { method: "GET" },
    client,
    { immediate: false }
  )
  const executeSearch = (text: string = "") =>
    text.length ? execute({ params: { text } }) : Promise.resolve()
  return { okvedList: data, isLoading, error, executeSearch }
}
