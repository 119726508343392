import type { BankAccountSummary } from "../interfaces"
import type { MaybeRef } from "vue"

import { useRequest } from "vue-request"
import { get } from "@vueuse/core"

import { useApiError } from "@/package/api-client"

import { bankAccountGet } from "../api/bank-account.get"

export const useBankAccountDetail = (entityId?: MaybeRef<string | number>) => {
  const { data, error, loading, refreshAsync, run, runAsync } = useRequest<
    BankAccountSummary | undefined
  >((id) => (id ? bankAccountGet(id) : Promise.resolve(undefined)), {
    cacheKey: ([id] = []) => `/bank-accounts/${id}`,
    defaultParams: [get(entityId)]
  })

  return {
    bankAccount: data,
    isLoading: loading,
    error: useApiError(error),
    requestbankAccountDetail: run,
    getbankAccountDetail: runAsync,
    refreshAsync
  }
}
