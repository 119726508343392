<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { ReferencesProjectsRouteName } from "@/router"
import { DETAIL_REQUEST_ERROR_MESSAGE, EditProjectForm, useProjectDetail } from "@/modules/project"

const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof EditProjectForm> | null>(null)

const router = useRouter()
const openListingAction = () => router.push({ name: ReferencesProjectsRouteName })

const projectId = useRouteParams<string>("id")
const { project, error } = useProjectDetail(projectId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)
</script>

<template>
  <APageDrawer v-model:open="isOpened" title="Редактирование проекта" @onClose="openListingAction">
    <EditProjectForm ref="formRef" :project="project" @success="() => toggleOpened(false)" />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="formRef?.isLoading"
            @click="formRef?.submitAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
