import type { RouteRecordRaw } from "vue-router"

import { inject } from "vue"
import { ABILITY_TOKEN } from "@casl/vue"

import { ReferencesLayout } from "@/layout"
import CounterpartiesPage from "@/views/references/counterparties/CounterpartiesPage.vue"
import CounterpartiesDetailPage from "@/views/references/counterparties/CounterpartiesDetailPage.vue"
import CounterpartiesCreatePage from "@/views/references/counterparties/CounterpartiesCreatePage.vue"
import CounterpartiesEditPage from "@/views/references/counterparties/CounterpartiesEditPage.vue"
import BankAccountPage from "@/views/references/bank-account/BankAccountPage.vue"
import BankAccountDetailPage from "@/views/references/bank-account/BankAccountDetailPage.vue"
import BankAccountCreatePage from "@/views/references/bank-account/BankAccountCreatePage.vue"
import BankAccountEditPage from "@/views/references/bank-account/BankAccountEditPage.vue"
import ProjectsPage from "@/views/references/projects/ProjectsPage.vue"
import ProjectDetailPage from "@/views/references/projects/ProjectDetailPage.vue"
import ProjectCreatePage from "@/views/references/projects/ProjectCreatePage.vue"
import ProjectEditPage from "@/views/references/projects/ProjectEditPage.vue"
import ProductsPage from "@/views/references/products/ProductsPage.vue"
import ProductDetailPage from "@/views/references/products/ProductDetailPage.vue"
import ProductCreatePage from "@/views/references/products/ProductCreatePage.vue"
import ProductEditPage from "@/views/references/products/ProductEditPage.vue"
import OperationCategoriesPage from "@/views/references/operation-categories/OperationCategoriesPage.vue"
import OperationCategoryDetail from "@/views/references/operation-categories/OperationCategoryDetail.vue"
import OperationCategoriesCreatePage from "@/views/references/operation-categories/OperationCategoriesCreatePage.vue"
import OperationCategoriesEditPage from "@/views/references/operation-categories/OperationCategoriesEditPage.vue"
import OperationCategoriesTemplateCreatePage from "@/views/references/operation-categories/OperationCategoriesTemplateCreatePage.vue"

import {
  AdminRouteName,
  BankAccountCreateRouteName,
  BankAccountDetailRouteName,
  BankAccountEditRouteName,
  BankAccountRouteName,
  CounterpartiesReferencesCreateRouteName,
  CounterpartiesReferencesDetailRouteName,
  CounterpartiesReferencesEditRouteName,
  CounterpartiesReferencesRouteName,
  OperationsCategoriesCreateRouteName,
  OperationsCategoriesCreateTemplateRouteName,
  OperationsCategoriesDetailRouteName,
  OperationsCategoriesEditRouteName,
  ProductsCreateRouteName,
  ProductsDetailRouteName,
  ProductsEditRouteName,
  ProjectCreateRouteName,
  ProjectDetailRouteName,
  ProjectEditRouteName,
  ReferencesOperationsCategoriesRouteName,
  ReferencesProductsRouteName,
  ReferencesProjectsRouteName,
  ReferencesRouteName
} from "./constants"

export const ReferencesRoute: RouteRecordRaw[] = [
  {
    path: "/references",
    name: ReferencesRouteName,
    redirect: () => "/references/counterparties",
    component: ReferencesLayout,
    meta: {
      breadcrumb: {
        disabled: true,
        name: "Справочники"
      }
    },
    beforeEnter: () => {
      const ability = inject(ABILITY_TOKEN)
      if (ability?.can("admin", "app")) {
        return { name: AdminRouteName }
      }
    },
    children: [
      {
        path: "counterparties",
        name: CounterpartiesReferencesRouteName,
        component: CounterpartiesPage,
        meta: {
          breadcrumb: undefined
        },
        children: [
          {
            path: ":id",
            name: CounterpartiesReferencesDetailRouteName,
            component: CounterpartiesDetailPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "create",
            name: CounterpartiesReferencesCreateRouteName,
            component: CounterpartiesCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id/edit",
            name: CounterpartiesReferencesEditRouteName,
            component: CounterpartiesEditPage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      },
      {
        path: "bank-account",
        name: BankAccountRouteName,
        component: BankAccountPage,
        meta: {
          breadcrumb: undefined
        },
        children: [
          {
            path: ":id",
            name: BankAccountDetailRouteName,
            component: BankAccountDetailPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "create",
            name: BankAccountCreateRouteName,
            component: BankAccountCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id/edit",
            name: BankAccountEditRouteName,
            component: BankAccountEditPage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      },
      {
        path: "projects",
        name: ReferencesProjectsRouteName,
        component: ProjectsPage,
        meta: {
          breadcrumb: undefined
        },
        children: [
          {
            path: ":id",
            name: ProjectDetailRouteName,
            component: ProjectDetailPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "create",
            name: ProjectCreateRouteName,
            component: ProjectCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id/edit",
            name: ProjectEditRouteName,
            component: ProjectEditPage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      },
      {
        path: "products",
        name: ReferencesProductsRouteName,
        component: ProductsPage,
        meta: {
          breadcrumb: undefined
        },
        children: [
          {
            path: ":id",
            name: ProductsDetailRouteName,
            component: ProductDetailPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "create",
            name: ProductsCreateRouteName,
            component: ProductCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id/edit",
            name: ProductsEditRouteName,
            component: ProductEditPage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      },
      {
        path: "operation-categories",
        name: ReferencesOperationsCategoriesRouteName,
        component: OperationCategoriesPage,
        meta: {
          breadcrumb: undefined
        },
        children: [
          {
            path: ":id",
            name: OperationsCategoriesDetailRouteName,
            component: OperationCategoryDetail,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "create",
            name: OperationsCategoriesCreateRouteName,
            component: OperationCategoriesCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "template",
            name: OperationsCategoriesCreateTemplateRouteName,
            component: OperationCategoriesTemplateCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id/edit",
            name: OperationsCategoriesEditRouteName,
            component: OperationCategoriesEditPage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      }
    ]
  }
]
