import { useRequest } from "vue-request"

import { fetchBankDataGet } from "../api/fetch-bank-data.get"

export const useBankDataSearch = () => {
  const {
    data,
    loading: isLoading,
    error,
    run: execute
  } = useRequest(fetchBankDataGet, {
    cacheKey(params) {
      let result = "/bank-accounts/fetch-bank-data"
      if (params) {
        const [bic] = params
        result += bic
      }
      return result
    },
    onBefore() {
      error.value = undefined
    },
    manual: true
  })
  const executeSearch = (bic: string = "") => execute(bic)
  return { data, isLoading, error, executeSearch }
}
