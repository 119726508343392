import { computed, type MaybeRef, readonly, ref } from "vue"
import { get, set } from "@vueuse/core"

import { SortOrder } from "@/interfaces"

export const useListingSort = () => {
  const sortOrder = ref<SortOrder>()
  const sortBy = ref<string>()
  const sortUsed = computed(() => get(sortOrder) !== undefined && get(sortBy) !== undefined)

  const setSort = (field: MaybeRef<string>, order: MaybeRef<SortOrder>) => {
    set(sortBy, get(field))
    set(sortOrder, get(order))
  }
  const resetSort = () => {
    sortBy.value = undefined
    sortOrder.value = undefined
  }

  return {
    sortUsed,
    setSort,
    resetSort,
    sortOrder: sortOrder,
    sortBy: readonly(sortBy)
  }
}
