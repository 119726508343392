import { useAxios } from "@vueuse/integrations/useAxios"

import type { SelectListAction } from "@/interfaces"
import { client, useApiError } from "@/package/api-client"

export const UseCounterpartiesListDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: string }>(
    "/counterparties/batch-delete",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const requestListDelete = async (data: SelectListAction<string | number, { query?: string }>) =>
    await execute({ data })

  return {
    requestListDelete,
    isListDeleting: isLoading,
    counterpartyDeleteListError: useApiError(error)
  }
}
