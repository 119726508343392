import type { RouteRecordRaw } from "vue-router"

import { inject } from "vue"
import { ABILITY_TOKEN } from "@casl/vue"

import PhysEntityPage from "@/views/profile/phys/PhysEntityPage.vue"
import PhysEntityEditPage from "@/views/profile/phys/PhysEntityEditPage.vue"
import LegalEntityPage from "@/views/profile/legal/LegalEntityPage.vue"
import LegalEntityCreatePage from "@/views/profile/legal/LegalEntityCreatePage.vue"
import LegalEntityDetailPage from "@/views/profile/legal/LegalEntityDetailPage.vue"
import LegalEntityEditPage from "@/views/profile/legal/LegalEntityEditPage.vue"
import PhysSecurityPage from "@/views/profile/phys/PhysSecurityPage.vue"
import PhysEntrepreneurPage from "@/views/profile/phys/PhysEntrepreneurPage.vue"
import PhysSubscriptionPage from "@/views/profile/phys/PhysSubscriptionPage.vue"

import {
  AdminRouteName,
  LegalProfileCreateRouteName,
  LegalProfileDetailRouteName,
  LegalProfileEditRouteName,
  LegalProfileRouteName,
  PhysEntrepreneurRouteName,
  PhysProfileEditRouteName,
  PhysProfileRouteName,
  PhysSecurityRouteName,
  PhysSubscriptionRouteName,
  ProfileRouteName
} from "./constants"

export const ProfileRoute: RouteRecordRaw[] = [
  {
    path: "/profile",
    name: ProfileRouteName,
    redirect: () => "/profile/phys",
    meta: {
      breadcrumb: {
        disabled: true,
        name: "Профиль"
      }
    },
    beforeEnter: () => {
      const ability = inject(ABILITY_TOKEN)
      if (ability?.can("admin", "app")) {
        return { name: AdminRouteName }
      }
    },
    children: [
      {
        path: "phys",
        name: PhysProfileRouteName,
        component: PhysEntityPage,
        meta: {
          breadcrumb: {
            name: "Карточка пользователя"
          },
          contentLayout: true
        },
        children: [
          {
            path: "edit",
            name: PhysProfileEditRouteName,
            component: PhysEntityEditPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "entrepreneur",
            name: PhysEntrepreneurRouteName,
            component: PhysEntrepreneurPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "security",
            name: PhysSecurityRouteName,
            component: PhysSecurityPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "subscription",
            name: PhysSubscriptionRouteName,
            component: PhysSubscriptionPage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      },
      {
        path: "legal",
        name: LegalProfileRouteName,
        component: LegalEntityPage,
        meta: {
          breadcrumb: {
            name: "Организации"
          }
        },
        children: [
          {
            path: "create",
            name: LegalProfileCreateRouteName,
            component: LegalEntityCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id",
            name: LegalProfileDetailRouteName,
            component: LegalEntityDetailPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id/edit",
            name: LegalProfileEditRouteName,
            component: LegalEntityEditPage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      }
    ]
  }
]
