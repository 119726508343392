import type { BankAccountSummary } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"
import { type MaybeRef } from "vue"
import { get } from "@vueuse/core"

import { client, useApiError, useApiResponseStatus } from "@/package/api-client"
import type { OffsetPaginationDto } from "@/interfaces"
import { SortOrder } from "@/interfaces"

interface BankAccountListRespone {
  success: boolean
  pagination: OffsetPaginationDto
  data: BankAccountSummary[]
}

export const useBankAccountList = () => {
  const { execute, isLoading, error, data, response } = useAxios<BankAccountListRespone>(
    "/bank-accounts",
    { method: "GET" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const listingApiError = useApiError(error)
  const listingResponseStatus = useApiResponseStatus(response, error)
  const requestBankAccountList = (
    params: MaybeRef<{
      limit: number
      offset: number
      query?: string
      orderBy?: string
      order?: SortOrder
    }>
  ) =>
    execute({
      params: get(params)
    })

  return {
    listingResponseStatus,
    listingApiError,
    isListLoading: isLoading,
    bankAccountListingResponse: data,
    requestBankAccountList
  }
}
