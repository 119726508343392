<script setup lang="ts">
import { useRoute } from "vue-router"

const route = useRoute()

const code = route.query.code as string
const state = route.query.state as string
const mdOrder = route.query.mdOrder as string

if (window.opener !== null) {
  window.opener.postMessage(
    { code: code ?? null, state: state ?? null, mdOrder: mdOrder ?? null },
    "*"
  )
  window.close()
}
</script>
