<script setup lang="ts">
import { useRouter } from "vue-router"
import { h, watch } from "vue"
import { noop, useToggle } from "@vueuse/core"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import {
  CounterpartiesDescription,
  DETAIL_REQUEST_ERROR_MESSAGE,
  useCounterpartiesDetail,
  useCounterpartiesStore
} from "@/modules/counterparty"
import { CounterpartiesReferencesEditRouteName, CounterpartiesReferencesRouteName } from "@/router"
import { DeleteConfirm } from "@/package/ui-kit"

const router = useRouter()
const counterpartyId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)

const counterpartiesStore = useCounterpartiesStore()

const { isLoading, counterparty, error } = useCounterpartiesDetail(counterpartyId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await router.push({ name: CounterpartiesReferencesRouteName })
    }
  },
  { immediate: true }
)

const deleteAction = () => {
  DeleteConfirm({
    content:
      "Вы действительно хотите безвозвратно удалить запись? Этот контрагент используется в товарах и услугах",
    centered: true,
    onOk: () =>
      counterpartiesStore
        .deleteCounterparty(+counterpartyId.value)
        .then(() => {
          push.success({ message: "Удалено" })
          toggleOpened(false)
        })
        .catch(noop)
  })
}
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Информация о контрагенте"
    @onClose="() => router.push({ name: CounterpartiesReferencesRouteName })"
  >
    <ASpin :spinning="isLoading">
      <CounterpartiesDescription :counterparty="counterparty" />
    </ASpin>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :icon="h(DeleteOutlined)" block size="large" danger @click="deleteAction">
            Удалить
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :icon="h(EditOutlined)"
            block
            type="primary"
            size="large"
            @click="
              () =>
                router.push({
                  name: CounterpartiesReferencesEditRouteName,
                  params: { id: counterpartyId }
                })
            "
            >Редактировать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
