import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useReportDownload = () => {
  const { execute, isLoading, error } = useAxios(
    "/reports/generate-report",
    { method: "GET", responseType: "blob" },
    client,
    { immediate: false }
  )

  const executeDownload = async ({
    type,
    date_to,
    date_from,
    project_ids,
    legal_entity_id
  }: {
    type: string
    date_from?: string
    date_to?: string
    project_ids?: Array<number> | null
    legal_entity_id?: number | null
  }) => {
    const response = await execute("/reports/generate-report", {
      params: { type, date_to, date_from, project_ids, legal_entity_id }
    })

    const url = URL.createObjectURL(response.data.value)
    const filename =
      decodeURIComponent(
        response.response.value?.headers?.["content-disposition"].split("filename*=utf-8''")[1]
      ) ||
      response.response.value?.headers?.["content-disposition"]
        .split("filename*=")[1]
        .split("; filename*=")[0] ||
      "file.xlsx"

    const link = document.createElement("a")
    link.href = url
    link.download = filename
    link.style.visibility = "hidden"
    link.style.position = "absolute"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    executeDownload,
    isLoadingFile: isLoading,
    downloadReportError: useApiError(error)
  }
}
