<script setup lang="ts">
import { injectProfile, injectProfileLoading } from "@/modules/profile"

import ProfileWrapper from "../ProfileWrapper/ProfileWrapper.vue"

import { useProfileView } from "./use-profile-view"

const props = defineProps({
  subscription: {
    type: String,
    default: null
  },

  subscriptionLoading: {
    type: Boolean,
    default: false
  }
})

defineEmits(["editProfile"])

const profile = injectProfile()
const isLoading = injectProfileLoading()
const profileView = useProfileView(profile)

defineOptions({
  inheritAttrs: true
})
</script>

<template>
  <ASpin :spinning="isLoading || props.subscriptionLoading" wrapper-class-name="a-spin-nested">
    <ProfileWrapper
      title="Личная информация"
      editable
      @editProfileData="() => $emit('editProfile')"
    >
      <AFlex align="center">
        <AFlex
          class="avatar"
          :class="{ 'avatar-pro': props?.subscription === 'PRO' }"
          justify="center"
          align="center"
          ><AFlex class="avatar-inner" justify="center" align="center">{{
            props?.subscription === "PRO" ? "ПРО" : "ФРИ"
          }}</AFlex></AFlex
        >
        <ADescriptions layout="vertical" v-bind="$attrs" :column="1">
          <ADescriptionsItem label="ФИО">{{
            `${profileView?.last_name} ${profileView?.first_name} ${profileView?.middle_name}`
          }}</ADescriptionsItem>
          <ADescriptionsItem label="E-mail">{{ profileView?.email }}</ADescriptionsItem>
          <ADescriptionsItem label="Номер телефона">{{
            profileView?.phone || "—"
          }}</ADescriptionsItem>
        </ADescriptions>
      </AFlex>
    </ProfileWrapper>
  </ASpin>
</template>

<style scoped>
.avatar {
  margin-right: 32px;
  width: 140px;
  height: 140px;
  background: linear-gradient(260.01deg, #969696 15%, #363635 85%);
  border-radius: 50%;
  flex-shrink: 0;
}

.avatar-pro {
  background: linear-gradient(260.01deg, #5297ff 15%, #0041d9 85%);
}

.avatar-inner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  font-size: 24px;
  color: #ffffff;
}

.a-spin-nested {
  height: 100%;
}

:deep(.ant-spin-container) {
  height: 100%;
}

.wrapper {
  height: 100%;
}
</style>
