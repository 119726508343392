<script setup lang="ts">
import type { Form } from "ant-design-vue"
import type { BankType } from "../../interface"

import { computed, onMounted, onUnmounted, type PropType, ref, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import { useToggle } from "@vueuse/core"

import { useOperationCategoriesStore } from "@/modules/operation-category"
import { AButton } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"
import router from "@/router"

import OperationConfirmBankForm from "../OperationCofirmBankForm/OperationConfirmBankForm.vue"
import BankAuthButton from "../BankAuthButton/BankAuthButton.vue"

import { useAuthtypeGet } from "./hooks/use-authtype-get"
import { useFormState } from "./hooks/use-form-state"
import { useRuleset } from "./hooks/use-ruleset"

const props = defineProps({
  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  },

  bankType: {
    type: String as PropType<BankType | undefined>,
    default: undefined
  },

  bankName: {
    type: String,
    default: undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  formTitle: {
    type: String,
    default: ""
  }
})

const emit = defineEmits(["success"])

const { formState } = useFormState()
const ruleset = useRuleset()
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const operationsStore = useOperationCategoriesStore()
const { createError, isCreating, isCreateFinished } = storeToRefs(operationsStore)
const { authTypeResponse, getAuthType, isLoading } = useAuthtypeGet()

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<typeof Form | null>(null)
const confirmFormRef = ref<typeof Form | null>(null)

const submitActionConfirm = () => confirmFormRef.value?.submitAction()

const submitAction = () =>
  validate()
    // .then(() => operationsStore.createOperationCategories(requestData.value))
    .then((id: number) => {
      openForm()
      push.success({
        message: "Статья создана"
      })
      emit("success", id)
      operationsStore.updateBufferAfterCreate(id)
    })
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isCreateFinished.value || isValidating.value)
})

const receiveMessage = (message: MessageEvent) => {
  if (message.data.code === null || message.data.state === null) {
    push.error({
      message: "Ошибка авторизации"
    })
  }
  if (typeof message !== "object" || !message.data || !message.data.code || !message.data.state) {
    return
  }

  const newUrl = `/finance/operations/connect-bank?code=${encodeURIComponent(message.data.code)}&state=${encodeURIComponent(message.data.state)}`
  router.push(newUrl)
}

onMounted(() => {
  window.addEventListener("message", receiveMessage, false)
})

onUnmounted(() => {
  window.removeEventListener("message", receiveMessage, false)
})

onUnmounted(() => {
  isCreateFinished.value = false
})

const [isFormOpen, toggleFormOpen] = useToggle(false)

const openForm = () => {
  toggleFormOpen(true)
}

const closeForm = () => {
  toggleFormOpen(false)
}

onMounted(async () => {
  await getAuthType(props.bankType as BankType)
})
</script>

<template>
  <ASkeleton v-if="isLoading" active />
  <template v-else>
    <BankAuthButton
      v-if="authTypeResponse?.success"
      :bank-type="props.bankType"
      :url="authTypeResponse?.data?.auth_url ?? ''"
      :bank-name="props.bankName"
    />
    <AForm
      v-else
      ref="formRef"
      name="create-operation-categories-form"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      scroll-to-first-error
      @submit.prevent="submitAction"
      @keydown.enter.prevent
    >
      <AFormItem label="Логин" name="login" v-bind="validateInfos?.login">
        <AInput v-model="formState.login" size="large" placeholder="Введите логин" />
      </AFormItem>
      <AFormItem
        label="Пароль"
        name="password"
        v-bind="validateInfos?.password"
        :style="{ marginTop: '44px' }"
      >
        <AInput v-model="formState.password" size="large" placeholder="Введите пароль" />
      </AFormItem>
      <APageDrawer
        v-if="isFormOpen"
        v-model:open="isFormOpen"
        :title="formTitle"
        class-name="bank-account-drawer"
      >
        <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
          <OperationConfirmBankForm ref="confirmFormRef" :form-title="formTitle" />
        </OverlayScrollbarsComponent>

        <template #footer>
          <ARow :gutter="[8, 8]">
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton size="large" block @click="closeForm">Отмена</AButton>
            </ACol>
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton size="large" block type="primary" @click="submitActionConfirm"
                >Подключить 5555</AButton
              >
            </ACol>
          </ARow>
        </template>
      </APageDrawer>
    </AForm>
  </template>
</template>
