<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useToggle } from "@vueuse/core"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { LegalProfileRouteName } from "@/router"
import {
  type LegalEntityCreate,
  LegalEntityForm,
  useLegalEntityStore
} from "@/modules/legal-entity"
import { ApiBadReqResponse, ApiForbiddenResponse } from "@/package/api-client"

const store = useLegalEntityStore()
const { isLegalEntityCreating, isCreateFinished } = storeToRefs(store)

const router = useRouter()
const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof LegalEntityForm> | null>(null)

const submitAction = async (requestData: LegalEntityCreate) => {
  try {
    await store.createLegalEntity(requestData)
    push.success({
      message: "Информация сохранена"
    })
    toggleOpened(false)
    await store.updateBufferAfterCreate()
  } catch (e) {
    if (e instanceof ApiBadReqResponse || e instanceof ApiForbiddenResponse) {
      push.error({
        message: e.message
      })
    } else {
      push.error({
        message: "Неизвестная ошибка, пожалуйста повторите позже или обратитесь в поддержку."
      })
    }
    isCreateFinished.value = false
  }
}
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Создание юридического лица"
    @onClose="() => router.push({ name: LegalProfileRouteName })"
  >
    <LegalEntityForm ref="formRef" @submit="submitAction" />

    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            size="large"
            block
            type="primary"
            :loading="isLegalEntityCreating"
            @click="() => formRef?.submitAction()"
            >Создать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
