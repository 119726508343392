import { client } from "@/package/api-client"
import { type UserRolesOptions } from "@/modules/admin"

interface UserRolesResponse {
  success: boolean
  data: UserRolesOptions
}

export const userRoleGet = () =>
  client.get<UserRolesResponse>("/admin/roles").then(({ data }) => data.data)
