import type {
  OperationsStatistics,
  RevenueStatistics,
  SummaryStatisticsFilter,
  SummaryStatisticsResponse
} from "../interface"
import type { AxiosError } from "axios"

import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, type MaybeRef } from "vue"
import { get, noop } from "@vueuse/core"

import { type ApiSuccessResponse, client } from "@/package/api-client"

interface FilterRequest {
  date_from: string | undefined
  date_to: string | undefined
  bank_account_id: Array<number | string> | undefined
  project_id: Array<number | string> | undefined
  operation_categories: Array<number | string> | undefined
  all_categories: boolean | undefined
  legal_entity_id: number | undefined
}

export const useSummaryStatistics = (filters?: MaybeRef<Partial<SummaryStatisticsFilter>>) => {
  const filterData = computed(() => {
    let result: Partial<FilterRequest> | undefined
    const filterData = get(filters)
    if (filterData) {
      const [date_from, date_to] = filterData["date"] ?? []
      const bank_account_id = filterData["bankAccountId"]
        ? [filterData["bankAccountId"]]
        : undefined
      const project_id = filterData["projectId"] ? [filterData["projectId"]] : undefined
      const operationCategories = (() => {
        const res = filterData["operationCategories"] ?? []
        return res.length > 0 ? res : undefined
      })()
      const allCategories = filterData["allCategories"]
      const legal_entity_id = filterData["legalEntityId"]

      result = {
        date_from: date_from?.format("DD-MM-YYYY"),
        date_to: date_to?.format("DD-MM-YYYY"),
        operation_categories: operationCategories,
        all_categories: allCategories,
        bank_account_id,
        project_id,
        legal_entity_id
      }
    } else {
      result = undefined
    }
    return result
  })
  const { execute, data, isLoading, error } = useAxios<
    ApiSuccessResponse<SummaryStatisticsResponse>
  >(
    "/statistic/get",
    {
      method: "POST",
      data: get(filterData)
    },
    client,
    {
      resetOnExecute: true,
      shallow: true
    }
  )

  const getStatistics = async () => {
    execute({ data: get(filterData) }).catch(noop)
  }

  const operationsStatistics = computed((): OperationsStatistics | undefined => {
    let result = undefined
    const serverResponse = get(data)?.data
    if (serverResponse) {
      const { income, incomePlan, outlay, outlayPlan, abscissa } = serverResponse
      result = {
        income,
        incomePlan,
        outlay,
        outlayPlan,

        abscissa
      }
    }

    return result
  })
  const revenueStatistics = computed((): RevenueStatistics | undefined => {
    let result = undefined
    const serverResponse = get(data)?.data
    if (serverResponse) {
      const { revenue, revenuePlan, revenueIncrease, revenuePlanIncrease, abscissa } =
        serverResponse
      const placeholderArray = Array.from({ length: abscissa.length }, () => null)
      result = {
        revenue,
        revenuePlan: revenuePlan ?? placeholderArray,
        revenueIncrease: revenueIncrease ?? placeholderArray,
        revenuePlanIncrease: revenuePlanIncrease ?? placeholderArray,
        abscissa
      }
    }

    return result
  })

  const detalization = computed(() => get(data)?.data.detalization)

  const errorMessage = computed(() => {
    const err = get(error)
    if (err && (err as AxiosError).code === "ERR_CANCELED") return
    return err ? "Не удалось получить статистику, пожалуйста обратитесь в поддержку" : null
  })
  return {
    statisticsLoading: isLoading,
    operationsStatistics,
    revenueStatistics,
    detalization,
    statisticsRequestErrorMessage: errorMessage,
    getStatistics
  }
}
