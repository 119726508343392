import type { CitizenshipCode } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

interface CitizenshipCodeResponse {
  success: boolean
  data: Array<CitizenshipCode>
}

export const useCitizenshipCode = () => {
  const { data, isLoading, error, execute } = useAxios<CitizenshipCodeResponse>(
    "/reference/country",
    { method: "GET" },
    client,
    { immediate: false }
  )
  const getCitizenshipCode = async () => {
    return await execute("/reference/country")
  }

  return { citizenshipCode: data, citizenshipCodePending: isLoading, error, getCitizenshipCode }
}
