<script setup lang="ts">
import type { Dayjs } from "dayjs"

import dayjs from "dayjs"
import { type PropType, ref } from "vue"
import { Form } from "ant-design-vue"
import { useDebounceFn, useToggle } from "@vueuse/core"
import { onMounted, watch } from "vue"

import type { BudgetFormInterface } from "@/modules/budget"
import { LegalEntitySelect } from "@/modules/legal-entity"
import { ProjectSelect } from "@/modules/project"
import { useForm } from "@/package/hooks"
import { OperationCategoryTreeSelect } from "@/modules/operation-category"
import { useMobileBp } from "@/package/ui-kit"
import { isValidateError, getFilterDatesPresets } from "@/package/util"
import { BankAccountSelect } from "@/modules/bank-account"

import { useFormRuleset, useBudgetForm } from "./hooks"

const props = defineProps({
  planning: {
    type: [Object, undefined] as PropType<BudgetFormInterface | undefined>,
    default: () => undefined
  }
})

const emit = defineEmits(["submit"])

const { formData, formState } = useBudgetForm()

const resetAction = useDebounceFn(() => {
  if (props.planning) {
    Object.assign(formState, props.planning)
  }
}, 100)
watch(() => props.planning, resetAction)
onMounted(resetAction)

const ruleset = useFormRuleset()
const { validate, validateInfos } = useForm(formState, ruleset)
const formRef = ref<typeof Form | null>(null)

const [isDatepickerOpened, toggleDatepickerOpened] = useToggle(false)
const [isDateChanging, toggleIsDateChanging] = useToggle<boolean>(false)

const currentDate = dayjs()
const datePresets = getFilterDatesPresets(currentDate)

const focusRangePicker = () => {
  if (!isDatepickerOpened.value) {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
  }

  if (!isDatepickerOpened.value) {
    toggleDatepickerOpened(true)
  }
}

const blurRangePicker = () => {
  if (!isDateChanging.value) {
    toggleDatepickerOpened(false)
  } else {
    toggleIsDateChanging(false)
  }
}

const handleCalendarChange = (
  dates: Array<Dayjs>,
  dateString: Array<string>,
  info: { range: string }
) => {
  if (!dates) {
    toggleDatepickerOpened(false)
    formState.period = null
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
    return
  }

  formState.period = dates
  if (info.range === "start" && dates[0]) {
    toggleIsDateChanging(true)
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.focus()
  }

  if (info.range === "end" && dates) {
    toggleDatepickerOpened(false)
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
  }
}

const isMobile = useMobileBp()

const submitAction = () =>
  validate()
    .then(() => emit("submit", formData))
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })
defineExpose({ submitAction })
</script>

<template>
  <Form
    ref="formRef"
    name="budget-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    :style="{ paddingBottom: '16px' }"
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem label="Название" name="name" v-bind="validateInfos?.name">
      <AInput v-model="formState.name" placeholder="Введите название" size="large" />
    </AFormItem>
    <AFormItem label="Период" name="period" v-bind="validateInfos?.period">
      <ARangePicker
        :value="formState.period"
        :open="isDatepickerOpened"
        :presets="isMobile ? [] : datePresets"
        :format="['DD-MM-YYYY', 'DD-MM-YYYY']"
        :style="{ width: '100%' }"
        :placeholder="['Начало', 'Конец']"
        popup-class-name="operation-filters-date-picker"
        size="large"
        @focus.prevent="focusRangePicker"
        @blur.prevent="blurRangePicker"
        @calendarChange="handleCalendarChange"
      />
    </AFormItem>
    <AFormItem label="Юридическое лицо" name="legalEntityId" v-bind="validateInfos?.legalEntityId">
      <LegalEntitySelect
        v-model="formState.legalEntityId"
        placeholder="Выберите юридическое лицо"
        size="large"
        hide-link
      />
    </AFormItem>
    <AFormItem
      label="Статьи доходов и расходов"
      name="category_operation_ids"
      v-bind="validateInfos?.category_operation_ids"
    >
      <OperationCategoryTreeSelect
        v-model="formState.category_operation_ids"
        placeholder="Выберите cтатьи"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Проект" name="projectId" v-bind="validateInfos?.projectId">
      <ProjectSelect
        v-model="formState.projectId"
        placeholder="Выберите проект"
        size="large"
        hide-link
      />
    </AFormItem>
    <AFormItem label="Комментарий" name="comment" v-bind="validateInfos?.comment">
      <ATextarea
        v-model:value="formState.comment"
        placeholder="Введите пояснение или комментарий к этому бюджету"
        size="large"
        :rows="3"
      />
    </AFormItem>
    <AFormItem
      class="income-form__item"
      label="Счет"
      name="bank_account_from_id"
      v-bind="validateInfos?.bank_account_id"
    >
      <BankAccountSelect
        v-model="formState.bank_account_id"
        size="large"
        placeholder="Выберите счет"
        nested
      />
    </AFormItem>
  </Form>
</template>
