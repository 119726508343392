import type { UserDetail } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, type MaybeRef } from "vue"
import { get } from "@vueuse/core"

import { ApiBadReqResponse, type ApiSuccessResponse, client } from "@/package/api-client"

export const useUserEdit = () => {
  const { execute, isLoading, error } = useAxios<ApiSuccessResponse<undefined>>(
    "/admin/edit-user",
    { method: "POST" },
    client,
    { immediate: false }
  )

  const userEditErrorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  const requestEditUser = (id: MaybeRef<number | string>, dto: MaybeRef<UserDetail>) =>
    execute({ data: get(dto), params: { user_id: get(id) } })

  return {
    requestEditUser,
    isUserEditing: isLoading,
    userEditErrorMessage
  }
}
