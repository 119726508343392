import { noop } from "@vueuse/core"
import { useAxios } from "@vueuse/integrations/useAxios"

import type { ChangePasswordRequest } from "@/modules/profile"
import { client, useApiError } from "@/package/api-client"

export const useChangePassword = () => {
  const { execute, isLoading, error } = useAxios(
    "/users/update-password",
    { method: "POST" },
    client,
    { immediate: false }
  )
  const changePassword = (data: ChangePasswordRequest, onSuccess: typeof noop) =>
    execute({ data }).then(onSuccess).catch(noop)

  return {
    error: useApiError(error),
    isLoading,
    changePassword
  }
}
