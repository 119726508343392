import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { OperationBankAuth } from "@/modules/operation"

export const useBankAuthByCode = () => {
  const { execute, data, isLoading, error } = useAxios<OperationBankAuth>(
    "/integrations/banks/{bank_type}/authentication/by-code",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const bankAuth = async (bank_type: string, state: string, code: string) => {
    const payload = {
      state,
      payload: {
        code
      }
    }
    return await execute(`/integrations/banks/${bank_type}/authentication/by-code`, {
      data: payload
    })
  }

  return {
    bankAuthResponse: data,
    bankAuth,
    bankAuthPending: isLoading,
    bankAuthError: useApiError(error)
  }
}
