<script setup lang="ts">
import { computed, type PropType } from "vue"
import { TypographyText } from "ant-design-vue"

import { type Profile, ProfileRolesMap } from "../interfaces"

import SubscriptionBadge from "./SubscriptionBadge/SubscriptionBadge.vue"

const props = defineProps({
  profile: {
    type: [Object, undefined] as PropType<Profile | undefined>,
    default: undefined
  },

  short: {
    type: Boolean as PropType<boolean>,
    default: false
  }
})

const fullName = computed(() =>
  [props.profile?.last_name, props.profile?.first_name, props.profile?.middle_name]
    .filter(Boolean)
    .join(" ")
)

const nonManagerRoleText = computed(() => {
  const profile = props?.profile
  if (!profile || !profile.role) {
    return null
  }
  return profile.role !== "manager" ? ProfileRolesMap[profile.role] : null
})
</script>

<template>
  <div class="summary" :class="[short ? 'summary--short' : null]">
    <template v-if="!short">
      <AFlex align="center">
        <AText
          class="summary__fullname"
          :class="props?.profile?.role !== 'manager' ? 'summary__small' : undefined"
          :el="TypographyText"
          >{{ fullName }}</AText
        >
        <SubscriptionBadge
          v-if="profile?.role === 'manager'"
          :style="{ marginLeft: '12px' }"
          size="small"
          :subscription="props.profile?.subscription"
        />
      </AFlex>
      <TypographyText v-if="nonManagerRoleText" type="secondary">{{
        nonManagerRoleText
      }}</TypographyText>
    </template>
  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.summary {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 0 8px;
}

.summary--short {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.summary__avatar {
  grid-column: 1/2;
  grid-row: 1/-1;
  align-self: center;
  justify-self: start;
}

.summary__fullname {
  text-transform: capitalize;
  font-size: 24px;
}

.summary__small {
  font-size: 16px;
}
</style>
