<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref } from "vue"
import { useToggle } from "@vueuse/core"

import { EditProfile, injectProfile, injectProfileRefresh } from "@/modules/profile"
import { PhysProfileRouteName } from "@/router"

const router = useRouter()

const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof EditProfile> | null>(null)

const profile = injectProfile()
const refreshAsync = injectProfileRefresh()
const onSuccess = async () => {
  await refreshAsync()
  toggleOpened(false)
}
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Редактирование личной информации"
    @onClose="() => router.push({ name: PhysProfileRouteName })"
  >
    <EditProfile ref="formRef" :profile="profile" @success="onSuccess" />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block type="primary" size="large" @click="formRef?.submitAction">
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
