import type { AxiosResponse } from "axios"
import type { Rules } from "async-validator"

import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { get, noop } from "@vueuse/core"
import { useRouteQuery } from "@vueuse/router"
import { useAsyncValidator } from "@vueuse/integrations/useAsyncValidator"

import type { ApiSuccessMessage } from "@/package/api-client"
import { ApiBadReqResponse, client } from "@/package/api-client"

export const useRegisterComplete = () => {
  const {
    execute,
    error,
    isLoading,
    data: completeResult
  } = useAxios<
    ApiSuccessMessage,
    AxiosResponse<ApiSuccessMessage>,
    {
      email: string
      hash: string
    }
  >("/users/confirm-email", { method: "POST" }, client, { immediate: false, resetOnExecute: true })
  const errorMessage = computed(() => {
    let result = null
    const Error = get(error)
    if (Error) {
      if (Error instanceof ApiBadReqResponse) {
        result = Error.message
      } else {
        result =
          "Не удалось подтвердить регистрацию, пожалуйста откройте ссылку из письма повторно позже или обратитесь в поддержку"
      }
    }
    return result
  })

  const email = useRouteQuery("email")
  const hash = useRouteQuery("hash")
  const data = computed(() => ({
    hash: typeof hash.value === "string" ? hash.value : "",
    email: typeof email.value === "string" ? email.value : ""
  }))
  const registerComplete = async () => execute({ data: get(data) }).catch(noop)

  const rules: Rules = {
    hash: {
      type: "string",
      required: true,
      message: "ключ восстановления не обнаружен"
    },
    email: {
      type: "email",
      required: true,
      message: "email не обнаружен"
    }
  }
  const { pass, execute: validate, errors: dataValidationError } = useAsyncValidator(data, rules)
  watch(pass, async (isValid) => {
    if (isValid) {
      await registerComplete()
    }
  })

  return {
    completeResult,
    isLoading,
    registerComplete,
    errorMessage,
    validate,
    dataValidationError
  }
}
