import { useAxios } from "@vueuse/integrations/useAxios"

import type { FlatOperationCategory } from "@/modules/operation-category"
import { client, useApiError } from "@/package/api-client"

interface ReferenceListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: FlatOperationCategory[]
}

export const useOperationCategoriesSelectList = () => {
  const { execute, isLoading, error, data } = useAxios<
    ReferenceListResponse,
    { offset: number; limit: number }
  >("/operation-categories/variants", { method: "GET" }, client, { immediate: false })

  const requestListing = (params: {
    offset?: number
    limit?: number
    query?: string
    action_type?: string
    operation_category_id?: number | string
    statistics?: boolean
  }) => execute({ params })

  return {
    listingResponse: data,
    requestListing,
    isListingLoading: isLoading,
    selectError: useApiError(error)
  }
}
