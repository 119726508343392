import type { BankAccountCreate } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { type ApiSuccessResponse, client, useApiError } from "@/package/api-client"

export const useBankAccountCreate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    ApiSuccessResponse<undefined>,
    { data: BankAccountCreate }
  >("/bank-accounts", { method: "POST" }, client, { immediate: false })

  const createBankAccount = (data: BankAccountCreate) => execute({ data })
  return {
    createBankAccount,
    isCreating: isLoading,
    createError: useApiError(error),
    isCreateFinished: isFinished
  }
}
