<script setup lang="ts">
import { Breadcrumb, BreadcrumbItem } from "ant-design-vue"
import { type RouteLocationRaw } from "vue-router"
import { computed } from "vue"
import { get } from "@vueuse/core"

import { useCurrentRoute } from "@/router"

type BreadCrumb = {
  to?: RouteLocationRaw
  disabled?: boolean
  name: string
}
const { currentRoute, currentRouteMeta } = useCurrentRoute()

const breadcrumbs = computed((): Array<BreadCrumb> => {
  const result = currentRoute.matched
    .filter(
      (matched) => matched.meta.breadcrumb !== undefined && matched.name !== currentRoute.name
    )
    .map((route) => ({
      ...route.meta.breadcrumb,
      to: { name: route.name }
    })) as Array<BreadCrumb>

  const currentBc = get(currentRouteMeta)?.breadcrumb
  const currentBcBefore = get(currentRouteMeta)?.breadcrumbBefore
  if (currentBcBefore) {
    result.push(...currentBcBefore)
  } else if (currentRoute.meta.breadcrumbBefore) {
    result.push(...currentRoute.meta.breadcrumbBefore)
  }
  if (currentBc) {
    result.push({ ...currentBc })
  } else if (currentRoute.meta.breadcrumb) {
    result.push({ ...currentRoute.meta.breadcrumb })
  }
  result[result.length - 1]["disabled"] = true

  return result
})
</script>

<template>
  <Breadcrumb>
    <BreadcrumbItem v-for="(bc, index) in breadcrumbs" :key="index" class="bc-item">
      <template v-if="bc.to && !bc.disabled">
        <RouterLink :to="bc.to">{{ bc.name }}</RouterLink>
      </template>
      <template v-else>{{ bc.name }}</template>
    </BreadcrumbItem>
  </Breadcrumb>
</template>

<style scoped>
.bc-item {
  display: flex;
  overflow: hidden;
}
.bc-item :deep(.ant-breadcrumb-link) {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
