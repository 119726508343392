import type { UploadRequestError, UploadRequestOption } from "ant-design-vue/es/vc-upload/interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

import { useOperationImportStore } from "../store"

export function useFileUpload() {
  const store = useOperationImportStore()
  const { execute, error } = useAxios(
    "/operations/import-excel",
    { method: "POST", headers: { "content-type": "multipart/form-data" } },
    client,
    {
      immediate: false
    }
  )

  return {
    upload: async (options: UploadRequestOption) => {
      const { onSuccess, onError, file } = options

      const url = URL.createObjectURL(file as File)

      const fmData = new FormData()
      fmData.append("file", file)

      try {
        const res = await execute({ data: fmData })
        store.setFileId(res.data.value.fileId, (file as File).name, url)
        store.setExcelColumnOptions(res.data.value.data)
        onSuccess!("Ok")
      } catch (error) {
        onError!(error as UploadRequestError)
      }
    },
    uploadError: useApiError(error, "Что-то пошло не так")
  }
}
