<script setup lang="ts">
import { useRouter } from "vue-router"
import { h, watch } from "vue"
import { noop, useToggle } from "@vueuse/core"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { ProductsEditRouteName, ReferencesProductsRouteName } from "@/router"
import { DeleteConfirm } from "@/package/ui-kit"
import {
  useProductsStore,
  ProductDescription,
  useProductDetail,
  DETAIL_REQUEST_ERROR_MESSAGE,
  useProductDetailView
} from "@/modules/product"

const router = useRouter()
const openListingAction = () => router.push({ name: ReferencesProductsRouteName })

const productId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)

const { isLoading, product, error } = useProductDetail(productId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)
const productDetail = useProductDetailView(product)
const productStore = useProductsStore()

const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить запись?",
    centered: true,
    onOk: () =>
      productStore
        .deleteProduct(+productId.value)
        .then(() => {
          push.success({ message: "Удалено" })
          toggleOpened(false)
        })
        .catch(noop)
  })
}
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Информация о товаре/услуге"
    @onClose="openListingAction"
  >
    <ASpin :spinning="isLoading">
      <ProductDescription :detail="productDetail" />
    </ASpin>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :icon="h(DeleteOutlined)" block size="large" danger @click="deleteAction">
            Удалить
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :icon="h(EditOutlined)"
            block
            type="primary"
            size="large"
            @click="
              () =>
                router.push({
                  name: ProductsEditRouteName,
                  params: { id: productId }
                })
            "
            >Редактировать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
