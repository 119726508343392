import type { Product, ProductEditForm } from "../interfaces"
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { defineStore } from "pinia"
import { computed, type MaybeRef, readonly, ref, watch } from "vue"
import { get, set } from "@vueuse/core"
import { push } from "notivue"
import { HttpStatusCode } from "axios"

import { SamoDecimal } from "@/package/util"
import { useListBufferApi, useListingSort, usePagination } from "@/package/hooks"
import { getOrderBySorter } from "@/package/ui-kit"
import { SortOrder } from "@/interfaces"

import {
  useGetProductSorting,
  useProductCreate,
  useProductDelete,
  useProductEdit,
  useProductsList,
  useProductsListDelete,
  useSaveProductSorting
} from "../hooks"

export const useProductsStore = defineStore("products", () => {
  const { pagination, paginationTotal, setPagination, decrementTotal } = usePagination()

  const { sortBy, sortOrder, sortUsed, setSort, resetSort } = useListingSort()

  const listingBuffer = ref(new Array<Product>())
  const idList = computed(() => listingBuffer.value.map(({ id }) => id))

  const searchQuery = ref("")
  const searchQueryRO = readonly(searchQuery)

  const {
    setUpdateFn,
    updateBufferFn,
    bufferConcat,
    bufferUnshift,
    bufferFilterOneConcat,
    bufferRewrite
  } = useListBufferApi(listingBuffer)
  const {
    productListingResponse,
    requestListing,
    isListingLoading,
    listingApiError,
    listingResponseStatus
  } = useProductsList()

  const isResourceEmpty = computed(() => get(listingResponseStatus) === HttpStatusCode.NoContent)
  const isLoadedFull = computed(() => {
    const total = get(pagination)?.total
    return (total !== null && total <= get(listingBuffer).length) || get(isResourceEmpty)
  })

  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
    }
  })
  watch(productListingResponse, (listing) => {
    if (listing && listing.data.length > 0) {
      setPagination(listing.pagination)
      updateBufferFn(listing.data)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })

  const loadNextPart = async () => {
    setUpdateFn(bufferConcat)
    await requestListing({
      offset: get(listingBuffer, "length"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }
  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: 0,
      limit: get(listingBuffer, "length"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { createProduct, isCreating, createError, isCreateFinished } = useProductCreate()

  const updateBufferAfterCreate = async () => {
    setUpdateFn(bufferUnshift)
    await requestListing({
      offset: 0,
      limit: 1,
      query: get(searchQuery) || undefined
    })
    if (get(sortUsed)) {
      await reloadBuffer()
    }
  }

  const { executeDelete, isDeleting, productDeleteError } = useProductDelete()
  const deleteProduct = async (id: number) => {
    if (get(isLoadedFull)) {
      await executeDelete(id)
      bufferFilterOneConcat(id, [])
      decrementTotal()
    } else {
      await executeDelete(id)
      setUpdateFn(bufferFilterOneConcat.bind(null, id))
      await requestListing({
        offset: get(listingBuffer, "length"),
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy)
      })
    }
  }

  const { requestListDelete, isListDeleting, productDeleteListError } = useProductsListDelete()
  const deleteByExcludedIdList = async (idList: MaybeRef<Array<number | string>>) => {
    await requestListDelete({ exclude_ids: get(idList), query: get(searchQuery) || undefined })
    await reloadBuffer()
  }
  const deleteBySelectedIdList = async (idList: MaybeRef<Array<number | string>>) => {
    await requestListDelete({ include_ids: get(idList) })
    await reloadBuffer()
  }

  const { editProduct, isEditing, editError, isEditingFinished } = useProductEdit()

  const updateBufferAfterEdit = async (data: ProductEditForm) => {
    const updatedId = listingBuffer.value.findIndex(({ id }) => data.id === id)
    if (updatedId !== -1) {
      listingBuffer.value[updatedId] = {
        id: data.id,
        name: data.name ?? "",
        article: data.article ?? "",
        type: data.type ?? "",
        tax_type: data.tax_type ?? "",
        unit_type: data.unit_type ?? "",
        counterparty: { id: data.counterparty_id, name: data.counterparty_name },
        cost: data.cost ? SamoDecimal(data.cost).format() : null,
        self_cost: data.self_cost ? SamoDecimal(data.self_cost).format() : null,
        description: data.description ?? "",
        quantity: data.quantity
      }
    }
  }

  const searchListing = async (query: MaybeRef<string>) => {
    set(searchQuery, get(query))
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const setSortOrder = async (sorter: SorterResult) => {
    const orderBy = getOrderBySorter(sorter)
    if (orderBy !== null && typeof sorter.field === "string") {
      setSort(sorter.field, orderBy)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { getSorting, isSortingLoading } = useGetProductSorting()

  const getProductSorting = async () => {
    const response = await getSorting()
    if (response?.sortBy && typeof response?.sortOrder === "string") {
      setSort(response?.sortBy, response?.sortOrder as SortOrder)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { executeSave } = useSaveProductSorting()

  const saveProductSorting = async () => {
    await executeSave({ sortBy: sortBy.value, sortOrder: sortOrder.value })
  }

  const $reset = () => {
    set(searchQuery, undefined)
    setPagination()
    set(listingBuffer, [])
    resetSort()
  }

  return {
    isLoadedFull,
    isResourceEmpty,
    listingApiError,
    searchQuery: searchQueryRO,
    pagination,
    paginationTotal,
    idList,
    listingBuffer,
    isListingLoading,
    searchListing,
    loadNextPart,

    createProduct,
    isCreating,
    isCreateFinished,
    createError,
    updateBufferAfterCreate,
    editProduct,
    isEditing,
    editError,
    isEditingFinished,
    updateBufferAfterEdit,
    deleteProduct,
    isDeleting,
    productDeleteError,
    productDeleteListError,
    deleteByExcludedIdList,
    deleteBySelectedIdList,
    sortOrder,
    sortUsed,
    isSortingLoading,
    getProductSorting,
    saveProductSorting,
    isListDeleting,
    setSortOrder,
    $reset
  }
})
