import { ref } from "vue"

export const useRuleset = () => {
  const ruleset: any = ref({
    login: [{ required: true, message: "Пожалуйста, введите логин", whitespace: true }],
    password: [{ required: true, message: "Пожалуйста, введите пароль" }]
  })

  return ruleset
}
