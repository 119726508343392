import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { OperationBankIntegration } from "@/modules/operation"

export const useBankIntegration = () => {
  const { execute, data, isLoading, error } = useAxios<OperationBankIntegration>(
    "/integrations/banks/integration/{integration_id}",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getBankIntegration = async (integration_id: number) => {
    return await execute(`/integrations/banks/integration/${integration_id}`)
  }

  return {
    bankIntegrationData: data,
    getBankIntegration,
    BankIntegrationPending: isLoading,
    BankIntegrationError: useApiError(error)
  }
}
