<script setup lang="ts">
import { Form } from "ant-design-vue"
import { ref } from "vue"

import { BankAccountSelect } from "@/modules/bank-account"
import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { isValidateError } from "@/package/util"

import { useTransferFormStateInject, useTransferRuleset } from "../hooks"

import OperationAttachmentUploadInput from "./OperationAttachmentUploadInput.vue"

const emit = defineEmits(["submit"])

const { formState, formData } = useTransferFormStateInject()
const ruleset = useTransferRuleset()
const { validate, validateInfos } = useForm(formState, ruleset)
const formRef = ref<typeof Form | null>(null)

const submitAction = () =>
  validate()
    .then(() => emit("submit", formData))
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({ submitAction })
const popupContainerGetter = usePopupContainerGetter()
</script>

<template>
  <Form
    ref="formRef"
    name="transfer-operation"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    :style="{ padding: '0 24px' }"
    @submit.prevent="submitAction"
  >
    <AFormItem label="Сумма" name="amount" v-bind="validateInfos?.amount">
      <AInputPrice v-model="formState.amount" size="large" placeholder="Введите сумму" />
    </AFormItem>
    <AFormItem label="Дата" name="date" v-bind="validateInfos?.date">
      <ADatePicker
        v-model="formState.date"
        size="large"
        :style="{ width: '100%' }"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem
      label="Счет отправителя"
      name="bank_account_from_id"
      v-bind="validateInfos?.bank_account_from_id"
    >
      <BankAccountSelect
        v-model="formState.bank_account_from_id"
        :excluded-list="formState.bank_account_to_id ? [formState.bank_account_to_id] : undefined"
        size="large"
        placeholder="Выберите счет"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem
      label="Счет получателя"
      name="bank_account_from_id"
      v-bind="validateInfos?.bank_account_to_id"
    >
      <BankAccountSelect
        v-model="formState.bank_account_to_id"
        :excluded-list="
          formState.bank_account_from_id ? [formState.bank_account_from_id] : undefined
        "
        size="large"
        placeholder="Выберите счет"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="Теги" name="tags" v-bind="validateInfos?.tags">
      <ASelect
        v-model:value="formState.tags"
        mode="tags"
        placeholder="Введите теги"
        size="large"
        :open="false"
        class="tag-select"
        max-tag-count="responsive"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="Назначение платежа" name="purpose" v-bind="validateInfos?.purpose">
      <ATextarea
        v-model:value="formState.purpose"
        mode="tags"
        placeholder="Введите назначение платежа"
        size="large"
        allow-clear
      />
    </AFormItem>
    <AFormItem
      class="transfer-form__item"
      name="files"
      v-bind="validateInfos?.files"
      style="margin-top: 16px"
    >
      <OperationAttachmentUploadInput v-model="formState.files" />
    </AFormItem>
  </Form>
</template>

<style scoped>
.transfer-form__item {
  grid-column: 2/3;
}
</style>

<style>
.tag-select > .ant-select-selector > .ant-select-selection-overflow {
  flex-wrap: wrap !important;
}
</style>
