import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { computed, type Ref } from "vue"
import { h, ref, watch } from "vue"
import { TypographyText } from "ant-design-vue"

import type { Counterparty } from "@/modules/counterparty"
import { useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import { SortOrder, SortOrderInternal } from "@/interfaces"
import { useProjectsStore } from "@/modules/project"

const defaultSizes = {
  action: 64
}
const adoptedColumns = [
  {
    key: "id",
    customRender: ({ record }: { record: Counterparty }) =>
      h(TypographyText, { content: viewValueOrDefault(record.name), strong: true, ellipsis: true })
  }
]

const defaultColumns = (): ColumnsType<any> => {
  const store = useProjectsStore()

  return [
    {
      title: "Название",
      key: "name",
      dataIndex: "name",
      ellipsis: { showTitle: true },
      colSpan: 2,
      align: "left",
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder) {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "",
      key: "action",
      width: defaultSizes.action
    }
  ]
}
export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns()
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
