<script setup lang="ts">
import { h } from "vue"
import { EditOutlined } from "@ant-design/icons-vue"

const props = defineProps({
  title: {
    type: String,
    default: undefined
  },

  editable: {
    type: Boolean,
    default: false
  }
})

defineEmits(["editProfileData"])
</script>

<template>
  <div class="wrapper">
    <AFlex justify="space-between" align="center" class="bottom-border header">
      <ATypographyTitle :level="5" :style="{ margin: 0 }">{{ props.title }}</ATypographyTitle>
      <AButton
        v-if="editable"
        type="default"
        :icon="h(EditOutlined)"
        @click="() => $emit('editProfileData')"
      /><slot v-if="!editable" name="extra" />
    </AFlex>

    <slot />
  </div>
</template>

<style scoped>
.wrapper {
  border: 1px solid rgb(213, 213, 213);
  padding: 12px 24px 24px;
  border-radius: 6px;
}

.header {
  padding-bottom: 12px !important;
  margin-bottom: 24px;
}
</style>
