import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useBudgetDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { id: number }>(
    "/budgets/:id",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const executeDelete = async (id: number) => await execute(`/budgets/${id}`)

  return {
    executeDelete,
    isDeleting: isLoading,
    budgetDeleteError: useApiError(error)
  }
}
