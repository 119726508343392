<script setup lang="ts">
const options = [
  {
    value: "male",
    label: "Мужской"
  },
  {
    value: "female",
    label: "Женский"
  }
]
</script>

<template>
  <ASelect placeholder="Выберите пол" :options="options" />
</template>
