import { Modal } from "ant-design-vue"
import { h } from "vue"
import { ExclamationCircleOutlined } from "@ant-design/icons-vue"
import { noop } from "@vueuse/core"

interface Props
  extends Pick<
    Parameters<typeof Modal.confirm>[0],
    "content" | "okText" | "cancelText" | "onOk" | "centered" | "title"
  > {}

export const InfoModal = ({
  onOk = noop,
  centered = true,
  content,
  title = "Информация",
  okText = "Понятно",
  cancelText
}: Props = {}) => {
  Modal.info({
    autoFocusButton: null,
    maskClosable: true,
    class: "ant-modal-confirm--custom",
    title,
    icon: h(ExclamationCircleOutlined),
    content,
    centered,

    onOk,
    okText,
    okButtonProps: { block: true, type: "default" },
    cancelText
  })
}
