<script setup lang="ts">
import type { BankAccountSummary, BankAccountSummaryRecord } from "../../interfaces"
import type { SorterResult } from "ant-design-vue/es/table/interface"
import type { PaginationConfig } from "ant-design-vue/es/pagination"

import { computed, h, onBeforeMount, ref, watch } from "vue"
import { DashOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { noop, useInfiniteScroll } from "@vueuse/core"
import { type TableProps } from "ant-design-vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useBufferSelection } from "@/package/hooks"

import { useBankAccountStore } from "../../store/bank-account.store"

import { useColumns } from "./use-columns"

const emit = defineEmits(["openDetail", "openEdit", "openCreate"])
const isAdoptable = useAdoptable()
const { columns } = useColumns()
const store = useBankAccountStore()
const { idList, paginationTotal } = storeToRefs(store)
const {
  isAllSelected,
  selectedCount,
  selectedIdList,
  onSelect,
  onSelectAll,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys
} = useBufferSelection(idList, paginationTotal)

const selectedKeys = ref(new Array<string | number>())
const rowSelectionConfig = computed(() => ({
  columnWidth: 32,
  onChange: onSelect,
  preserveSelectedRowKeys: true,
  selectedRowKeys: selectedIdList,
  onSelectAll,
  getCheckboxProps: (record: BankAccountSummaryRecord) => ({
    disabled: record.name === "Неактивные",
    name: record.name
  })
}))

const clearSelected = () => {
  selectedKeys.value = []
}
const selectAllAction = () => {
  selectedKeys.value = store.idList
}

watch(
  () => store.idList,
  (idList) => {
    selectedKeys.value = selectedKeys.value.filter((selectedKey) => idList.includes(+selectedKey))
  }
)

const customRow: TableProps["customRow"] = (data: BankAccountSummary) => ({
  onClick: () => {
    if (data.id === -1) return
    emit("openDetail", data.id)
  }
})

const tableWrapper = ref<HTMLElement | null>(null)
const stickyConfig = {
  offsetHeader: 0,
  offsetScroll: 0,
  getContainer: () => tableWrapper.value
}
useInfiniteScroll(tableWrapper, store.loadBufferNextPage, {
  distance: 1000,
  interval: 2000,
  canLoadMore: () =>
    !store.isLoadedFull && store.listingApiError === null && !store.isSortingLoading
})

const deleteAction = (id: number) => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить запись?",
    onOk: () =>
      store
        .deleteOne(id)
        .then(() => {
          push.success({ message: "Удалено" })
        })
        .catch(noop)
  })
}

onBeforeMount(async () => {
  await store.getBankAccountSorting()
})

defineExpose({
  selectedKeys,
  isAllSelected,
  selectAllAction,
  clearSelected,
  selectedCount,
  toggleAllSelected,
  unselectAllAction,
  excludeKeysMode,
  currentModeKeys
})

const rowClassName = (record: BankAccountSummaryRecord) => {
  return record.key === -1 ? "non-clickable-row" : ""
}

const onRow = (record: BankAccountSummaryRecord) => {
  if (record.key === -1) {
    return {
      onClick: () => {}
    }
  }
  return {}
}
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%', scrollbarGutter: 'stable' }">
    <ATable
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="stickyConfig"
      class="custom-table"
      :row-class-name="rowClassName"
      :loading="store.isListLoading"
      :columns="columns"
      :data-source="store.formattedListing"
      :show-header="!isAdoptable"
      :row-selection="rowSelectionConfig"
      :on-row="onRow"
      :pagination="false"
      row-key="id"
      :custom-row="customRow"
      :show-sorter-tooltip="false"
      @change="
        async (
          pagination: PaginationConfig,
          filters: Record<string, (string | number)[]>,
          sorter: SorterResult
        ) => {
          await store.setSortOrder(sorter)
          await store.saveBankAccountSorting()
        }
      "
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <ADropdown
            :disabled="!(record as BankAccountSummaryRecord).editable"
            :overlay-style="{ zIndex: 100 }"
            placement="bottomRight"
            arrow
            :trigger="['hover']"
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :disabled="!(record as BankAccountSummaryRecord).editable"
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (record as BankAccountSummaryRecord).id)"
                >
                  Редактировать
                </AMenuItem>
                <AMenuItem
                  danger
                  :disabled="store.isDeleting || !(record as BankAccountSummaryRecord).editable"
                  :icon="h(DeleteOutlined)"
                  @click="() => deleteAction((record as BankAccountSummaryRecord).id)"
                >
                  Удалить
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder
          create-text="Добавить счет"
          :show-create-button="store.isResourceEmpty || !store.paginationTotal"
          @openCreate="() => emit('openCreate')"
        />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon) {
  border: 1px solid black;
  border-radius: 0;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon:before),
:deep(.ant-table-wrapper .ant-table-row-expand-icon:after) {
  color: black;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}

:deep(td.ant-table-cell.ant-table-cell-ellipsis.ant-table-column-sort) {
  background-color: unset !important;
}
</style>
