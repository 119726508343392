<script setup lang="ts">
import type {
  IPorfileStatus,
  JwtPayloadWithScopes,
  UnavailableProfileErrorPayload
} from "../../interfaces"

import { onMounted, provide, shallowRef, watch } from "vue"
import dayjs from "dayjs"
import { setUser } from "@sentry/vue"
import { set } from "@vueuse/core"
import { useJwt } from "@vueuse/integrations/useJwt"

import { hasAuth, ApiForbiddenResponse, getAccessToken } from "@/package/api-client"

import { useProfile } from "../../hooks/use-profile"
import { ProfileStatus } from "../../interfaces"

import {
  DECREMENT_ATTEMPTS_COUNT,
  defaultProfile,
  PROFILE,
  PROFILE_LOADING,
  PROFILE_REFRESH,
  PROFILE_RESET,
  PROFILE_STATUS
} from "./constants"

const { profile, error, isLoading, refreshAsync } = useProfile()

const profileStatus = shallowRef<IPorfileStatus>(Object.assign({}, defaultProfile))
watch(
  [error, profile],
  ([error, data]) => {
    // noinspection SuspiciousTypeOfGuard
    if (error instanceof ApiForbiddenResponse) {
      const errorPayload = (error as ApiForbiddenResponse<UnavailableProfileErrorPayload>).payload
      if (errorPayload) {
        const status = ProfileStatus.UNCONFIRMED
        const deltedAt = dayjs.unix(errorPayload.deletion_date).toDate()
        const attempts = errorPayload.attempts
        set(profile, errorPayload.data)
        profileStatus.value = { status, deltedAt, attempts, asAdmin: false, authAsUser: false }
      }
    } else if (data !== undefined) {
      setUser({
        email: data.email,
        username: [data.last_name, data.first_name, data.middle_name].filter(Boolean).join(" ")
      })
      const status = ProfileStatus.ACTIVE
      const deltedAt = null
      const token = getAccessToken()
      const { payload } = useJwt(token!)

      profileStatus.value = {
        status,
        deltedAt,
        attempts: null,
        asAdmin: (payload?.value as JwtPayloadWithScopes)?.scopes.includes("admin"),
        authAsUser: (payload?.value as JwtPayloadWithScopes)?.scopes.includes("authorise.as-user")
      }
    }
  },
  { deep: true }
)

provide(PROFILE, profile)
provide(PROFILE_REFRESH, refreshAsync)
provide(PROFILE_RESET, () => {
  profileStatus.value = Object.assign({}, defaultProfile)
  profile.value = undefined
})
provide(PROFILE_STATUS, profileStatus)
provide(DECREMENT_ATTEMPTS_COUNT, () => {
  const status = Object.assign({}, profileStatus.value)
  if (status.attempts) {
    status.attempts -= 1
  }
  profileStatus.value = status
})
provide(PROFILE_LOADING, isLoading)
onMounted(async () => {
  if (hasAuth()) {
    await refreshAsync()
  }
})
</script>

<template>
  <slot />
</template>
