import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { computed, type Ref } from "vue"
import { h, ref, watch } from "vue"
import { Flex, Tag, TypographyText } from "ant-design-vue"

import { UserRole, UserRoleDict, type UserSummary, useUserStore } from "@/modules/admin"
import { AText, renderPlaceholder, useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import { UserEmailStatus, UserEmailStatusDict, UserStatus, UserStatusDict } from "@/modules/admin"
import { SortOrder, SortOrderInternal } from "@/interfaces"

const defaultSizes = {}
const adoptedColumns: ColumnsType<UserSummary> = [
  {
    key: "id",
    customRender: ({ record }) =>
      h(Flex, { vertical: true }, () => [
        h(
          Flex,
          {
            style: { width: "100%" },
            justify: "space-between"
          },
          () => [
            h(TypographyText, { strong: true }, () => [
              record.middle_name,
              " ",
              record.first_name,
              " ",
              record.last_name
            ]),
            h(Tag, { color: record.status === UserStatus.active ? "success" : "error" }, () =>
              viewValueOrDefault(UserStatusDict[record.status as UserStatus])
            )
          ]
        ),
        h(AText, { type: "secondary", style: { marginTop: "8px" }, content: record.email })
      ])
  }
]
const defaultColumns = (): ColumnsType<UserSummary> => {
  const store = useUserStore()

  return [
    {
      title: "Фамилия",
      dataIndex: "last_name",
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "last_name") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Имя",
      dataIndex: "first_name",
      ellipsis: {
        showTitle: true
      },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "first_name") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Отчество",
      dataIndex: "middle_name",
      ellipsis: {
        showTitle: true
      },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "middle_name") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "E-mail",
      dataIndex: "email",
      ellipsis: {
        showTitle: true
      },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "email") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Роль",
      dataIndex: "role",
      ellipsis: {
        showTitle: true
      },
      customRender: ({ value }: { value: UserRole }) =>
        h(AText, () => viewValueOrDefault(UserRoleDict[value])),
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder || store.sortBy !== "role") {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Статус",
      dataIndex: "status",
      ellipsis: {
        showTitle: true
      },
      customRender: ({ value }) =>
        h(Tag, { color: value === UserStatus.active ? "success" : "error" }, () => {
          const computedValue = value === UserStatus.active ? "Активен" : "Заблокирован"
          return viewValueOrDefault(computedValue)
        })
    },
    {
      title: "Подтверждение почты",
      dataIndex: "status_email",
      colSpan: 2,
      align: "left",
      ellipsis: {
        showTitle: true
      },
      customRender: ({ value }) =>
        h(Tag, { color: value === UserEmailStatus.active ? "success" : "error" }, () =>
          viewValueOrDefault(UserEmailStatusDict[value as UserEmailStatus])
        )
    },
    {
      title: "",
      key: "action",
      width: 64
    }
  ]
}

export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns()
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
