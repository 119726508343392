<script setup lang="ts">
import { InputNumber, theme } from "ant-design-vue"
import { useSlots } from "vue"

const { token } = theme.useToken()
const slots: Record<keyof typeof InputNumber.slots, unknown> = useSlots()
</script>

<template>
  <InputNumber :style="{ width: '100%' }">
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </InputNumber>
</template>

<style scoped>
:deep(.ant-input-number-group-addon) {
  background-color: v-bind("token.colorBgBase");
}
</style>
