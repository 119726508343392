import { useAxios } from "@vueuse/integrations/useAxios"
import { noop } from "@vueuse/core"

import { client, useApiError, useApiResponseStatus } from "@/package/api-client"

export const usePasswordResetRequest = () => {
  const { execute, isLoading, error, response } = useAxios(
    "/security/request-password-reset",
    { method: "POST" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const createPasswordResetRequest = (email: string, onSuccess: typeof noop) =>
    execute({ data: { email } }).then(onSuccess).catch(noop)

  return {
    createPasswordResetRequest,
    isLoading,
    apiError: useApiError(error),
    apiStatus: useApiResponseStatus(response, error)
  }
}
