import type { validateInfos } from "./use-form"

import { computed, type MaybeRef, readonly, shallowRef, unref } from "vue"
import { get, set } from "@vueuse/core"
import cloneDeep from "lodash-es/cloneDeep"

export const useRequestFieldErrors = <T extends object>() => {
  const fieldErrors = shallowRef<Record<Partial<keyof T>, Array<string>> | null>(null)
  const cleanFieldError = (path: keyof T) => {
    const errors = get(fieldErrors)
    if (errors) {
      set(fieldErrors, Object.fromEntries(Object.entries(errors).filter(([key]) => key !== path)))
    }
  }

  const setFieldErrors = (errors: Record<Partial<keyof T>, Array<string>>) =>
    set(fieldErrors, errors)
  const resetFieldErrors = () => set(fieldErrors, null)

  return {
    fieldErrors: readonly(fieldErrors),
    resetFieldErrors,
    setFieldErrors,
    cleanFieldError
  }
}

export const useValidateInfoWithFieldMessages = <T extends object>(
  validateInfos: validateInfos,
  fieldErrors: MaybeRef<T>
) =>
  computed(() => {
    const infos = unref(validateInfos)
    const fieldErrs = unref(fieldErrors)

    const result = cloneDeep(infos)
    const fieldKeys = Object.keys(fieldErrs ?? {}) as Array<keyof typeof fieldErrs>

    for (const key of fieldKeys) {
      if (fieldErrs[key]) {
        if (key in result) {
          result[key as string].validateStatus = "warning"
          result[key as string].help = (result[key as string].help ?? []).concat(fieldErrs[key])
        } else {
          result[key as string] = {
            validateStatus: "warning",
            help: [fieldErrs[key]]
          }
        }
      }
    }

    return result
  })
