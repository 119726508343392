<script setup lang="ts">
import {
  LoginForm,
  injectAdminAuthenticatedAction,
  injectUserAuthenticatedAction
} from "@/modules/auth"
import { registerRoute } from "@/router"
import { injectProfileLoading, injectProfileRefresh, UserRole } from "@/modules/profile"
import { ApiForbiddenResponse } from "@/package/api-client"

defineProps({
  logoSize: {
    type: Number,
    default: 0
  }
})
const userAuthenticatedAction = injectUserAuthenticatedAction()
const adminAuthenticatedAction = injectAdminAuthenticatedAction()
const refreshProfile = injectProfileRefresh()
const profileLoading = injectProfileLoading()

const loginSuccessAction = async () => {
  try {
    const profile = await refreshProfile()
    if (profile) {
      if (profile.role === UserRole.Admin || profile.role === UserRole.ServiceManager) {
        adminAuthenticatedAction()
      } else {
        userAuthenticatedAction()
      }
    }
  } catch (e) {
    if (e instanceof ApiForbiddenResponse) {
      userAuthenticatedAction()
    }
  }
}
</script>

<template>
  <AFlex vertical :style="{ flexGrow: 1 }" justify="center">
    <ATypographyTitle :style="{ marginBottom: '8px', textAlign: 'center' }"
      >С возвращением
    </ATypographyTitle>
    <div :style="{ textAlign: 'center' }">
      <AText variant="lg">Вы еще не зарегистрированы?{{ " " }}</AText>
      <ALink variant="lg" :to="{ name: registerRoute }"> Создать новый аккаунт</ALink>
    </div>
    <LoginForm
      :loading="profileLoading"
      :style="{ marginTop: '8px' }"
      @success="loginSuccessAction"
    />
  </AFlex>
  <AFlex :style="{ height: `${logoSize}px`, marginTop: '16px' }" justify="center" align="end">
    <ALink to="/docs/SAMO_privacy_policy.pdf" target="_blank" underline type="secondary">
      Политика конфиденциальности
    </ALink>
  </AFlex>
</template>
