import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { OperationBankIntegrationEdit } from "@/modules/operation"

export const useBankIntegrationSync = () => {
  const { execute, data, isLoading, error } = useAxios<OperationBankIntegrationEdit>(
    "/integrations/banks/integration/{integration_id}/sync",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const syncBankIntegration = async (integration_id: number) => {
    return await execute(`/integrations/banks/integration/${integration_id}/sync`)
  }

  return {
    syncRequestData: data,
    syncBankIntegration,
    syncBankDataPending: isLoading,
    syncBankDataError: useApiError(error)
  }
}
