import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { push } from "notivue"

import { ApiBadReqResponse, client } from "@/package/api-client"

export const useFileDownload = () => {
  const { execute, isLoading, error } = useAxios(
    "/budgets/:id/details/as-file",
    { method: "GET", responseType: "blob" },
    client,
    { immediate: false }
  )

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  watch(errorMessage, () => {
    if (errorMessage.value !== null) {
      push.error({ message: errorMessage.value })
    }
  })

  const executeDownload = async ({ id, to, from }: { id: number; from?: string; to?: string }) => {
    const response = await execute(`/budgets/${id}/details/as-file`, { params: { to, from } })

    const url = URL.createObjectURL(response.data.value)
    const filename =
      response.response.value?.headers?.["content-disposition"]
        .split('filename="')[1]
        .split('"')[0] || "file.xlsx"

    const link = document.createElement("a")
    link.href = url
    link.download = filename
    link.style.visibility = "hidden"
    link.style.position = "absolute"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    executeDownload,
    isLoadingFile: isLoading
  }
}
