import { computed, reactive } from "vue"

import type { ProductEditDTO, ProductEditForm } from "@/modules/product"
import { parseThousands } from "@/package/util"

export const useFormState = () => {
  const formState = reactive<ProductEditForm>({
    id: 0,
    article: null,
    type: null,
    name: null,
    description: null,
    counterparty_id: null,
    counterparty_name: null,
    tax_type: null,
    nds: null,
    unit_type: null,
    self_cost: null,
    cost: null,
    quantity: null
  })
  const requestData = computed((): ProductEditDTO => {
    const {
      id,
      name,
      article,
      type,
      description,
      counterparty_id,
      tax_type,
      nds,
      self_cost,
      cost,
      unit_type,
      quantity
    } = formState

    return {
      id,
      name: name ?? null,
      article: article ?? null,
      type: type ?? null,
      description: description ?? null,
      counterparty_id: counterparty_id ?? null,
      cost: cost ? +parseThousands(cost) : null,
      self_cost: self_cost ? +parseThousands(self_cost) : null,
      tax_type: tax_type ?? null,
      unit_type: unit_type ?? null,
      nds_type: nds ?? null,
      quantity: quantity
    }
  })

  return {
    formState,
    requestData
  }
}
