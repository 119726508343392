import { computed, reactive } from "vue"

import type { BudgetFormInterface } from "@/modules/budget"

import { FORM_DEFAULT_STATE } from "./constants"

export const useFormRuleset = () =>
  computed(() => ({
    name: [{ required: true, message: "Необходимо указать название", whitespace: true }],
    period: [{ required: true, message: "Необходимо указать период" }],
    comment: [{ max: 4096, message: "Привышенно допустимое количество символов" }]
  }))

export const useBudgetForm = () => {
  const formState = reactive<BudgetFormInterface>(Object.assign({}, FORM_DEFAULT_STATE))

  const formData = computed(() => {
    const {
      id,
      name,
      legalEntityId,
      period,
      projectId,
      comment,
      category_operation_ids,
      bank_account_id
    } = formState as BudgetFormInterface

    return {
      id,
      name,
      legalEntityId,
      period,
      projectId,
      comment,
      category_operation_ids,
      bank_account_id
    }
  })
  return {
    formData,
    formState
  }
}
