import { useRequest } from "vue-request"

import { type ApiSuccessResponse, client } from "@/package/api-client"
import type { TurnoverDetail } from "@/modules/legal-entity"

type TurnoverDataList = Array<TurnoverDetail>
export const useTurnover = () =>
  useRequest<TurnoverDataList>(
    () =>
      client
        .get<ApiSuccessResponse<TurnoverDataList>>("/legal-entities/turnover-rates")
        .then((response) => response.data.data),
    {
      cacheKey: "/legal-entities/turnover-rates",
      staleTime: -1
    }
  )
