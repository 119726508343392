import type { BankAuthtype } from "../../../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useAuthtypeGet = () => {
  const { execute, isLoading, data, error } = useAxios<BankAuthtype>(
    "/integrations/banks/{bank}/authentication/url",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getAuthType = async (bank: string) =>
    await execute(`/integrations/banks/${bank}/authentication/url`)

  return {
    authTypeResponse: data,
    getAuthType,
    isLoading,
    error: useApiError(error)
  }
}
