import type { BudgetRecord } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError, useApiResponseStatus } from "@/package/api-client"

interface ReferenceListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: BudgetRecord[]
}

export const useBudgetList = () => {
  const { execute, isLoading, error, data, response } = useAxios<ReferenceListResponse>(
    "/budgets",
    { method: "GET" },
    client,
    { immediate: false, resetOnExecute: true }
  )

  const listingApiError = useApiError(
    error,
    "Не удалось получить планы бюджетирования, обратитесь в поддержку."
  )
  const responseStatus = useApiResponseStatus(response, error)
  const executeListing = async (params: { offset?: number; limit?: number; query?: string }) =>
    await execute({ params })

  return {
    responseStatus,
    listingApiError,
    isListingLoading: isLoading,
    budgetListingResponse: data,
    executeListing
  }
}
