<script setup lang="ts">
import { computed } from "vue"

import { useCategoryActionTypes } from "../hooks/use-category-action-types"

const { data } = useCategoryActionTypes()
const options = computed(() =>
  (data.value ?? []).map(({ slug, value }) => ({ value: slug, label: value }))
)
</script>

<template>
  <ARadioGroup :options="options" button-style="solid" option-type="button" />
</template>

<style scoped>
.ant-radio-group:deep(.ant-radio-button-wrapper-checked) {
  font-weight: 700;
}
</style>
