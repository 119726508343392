import { createSharedComposable, useMediaQuery } from "@vueuse/core"

import { useBpMax } from "@/package/ui-kit"

export const useAdoptable = createSharedComposable(() => useBpMax("sm"))

export const useTableResolution = createSharedComposable(() => useBpMax("md"))

export const useLargeTabletResolution = createSharedComposable(() => useBpMax("lg"))

export const useSmallDesktopResolution = createSharedComposable(() =>
  useMediaQuery("(max-width: 1600px)")
)

export const useMobileBp = createSharedComposable(() => useMediaQuery("(max-width: 435px)"))
