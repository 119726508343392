import { Modal } from "ant-design-vue"
import { h } from "vue"
import { ExclamationCircleOutlined } from "@ant-design/icons-vue"
import { noop } from "@vueuse/core"

interface Props
  extends Pick<
    Parameters<typeof Modal.confirm>[0],
    "content" | "okText" | "cancelText" | "onOk" | "centered" | "icon" | "title"
  > {}

export const DeleteConfirm = ({
  content = "Вы действительно хотите безвозвратно удалить?",
  okText = "Удалить",
  cancelText = "Отмена",
  title = "Удаление данных",
  onOk = noop,
  centered = true,
  icon = h(ExclamationCircleOutlined)
}: Props = {}) => {
  const modal = Modal.confirm({
    autoFocusButton: null,
    maskClosable: true,
    class: "ant-modal-confirm--custom",
    title,
    icon,
    content,
    centered,

    onOk: async (...args) => {
      modal.update({
        keyboard: false,
        maskClosable: false,
        cancelButtonProps: { block: true, disabled: true }
      })
      await onOk(...args)
    },
    okText,
    okButtonProps: { block: true, danger: true },

    cancelText,
    cancelButtonProps: { block: true }
  })
}
