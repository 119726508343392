import { client } from "@/package/api-client"
import type { OperationCategoryEdit } from "@/modules/operation-category"

interface Response {
  success: boolean
  data: OperationCategoryEdit
}

export const operationCategoryDetailGet = (id: string | number) =>
  client.get<Response>(`/operation-categories/${id}`).then(({ data }) => data.data)
