<script setup lang="ts">
import type { LegalEntityData } from "../interfaces"

import { debounce } from "lodash-es"
import { computed, h, onMounted, type PropType, ref } from "vue"
import { Flex, theme, TypographyText } from "ant-design-vue"
import { useToggle } from "@vueuse/core"

import { ApiNotFoundResponse } from "@/package/api-client"

import { useLegalDataSearch } from "../hooks/use-legal-data-search"

defineProps({
  isSearchDisabled: {
    type: Boolean,
    default: false
  }
})

const selectedEntity = defineModel({
  type: Object as PropType<LegalEntityData>
})

const { searchResult, executeSearch, isLoading, error } = useLegalDataSearch()
const options = computed(() =>
  (searchResult.value?.data ?? []).map(({ organization_name, itn, legal_address, ...dto }) => ({
    value: `${itn} ${organization_name} ${legal_address}`,
    data: {
      organization_name,
      itn,
      legal_address,
      ...dto
    }
  }))
)

const [isOpened, toggleOpened] = useToggle(false)
const query = ref("")
const selectedEntityQuery = computed(() => {
  let result = null
  if (selectedEntity.value) {
    result = `${selectedEntity.value.itn}, ${selectedEntity.value.organization_name}`
  }
  return result
})

const { token } = theme.useToken()

const searchAction = debounce(async () => {
  toggleOpened(false)
  await executeSearch(query.value)
  toggleOpened()
}, 3000)

const selectAction = (_: string, { data }: { data: LegalEntityData }) => {
  toggleOpened(false)
  selectedEntity.value = data
}

const blurAction = () => {
  toggleOpened(false)
  if (selectedEntityQuery.value !== null && query.value !== selectedEntityQuery.value) {
    query.value = selectedEntityQuery.value
  }
}
onMounted(blurAction)

const AutoCompleteOption = ({ option }: { option: LegalEntityData }) =>
  h(Flex, { vertical: true, style: { overflow: "hidden" } }, () => [
    h(TypographyText, {}, () => option.organization_name),
    h(
      TypographyText,
      { type: "secondary", style: { textOverflow: "ellipsis", overflow: "hidden" } },
      () => `${option.itn} ${option.legal_address}`
    )
  ])
</script>

<template>
  <AAutoComplete
    id="legal-entity-search"
    v-model:value="query"
    :open="isOpened"
    :default-active-first-option="false"
    size="large"
    :disabled="isSearchDisabled"
    :options="options"
    @focus="() => toggleOpened(true)"
    @focusout="searchAction.cancel"
    @select="selectAction"
    @blur="blurAction"
    @search="searchAction"
  >
    <template #option="{ data }">
      <AutoCompleteOption :option="data" />
    </template>
    <AInputSearch
      placeholder="Поиск по ИНН или названию компании"
      size="large"
      :loading="isLoading"
      :status="error instanceof ApiNotFoundResponse && 'warning'"
      @keydown.enter.prevent
      @keyup.enter.prevent
      @search="searchAction.flush"
    />
  </AAutoComplete>
  <div v-if="error instanceof ApiNotFoundResponse" :style="{ color: token.colorWarning }">
    Данные об организации не получены
  </div>
</template>
