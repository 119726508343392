import type { FormValidator } from "./types"

import { parsePhoneNumberWithError, validatePhoneNumberLength } from "libphonenumber-js"

export const phoneNumberValidator: FormValidator = (rule, value) =>
  new Promise((resolve, reject) => {
    if (!rule.required && value.length === 0) {
      resolve(undefined)
      return
    }
    if (value && value.length) {
      const lengthValidity = validatePhoneNumberLength(value, "RU")
      if (lengthValidity !== undefined) {
        switch (lengthValidity) {
          case "INVALID_COUNTRY":
            reject("Пожалуйста, введите телефон")
            break
          case "INVALID_LENGTH":
            reject("Необходимо ввести номер")
            break
          case "NOT_A_NUMBER":
            reject("Пожалуйста, введите телефон")
            break
          case "TOO_LONG":
            reject("Пожалуйста, введите телефон")
            break
          case "TOO_SHORT":
            reject("Пожалуйста, введите телефон")
            break
        }
      } else {
        try {
          const phoneNumber = parsePhoneNumberWithError(value, "RU")

          if (phoneNumber.country !== "RU") {
            reject("Введен не код РФ")
          }

          if (phoneNumber && phoneNumber.isValid() && phoneNumber.isPossible()) {
            resolve(undefined)
          } else {
            reject("Пожалуйста, введите телефон")
          }
        } catch (e) {
          reject("Пожалуйста, введите телефон")
        }
      }
    } else {
      reject("Пожалуйста, введите телефон")
    }
  })
