import type { BudgetCreateDTO, BudgetEditDTO, BudgetFormInterface } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useBudgetCreate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: BudgetEditDTO }
  >("/budgets", { method: "POST" }, client, { immediate: false })

  const transformFormDataToRequest = (data: BudgetFormInterface): BudgetCreateDTO => {
    return {
      name: data.name || null,
      from: data.period?.[0]?.format("YYYY-MM-DD") || null,
      to: data.period?.[1]?.format("YYYY-MM-DD") || null,
      comment: data.comment || null,
      project_id: data.projectId || null,
      legal_entity_id: data.legalEntityId || null,
      category_operation_ids: data.category_operation_ids ?? [],
      bank_account_id: data.bank_account_id || null
    }
  }
  const createBudget = async (formData: BudgetFormInterface) => {
    const data = transformFormDataToRequest(formData)
    await execute("/budgets", { data })
    return data
  }
  return {
    createBudget,
    isCreating: isLoading,
    createError: useApiError(error),
    isCreateFinished: isFinished
  }
}
