import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { push } from "notivue"

import { ApiBadReqResponse, client, useApiError } from "@/package/api-client"
import type { SelectListAction } from "@/interfaces"

export const useBudgetListDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { ids: Array<number> }>(
    "/budgets",
    { method: "DELETE" },
    client,
    {
      immediate: false
    }
  )

  const budgetListDeleteErrorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  watch(budgetListDeleteErrorMessage, (message) => {
    if (message !== null) {
      push.error({ message })
    }
  })

  const requestListDelete = async (data: SelectListAction<number | string, { query?: string }>) =>
    await execute({ data })

  return {
    requestListDelete,
    budgetDeleteListError: useApiError(error),
    budgetListDeleting: isLoading
  }
}
