import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { ProjectEdit } from "@/modules/project"

export const useProjectEdit = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: ProjectEdit }
  >("/projects", { method: "PATCH" }, client, { immediate: false })

  const transformFormDataToRequest = (data: ProjectEdit): ProjectEdit => ({
    id: data.id,
    name: data.name || null
  })
  const editProject = async (formData: ProjectEdit) => {
    const data = transformFormDataToRequest(formData)
    await execute(`/projects/${data.id}`, { data })
  }
  return {
    editProject,
    isEditing: isLoading,
    editError: useApiError(error),
    isEditingFinished: isFinished
  }
}
