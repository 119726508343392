<script setup lang="ts">
import type { LegalEntityInfoFields, LegalEntityRequestData } from "../../interfaces"
import type { Dayjs } from "dayjs"

import { computed, onMounted, type PropType, reactive, ref, watch } from "vue"
import { Form } from "ant-design-vue"
import { noop, useDebounceFn } from "@vueuse/core"
import { push } from "notivue"
import dayjs from "dayjs"
import { InfoCircleOutlined } from "@ant-design/icons-vue"

import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"
import { usePopupContainerGetter } from "@/package/ui-kit"

import { useLegalEntityEdit } from "../../hooks/use-legal-entity-edit"
import GenderSelect from "../../../legal-entity/components/GenderSelect.vue"
import CitizenshipSelect from "../../../legal-entity/components/CitizenshipSelect.vue"
import DocumentSelect from "../../../legal-entity/components/DocumentSelect.vue"
import TaxpayerSelect from "../../../legal-entity/components/TaxpayerSelect.vue"
import PayerTariffSelect from "../../../legal-entity/components/PayerTariffSelect.vue"

import { useRuleset } from "./use-ruleset"

const props = defineProps({
  profile: {
    type: [Object, undefined] as PropType<LegalEntityRequestData | undefined>,
    default: () => undefined
  },

  isLoading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["success"])

const getContainer = (): HTMLElement | null =>
  document.querySelector(
    ".entrepreneur-drawer .ant-drawer-body div[data-overlayscrollbars-contents]"
  )

const popupContainerGetter = usePopupContainerGetter(getContainer)

const formRef = ref<typeof Form | null>(null)

const formState = reactive<LegalEntityInfoFields>({
  birth_date: "",
  gender: undefined,
  citizenship_code: undefined,
  kbk: "",
  kpp: "",
  ogrnip: "",
  oktmo: "",
  document_type_code: undefined,
  document_series: "",
  document_number: "",
  taxpayer_category_code: undefined,
  payer_tariff_code: undefined,
  email: "",
  phone: "",
  organization_name: "",
  head_fullname: ""
})
const requestData = computed(() => {
  return {
    ...formState,
    birth_date: formState.birth_date ? dayjs(formState.birth_date).format("YYYY-MM-DD") : null,
    phone: formState.phone.replace(/\D/g, "")
  }
})

const resetAction = useDebounceFn(() => {
  if (props.profile) {
    const {
      phone,
      email,
      kbk,
      kpp,
      ogrnip,
      birth_date,
      taxpayer_category_code,
      gender,
      document_type_code,
      citizenship_code,
      payer_tariff_code,
      ...rest
    } = props?.profile.data?.info || {}

    const formattedFormState = {
      ...rest,
      birth_date: ref<Dayjs | null>(birth_date ? dayjs(birth_date, "DD-MM-YYYY") : null),
      kbk: kbk || "",
      kpp: kpp || "",
      ogrnip: ogrnip || "",
      phone: phone,
      email: email,
      organization_name: props?.profile?.data?.legal_entity?.organization_name,
      head_fullname: props?.profile?.data?.legal_entity?.head_fullname,
      taxpayer_category_code: taxpayer_category_code || undefined,
      gender: gender || undefined,
      document_type_code: document_type_code || undefined,
      citizenship_code: citizenship_code || undefined,
      payer_tariff_code: payer_tariff_code || undefined
    }

    Object.assign(formState, formattedFormState)
  }
}, 100)
onMounted(resetAction)
watch(() => props.profile, resetAction)

const ruleSet = useRuleset()

const { validate, validateInfos } = useForm(formState, ruleSet, {
  watchValidateOptions: { validateFirst: true }
})

const { editProfile, error, isLoading: isLoadingEdit } = useLegalEntityEdit()
watch(error, (err) => {
  if (err) {
    console.log(err)
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
const submitAction = () =>
  validate()
    .then(() =>
      editProfile(requestData.value, () => {
        push.success({ message: "Профиль обновлен." })
        emit("success")
      })
    )
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({ isLoadingEdit, resetAction, submitAction })
</script>

<template>
  <ASkeleton v-if="props.isLoading" active :paragraph="{ rows: 8 }" />
  <KeepAlive>
    <Form
      v-if="!props.isLoading"
      ref="formRef"
      name="edit-profile"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      hide-required-mark
      @submit.prevent="submitAction"
    >
      <ATypographyText type="secondary"
        ><InfoCircleOutlined :style="{ marginRight: '8px' }" />САМО использует информацию из этого
        блока для автоматического формирования бухгалтерских отчетов для ИП. Если поля оставить
        пустыми - Вам необходимо будет указывать информацию в отчетах вручную</ATypographyText
      >
      <ADivider :style="{ marginTop: '24px', marginBottom: '24px' }" />
      <AFormItem label="Контактное лицо" name="head_fullname">
        <AInput
          v-model="formState.head_fullname"
          size="large"
          placeholder="Введите контактное лицо"
        />
      </AFormItem>
      <AFormItem label="Название организации" name="organization_name">
        <AInput
          v-model="formState.organization_name"
          size="large"
          placeholder="Введите название организации"
        />
      </AFormItem>
      <AFormItem
        label="Номер телефона"
        name="phone"
        v-bind="validateInfos?.phone"
        placeholder="Введите номер телефона"
      >
        <APhoneInput
          v-model="formState.phone"
          size="large"
          @blur="() => validate('phone', { trigger: 'blur' }).catch(noop)"
        />
      </AFormItem>
      <AFormItem label="E-mail" name="email">
        <AInput
          v-model="formState.email"
          size="large"
          placeholder="Введите E-mail"
          @change="
            () => {
              if (validateInfos?.email.validateStatus === 'error') {
                validate('email', { trigger: 'blur' }).catch(noop)
              }
            }
          "
          @blur="() => validate('email', { trigger: 'blur' }).catch(noop)"
        />
      </AFormItem>
      <AFormItem label="Дата рождения" name="birth_date">
        <ADatePicker v-model:value="formState.birth_date" size="large" :style="{ width: '100%' }" />
      </AFormItem>
      <AFormItem label="Пол" name="gender">
        <GenderSelect
          v-model:value="formState.gender"
          size="large"
          :get-popup-container="popupContainerGetter"
        />
      </AFormItem>
      <AFormItem label="Гражданство" name="citizenship_code">
        <CitizenshipSelect
          v-model:value="formState.citizenship_code"
          size="large"
          :get-popup-container="popupContainerGetter"
        />
      </AFormItem>
      <AFormItem label="Тип документа" name="document_type_code">
        <DocumentSelect
          v-model:value="formState.document_type_code"
          size="large"
          :get-popup-container="popupContainerGetter"
        />
      </AFormItem>
      <AInputGroup size="large">
        <ARow :gutter="8">
          <ACol :span="8">
            <AFormItem label="Серия документа" name="document_series">
              <AInputGroup size="large" compact>
                <AInput v-model.trim="formState.document_series" size="large" placeholder="Серия" />
              </AInputGroup>
            </AFormItem>
          </ACol>
          <ACol :span="16">
            <AFormItem label="Номер документа" name="document_number">
              <AInputGroup size="large" compact>
                <AInput v-model.trim="formState.document_number" size="large" placeholder="Номер" />
              </AInputGroup>
            </AFormItem>
          </ACol>
        </ARow>
      </AInputGroup>
      <AFormItem label="КБК" name="kbk" v-bind="validateInfos?.kbk">
        <AInput v-model.trim="formState.kbk" size="large" placeholder="Введите КБК" />
      </AFormItem>
      <AFormItem label="КПП" name="kpp" v-bind="validateInfos?.kpp">
        <AInput v-model.trim="formState.kpp" size="large" placeholder="Введите КПП" />
      </AFormItem>
      <AFormItem label="ОГРНИП" name="ogrnip" v-bind="validateInfos?.ogrnip">
        <AInput v-model.trim="formState.ogrnip" size="large" placeholder="Введите ОГРНИП" />
      </AFormItem>
      <AFormItem label="ОКТМО" name="oktmo">
        <AInputGroup size="large" compact>
          <AInput v-model.trim="formState.oktmo" size="large" placeholder="Введите ОКТМО" />
        </AInputGroup>
      </AFormItem>
      <AFormItem label="Код категории налогоплательщика" name="taxpayer_category_code">
        <TaxpayerSelect
          v-model:value="formState.taxpayer_category_code"
          size="large"
          :get-popup-container="popupContainerGetter"
        />
      </AFormItem>
      <AFormItem
        label="Код тарифа плательщика"
        name="payer_tariff_code"
        v-bind="validateInfos?.payer_tariff_code"
      >
        <PayerTariffSelect
          v-model:value="formState.payer_tariff_code"
          size="large"
          :get-popup-container="popupContainerGetter"
        />
      </AFormItem>
    </Form>
  </KeepAlive>
</template>
