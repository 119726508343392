import type { ProductDetail, ProductDetailView } from "../interfaces"

import { computed, type Ref } from "vue"

import { formatThousands } from "@/package/util"

import { useTaxTypes } from "./use-tax-types"
import { useUnitTypes } from "./use-unit-types"
import { useProductTypes } from "./use-product-types"

export const useProductDetailView = (product: Ref<ProductDetail | undefined>) => {
  const { data: types } = useProductTypes()
  const { data: units } = useUnitTypes()
  const { data: taxes } = useTaxTypes()
  const formattedProductDetail = computed(() => {
    let result: ProductDetailView | undefined = undefined
    if (product.value) {
      const { ...dto } = product.value
      result = {
        ...dto,
        cost: formatThousands(product?.value?.cost),
        self_cost: formatThousands(product?.value?.self_cost),
        type: types.value?.find(({ slug }) => slug === product.value?.type)?.value,
        unit_type: units.value?.find(({ slug }) => slug === product.value?.unit_type)?.value,
        tax_type: taxes.value?.find(({ slug }) => slug === product.value?.tax_type)?.value,
        counterparty: product.value?.counterparty?.name
      }
    }
    return result
  })
  return formattedProductDetail
}
