import type { TableProps } from "ant-design-vue"
import type { ColumnsType } from "ant-design-vue/es/table"

import { computed, type Ref } from "vue"
import { h, ref, watch } from "vue"
import { Flex, TypographyText } from "ant-design-vue"
import { Mask } from "maska"

import { type Counterparty, useCounterpartiesStore } from "@/modules/counterparty"
import { AText, renderPlaceholder, useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import { SortOrder, SortOrderInternal } from "@/interfaces"

const phoneMask = new Mask({ mask: "+7 (###) ###-##-##" })

const defaultSizes = {
  contact_person: 200,
  phone: 200,
  action: 65
}
const adoptedColumns = [
  {
    key: "id",
    customRender: ({ record }: { record: Counterparty }) =>
      h(Flex, { vertical: true }, () => [
        h(TypographyText, { strong: true, content: viewValueOrDefault(record.name) }),
        h(Flex, { style: { width: "100%", marginTop: "8px" }, justify: "space-between" }, () => [
          h(AText, {
            type: "secondary",
            ellipsis: true,
            content: viewValueOrDefault(record.contact_person)
          }),
          h(AText, {
            type: "secondary",
            content: viewValueOrDefault(
              record.phone ? phoneMask.masked(record.phone) : record.phone
            )
          })
        ])
      ])
  }
]
const defaultColumns = (): ColumnsType<any> => {
  const store = useCounterpartiesStore()

  return [
    {
      title: "Название",
      key: "name",
      dataIndex: "name",
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder,
      width: "max-content",
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder) {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Контактное лицо",
      key: "contact_person",
      dataIndex: "contact_person",
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder,
      width: "max-content"
    },
    {
      title: "Телефон",
      key: "phone",
      dataIndex: "phone",
      colSpan: 2,
      align: "left",
      ellipsis: { showTitle: true },
      customRender: ({ value }) =>
        renderPlaceholder({ value: value ? phoneMask.masked(value) : value }),
      width: "max-content"
    },
    {
      title: "",
      key: "action",
      width: defaultSizes.action
    }
  ]
}
export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns()
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
