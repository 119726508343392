<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue"

import { useAdoptable } from "@/package/ui-kit"
import { formatThousands } from "@/package/util"

import { useBudgetDetailStore } from "./store"
import { useColumns } from "./use-columns"

const isAdoptable = useAdoptable()
const { columns } = useColumns()

const store = useBudgetDetailStore()

const expandedKeys = ref(new Array<number>())
const expandAll = () => {
  expandedKeys.value = Array.from(store.idList)
}
const shrinkAll = () => {
  expandedKeys.value = []
}
const isExpanded = computed(() => expandedKeys.value.length > 0)
const expandAllToggle = () => {
  if (isExpanded.value) {
    shrinkAll()
  } else {
    expandAll()
  }
}

const tableWrapper = ref<HTMLElement | null>(null)
const stickyConfig = {
  offsetHeader: 0,
  offsetScroll: 0,
  getContainer: () => tableWrapper.value
}

onUnmounted(store.$reset)
defineExpose({
  expandAll,
  shrinkAll,
  expandAllToggle,
  isExpanded
})
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%', marginTop: '16px' }">
    <ATable
      v-model:expanded-row-keys="expandedKeys"
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="stickyConfig"
      class="custom-table"
      :loading="store.isListingLoading"
      :columns="columns"
      :data-source="store.listingTree"
      :pagination="false"
      row-key="id"
    >
      <template #headerCell="{ title }">
        <div style="text-align: start; width: 100%">{{ title }}</div>
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'category_name' && record.id < 0">
          <AText strong> {{ record.category_name }} </AText>
        </template>
        <template v-if="column.key.includes('absolut_value')">
          <span :class="record[column.key] < 0 ? 'absolute-difference-value__negative' : ''">{{
            +record[column.key] === 0 ? 0 : formatThousands(record[column.key])
          }}</span>
        </template>
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon) {
  border: 1px solid black;
  border-radius: 0;
}

:deep(.ant-table-wrapper .ant-table-row-expand-icon:before),
:deep(.ant-table-wrapper .ant-table-row-expand-icon:after) {
  color: black;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}

.custom-table:deep(.absolute-difference-value__negative) {
  color: var(--outgoing-operation-color);
}
</style>
