<script setup lang="ts">
import type { OperationFile, OperationFileData } from "../interface"

import { watch, computed, h, type PropType, ref } from "vue"
import { get, set } from "@vueuse/core"
import {
  DeleteOutlined,
  InboxOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons-vue"
import { push } from "notivue"
import { theme } from "ant-design-vue"

import { useFileDownload } from "@/modules/operation"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { AButton } from "@/package/ui-kit"

import { useFileUpload } from "../hooks/use-file-upload"
import { showFormatModal } from "../util"

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
})
const files = defineModel({
  type: Array as PropType<Array<OperationFileData>>,
  default: []
})
const { executeDownload, downloadFileError } = useFileDownload()

const { token } = theme.useToken()

const loadingState = ref(false)
const progressStatus = ref<undefined | "exception">(undefined)
const errorMessage = ref<undefined | string>(undefined)

watch(downloadFileError, (err) => {
  if (err?.message) {
    push.error({ message: err.message })
  }
})

const { loadedData, progress, isLoading, upload, uploadError } = useFileUpload()
watch(uploadError, (err) => {
  if (err) {
    progressStatus.value = "exception"
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
    errorMessage.value = err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE
  }
})

const beforeUpload = async (file: File, fileListArr: FileList) => {
  const maxSize = 5242880

  const limitedFileList = Array.from(fileListArr).slice(0, 10)

  const validFiles = limitedFileList.filter(({ size }: File) => size <= maxSize)
  const invalidFiles = limitedFileList.filter(({ size }: File) => size > maxSize)

  if (validFiles.length === 0 || validFiles.length < limitedFileList.length) {
    errorMessage.value = `Один из файлов: "${invalidFiles.map((item: { name: string }) => item.name).join('", "')}" превышает допустимые - 5 MB`
    if (validFiles.length === 0) {
      return false
    }
  } else {
    errorMessage.value = undefined
  }

  loadingState.value = true

  await upload(validFiles)

  const existingFiles = get(files)
  const convertedArray = loadedData?.value?.data?.uploaded_files
    .filter(
      (item: OperationFile) =>
        !existingFiles.some((existing) => existing.id === item.file_storage_item_id)
    )
    .map((item: OperationFile) => ({
      id: item.file_storage_item_id,
      name: item.file_name,
      url: item.file_url
    }))

  if (convertedArray && convertedArray.length > 0) {
    const resFiles: Array<OperationFileData> = [...existingFiles, ...convertedArray]
    set(files, resFiles)
  }

  return false
}

const fileData = computed(() => (props.disabled ? [] : get(files)))

watch(fileData, async (newFileList) => {
  if (newFileList.length === 0) {
    loadingState.value = false
    errorMessage.value = undefined
  }
})

const remove = (removedFileId: number) => {
  const filteredValues = get(files).filter(({ id }) => id !== removedFileId)
  files.value = filteredValues ?? []
}
const isDisabledUpload = computed(() => props.disabled || (files?.value?.length ?? 0) >= 10)
</script>

<template>
  <AUploadDragger
    :file-list="fileData"
    :disabled="isDisabledUpload"
    :files="fileData"
    name="file"
    multiple
    :before-upload="beforeUpload"
  >
    <p class="ant-upload-drag-icon upload__drag-icon">
      <InboxOutlined style="width: 32px; height: 32px" />
    </p>
    <p class="ant-upload-text" style="letter-spacing: -0.25px">
      Нажмите или перетащите файл для загрузки
    </p>
    <div style="padding: 0 25px; margin-bottom: 10px">
      <AText type="secondary">Файлы до 5MB, не более 10 файлов</AText>
    </div>
    <AFlex :style="{ padding: '0 25px' }" justify="center">
      <AText type="secondary">Поддерживаемые форматы</AText>
      <AButton
        size="small"
        type="text"
        :style="{ color: token.colorPrimary, marginLeft: '8px' }"
        :icon="h(QuestionCircleOutlined)"
        @click.stop="showFormatModal"
      />
    </AFlex>
    <template #itemRender="{ file }">
      <AFlex style="width: 100%; display: flex; justify-content: space-between">
        <AFlex :style="{ maxWidth: '90%' }">
          <PaperClipOutlined />
          <AButton
            class="download-button"
            type="link"
            @click="() => executeDownload(file.id, file.name)"
            >{{ file.name }}</AButton
          >
        </AFlex>
        <AButton
          :disabled="isLoading"
          :icon="h(DeleteOutlined)"
          type="text"
          @click="remove(file.id)"
        />
      </AFlex>
    </template>
    <AFlex :style="{ paddingLeft: '20px' }"
      ><AProgress v-if="loadingState" :percent="progress" size="small" :status="progressStatus"
    /></AFlex>
  </AUploadDragger>
  <AFlex v-if="errorMessage" justify="center" :style="{ marginTop: '10px' }">
    <ATypographyText type="danger">{{ errorMessage }}</ATypographyText></AFlex
  >
</template>

<style scoped>
.upload--visible :deep(.ant-upload-drag) {
  padding: 16px 0;
}

.upload--visible :deep(.ant-upload-drag .ant-upload) {
  padding: 0;
}

.upload__drag-icon {
  margin-bottom: 0 !important;
}

.download-button {
  width: 100%;
}

:deep(.download-button span) {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
