import type { SubscriptionTokenContext } from "centrifuge"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client } from "@/package/api-client"

export const useCentrifugoSubscriptionToken = () => {
  const { execute } = useAxios("/centrifugo/channel", { method: "GET" }, client, {
    immediate: false
  })

  const getSubscriptionToken = async (ctx: SubscriptionTokenContext) => {
    const res = await execute({ params: { name: ctx.channel } })

    const data = await res.response.value?.data
    return data.token
  }

  return {
    getSubscriptionToken
  }
}
