<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue"
import { useRouteQuery } from "@vueuse/router"
import { noop } from "@vueuse/core"
import { TransitionFade } from "@morev/vue-transitions"

import { type FormValidator, passwordValidator } from "@/package/validators"
import { useForm } from "@/package/hooks"

import { usePasswordReset } from "../hooks/use-password-reset"

const emit = defineEmits(["success"])

const { errorMessage, passwordPost, isLoading } = usePasswordReset()

const email = useRouteQuery("email")
const token = useRouteQuery("token")

const formState = reactive({
  password: "",
  password_confirmation: ""
})

const confirmValidator: FormValidator = (_, value: string) =>
  new Promise((resolve, reject) => {
    if (formState.password === value) {
      resolve(undefined)
    } else {
      reject("Пароли должны совпадать")
    }
  })
const requestData = computed(() => ({
  password: formState.password,
  password_confirmation: formState.password_confirmation,
  email: typeof email.value === "string" ? email.value : "",
  token: typeof token.value === "string" ? token.value : ""
}))
const { validate, validateInfos, clearValidate } = useForm(
  requestData,
  reactive({
    email: [{ type: "email", required: true, message: "Email не найден" }],
    token: [{ type: "string", required: true, message: "Ключ восстановления не обнаружен." }],
    password: [{ required: true, asyncValidator: passwordValidator }],
    password_confirmation: [{ required: true, asyncValidator: confirmValidator }]
  })
)
onMounted(() => {
  validate(["email", "token"])
})
const hiddenErrors = computed(() =>
  (validateInfos?.email?.help ?? [])
    .concat(validateInfos?.token?.help ?? [])
    .flatMap((el: unknown) => el)
)

const showPassword = ref(false)
const submitAction = () => {
  clearValidate()
  return validate()
    .then(() => passwordPost(requestData.value, () => emit("success")))
    .catch(noop)
}
</script>

<template>
  <AFlex vertical>
    <AAffix :offset-top="14" :style="{ minHeight: '40px' }">
      <TransitionFade group tag="div" :style="{ display: 'grid', gap: '12px' }">
        <AAlert
          v-for="(error, id) in hiddenErrors"
          :key="id"
          show-icon
          type="error"
          :message="error"
          class="shake"
        />
        <AAlert
          v-if="errorMessage !== null"
          show-icon
          type="error"
          :message="errorMessage"
          class="shake"
        />
      </TransitionFade>
    </AAffix>
    <AForm
      v-enter-to-tab
      :model="formState"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      hide-required-mark
      :style="{ marginTop: '12px' }"
      @submit.prevent="submitAction"
    >
      <AFormItem label="Пароль" name="password" v-bind="validateInfos?.password">
        <AInputPassword
          v-model:visible="showPassword"
          v-model:value="formState.password"
          size="large"
          placeholder="Введите пароль"
        />
      </AFormItem>
      <AFormItem
        label="Подтверждение пароля"
        name="password_confirmation"
        v-bind="validateInfos?.password_confirmation"
      >
        <AInputPassword
          v-model:visible="showPassword"
          v-model:value="formState.password_confirmation"
          size="large"
          :visibility-toggle="false"
          placeholder="Введите пароль повторно"
        >
        </AInputPassword>
      </AFormItem>
      <AButton
        type="primary"
        html-type="submit"
        block
        :style="{ marginTop: '32px' }"
        size="large"
        :loading="isLoading"
        >Сохранить пароль
      </AButton>
    </AForm>
  </AFlex>
</template>
