<script setup lang="ts">
import { injectProfile, injectProfileLoading } from "@/modules/profile"

import ProfileWrapper from "../ProfileWrapper/ProfileWrapper.vue"
import { useProfileView } from "../ProfileDescription/use-profile-view"

const profile = injectProfile()
const isLoading = injectProfileLoading()
const profileView = useProfileView(profile)
</script>

<template>
  <ASpin :spinning="isLoading">
    <ProfileWrapper title="Сформировано" class="content">
      <div class="content-wrapper">
        <AFlex justify="space-between" align="center">
          <ATypographyText>Бюджетов</ATypographyText>
          <ATypographyText class="description">{{ profileView?.budget_count }}</ATypographyText>
        </AFlex>
        <AFlex justify="space-between" align="center">
          <ATypographyText>Отчетов</ATypographyText>
          <ATypographyText class="description">{{ profileView?.report_count }}</ATypographyText>
        </AFlex>
      </div>
    </ProfileWrapper>
  </ASpin>
</template>

<style scoped>
.description {
  font-size: 24px;
}

.content {
  min-height: 166px;
}

.content-wrapper {
  margin-top: -12px;
}
</style>
