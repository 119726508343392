<script setup lang="ts">
import { useRouter } from "vue-router"
import { ref, h, computed, watch } from "vue"
import { PlusOutlined, ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons-vue"
import { TransitionSlide } from "@morev/vue-transitions"
import { watchThrottled } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { push } from "notivue"

import {
  OperationsCategoriesCreateRouteName,
  OperationsCategoriesCreateTemplateRouteName,
  OperationsCategoriesDetailRouteName,
  OperationsCategoriesEditRouteName
} from "@/router"
import { useAdoptable } from "@/package/ui-kit"
import {
  useOperationCategoriesStore,
  OperationCategoryListing,
  ActionTypeRadioList,
  useCategoryActionTypes
} from "@/modules/operation-category"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

const tableRef = ref<null | InstanceType<typeof OperationCategoryListing>>(null)
const searchQuery = ref("")
watchThrottled(
  searchQuery,
  (value) => {
    store.searchListing(value)
  },
  { throttle: 1000, leading: false }
)

const store = useOperationCategoriesStore()

const { operationCategoriesDeleteError } = storeToRefs(store)

const actionType = computed({
  get: () => store.actionType,
  set: (actionType: string) => {
    searchQuery.value = ""
    store.changeActionType(actionType)
  }
})
const changeActionTypeAction = () => {
  tableRef.value?.shrinkAll()
}

const { loading: categoryActionTypesLoading } = useCategoryActionTypes()

const router = useRouter()
const openCreateAction = () => router.push({ name: OperationsCategoriesCreateRouteName })

const openCreateFromTemplateAction = () =>
  router.push({ name: OperationsCategoriesCreateTemplateRouteName })
const openDetailAction = (id: string | number) =>
  router.push({ name: OperationsCategoriesDetailRouteName, params: { id } })
const openEditAction = (id: string | number) =>
  router.push({ name: OperationsCategoriesEditRouteName, params: { id } })

watch(operationCategoriesDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const isAdoptable = useAdoptable()
</script>

<template>
  <div
    class="radio-list-wrapper"
    :style="{ marginTop: '32px', minHeight: isAdoptable ? '32px' : '40px' }"
  >
    <TransitionSlide>
      <ActionTypeRadioList
        v-if="!categoryActionTypesLoading"
        v-model:value="actionType"
        :size="isAdoptable ? 'normal' : 'large'"
        :class="isAdoptable ? 'radio-list--adopted' : null"
        @change="changeActionTypeAction"
      />
    </TransitionSlide>
    <AButton
      size="large"
      :style="{
        marginLeft: isAdoptable ? 0 : '8px',
        marginTop: isAdoptable ? '8px' : 0,
        width: isAdoptable ? '100%' : 'auto'
      }"
      @click="openCreateFromTemplateAction"
      >Создать справочник по шаблону</AButton
    >
  </div>
  <ARow justify="space-between" :gutter="[16, 0]" :style="{ marginTop: '32px' }">
    <ACol :xs="isAdoptable ? 0 : undefined">
      <ASpace>
        <AButton default-styling size="large" type="primary" @click="openCreateAction">
          Добавить
        </AButton>
        <AButton
          :icon="h(tableRef?.isExpanded ? ShrinkOutlined : ExpandAltOutlined)"
          default-styling
          size="large"
          type="default"
          @click="tableRef?.expandAllToggle"
        >
          {{ tableRef?.isExpanded ? "Скрыть" : "Показать" }}
        </AButton>
      </ASpace>
    </ACol>
    <ACol :xs="24" :md="12" :lg="8">
      <AInputSearch
        v-model:value="searchQuery"
        placeholder="Название"
        size="large"
        :loading="searchQuery.length > 0 && store.isListingLoading"
        @search="store.searchListing"
      />
    </ACol>
  </ARow>
  <OperationCategoryListing
    ref="tableRef"
    :style="{ marginTop: isAdoptable ? '32px' : '16px', flexGrow: 1 }"
    @openDetail="openDetailAction"
    @openCreate="openCreateAction"
    @openEdit="openEditAction"
  />
  <RouterView />
  <AListingControlPanel v-if="isAdoptable">
    <AButton size="large" :icon="h(PlusOutlined)" type="primary" @click="openCreateAction" />
    <AButton
      :icon="h(tableRef?.isExpanded ? ShrinkOutlined : ExpandAltOutlined)"
      default-styling
      size="large"
      type="default"
      @click="() => tableRef?.expandAllToggle()"
    />
  </AListingControlPanel>
</template>

<style scoped lang="scss">
.radio-list-wrapper {
  width: 100%;
}

.radio-list--adopted {
  width: 100%;
  display: flex;

  :deep(.ant-radio-button-wrapper) {
    width: 50%;
    text-align: center;
  }
}
</style>
