import { useAxios } from "@vueuse/integrations/useAxios"

import { type ApiSuccessResponse, client, useApiError } from "@/package/api-client"

export const useBankAccountDelete = () => {
  const { execute, isLoading, error } = useAxios<ApiSuccessResponse<undefined>>(
    { method: "DELETE" },
    client
  )

  const deleteBankAccount = (id: number | string) => execute(`/bank-accounts/${id}`)

  return {
    deleteBankAccount,
    isDeleting: isLoading,
    bankAccountDeleteError: useApiError(error)
  }
}
