import { computed, reactive } from "vue"
import dayjs from "dayjs"

import type { OperationFilterParams } from "@/modules/operation"

import { defaultFormFilters, defaultSeparateFilters, type FilterForm } from "../interface"

export const useFormState = () => {
  const formState = reactive<Omit<FilterForm, "date" | "actions" | "types">>(
    Object.assign({}, defaultFormFilters)
  )
  const setupStateFromParams = (state: OperationFilterParams = {}) => {
    const {
      counterparty_ids,
      project_ids,
      bank_account_ids,
      category_ids,

      tags
    } = state
    Object.assign(formState, {
      counterparty_id: counterparty_ids ? counterparty_ids[0] : undefined,
      project_id: project_ids ? project_ids[0] : null,
      bank_account_id: bank_account_ids ? bank_account_ids[0] : null,
      category_id: category_ids ?? [],
      tags: tags ?? []
    })
  }
  const requestData = computed<OperationFilterParams>(() => {
    const { bank_account_id, counterparty_id, project_id, category_id, tags } = formState
    const formattedData: OperationFilterParams = {}

    if (bank_account_id) {
      formattedData.bank_account_ids = [bank_account_id]
    }
    if (counterparty_id !== undefined) {
      formattedData.counterparty_ids = [counterparty_id]
    }
    if (project_id) {
      formattedData.project_ids = [project_id]
    }

    if (category_id.length > 0) {
      formattedData.category_ids = category_id
    }

    if (tags.length > 0) {
      formattedData.tags = tags
    }
    return formattedData
  })

  return {
    formState,
    requestData,
    setupStateFromParams
  }
}

export const useSeparateFiltersState = () => {
  const formState = reactive<Pick<FilterForm, "date" | "actions" | "types">>(
    Object.assign({}, defaultSeparateFilters)
  )
  const setupStateFromParams = (state: OperationFilterParams = {}) => {
    const { date_to, date_from, types, actions } = state
    Object.assign(formState, {
      date: [
        date_from ? dayjs(date_from, "DD-MM-YYYY") : undefined,
        date_to ? dayjs(date_to, "DD-MM-YYYY") : undefined
      ] as FilterForm["date"],
      types: types ?? [],
      actions: actions ?? []
    })
  }
  const requestData = computed<OperationFilterParams>(() => {
    const { date, actions, types } = formState
    const formattedData: OperationFilterParams = {}

    if (date[0]) {
      formattedData.date_from = date[0].format("DD-MM-YYYY")
    }
    if (date[1]) {
      formattedData.date_to = date[1].format("DD-MM-YYYY")
    }

    if (actions.length > 0) {
      formattedData.actions = actions
    }

    if (types.length > 0) {
      formattedData.types = types
    }
    return formattedData
  })

  const isFiltersEmpty = computed(
    () =>
      formState.types.length === 0 &&
      formState.actions.length === 0 &&
      !formState.date[0] &&
      !formState.date[1]
  )

  return {
    formState,
    requestData,
    setupStateFromParams,
    isFiltersEmpty
  }
}
