<script setup lang="ts">
defineProps({
  showFilterPanel: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["submit", "cancel"])
import type { ReportsFilters } from "../interfaces"
import type { Dayjs } from "dayjs"

import { nextTick, onMounted, ref } from "vue"
import { useToggle } from "@vueuse/core"

import { LegalEntitySelect } from "@/modules/legal-entity"
import { ProjectSelect } from "@/modules/project"

import { useReportsStore } from "../store"

const backupDateState = ref<Array<Dayjs>>([])
const currentDates = ref<Array<Dayjs>>([])
const [isDatepickerOpened, toggleDatepickerOpened] = useToggle(false)
const [isDateChanging, toggleIsDateChanging] = useToggle<boolean>(false)

const store = useReportsStore()

const formState = ref<Partial<ReportsFilters>>({ projectsFilter: [], legalEntityFilter: null })

const applyDatePicker = async () => {
  formState.value.dateFilter = currentDates.value
  backupDateState.value = []
  toggleDatepickerOpened(false)
  await nextTick(() => {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
  })
}

const cancelDateSelect = () => {
  formState.value.dateFilter = backupDateState.value
  backupDateState.value = []
  toggleDatepickerOpened(false)
  ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
  ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
}

const focusRangePicker = () => {
  if (!isDatepickerOpened.value) {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
  }

  if (!isDatepickerOpened.value && formState.value.dateFilter) {
    backupDateState.value = formState.value.dateFilter
    toggleDatepickerOpened(true)
  }
}

const blurRangePicker = () => {
  if (!isDateChanging.value) {
    toggleDatepickerOpened(false)
  } else {
    toggleIsDateChanging(false)
  }
}

const handleCalendarChange = (
  dates: Array<Dayjs>,
  dateString: Array<string>,
  info: { range: string }
) => {
  if (!dates) {
    toggleDatepickerOpened(false)
    Object.assign(currentDates.value, [null, null])
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
    return
  }

  currentDates.value = dates
  if (info.range === "start" && dates[0]) {
    toggleIsDateChanging(true)
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.focus()
  }

  if (info.range === "end" && dates[1]) {
    toggleIsDateChanging(true)
    nextTick(() =>
      (document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
    )
  }
}

onMounted(() => {
  formState.value.dateFilter = [...store.reportsFilters.dateFilter]
  formState.value.projectsFilter = store.reportsFilters?.projectsFilter
    ? [...store.reportsFilters.projectsFilter]
    : []
  formState.value.legalEntityFilter = store.reportsFilters.legalEntityFilter
})
</script>

<template>
  <APageDrawer :open="showFilterPanel" title="Фильтры отчетов" @close="emit('cancel')">
    <AForm
      name="report-filters-form"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      scroll-to-first-error
    >
      <AFormItem label="Период" name="dateFilter">
        <ARangePicker
          style="width: 100%"
          :value="formState.dateFilter"
          :format="['MM-YYYY', 'MM-YYYY']"
          :open="isDatepickerOpened"
          :placeholder="['Начало', 'Конец']"
          size="large"
          picker="month"
          @focus.prevent="focusRangePicker"
          @calendarChange="handleCalendarChange"
          @blur="blurRangePicker"
        >
          <template #renderExtraFooter>
            <AFlex gap="8" justify="end" :style="{ padding: '8px' }">
              <AButton size="small" type="default" @click="cancelDateSelect">Отмена</AButton>
              <AButton size="small" type="primary" @click="applyDatePicker"> Применить </AButton>
            </AFlex>
          </template>
        </ARangePicker>
      </AFormItem>

      <AFormItem label="Юридическое лицо" name="legalEntityFilter">
        <LegalEntitySelect
          v-model.null-on-empty="formState.legalEntityFilter"
          hide-link
          placeholder="Выберите юр.лицо"
        />
      </AFormItem>

      <AFormItem label="Проекты" name="projectsFilter">
        <ProjectSelect
          v-model.null-on-empty="formState.projectsFilter"
          hide-link
          mode="multiple"
          :max-tag-count="2"
          placeholder="Выберите проекты"
        />
      </AFormItem>
    </AForm>
    <template #footer>
      <ARow :gutter="8">
        <ACol :span="12">
          <AButton size="large" block type="default" @click="emit('cancel')">Отменить</AButton>
        </ACol>
        <ACol :span="12">
          <AButton size="large" block type="primary" @click="emit('submit', formState)"
            >Применить</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
