<script setup lang="ts">
import { computed, type PropType, type FunctionalComponent, useSlots } from "vue"
import { theme, TypographyText } from "ant-design-vue"

defineProps({
  variant: {
    type: String,
    validator: (value: string) => ["sm", "lg", "xl", "base"].includes(value),
    default: "base"
  },

  el: {
    type: [String, Function] as PropType<string | FunctionalComponent>,
    default: () => TypographyText
  }
})
const token = theme.useToken()
const fontSize = computed(() => ({
  xl: `${token.token.value.fontSizeXL}px`,
  sm: `${token.token.value.fontSizeSM}px`,
  lg: `${token.token.value.fontSizeLG}px`
}))
const slots = useSlots()
</script>

<template>
  <component
    :is="el"
    :class="{
      ['ant-custom-text--xl']: variant === 'xl',
      ['ant-custom-text--sm']: variant === 'sm',
      ['ant-custom-text--lg']: variant === 'lg'
    }"
  >
    <template v-for="(_, slotname) in slots" #[slotname]>
      <slot :name="slotname" />
    </template>
  </component>
</template>

<style>
.ant-custom-text--xl {
  font-size: v-bind("fontSize.xl");
}

.ant-custom-text--sm {
  font-size: v-bind("fontSize.sm");
  line-height: v-bind("token.token.value.lineHeightSM");
}

.ant-custom-text--lg {
  font-size: calc(v-bind("fontSize.lg"));
  line-height: v-bind("token.token.value.lineHeightLG");
}
</style>
