<script setup lang="ts">
import { computed } from "vue"
import { useToggle } from "@vueuse/core"

import { useProductTypes } from "@/modules/product"

const { data, loading, error, refreshAsync } = useProductTypes()

const options = computed(() =>
  (data.value ?? []).map(({ slug, value }) => ({
    value: slug,
    label: value
  }))
)
const [isOpened, toggleOpen] = useToggle(false)
const retryAction = () => {
  toggleOpen()
  setTimeout(refreshAsync, 500)
}
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    placeholder="Выберите тип"
    :loading="loading"
    :options="options"
    :status="error ? 'warning' : undefined"
    @dropdownVisibleChange="() => toggleOpen()"
    @focus="() => toggleOpen(true)"
  >
    <template v-if="data === undefined || data.length" #notFoundContent>
      <AEmpty>
        <template #description> {{ error }}</template>
        <AButton type="text" @click.stop="retryAction"> Повторить </AButton>
      </AEmpty>
    </template>
  </ASelect>
</template>
