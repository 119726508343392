import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { LegalEntityCreate } from "@/modules/legal-entity"

export const useLegalEntityCreate = () => {
  const { execute, isLoading, error, isFinished } = useAxios(
    "/legal-entities/create",
    { method: "POST" },
    client,
    { immediate: false }
  )

  const createLegalEntity = async (data: LegalEntityCreate) => {
    const response = await execute({ data })
    return response.data.value.legal_entity_id
  }

  return {
    createLegalEntity,
    isLegalEntityCreating: isLoading,
    legalEntityCreateError: useApiError(error),
    isCreateFinished: isFinished
  }
}
