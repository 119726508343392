export enum UserStatus {
  active = 1,
  inactive = 2,
  block = 3
}

export const UserStatusDict: Record<UserStatus, string> = {
  1: "Активен",
  2: "Неактивен",
  3: "Заблокирован"
}

export enum UserEmailStatus {
  active = 1,
  disabled = 2
}

export const UserEmailStatusDict: Record<UserEmailStatus, string> = {
  1: "Подтверждена",
  2: "Не подтверждена"
}

export enum UserRole {
  Manager = "manager",
  Admin = "admin",
  ServiceManager = "service_manager"
}

export const UserRoleDict: Record<UserRole, string> = {
  [UserRole.Manager]: "Менеджер",
  [UserRole.Admin]: "Админ",
  [UserRole.ServiceManager]: "Руководитель сервиса"
}

export interface UserSummary {
  id: number
  first_name: string
  last_name: string
  middle_name: string
  email: string
  role: UserRole
  status: UserStatus
  status_email: UserEmailStatus
}

export enum UserPasswordStatus {
  block = 0,
  active = 1
}

export const UserPasswordStatusDict: Record<UserPasswordStatus, string> = {
  0: "Заблокирован",
  1: "Активен"
}

export interface UserDetail {
  first_name: string
  last_name: string
  middle_name: string
  email: string
  role: UserRole

  status: UserStatus
  status_email: UserEmailStatus
  status_pass: UserPasswordStatus
  storage: {
    usage_mb: number
  }
}

export type UserRolesOptions = Array<{ id: UserRole; name: keyof typeof UserRoleDict }>

export interface UserFilters {
  role?: UserRole | null
  first_name?: string | null
  middle_name?: string | null
  last_name?: string | null
  email?: string | null
}
