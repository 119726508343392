<script setup lang="ts">
import { useToggle } from "@vueuse/core"
import { useRoute, useRouter } from "vue-router"
import { TypographyParagraph } from "ant-design-vue"
import { onMounted, ref } from "vue"

import { RegisterForm } from "@/modules/auth"
import { loginRoute } from "@/router"

defineProps({
  logoSize: {
    type: Number,
    default: 0
  }
})

const router = useRouter()
const route = useRoute()
const [isCompleted, toggleCompleted] = useToggle(false)

const promoCode = ref<string | undefined>(undefined)

onMounted(() => {
  promoCode.value = route?.params?.promoCode as string
})
</script>

<template>
  <div :style="{ margin: 'auto', width: '100%' }">
    <template v-if="isCompleted">
      <ATypographyTitle :style="{ marginBottom: '8px', textAlign: 'center' }"
        >Подтверждение почты
      </ATypographyTitle>
      <AText :style="{ textAlign: 'center' }" variant="lg" :el="TypographyParagraph"
        >На вашу почту было отправлено письмо с подтверждением. Для продолжения регистрации
        подтвердите почту.
      </AText>
      <AButton
        type="primary"
        html-type="submit"
        block
        size="large"
        :style="{ marginTop: '48px' }"
        @click="() => router.push({ name: loginRoute })"
      >
        Понятно
      </AButton>
    </template>
    <template v-else>
      <ATypographyTitle :style="{ marginBottom: '24px', textAlign: 'center' }"
        >Создайте новый аккаунт
      </ATypographyTitle>
      <RegisterForm
        :style="{ marginTop: '24px' }"
        :promo-code="promoCode"
        @success="() => toggleCompleted(true)"
      />
      <div :style="{ textAlign: 'center', marginTop: '8px' }">
        <ATypographyText type="secondary">У Вас уже есть аккаунт?{{ " " }}</ATypographyText>
        <ALink :to="{ name: loginRoute }">Войти</ALink>
      </div>
    </template>
  </div>
  <div :style="{ height: `${logoSize}px`, marginTop: '16px' }" />
</template>
