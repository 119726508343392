<script setup lang="ts">
import dayjs, { type Dayjs } from "dayjs"
import { computed, h, nextTick, ref, watch } from "vue"
import { CloseOutlined, ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { useToggle } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { push } from "notivue"
import { useRouter } from "vue-router"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import { theme } from "ant-design-vue"

import {
  useBudgetDetailStore,
  useFileDownload,
  BudgetDetailListing,
  DETAIL_REQUEST_ERROR_MESSAGE
} from "@/modules/budget"
import { FinancePlanningRouteName } from "@/router"
import { getFilterDatesPresets } from "@/package/util"
import {
  OperationAction,
  type OperationCreatRequest,
  OperationType,
  useCreateOperationTabs,
  useOperationCreate,
  useOperationFormStateProvider
} from "@/modules/operation"
import { APageDrawer, MAX_Z_INDEX, useMobileBp } from "@/package/ui-kit"
import { ApiBadReqResponse, ApiForbiddenResponse } from "@/package/api-client"

import { idAndNameTypeguard } from "../../../modules/budget/components/BudgetDetailListing/interface"

const router = useRouter()
const openListingAction = () => router.push({ name: FinancePlanningRouteName })
const isMobile = useMobileBp()

const store = useBudgetDetailStore()

useOperationFormStateProvider()
const { current, submitAction, tabList, setFormRef } = useCreateOperationTabs()

const { token } = theme.useToken()

const [isOpened, toggleOpened] = useToggle(false)

const openModal = () => {
  resetAction({
    type: OperationType.planned,
    transactions: [
      {
        project: idAndNameTypeguard(store.budget.budget.project)
          ? store.budget.budget.project
          : null
      }
    ],
    bank_account_from: idAndNameTypeguard(store.budget.budget.bankAccount)
      ? store.budget.budget.bankAccount
      : undefined
  })
  toggleOpened(true)
}

const resetAction = useOperationFormStateProvider()

const closeAction = () => toggleOpened(false)

const { createOperation, isCreating, isCreateFinished } = useOperationCreate()
const createOperationAction = async (formData: OperationCreatRequest) => {
  try {
    await createOperation(formData)
    switch (current.value) {
      case OperationAction.incoming:
        push.success({ message: "Доход зафиксирован!" })
        break
      case OperationAction.outgoing:
        push.success({ message: "Расход зафиксирован!" })
        break
      case OperationAction.transfer:
        push.success({ message: "Перевод зафиксирован!" })
        break
    }
    toggleOpened(false)
    isCreateFinished.value = false
    await store.getBudget(+budgetId.value)
  } catch (e) {
    isCreateFinished.value = false
    if (e instanceof ApiBadReqResponse || e instanceof ApiForbiddenResponse) {
      push.error({ message: e.message })
    } else {
      switch (current.value) {
        case OperationAction.incoming:
          push.error({ message: "Не удается зафискировать доход, обратитесь в поддержку" })
          break
        case OperationAction.outgoing:
          push.error({ message: "Не удается зафискировать расход, обратитесь в поддержку" })
          break
        case OperationAction.transfer:
          push.error({ message: "Не удается зафискировать перевод, обратитесь в поддержку" })
          break
      }
      isCreateFinished.value = false
    }
  }
}

const { error } = storeToRefs(store)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)

const budgetId = useRouteParams<string>("id")
store.getBudget(+budgetId.value)

const [isDatepickerOpened, toggleDatepickerOpened] = useToggle(false)
const [isDateChanging, toggleIsDateChanging] = useToggle<boolean>(false)
const currentDate = dayjs()
const datePresets = getFilterDatesPresets(currentDate)

const applyDatePicker = async () => {
  await store.getBudget(+budgetId.value)
  toggleDatepickerOpened(false)
  await cancelDateSelect()
}
const cancelDateSelect = async () => {
  toggleDatepickerOpened(false)
  await nextTick(() => {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
  })
}

const focusRangePicker = () => {
  if (!isDatepickerOpened.value) {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
  }

  if (!isDatepickerOpened.value) {
    toggleDatepickerOpened(true)
  }
}

const blurRangePicker = () => {
  if (!isDateChanging.value) {
    toggleDatepickerOpened(false)
  } else {
    toggleIsDateChanging(false)
  }
}

const handleCalendarChange = (
  dates: Array<Dayjs>,
  dateString: Array<string>,
  info: { range: string }
) => {
  if (!dates) {
    toggleDatepickerOpened(false)
    store.dateFilter = null
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
    return
  }

  store.dateFilter = dates
  if (info.range === "start" && dates[0]) {
    toggleIsDateChanging(true)
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.focus()
  }

  if (info.range === "end" && dates[1]) {
    toggleIsDateChanging(true)
    nextTick(() =>
      (document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
    )
  }
}

const listingRef = ref<InstanceType<typeof BudgetDetailListing> | null>(null)
const { executeDownload } = useFileDownload()

const isCreatingOperation = computed(() => isCreateFinished.value || isCreating.value)
</script>

<template>
  <ARangePicker
    id="firstInput"
    :value="store.dateFilter"
    :format="['DD-MM-YYYY', 'DD-MM-YYYY']"
    :open="isDatepickerOpened"
    :style="{
      width: '200px',
      backgroundColor: '#E6F2FF',
      color: '#1890FF',
      borderColor: '#7AB4FF',
      borderRadius: '0',
      marginTop: '8px',
      marginBottom: '32px'
    }"
    popup-class-name="budget-date-picker"
    :presets="isMobile ? [] : datePresets"
    :placeholder="['Начало', 'Конец']"
    size="small"
    @focus="focusRangePicker"
    @blur.prevent="blurRangePicker"
    @calendarChange="handleCalendarChange"
  >
    <template #renderExtraFooter>
      <AFlex gap="8" justify="end" :style="{ padding: '8px' }">
        <AButton size="small" type="default" @click="cancelDateSelect">Отмена</AButton>
        <AButton size="small" type="primary" @click="applyDatePicker"> Применить </AButton>
      </AFlex>
    </template>
    <template #suffixIcon></template>
  </ARangePicker>
  <AFlex justify="space-between" wrap="wrap" :gap="8">
    <ASpace wrap>
      <AButton
        type="primary"
        size="large"
        @click="
          () =>
            executeDownload({
              id: +budgetId,
              from: store.dateFilter?.[0].format('YYYY-MM-DD'),
              to: store.dateFilter?.[1].format('YYYY-MM-DD')
            })
        "
        >Сформировать отчет</AButton
      >
      <AButton size="large" type="primary" @click="openModal"> Создать операцию </AButton>
    </ASpace>
    <ASpace wrap>
      <AButton
        :icon="h(listingRef?.isExpanded ? ShrinkOutlined : ExpandAltOutlined)"
        default-styling
        size="large"
        type="default"
        @click="listingRef?.expandAllToggle"
      >
        {{ listingRef?.isExpanded ? "Скрыть" : "Показать" }}
      </AButton>

      <ASpace wrap>
        <ACheckbox v-model:checked="store.filters.factual">Факт</ACheckbox>
        <ACheckbox v-model:checked="store.filters.declined">Отклонение</ACheckbox>
        <ACheckbox v-model:checked="store.filters.percentage">Процент,%</ACheckbox>
      </ASpace>
    </ASpace>
  </AFlex>
  <APageDrawer
    v-if="isOpened"
    v-model:open="isOpened"
    custom-scroll-body
    :closeable="false"
    :body-style="{
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '--body-color': token.colorBgBase,
      '--zIndex': MAX_Z_INDEX
    }"
    @onClose="openListingAction"
  >
    <AFlex
      :style="{
        padding: '24px 24px 8px',
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: token.colorBgBase,
        zIndex: 'var(--zIndex)'
      }"
    >
      <ATypographyTitle :level="4" :style="{ margin: 0 }">Создание операции</ATypographyTitle>
      <AButton
        :style="{ position: 'absolute', right: '16px', top: '24px' }"
        type="link"
        class="ant-drawer-close"
        :icon="h(CloseOutlined)"
        @click="closeAction"
      />
    </AFlex>
    <ATabs v-model:active-key="current" class="operation-type-tabs" destroy-inactive-tab-pane>
      <ATabPane v-for="option in tabList" :key="option.key" :tab="option.tab">
        <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
          <component
            :is="option.form"
            :ref="(el: any) => setFormRef(option.key, el)"
            disable-split
            clean-files-on-change
            @submit="createOperationAction"
          />
        </OverlayScrollbarsComponent>
      </ATabPane>
    </ATabs>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="isCreatingOperation"
            @click="submitAction"
            >Создать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
  <BudgetDetailListing ref="listingRef" />
</template>

<style scoped>
.operation-type-tabs {
  overflow: hidden;
}
.operation-type-tabs:deep(.ant-tabs-tab:first-child) {
  margin-left: 24px;
}

.operation-type-tabs:deep(.ant-tabs-content) {
  overflow: hidden;
  max-height: 100%;
}
.operation-type-tabs:deep(.ant-tabs-nav) {
  z-index: var(--zIndex);
  background-color: var(--body-color);
}
</style>

<style>
.budget-date-picker .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.budget-date-picker .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.budget-date-picker .ant-picker-panels > *:last-child {
  display: none !important;
}
</style>
