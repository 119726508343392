import type { Budget } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

interface ReferenceListResponse {
  success: boolean
  pagination: {
    total: number
    count: number
    limit: number
    offset: number
  }
  data: Budget
}

export const useBudgetList = () => {
  const { execute, isLoading, error } = useAxios<ReferenceListResponse, { id: number }>(
    "/budgets/:id/details",
    { method: "GET" },
    client,
    { immediate: false }
  )

  const executeListing = async (params: { id: number; to?: string; from?: string }) =>
    await execute(`/budgets/${params.id}/details`, { params: { to: params.to, from: params.from } })

  return {
    error: useApiError(error),
    executeListing,
    isListingLoading: isLoading
  }
}
