import type { BankAccountCreate } from "../../interfaces"

import { computed, reactive } from "vue"

export const useFormState = () => {
  const formState = reactive<BankAccountCreate>({
    name: "",
    account_number: "",
    bic: "",
    correspondent_account_number: "",
    bank_name: "",
    legal_entity_id: null,
    is_active: true,
    balance: null
  })
  const requestData = computed<BankAccountCreate>(() => formState)

  return {
    formState,
    requestData
  }
}
