import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { OperationBankIntegrationEdit } from "@/modules/operation"

export const useBankIntegrationPatch = () => {
  const { execute, data, isLoading, error } = useAxios<OperationBankIntegrationEdit>(
    "/integrations/banks/integration/{integration_id}",
    { method: "PATCH" },
    client,
    {
      immediate: false
    }
  )

  const patchBankIntegration = async (
    integration_id: number,
    operations_date_from: string | undefined,
    bank_accounts: Array<{ number: string; is_connected: boolean }>
  ) => {
    const payload = {
      operations_date_from,
      bank_accounts
    }
    return await execute(`/integrations/banks/integration/${integration_id}`, { data: payload })
  }

  return {
    patchRequestData: data,
    patchBankIntegration,
    patchBankDataPending: isLoading,
    patchBankDataError: useApiError(error)
  }
}
