<script setup lang="ts">
import { h, onMounted, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { CloseOutlined, FilterOutlined } from "@ant-design/icons-vue"

import { type UserFilters, useUserStore } from "@/modules/admin"
import { useTableResolution } from "@/package/ui-kit"

import UserRoleSelect from "../../UserForm/ui/UserRoleSelect.vue"

import FiltersMobileForm from "./FiltersMobileForm.vue"

const store = useUserStore()
const isTableResolution = useTableResolution()
const [showFilterPanel, toggleShowFilterPanel] = useToggle(false)

onMounted(store.getSavedData)

watch(
  () => store.filters,
  () => {
    if (store.isFilteredReceived && !store.isGettingFilters) {
      store.saveFilters()
      store.applyFilters(store.filters)
    }
  },
  { deep: true, immediate: false }
)

const updateStateMobileFilterForm = (newState: Partial<UserFilters>) => {
  store.filters.first_name = newState.first_name ?? null
  store.filters.last_name = newState.last_name ?? null
  store.filters.middle_name = newState.middle_name ?? null
  store.filters.email = newState.email ?? null
  toggleShowFilterPanel(false)
}

const cancelMobileFilterForm = () => {
  toggleShowFilterPanel(false)
}

const resetFilters = () => {
  store.$reset()
}
</script>

<template>
  <ARow
    :style="{
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: '16px'
    }"
    :gutter="24"
  >
    <ACol :flex="isTableResolution ? null : '0 1 276px'">
      <UserRoleSelect
        v-model:value.null-on-empty="store.filters.role"
        style="width: 276px"
        size="large"
      />
    </ACol>

    <ACol style="display: flex; justify-content: flex-end; padding-right: 22px">
      <div style="position: relative; max-width: 40px">
        <AButton
          size="large"
          :type="!store.isFilterExists ? 'default' : 'primary'"
          :icon="h(FilterOutlined)"
          @click="() => toggleShowFilterPanel(true)"
        />

        <AButton
          v-if="store.isFilterExists"
          type="default"
          size="small"
          :icon="h(CloseOutlined, { class: 'close-icon' })"
          class="close-button"
          @click="resetFilters"
        />
      </div>

      <FiltersMobileForm
        v-if="showFilterPanel"
        :show-filter-panel="showFilterPanel"
        @submit="updateStateMobileFilterForm"
        @cancel="cancelMobileFilterForm"
      />
    </ACol>
  </ARow>
</template>

<style scoped>
.close-button {
  margin-left: auto;
  border-radius: 50px !important;
  border: 2px solid black;
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  z-index: 1000;
  right: -8px;
  top: -5px;
}
</style>

<style>
.close-icon svg {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -13px;
  right: -5px;
}
</style>
