import "dayjs/locale/ru"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { createApp } from "vue"
import { createPinia } from "pinia"
import { abilitiesPlugin } from "@casl/vue"

import { refreshPost } from "@/modules/auth"
import { setRefresher, hasAuth, onRefreshFailed } from "@/package/api-client"
import { CustomAnt, pushNotificatonsPlugin } from "@/package/ui-kit"
import { AppDefaultAbilities, sentryPlugin } from "@/package/config"

import { useUserAuth, default as router, loginRoute } from "./router"
import App from "./App.vue"

import "./scss/index.scss"

dayjs.locale("ru")
dayjs.extend(utc)
dayjs.extend(timezone)

useUserAuth(hasAuth)
onRefreshFailed(() => router.push({ name: loginRoute }))
setRefresher((refreshToken) =>
  refreshPost(refreshToken)
    .then(({ refresh_token, access_token }) => {
      return {
        token: access_token,
        refresh: refresh_token
      }
    })
    .catch(() => null)
)

createApp(App)
  .use(createPinia())
  .use(abilitiesPlugin, AppDefaultAbilities)
  .use(router)
  .use(CustomAnt)
  .use(pushNotificatonsPlugin)
  .use(sentryPlugin, { router })
  .mount("#app")
