import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useFileDownload = () => {
  const { execute, isLoading, error } = useAxios(
    "/storage/download-attachment/",
    { method: "GET", responseType: "blob" },
    client,
    { immediate: false }
  )

  const executeDownload = async (id: number, filename: string) => {
    const response = await execute(`/storage/download-attachment/${id}`)

    const url = URL.createObjectURL(response.data.value)

    const link = document.createElement("a")
    link.href = url
    link.download = filename
    link.style.visibility = "hidden"
    link.style.position = "absolute"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    executeDownload,
    isLoadingFile: isLoading,
    downloadFileError: useApiError(error)
  }
}
