import type { BudgetEditDTO, BudgetFormInterface } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useBudgetEdit = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: BudgetEditDTO }
  >("/budgets", { method: "PATCH" }, client, { immediate: false })

  const transformFormDataToRequest = (data: BudgetFormInterface): BudgetEditDTO => {
    return {
      id: data.id!,
      name: data.name || null,
      from: data.period?.[0]?.format("YYYY-MM-DD") || null,
      to: data.period?.[1]?.format("YYYY-MM-DD") || null,
      comment: data.comment || null,
      project_id: data.projectId || null,
      legal_entity_id: data.legalEntityId || null,
      category_operation_ids: data.category_operation_ids || null,
      bank_account_id: data.bank_account_id || null
    }
  }
  const editBudget = async (formData: BudgetFormInterface) => {
    const data = transformFormDataToRequest(formData)
    await execute(`/budgets/${data.id}`, { data })
    return data
  }
  return {
    editBudget,
    isEditing: isLoading,
    editError: useApiError(error),
    isEditingFinished: isFinished
  }
}
