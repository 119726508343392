<script setup lang="ts">
import { useRouter } from "vue-router"
import { h, watch } from "vue"
import { get, useToggle } from "@vueuse/core"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { LegalProfileEditRouteName, LegalProfileRouteName } from "@/router"
import {
  LegalEntityDescription,
  useLegalEntityDetail,
  useLegalEntityStore,
  useLegalEntityDetailView,
  DETAIL_REQUEST_ERROR_MESSAGE
} from "@/modules/legal-entity"
import { DeleteConfirm } from "@/package/ui-kit"

const router = useRouter()
const profileId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)

const openListingAction = () => router.push({ name: LegalProfileRouteName })
const openEditAction = () =>
  router.push({ name: LegalProfileEditRouteName, params: { id: get(profileId) } })

const store = useLegalEntityStore()
const deleteAction = () => {
  DeleteConfirm({
    content:
      "Вы действительно хотите безвозвратно удалить запись? Также будет удален счет данной организации",
    onOk: async () => {
      try {
        await store.deleteOne(profileId.value)
        push.success({ message: "Удалено" })
        toggleOpened(false)
      } catch (e) {
        /* empty */
      }
    }
  })
}

const { error, profile } = useLegalEntityDetail(profileId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)
const { legalEntityView } = useLegalEntityDetailView(profile)
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Информация о юридическом лице"
    @onClose="openListingAction"
  >
    <LegalEntityDescription :profile="legalEntityView" />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :icon="h(DeleteOutlined)" block size="large" danger @click="deleteAction">
            Удалить
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :icon="h(EditOutlined)"
            block
            type="primary"
            size="large"
            @click="openEditAction"
          >
            Редактировать
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
