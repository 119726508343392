<script setup lang="ts">
import { computed, h, ref, watch } from "vue"
import { DeleteOutlined, RollbackOutlined } from "@ant-design/icons-vue"
import { push } from "notivue"
import { TransitionFade } from "@morev/vue-transitions"
import { storeToRefs } from "pinia"
import { noop } from "@vueuse/core"
import { Modal } from "ant-design-vue"

import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { ApiNotFoundResponse } from "@/package/api-client"
import { useImportBus, useOperationsCreateBus } from "@/modules/centrifugo"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { OperationTrashBinListing, useOperationTrashBinStore } from "@/modules/operation"

const isAdoptable = useAdoptable()
const store = useOperationTrashBinStore()

const { operationRestoreError, operationDeleteError } = storeToRefs(store)
const operationsCreateBus = useOperationsCreateBus()
const importBus = useImportBus()
operationsCreateBus.on(store.busEventsHandler.bind(null, "Повторения операции созданы"))
importBus.on(store.busEventsHandler.bind(null, "Импорт завершен"))

const listingRef = ref<InstanceType<typeof OperationTrashBinListing> | null>(null)
const allChecked = computed({
  get: () => listingRef.value?.isAllSelected,
  set: (value) => {
    listingRef.value?.toggleAllSelected(value)
  }
})
const summary = computed(() => {
  let result = null
  if (
    listingRef.value &&
    listingRef.value.selectedCount > 0 &&
    !(store.listingApiError?.status === ApiNotFoundResponse.STATUS)
  ) {
    result = `Выбрано ${listingRef.value.selectedCount} из ${store.paginationTotal}`
  }
  return result
})

watch(operationRestoreError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(operationDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const restoreAction = () => {
  Modal.confirm({
    title: "Восстановление",
    content: "Вы действительно хотите восстановить выбранные записи?",
    onOk: async () => {
      if (listingRef.value !== null) {
        const keys = listingRef.value.currentModeKeys
        let promise = Promise.resolve()
        if (listingRef.value) {
          if (listingRef.value.excludeKeysMode) {
            promise = store.restoreByExcludedIdList(keys)
          } else {
            promise = store.restoreBySelectedIdList(keys)
          }
        }
        return promise
          .then(() => {
            push.success({ message: "Записи восстановлены" })
            listingRef.value?.unselectAllAction()
          })
          .catch(noop)
      }
    },
    okText: "Подтвердить",
    cancelText: "Отмена"
  })
}

const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    onOk: () => {
      if (listingRef.value !== null) {
        const keys = listingRef.value.currentModeKeys
        let promise = Promise.resolve()
        if (listingRef.value) {
          if (listingRef.value.excludeKeysMode) {
            promise = store.deleteByExcludedIdList(keys)
          } else {
            promise = store.deleteBySelectedIdList(keys)
          }
        }
        return promise
          .then(() => {
            push.success({ message: "Удалено" })
            listingRef.value?.unselectAllAction()
          })
          .catch(noop)
      }
    }
  })
}
</script>

<template>
  <ARow
    :gutter="[16, 0]"
    :style="{ marginTop: isAdoptable ? '32px' : '16px', justifyContent: 'space-between' }"
  >
    <ACol :xs="0" :md="24" :style="{ minHeight: '32px' }">
      <AFlex justify="space-between">
        <TransitionFade>
          <ASpace v-if="summary !== null" :size="16">
            <ATypographyText :content="summary" ellipsis />
            <AButton type="text" danger :icon="h(RollbackOutlined)" @click="restoreAction">
              Восстановить
            </AButton>
            <AButton type="text" danger :icon="h(DeleteOutlined)" @click="deleteAction">
              Удалить
            </AButton>
          </ASpace>
        </TransitionFade>
      </AFlex>
    </ACol>
    <ACol :xs="24" :md="0">
      <AFlex :gap="16" justify="space-between">
        <ACheckbox
          v-model:checked="allChecked"
          :indeterminate="listingRef && listingRef.selectedCount > 1 && !allChecked"
        >
          Выбрать все
        </ACheckbox>

        <div v-if="summary !== null">{{ summary }}</div>
      </AFlex>
    </ACol>
  </ARow>

  <OperationTrashBinListing ref="listingRef" :style="{ marginTop: '16px', flexGrow: 1 }" />

  <AListingControlPanel v-if="isAdoptable">
    <AButton
      v-if="listingRef && listingRef.selectedCount"
      type="default"
      size="large"
      :icon="h(RollbackOutlined)"
      danger
      @click="restoreAction"
    >
      Восстановить
    </AButton>
    <AButton
      v-if="listingRef && listingRef.selectedCount"
      type="default"
      size="large"
      :icon="h(DeleteOutlined)"
      danger
      @click="deleteAction"
    >
      Удалить
    </AButton>
  </AListingControlPanel>
</template>
