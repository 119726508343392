import { computed, type Ref, watch } from "vue"

import type { LegalEntityCreate, LegalEntityDetailView } from "@/modules/legal-entity"

import { useTurnover } from "./use-turnover"
import { useOkvedDetail } from "./use-okved-detail"

export const useLegalEntityDetailView = (legalEntity: Ref<LegalEntityCreate | undefined>) => {
  const { data: turnoverData } = useTurnover()
  const { getDetail, okvedDetail } = useOkvedDetail()
  const legalEntityView = computed<LegalEntityDetailView | undefined>(() => {
    let result = undefined
    if (legalEntity.value && turnoverData.value) {
      const { monthly_turnover_rate, ...profile } = legalEntity.value
      result = {
        ...profile,
        monthly_turnover_rate: turnoverData.value?.find(({ id }) => id === monthly_turnover_rate)
          ?.name,
        okved: okvedDetail.value ? `${okvedDetail.value.code} ${okvedDetail.value.name}` : undefined
      }
    }
    return result
  })

  watch(legalEntity, (legalEntity) => {
    if (legalEntity) {
      if (!legalEntity.okved_id) {
        throw new Error("Ошибка логики у юр. лица нет оквэд-id" + JSON.stringify(legalEntity))
      }
      getDetail(legalEntity.okved_id)
    }
  })

  return { legalEntityView }
}
