<script setup lang="ts">
import { computed, h, onBeforeUnmount, onMounted, type PropType, ref, watch } from "vue"
import { useDebounceFn, useToggle } from "@vueuse/core"
import { TransitionFade } from "@morev/vue-transitions"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import {
  type Counterparty,
  CreateCounterpartyForm,
  useCounterpartiesStore
} from "@/modules/counterparty"
import { AFakeOption, VNodeRender } from "@/package/ui-kit"
import { DETAIL_REQUEST_ERROR_MESSAGE } from "@/modules/operation"
import { ApiNotFoundResponse } from "@/package/api-client"
import { injectProfile, useProfileSubscription } from "@/modules/profile"

const props = defineProps({
  id: { type: Number, default: null },
  hasNoneOption: { type: Boolean, default: false },

  hideLink: {
    type: Boolean,
    default: false
  },

  isSelectOptionsReset: {
    type: Boolean,
    default: true
  },

  disabled: {
    type: Boolean,
    default: () => false
  }
})

const selectedId = defineModel("selectedId", {
  type: [Number, undefined] as PropType<number | undefined>,
  get(v) {
    if (store.isListingLoading && store.listing.length === 0) {
      return -1
    }
    return v
  }
})

const selectedCounterPartyLabel = defineModel("selectedCounterPartyLabel", {
  type: [String, undefined] as PropType<string | undefined>,
  get(v) {
    if (store.isListingLoading && store.listing.length === 0) {
      return -1
    }
    return v
  }
})

const [isSelectOpen, toggleSelectOpen] = useToggle(false)

const [isFormOpen, toggleFormOpen] = useToggle(false)
const formRef = ref<InstanceType<typeof CreateCounterpartyForm> | null>(null)

const store = useCounterpartiesStore()
const { listingApiError } = storeToRefs(store)

const createCounterparty = () => {
  formRef?.value?.submitAction()
}

const cancelCreateForm = () => {
  toggleFormOpen(false)
}

const selectNewCounterparty = ({ id, name }: Counterparty) => {
  selectedId.value = id
  selectedCounterPartyLabel.value = name
  toggleFormOpen(false)
}

const selectCounterpartyLabel = (val: number, { label }: { label: string }) => {
  selectedCounterPartyLabel.value = label
}

const openForm = () => {
  toggleSelectOpen(false)
  toggleFormOpen(true)
}

const profile = injectProfile()
const isSubscriptionExpired = computed(() => useProfileSubscription(profile?.value).value)

const options = computed(() => {
  if (store.isListingLoading && store.listing.length === 0) {
    return [{ label: "Загрузка...", value: -1 }]
  }
  const optList = store.listing.map(({ id, name }) => ({ key: id, value: id, label: name }))
  return props.hasNoneOption ? [{ label: "Без контрагента", value: null }, ...optList] : optList
})

const LoadingOption = () =>
  h(AFakeOption, { disabled: true }, () =>
    store.pagination.total
      ? `Список статей загружается, ${store.pagination.offset} из ${store.pagination.total}`
      : "Список статей загружается"
  )

watch(
  listingApiError,
  async (err) => {
    if (err) {
      push.error({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
    }
  },
  { immediate: true }
)

watch(
  () => [selectedId.value, store.listing.length],
  async ([newId]) => {
    if (newId && newId > -1 && !store.idList.includes(newId) && store.listing.length) {
      try {
        await store.loadOne(newId)
      } catch (e) {
        if (e instanceof ApiNotFoundResponse) {
          selectedId.value = undefined
        } else {
          push.error({ message: "Не удалось загрузить информацию о контрагенете" })
        }
      }
    }
  }
)

onMounted(async () => {
  if (!store.listing.length && !store.isListingLoading && !isSubscriptionExpired.value) {
    await store.loadNextPart()
  }
})

const [isLoading, toggleLoading] = useToggle(false)
const mounted = async (e: any) => {
  if (
    e?.target?.scrollTop &&
    e?.target?.scrollHeight &&
    !isLoading.value &&
    !store.isListingLoading
  ) {
    const scrolled = e.target.scrollHeight - e.target.scrollTop
    const scrollHalf = e.target.scrollHeight / 2
    if (scrolled <= scrollHalf) {
      toggleLoading(true)
      if (store.pagination.total !== null && store.pagination.total > options.value.length) {
        await store.loadNextPart()
      }
      toggleLoading()
    }
  }
}

const handleSearch = useDebounceFn(async (searchQuery: string) => {
  if (!searchQuery) {
    await store.searchListing("")
  } else {
    await store.searchListing(searchQuery)
  }
}, 1000)

const resetSearch = (ids?: Array<number> | number) => {
  if (Array.isArray(ids) && ids.length === 0) {
    store.searchListing("")
  } else if (!ids) {
    store.searchListing("")
  }
}

onBeforeUnmount(() => store.$reset(props.isSelectOptionsReset))
</script>

<template>
  <ASelect
    v-model:value="selectedId"
    :open="isSelectOpen"
    size="large"
    show-search
    allow-clear
    virtual
    :filter-option="false"
    :options="options"
    :loading="isLoading"
    :disabled="isSubscriptionExpired || props.disabled"
    @search="handleSearch"
    @popupScroll="mounted"
    @dropdownVisibleChange="toggleSelectOpen"
    @focus="() => toggleSelectOpen(true)"
    @select="selectCounterpartyLabel"
    @change="resetSearch"
  >
    <template #dropdownRender="{ menuNode }">
      <APageDrawer v-if="isFormOpen" v-model:open="isFormOpen" title="Создание контрагента">
        <CreateCounterpartyForm ref="formRef" @success="selectNewCounterparty" />
        <template #footer>
          <ARow :gutter="[8, 8]">
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton size="large" block @click="cancelCreateForm">Отмена</AButton>
            </ACol>
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton
                size="large"
                block
                type="primary"
                :loading="formRef?.isCreating"
                @click="createCounterparty"
                >Создать</AButton
              >
            </ACol>
          </ARow>
        </template>
      </APageDrawer>
      <AButton v-if="!hideLink" type="link" @click="openForm">Создать нового контрагента</AButton>
      <VNodeRender :node="menuNode" />
      <TransitionFade :easing="{ enter: '1s', leave: '1s' }">
        <LoadingOption v-if="isLoading" />
      </TransitionFade>
    </template>
  </ASelect>
</template>
