import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { LegalEntityRequestData } from "@/modules/profile"

export const useLegalEntity = () => {
  const { execute, data, isLoading, error } = useAxios<LegalEntityRequestData>(
    "users/legal-entity",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getLegalEntityData = async () => {
    return await execute("users/legal-entity")
  }

  return {
    legalEntityData: data,
    getLegalEntityData,
    legalEntityDataPending: isLoading,
    LegalEntityDataError: useApiError(error)
  }
}
