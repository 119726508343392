<script setup lang="ts">
import { useRouter } from "vue-router"
import { h, watch } from "vue"
import { noop, useToggle } from "@vueuse/core"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"

import { ProjectEditRouteName, ReferencesProjectsRouteName } from "@/router"
import { DeleteConfirm } from "@/package/ui-kit"
import {
  useProjectsStore,
  ProjectDescription,
  useProjectDetail,
  DETAIL_REQUEST_ERROR_MESSAGE
} from "@/modules/project"

const router = useRouter()
const openListingAction = () => router.push({ name: ReferencesProjectsRouteName })
const [isOpened, toggleOpened] = useToggle(true)

const projectId = useRouteParams<string>("id")
const { isLoading, project, error } = useProjectDetail(projectId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)

const projectsStore = useProjectsStore()
const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить запись?",
    centered: true,
    onOk: () =>
      projectsStore
        .deleteProject(+projectId.value)
        .then(() => {
          push.success({ message: "Удалено" })
          toggleOpened(false)
        })
        .catch(noop)
  })
}
</script>

<template>
  <APageDrawer v-model:open="isOpened" title="Информация о проекте" @onClose="openListingAction">
    <ASpin :spinning="isLoading">
      <ProjectDescription :project="project" />
    </ASpin>
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton :icon="h(DeleteOutlined)" block size="large" danger @click="deleteAction">
            Удалить
          </AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            :icon="h(EditOutlined)"
            block
            type="primary"
            size="large"
            @click="
              () =>
                router.push({
                  name: ProjectEditRouteName,
                  params: { id: projectId }
                })
            "
            >Редактировать</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
