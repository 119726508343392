import type { BankData } from "../interfaces"

import { client } from "@/package/api-client"

interface BankDataResponse {
  success: boolean
  data: BankData
}
export const fetchBankDataGet = (bic: number | string) =>
  client
    .get<BankDataResponse>("/bank-accounts/fetch-bank-data", { params: { bic } })
    .then(({ data }) => data.data)
