import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"
import type { ProductEditDTO } from "@/modules/product"

export const useProductEdit = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean },
    { data: ProductEditDTO }
  >("/products", { method: "PATCH" }, client, { immediate: false })

  const editProduct = async (requestData: ProductEditDTO) => {
    await execute(`/products/${requestData.id}`, { data: requestData })
    return requestData
  }
  return {
    editProduct,
    isEditing: isLoading,
    editError: useApiError(error),
    isEditingFinished: isFinished
  }
}
