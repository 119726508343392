import dayjs from "dayjs"
import { computed } from "vue"

import type { Profile } from "@/modules/profile"

export const useProfileSubscription = (profile: Profile | undefined) => {
  return computed(() => {
    if (profile) {
      if (profile?.subscription === "FREE") {
        return true
      } else {
        const daysLeft = profile?.days_left

        if (daysLeft === undefined || daysLeft === null || daysLeft === 0) {
          return true
        }

        const currentTime = dayjs()

        if (daysLeft === 1) {
          const endOfToday = dayjs().endOf("day")
          return currentTime.isAfter(endOfToday)
        }

        if (daysLeft > 1) {
          const trialEndDate = currentTime.add(daysLeft - 1, "days").endOf("day")
          return currentTime.isAfter(trialEndDate)
        }
      }
    }
    return false
  })
}
