import type { RouteRecordRaw } from "vue-router"

import { appRoot, FinanceStatistics } from "@/router"
import { AppLayout } from "@/layout"

import { ProfileRoute } from "./profile.route"
import { FinanceRoute } from "./finance.route"
import { ReferencesRoute } from "./references.route"
import { AdminRoute } from "./admin"
import { SupportRoute } from "./support"
import { BankRedirectRoute } from "./bankRedirect.route"
import { SubscriptionRoute } from "./subscription.route"

export const AppSection: RouteRecordRaw[] = [
  {
    path: "/",
    name: appRoot,
    component: AppLayout,
    redirect: { name: FinanceStatistics },
    children: [
      ...AdminRoute,
      ...ProfileRoute,
      ...FinanceRoute,
      ...ReferencesRoute,
      ...SupportRoute,
      ...BankRedirectRoute,
      ...SubscriptionRoute
    ]
  }
]
