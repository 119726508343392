<script setup lang="ts">
import { computed, toRefs } from "vue"
import { useToggle } from "@vueuse/core"

const props = defineProps({
  duration: {
    type: Number,
    default: 0
  }
})
defineEmits(["timeUp"])

const { duration } = toRefs(props)
const durationInSeconds = computed(() => (duration.value ? duration.value / 1000 : 0))
const [isActive, toggleActive] = useToggle(true)
const pauseInterval = () => toggleActive(false)
const startInterval = () => toggleActive(true)
defineExpose({ pauseInterval, startInterval })
</script>

<template>
  <div
    class="progress-timeout"
    :class="{ 'progress-timeout--pause': !isActive }"
    :style="{ '--duration': durationInSeconds }"
    @animationend="() => $emit('timeUp')"
  />
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped>
.progress-timeout {
  height: 3px;
  display: flex;
  color: black;
}

.progress-timeout::before {
  content: "";
  display: flex;
  width: 100%;
  height: 100%;
  background: currentColor;
  transform-origin: left center;
  animation: roundtime calc(var(--duration) * 1s) linear forwards;
}

.progress-timeout--pause::before {
  animation-play-state: paused;
}

@keyframes roundtime {
  to {
    transform: scaleX(0);
  }
}
</style>
