<script setup lang="ts">
import { useRoute, useRouter } from "vue-router"
import { inject, provide } from "vue"
import { useRouteQuery } from "@vueuse/router"
import { get } from "@vueuse/core"
import { PureAbility } from "@casl/ability"
import { ABILITY_TOKEN } from "@casl/vue"

import { AdminUserRouteName, FinanceStatistics, isAdminRouteName } from "@/router"
import { AuthAbilitiesProvider } from "@/modules/auth"
import { ProfileProvider } from "@/modules/profile"

import { ADMIN_AUTHENTICATED_EVENT, USER_AUTHENTICATED_EVENT } from "./constants"

const backUrl = useRouteQuery("backUrl")
const route = useRoute()
const router = useRouter()
const ability = inject<PureAbility>(ABILITY_TOKEN)

const adminAuthorizedRedirect = () => {
  if (!isAdminRouteName(route.name) && ability?.can("admin", "app")) {
    const back_url = get(backUrl)
    router.push(typeof back_url === "string" ? back_url : { name: AdminUserRouteName })
  }
}

const userAuthorizedRedirect = () => {
  if (isAdminRouteName(route.name) && ability?.cannot("admin", "app")) {
    router.push({ name: FinanceStatistics })
  }
}
provide(USER_AUTHENTICATED_EVENT, () => {
  router.push({ name: FinanceStatistics })
})
provide(ADMIN_AUTHENTICATED_EVENT, () => {
  router.push({ name: AdminUserRouteName })
})
</script>

<template>
  <ProfileProvider>
    <AuthAbilitiesProvider
      @adminAuthorized="adminAuthorizedRedirect"
      @userAuthorized="userAuthorizedRedirect"
    >
      <slot />
    </AuthAbilitiesProvider>
  </ProfileProvider>
</template>
