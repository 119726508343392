import type { Plugin } from "vue"

import { install } from "ant-design-vue"

import {
  AButton,
  ACheckbox,
  ADescriptions,
  ADivider,
  AFormItem,
  AInput,
  AInputNumber,
  ALink,
  APageDrawer,
  APhoneInput,
  AText,
  AFakeOption,
  enterToTab,
  ListingControlPanel,
  AEmptyListingPlaceholder,
  AInputPrice,
  ADatePicker
} from "./index"

export const CustomAnt: Plugin = {
  install(app) {
    install(app)
    app
      .directive("EnterToTab", enterToTab)
      .component("AEmptyListingPlaceholder", AEmptyListingPlaceholder)
      .component("AListingControlPanel", ListingControlPanel)
      .component("AInputPrice", AInputPrice)
      .component("ADatePicker", ADatePicker)
      .component("AFakeOption", AFakeOption)
      .component("AButton", AButton)
      .component("ACheckbox", ACheckbox)
      .component("ADescriptions", ADescriptions)
      .component("ADivider", ADivider)
      .component("AFormItem", AFormItem)
      .component("AInputNumber", AInputNumber)
      .component("AInput", AInput)
      .component("ALink", ALink)
      .component("APageDrawer", APageDrawer)
      .component("AText", AText)
      .component("APhoneInput", APhoneInput)
  }
}
