import type { SorterResult } from "ant-design-vue/es/table/interface"

import { defineStore } from "pinia"
import { computed, type MaybeRef, readonly, ref, watch } from "vue"
import { get, set } from "@vueuse/core"
import { push } from "notivue"
import { HttpStatusCode } from "axios"

import type { Project, ProjectEdit } from "@/modules/project"
import { useListBufferApi, useListingSort, usePagination } from "@/package/hooks"
import { getOrderBySorter } from "@/package/ui-kit"
import { DETAIL_REQUEST_ERROR_MESSAGE } from "@/modules/operation"
import { SortOrder } from "@/interfaces"

import {
  useProjectCreate,
  useProjectDelete,
  useProjectEdit,
  useProjectsList,
  useProjectsListDelete,
  useSaveProjectSorting,
  useGetProjectSorting
} from "../hooks"
import { projectGet } from "../api/project.get"

export const useProjectsStore = defineStore("projects", () => {
  const listingBuffer = ref<Array<Project>>(new Array<Project>())
  const idList = computed(() => listingBuffer.value.map(({ id }) => id))

  const { pagination, paginationTotal, currentOffset, decrementTotal, setPagination } =
    usePagination()
  const { sortOrder, sortBy, sortUsed, setSort, resetSort } = useListingSort()

  const searchQuery = ref<string>()
  const searchQueryRO = readonly(searchQuery)

  const {
    setUpdateFn,
    bufferRO,
    bufferConcat,
    bufferUnshift,
    bufferRewrite,
    updateBufferFn,
    bufferFilterOneConcat
  } = useListBufferApi(listingBuffer)

  const {
    requestListing,
    isListingLoading,
    projectListingResponse,
    listingApiError,
    listingResponseStatus
  } = useProjectsList()

  const isResourceEmpty = computed(() => get(listingResponseStatus) === HttpStatusCode.NoContent)
  const isLoadedFull = computed(() => {
    const total = get(pagination).total
    return (total !== null && total <= get(listingBuffer).length) || get(isResourceEmpty)
  })

  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: error.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
    }
  })
  watch(projectListingResponse, (listing) => {
    if (listing && listing.data.length > 0) {
      updateBufferFn(listing.data)
      setPagination(listing.pagination)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })
  const loadNextPart = async () => {
    setUpdateFn(bufferConcat)
    await requestListing({
      offset: get(listingBuffer, "length"),
      limit: get(pagination, "limit"),
      query: get(searchQuery),
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }
  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: 0,
      limit: get(listingBuffer, "length"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { createProject, isCreating, createError, isCreateFinished } = useProjectCreate()

  const updateBufferAfterCreate = async () => {
    let result = null
    setUpdateFn(bufferUnshift)
    const response = await requestListing({
      offset: 0,
      limit: 1,
      query: get(searchQuery) || undefined
    })
    result = response.data.value?.data[0].id
    if (get(sortUsed)) {
      await reloadBuffer()
    }
    return result
  }

  const { executeDelete, isDeleting, projectDeleteError } = useProjectDelete()

  const deleteProject = async (id: number) => {
    if (get(isLoadedFull)) {
      await executeDelete(id)
      bufferFilterOneConcat(id, [])
      decrementTotal()
    } else {
      await executeDelete(id)
      setUpdateFn(bufferConcat)
      await requestListing({
        offset: get(listingBuffer, "length"),
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy)
      })
    }
  }
  const { requestProejctListDelete, isListDeleting, projectDeleteListError } =
    useProjectsListDelete()

  const deleteByExcludedIdList = async (idList: MaybeRef<Array<string | number>>) => {
    await requestProejctListDelete({
      exclude_ids: get(idList),
      query: get(searchQuery) || undefined
    })
    await reloadBuffer()
  }
  const deleteBySelectedIdList = async (idList: MaybeRef<Array<string | number>>) => {
    await requestProejctListDelete({ include_ids: get(idList) })
    await reloadBuffer()
  }

  const { editProject, isEditing, editError, isEditingFinished } = useProjectEdit()

  const updateBufferAfterEdit = async (data: ProjectEdit) => {
    const updatedId = listingBuffer.value.findIndex(({ id }) => data.id === id)
    if (updatedId !== -1) {
      listingBuffer.value[updatedId] = {
        id: data.id,
        name: data.name ?? ""
      }
    }
  }

  const searchListing = async (query: string) => {
    searchQuery.value = query
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const loadOne = async (id: string | number) => {
    const { name } = await projectGet(id)
    bufferConcat({
      id: +id,
      name
    })
  }

  const setSortOrder = async (sorter: SorterResult) => {
    const orderBy = getOrderBySorter(sorter)
    if (orderBy !== null && typeof sorter.field === "string") {
      setSort(sorter.field, orderBy)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { getSorting, isSortingLoading } = useGetProjectSorting()

  const getProjectSorting = async () => {
    const response = await getSorting()
    if (response?.sortBy && typeof response?.sortOrder === "string") {
      setSort(response?.sortBy, response?.sortOrder as SortOrder)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { executeSave } = useSaveProjectSorting()

  const saveProjectSorting = async () => {
    await executeSave({ sortBy: sortBy.value, sortOrder: sortOrder.value })
  }

  const $reset = async (isSelectOptionsReset: boolean = true) => {
    if (isSelectOptionsReset) {
      setPagination()
      set(listingBuffer, [])
    }
    set(searchQuery, undefined)
    resetSort()
  }

  return {
    isResourceEmpty,
    pagination,
    currentOffset,
    paginationTotal,
    listingApiError,
    idList,
    listingBuffer,
    listing: bufferRO,
    searchQuery: searchQueryRO,
    isLoadedFull,
    loadNextPart,
    searchListing,
    isListingLoading,
    createProject,
    isCreating,
    createError,
    isCreateFinished,
    updateBufferAfterCreate,
    loadOne,
    isDeleting,
    deleteProject,
    isListDeleting,
    deleteByExcludedIdList,
    deleteBySelectedIdList,
    projectDeleteError,
    projectDeleteListError,
    editProject,
    isEditing,
    editError,
    isEditingFinished,
    sortOrder,
    isSortingLoading,
    setSortOrder,
    sortUsed,
    updateBufferAfterEdit,
    getProjectSorting,
    saveProjectSorting,
    $reset
  }
})
