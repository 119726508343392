import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useLegalEntityDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { id: number }>(
    "/legal-entities/delete",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const requestLegalEntityDelete = async (id: number | string) => await execute({ params: { id } })

  return {
    legalEntityDeleteError: useApiError(error),
    requestLegalEntityDelete,
    isLegalEntityDeleting: isLoading
  }
}
