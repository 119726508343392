<script setup lang="ts">
import { computed, h, onBeforeUnmount, onMounted, type PropType, ref, watch } from "vue"
import { debounce, isArray, throttle } from "lodash-es"
import { TransitionFade } from "@morev/vue-transitions"
import { useToggle } from "@vueuse/core"
import { push } from "notivue"

import { AFakeOption, VNodeRender } from "@/package/ui-kit"
import { CreateProjectForm, useProjectsStore } from "@/modules/project"
import { ApiNotFoundResponse } from "@/package/api-client"

const props = defineProps({
  hideLink: {
    type: Boolean,
    default: false
  },

  isSelectOptionsReset: {
    type: Boolean,
    default: true
  }
})
const selectedId = defineModel({
  type: [Number, Array<number>] as PropType<number | Array<number>>,
  get(v) {
    if (store.isListingLoading && store.listing.length === 0) {
      return -1
    }
    return v
  }
})

const [isSelectOpen, toggleSelectOpen] = useToggle(false)

const [isFormOpen, toggleFormOpen] = useToggle(false)
const formRef = ref<InstanceType<typeof CreateProjectForm> | null>(null)

const createProject = () => {
  formRef?.value?.submitAction()
}

const cancelCreateForm = () => {
  toggleFormOpen(false)
}

const selectNewProject = (newId: number) => {
  selectedId.value = newId
  toggleFormOpen(false)
}

const openForm = () => {
  toggleSelectOpen(false)
  toggleFormOpen(true)
}

const store = useProjectsStore()
const options = computed(() =>
  store.isListingLoading && store.listing.length === 0
    ? [{ label: "Загрузка...", value: -1 }]
    : store.listing.map((item) => ({ label: item.name, value: item.id }))
)
const LoadingOption = () =>
  h(AFakeOption, { disabled: true }, () =>
    store.paginationTotal
      ? `Список проектов загружается, ${store.currentOffset} из ${store.paginationTotal}`
      : "Список проектов загружается"
  )

watch([selectedId, store.listing], async ([id]) => {
  if (isArray(id)) {
    for (const singleId of id) {
      if (!store.idList.includes(singleId) && (store.listing.length || store.isLoadedFull)) {
        try {
          await store.loadOne(singleId)
        } catch (e) {
          console.log(e)
          selectedId.value = id.filter((currentId) => currentId !== singleId)
        }
      }
    }
    return
  }

  if (id && id > -1 && !store.idList.includes(id) && (store.listing.length || store.isLoadedFull)) {
    try {
      await store.loadOne(id)
    } catch (e) {
      if (e instanceof ApiNotFoundResponse) {
        selectedId.value = undefined
      } else {
        push.error({ message: "Не удалось загрузить информацию о проекте" })
      }
    }
  }
})

const scrollAction = throttle(async (e: any) => {
  if (
    e?.target?.scrollTop &&
    e?.target?.scrollHeight &&
    !store.isListingLoading &&
    !store.isLoadedFull
  ) {
    const scrolled = e.target.scrollHeight - e.target.scrollTop
    const scrollHalf = e.target.scrollHeight / 4
    if (scrolled <= scrollHalf) {
      await store.loadNextPart()
    }
  }
}, 1000)
const searchAction = debounce(store.searchListing, 1000)
const cancelAction = () => {
  scrollAction.flush()
  searchAction.flush()
}

const resetSearch = (ids?: Array<number> | number) => {
  if (Array.isArray(ids) && ids.length === 0) {
    store.searchListing("")
  } else if (!ids) {
    store.searchListing("")
  }
}

onBeforeUnmount(() => store.$reset(props.isSelectOptionsReset))
onMounted(async () => {
  if (!store.listing.length && !store.isListingLoading) {
    await store.loadNextPart()
  }
})
</script>

<template>
  <ASelect
    v-model:value="selectedId"
    v-model:open="isSelectOpen"
    size="large"
    :options="options"
    :loading="store.isListingLoading"
    :default-active-first-option="false"
    show-search
    show-arrow
    allow-clear
    virtual
    :filter-option="false"
    @search="searchAction"
    @popupScroll="scrollAction"
    @blur="cancelAction"
    @dropdownVisibleChange="toggleSelectOpen"
    @focus="() => toggleSelectOpen(true)"
    @change="resetSearch"
  >
    <template #dropdownRender="{ menuNode }">
      <APageDrawer v-if="isFormOpen" v-model:open="isFormOpen" title="Создание проекта">
        <CreateProjectForm ref="formRef" @success="selectNewProject" />
        <template #footer>
          <ARow :gutter="[8, 8]">
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton size="large" block @click="cancelCreateForm">Отмена</AButton>
            </ACol>
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton
                size="large"
                block
                type="primary"
                :loading="formRef?.isCreating"
                @click="createProject"
                >Создать</AButton
              >
            </ACol>
          </ARow>
        </template>
      </APageDrawer>
      <AButton v-if="!hideLink" type="link" @click="openForm">Создать новый проект</AButton>
      <VNodeRender :node="menuNode" />
      <TransitionFade :easing="{ enter: '1s', leave: '1s' }">
        <LoadingOption v-if="store.isListingLoading" />
      </TransitionFade>
    </template>
    <template #tagRender="{ label }">
      <ATag color="blue">
        <ATypographyText ellipsis style="width: 70px">{{ label }}</ATypographyText></ATag
      >
    </template>
    <template #maxTagPlaceholder="omitedVals">
      <ATag color="blue" :style="{ marginRight: 0 }">+{{ omitedVals.length }}</ATag>
    </template>
  </ASelect>
</template>
