import type { FormValidator } from "./types"

export const digitsExcludeValidator: FormValidator = (rule, value) =>
  new Promise((resolve, reject) => {
    if (!rule.required && value.length === 0) {
      resolve(undefined)
      return
    }
    if (value.match(/\d/)) {
      reject("Недопустимо использование цифр в контактном лице")
    } else {
      resolve(undefined)
    }
  })
