import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { push } from "notivue"

import { ApiBadReqResponse, client } from "@/package/api-client"
import type { SaveFiltersData } from "@/modules/statistics"

export const useSaveStatisticsFilters = () => {
  const { execute, isLoading, error } = useAxios<
    { success: string; data: Partial<SaveFiltersData> },
    { ids: Array<number> }
  >("/parameters/statistics-filters", { method: "PATCH" }, client, {
    immediate: false
  })

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  watch(errorMessage, () => {
    if (errorMessage.value !== null) {
      push.error({ message: errorMessage.value })
    }
  })

  const executeSave = async (payload: SaveFiltersData) => await execute({ data: payload })

  return {
    executeSave,
    isFiltersSaving: isLoading
  }
}
