import { ref } from "vue"

export const useRuleset = () => {
  const ruleset: any = ref({
    type: [{ required: true, message: "Пожалуйста, выберите тип" }],
    name: [{ required: true, message: "Пожалуйста, введите название", whitespace: true }],
    description: [
      {
        type: "string",
        max: 4096,
        message: "Превышено допустимое количество символов"
      }
    ]
  })

  return ruleset
}
