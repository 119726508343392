import type { Ref } from "vue"
import type { OperationCreatRequest } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"
import { get } from "@vueuse/core"

import { client } from "@/package/api-client"

export const useOperationEdit = () => {
  const { data, error, execute, isLoading, isFinished } = useAxios(
    "/operations",
    { method: "PATCH" },
    client,
    {
      immediate: false
    }
  )

  const editOperation = (id: string, requestData: Ref<OperationCreatRequest>) =>
    execute(`/operations/${id}`, {
      data: { ...get(requestData), date: requestData.value.date.format("DD-MM-YYYY") }
    })

  return {
    data,
    error,
    isEditing: isLoading,
    editOperation,
    isEditingFinished: isFinished
  }
}
