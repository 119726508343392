import { computed, reactive } from "vue"

import type { CreateProject } from "@/modules/project"

export const useFormState = () => {
  const formState = reactive<CreateProject>({
    name: ""
  })
  const requestData = computed<CreateProject>(() => {
    const { name } = formState

    return {
      name
    }
  })

  return {
    formState,
    requestData
  }
}
