import type { MaybeRef } from "vue"

import { computed } from "vue"
import { useAxios } from "@vueuse/integrations/useAxios"
import { get } from "@vueuse/core"

import { ApiBadReqResponse, type ApiSuccessResponse, client } from "@/package/api-client"
import { UserStatus } from "@/modules/admin"

interface ChangeStatusRequest {
  user_id: number | string
  status: UserStatus
}

export const useUserBatchBlock = () => {
  const { execute, isLoading, error } = useAxios<
    ApiSuccessResponse<undefined>,
    { data: ChangeStatusRequest }
  >("/admin/batch-blocked", { method: "POST" }, client, { immediate: false })

  const batchBlockErrorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      } else {
        result = "Неизвестная ошибка, пожалуйста обратитесь в поддержку."
      }
    }
    return result
  })

  const requestBlockByIdList = (id_list: MaybeRef<Array<string | number>>) =>
    execute({
      data: {
        include_ids: get(id_list)
      }
    })
  const requestBlockByExcludedIdList = (
    id_list: MaybeRef<Array<string | number>>,
    query?: string
  ) =>
    execute({
      data: {
        exclude_ids: get(id_list),
        query
      }
    })

  return {
    requestBlockByIdList,
    requestBlockByExcludedIdList,
    isBatchBlockLoading: isLoading,
    batchBlockErrorMessage
  }
}
