import type { MaybeRef } from "vue"

import { get } from "@vueuse/core"
import { useRequest } from "vue-request"

import type { OperationDetailDTO } from "@/modules/operation"
import { type ApiSuccessResponse, client, useApiError } from "@/package/api-client"

export const useOperationDetail = (entityId?: MaybeRef<string | number>) => {
  const { data, error, loading, refreshAsync, run, runAsync } = useRequest<
    OperationDetailDTO | undefined
  >(
    (id) =>
      id
        ? client
            .get<ApiSuccessResponse<OperationDetailDTO>>(`/operations/${id}`)
            .then(({ data }) => data.data)
        : Promise.resolve(undefined),
    {
      cacheKey: ([id] = []) => `/operations/${id}`,
      defaultParams: [get(entityId)]
    }
  )

  return {
    operation: data,
    isLoading: loading,
    error: useApiError(error),
    requestOperationDetail: run,
    getOperationDetail: runAsync,
    refreshAsync
  }
}
