import type { UserFilters } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"
import { computed, watch } from "vue"
import { push } from "notivue"

import { ApiBadReqResponse, client } from "@/package/api-client"

export const useGetFilters = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: string; data: Partial<UserFilters> },
    { ids: Array<number> }
  >("/parameters/user-filters", { method: "GET" }, client, {
    immediate: false
  })

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  watch(errorMessage, () => {
    if (errorMessage.value !== null) {
      push.error({ message: errorMessage.value })
    }
  })

  const getFilters = async () => await execute()

  return {
    getFilters,
    isGettingFilters: isLoading,
    isFilteredReceived: isFinished
  }
}
