import {
  OperationsCategoriesCreateRouteName,
  OperationsCategoriesDetailRouteName,
  OperationsCategoriesEditRouteName
} from "@/router"

export const PageNamesMap: Record<string, Record<string, string>> = {
  [OperationsCategoriesEditRouteName]: {
    income: "Редактирование статьи дохода",
    expense: "Редактирование статьи расхода"
  },
  [OperationsCategoriesDetailRouteName]: {
    income: "Информация о статье дохода",
    expense: "Информация о статье расхода"
  },
  [OperationsCategoriesCreateRouteName]: {
    income: "Создание статьи дохода",
    expense: "Создание статьи расхода"
  }
}

export const CreateBtnActionTextDefault = "Создать статью"
export const CreateBtnActionText: Record<string, string> = {
  income: "Создать статью дохода",
  expense: "Создать статью расхода"
}

export const DETAIL_REQUEST_ERROR_MESSAGE = "Статья не найдена."
