import { useAxios } from "@vueuse/integrations/useAxios"
import { computed } from "vue"

import { ApiBadReqResponse, client } from "@/package/api-client"

export const useLoginUserAs = () => {
  const { data, error, execute } = useAxios("/oauth/token/as", { method: "POST" }, client, {
    immediate: false
  })

  const loginAs = async (id: number) => {
    await execute({ data: { user_id: id } })
  }

  const errorMessage = computed(() => {
    let result = null
    if (error.value) {
      if (error.value instanceof ApiBadReqResponse) {
        result = error.value.message
      }
    }
    return result
  })

  return {
    data,
    errorMessage,
    loginAs
  }
}
