import { useRequest } from "vue-request"

import { type ApiSuccessResponse, client } from "@/package/api-client"
import type { BackendEnumValue } from "@/interfaces"

export const useCategoryActionTypes = () =>
  useRequest<Array<BackendEnumValue>>(
    () =>
      client
        .get<
          ApiSuccessResponse<Array<BackendEnumValue>>
        >("/operation-categories/category-action-types")
        .then((response) => response.data.data),
    {
      cacheKey: "/operation-categories/category-action-types",
      staleTime: -1
    }
  )
