import type { FromField } from "./interfaces"

import { computed } from "vue"

import type { FormValidator } from "@/package/validators"

export const useRuleset = (formFields: Array<FromField>) => {
  const formFieldValidator: (
    formFields: Array<FromField>,
    key: string,
    reason: string
  ) => FormValidator = (formFields, key, reason) => {
    const validator: FormValidator = () =>
      new Promise((resolve, reject) => {
        const currentField = formFields.find((field) => field.operationKey === key)
        if (currentField) {
          if (currentField.value) {
            resolve()
          } else {
            reject(reason)
          }
        } else {
          resolve()
        }
      })
    return validator
  }

  const allFormFieldsValidator: (formFields: Array<FromField>) => FormValidator = (formFields) => {
    const validator: FormValidator = () =>
      new Promise((resolve, reject) => {
        for (const field of formFields) {
          if (!field.operationKey) {
            reject("Заполните все добавленные поля")
            return
          }
        }
        resolve()
      })
    return validator
  }

  return computed(() => ({
    type: [
      {
        asyncValidator: formFieldValidator(formFields, "type", "Укажите тип"),
        trigger: "submit"
      }
    ],
    purpose: [
      {
        asyncValidator: formFieldValidator(formFields, "purpose", "Укажите назначение"),
        trigger: "submit"
      }
    ],
    date: [
      {
        asyncValidator: formFieldValidator(formFields, "date", "Укажите дату"),
        trigger: "submit"
      }
    ],
    operation_category_id: [
      {
        asyncValidator: formFieldValidator(formFields, "operation_category_id", "Укажите статью"),
        trigger: "submit"
      }
    ],
    counterparty_id: [
      {
        asyncValidator: formFieldValidator(formFields, "counterparty_id", "Укажите контрагента"),
        trigger: "submit"
      }
    ],
    project_id: [
      {
        asyncValidator: formFieldValidator(formFields, "project_id", "Укажите проект"),
        trigger: "submit"
      }
    ],
    fields: [
      {
        asyncValidator: allFormFieldsValidator(formFields),
        trigger: "submit"
      }
    ]
  }))
}
