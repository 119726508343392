import { computed } from "vue"

import { createMinValidator, cyrillicValidator } from "@/package/validators"

export const useRuleset = () =>
  computed(() => ({
    middle_name: [
      {
        asyncValidator: createMinValidator(2, "Фамилия должна содержать не менее 2 символов")
      },
      {
        asyncValidator: cyrillicValidator
      }
    ],
    first_name: [
      {
        required: true,
        message: "Пожалуйста, введите имя"
      },
      {
        asyncValidator: createMinValidator(2, "Имя  должно содержать не менее 2 символов")
      },
      {
        asyncValidator: cyrillicValidator
      }
    ],
    last_name: [
      {
        required: true,
        message: "Пожалуйста, введите фамилию"
      },
      {
        asyncValidator: createMinValidator(2, "Отчество  должно содержать не менее 2 символов")
      },
      {
        asyncValidator: cyrillicValidator
      }
    ],
    email: [
      { type: "email", required: true, message: "Пожалуйста, введите e-mail", trigger: "blur" }
    ],
    role: [
      {
        required: true,
        message: "Пожалуйста, укажите роль"
      }
    ],
    status: [{ required: true, message: "Пожалуйста укажите статус пользователя" }],
    status_email: [
      { required: true, message: "Пожалуйста укажите статус подтверждения e-mail пользователя" }
    ],
    status_pass: [{ required: true, message: "Пожалуйста укажите статус пароля пользователя" }]
  }))
