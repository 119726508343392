import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useBudgetCopy = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { id: number }>(
    "/budgets/:id/copy",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const executeCopy = async (id: number) => await execute(`/budgets/${id}/copy`)

  return {
    executeCopy,
    isCopying: isLoading,
    budgetCopyError: useApiError(error)
  }
}
