import type { NotificationType } from "notivue"

import { h, type MaybeRef } from "vue"
import { get } from "@vueuse/core"

import AText from "../AText.vue"

export const NotificationTitle = ({
  title,
  type,
  ...props
}: {
  title?: MaybeRef<string>
  type: NotificationType
}) => {
  let resultText = get(title)
  if (!resultText) {
    switch (type) {
      case "promise-resolve":
        resultText = "Успешно завершено"
        break
      case "success":
        resultText = "Успешно"
        break
      case "warning":
        resultText = "Предупреждение"
        break
      case "promise":
      case "info":
        resultText = "Информация"
        break
      case "promise-reject":
        resultText = "Не удалось завершить"
        break
      case "error":
        resultText = "Ошибка"
        break
    }
  }
  return h(AText, { ...props, variant: "lg", style: { fontWeight: "bold" } }, () => resultText)
}
