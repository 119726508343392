import type { Options, SubscriptionOptions } from "centrifuge/build/types"

import { Centrifuge, Subscription } from "centrifuge"
import { shallowRef } from "vue"

interface UseCentrifugoOpts {
  getConnectionToken: Options["getToken"]
  getSubscriptionToken: SubscriptionOptions["getToken"]
}
export const useCentrifugo = ({ getConnectionToken, getSubscriptionToken }: UseCentrifugoOpts) => {
  const centrifugeRef = shallowRef<Centrifuge>()
  const subscribtionRef = shallowRef<Subscription>()
  const connect = (subscriptionChannel?: string) => {
    centrifugeRef.value = new Centrifuge(
      `${import.meta.env.VITE_WS_BASE_URL}/connection/websocket`,
      {
        getToken: getConnectionToken
      }
    )

    centrifugeRef.value.connect()

    if (subscriptionChannel) {
      subscribtionRef.value = centrifugeRef.value.newSubscription(subscriptionChannel, {
        getToken: getSubscriptionToken
      })
      subscribtionRef.value.subscribe()
    }
  }

  const cleanup = () => {
    subscribtionRef.value?.unsubscribe()
    centrifugeRef.value?.disconnect()
    subscribtionRef.value = undefined
    centrifugeRef.value = undefined
  }

  return {
    connect,
    cleanup,
    centrifuge: centrifugeRef,
    subscribtion: subscribtionRef
  }
}
