import type { FormValidator } from "./types"

export const trueValidator: FormValidator = (_: any, value: boolean) =>
  new Promise((resolve, reject) => {
    if (value) {
      resolve(undefined)
    } else {
      reject(false)
    }
  })
