<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, onUnmounted, type PropType, ref } from "vue"
import { useDebounceFn } from "@vueuse/core"
import { onMounted, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { LegalEntitySelect } from "@/modules/legal-entity"
import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import { useBankAccountStore } from "../../store/bank-account.store"
import { useBankAutocomplete } from "../../hooks/use-bank-autocomplete"
import { type BankAccountSummary } from "../../interfaces"
import StatusSelect from "../StatusSelect.vue"

import { useRuleset } from "./use-ruleset"
import { useFormState } from "./use-form-state"

const props = defineProps({
  id: {
    type: [String, undefined] as PropType<string | undefined>,
    default: () => undefined
  },

  detail: {
    type: [Object, undefined] as PropType<BankAccountSummary | undefined>,
    default: () => undefined
  }
})
const emit = defineEmits(["success"])

const { formState, requestData } = useFormState()

const ruleset = useRuleset(formState)
const resetAction = useDebounceFn(() => {
  if (props.detail) {
    const { legal_entity, active, ...dto } = props.detail
    Object.assign(formState, { ...dto, legal_entity_id: legal_entity.id, is_active: active })
  }
}, 100)
watch(() => props.detail, resetAction)
onMounted(resetAction)

const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const { bankDataRequestErrorMessage } = useBankAutocomplete(formState, validate)

const store = useBankAccountStore()
const { isEditing, editError, isEditingFinished } = storeToRefs(store)

const formRef = ref<typeof Form | null>(null)

const submitAction = () =>
  validate()
    .then(async () => {
      if (props.id) {
        await store.editBankAccount(props.id, requestData.value)
      } else {
        throw new Error("Cannot save bank account")
      }
    })
    .then(() => {
      if (props.id) {
        push.success({
          message: "Банковский счет изменен"
        })
        emit("success")
        isEditingFinished.value = false
        store.updateBufferAfterEdit(props.id)
      }
    })
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

watch(bankDataRequestErrorMessage, (message) => {
  if (message) {
    push.error({ message })
  }
})

watch([editError, isEditingFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isEditingFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

defineExpose({
  submitAction,
  isLoading: computed(() => isEditing.value || isValidating.value || isEditingFinished.value),
  resetAction
})
const popupContainerGetter = usePopupContainerGetter()

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-bank-account"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem label="Название" name="name" v-bind="validateInfos?.name">
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
    <AFormItem
      label="Юридическое лицо"
      name="legal_entity_id"
      v-bind="validateInfos?.legal_entity_id"
    >
      <LegalEntitySelect
        v-model.null-on-empty="formState.legal_entity_id"
        :get-popup-container="popupContainerGetter"
      />
    </AFormItem>
    <AFormItem label="БИК" name="bic" v-bind="validateInfos?.bic">
      <AInput
        v-model.numbered="formState.bic"
        size="large"
        placeholder="Введите БИК"
        :maxlength="9"
        disabled
      />
    </AFormItem>
    <AFormItem label="Банк" name="bank_name" v-bind="validateInfos?.bank_name">
      <AInput
        v-model="formState.bank_name"
        disabled
        size="large"
        placeholder="Автозаполнение данных"
      />
    </AFormItem>
    <AFormItem
      label="Корреспондентский счет"
      name="correspondent_account_number"
      v-bind="validateInfos?.correspondent_account_number"
    >
      <AInput
        v-model="formState.correspondent_account_number"
        disabled
        size="large"
        placeholder="Автозаполнение данных"
      />
    </AFormItem>
    <AFormItem label="Расчетный счет" name="account_number" v-bind="validateInfos?.account_number">
      <AInput
        v-model.numbered="formState.account_number"
        disabled
        size="large"
        placeholder="Введите расчетный счет"
        :maxlength="20"
      />
    </AFormItem>
    <AFormItem label="Статус" name="type" v-bind="validateInfos?.is_active">
      <StatusSelect v-model:value="formState.is_active" size="large" />
    </AFormItem>
    <AFormItem label="Баланс" name="balance">
      <AInput
        v-model.numbered="formState.balance"
        disabled
        size="large"
        placeholder="Введите баланс"
        :maxlength="20"
      />
    </AFormItem>
  </AForm>
</template>
