import type { RouteRecordRaw } from "vue-router"

import BankConnectionSuccess from "@/views/BankConnectionSuccess.vue"
import { BankRedirect } from "@/router"

export const BankRedirectRoute: RouteRecordRaw[] = [
  {
    path: "/callback/payment/success",
    name: BankRedirect,
    component: BankConnectionSuccess,
    meta: {
      breadcrumb: {
        disabled: true,
        name: "Подключение"
      }
    }
  }
]
