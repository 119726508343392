<script setup lang="ts">
import { computed, type PropType, watch } from "vue"
import { PaperClipOutlined } from "@ant-design/icons-vue"
import { push } from "notivue"

import { type OperationDetailDTO, type OperationDisplayData } from "@/modules/operation"
import { ExpandableText, useMobileBp, viewValueOrDefault } from "@/package/ui-kit"
import { OperationTypeDict } from "@/modules/operation"
import { RepeatPeriodCodeMap } from "@/interfaces"

import { OperationActionDict } from "../../interface"
import { useFileDownload } from "../../hooks"

import OperationSplitsTable from "./OperationSplitsTable.vue"

const props = defineProps({
  detail: {
    type: [Object, undefined] as PropType<OperationDetailDTO | undefined>,
    default: () => undefined
  }
})

const { executeDownload, downloadFileError } = useFileDownload()

watch(downloadFileError, (err) => {
  if (err?.message) {
    push.error({ message: err.message })
  }
})

const transformDetailData = computed(() =>
  props.detail?.transactions.reduce(
    (accum: OperationDisplayData, current) => {
      const split = {
        category: current.category?.name,
        counterparty: current.counterparty?.name,
        project: current.project?.name,
        amount: current.amount
      }

      accum.splits.push(split)
      if (
        current.counterparty?.id &&
        !accum.counterparties.ids.includes(current.counterparty?.id)
      ) {
        accum.counterparties.ids.push(current.counterparty.id)
        accum.counterparties.nameList.push(current.counterparty.name)
      }
      if (current.category?.id && !accum.categories.ids.includes(current.category?.id)) {
        accum.categories.ids.push(current.category.id)
        accum.categories.nameList.push(current.category.name)
      }
      if (current.project?.id && !accum.projects.ids.includes(current.project?.id)) {
        accum.projects.ids.push(current.project.id)
        accum.projects.nameList.push(current.project.name)
      }

      return accum
    },
    {
      splits: [],
      counterparties: { ids: [], nameList: [] },
      categories: { ids: [], nameList: [] },
      projects: { ids: [], nameList: [] }
    }
  )
)
const isMobile = useMobileBp()

const operationDoesntHaveMultipleTransactions = computed(
  () => !transformDetailData.value?.splits || transformDetailData.value?.splits?.length <= 1
)
</script>

<template>
  <ADescriptions layout="vertical" :column="isMobile ? 12 : 24">
    <ADescriptionsItem :span="24" label="Дата">{{ detail?.date }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Расчетный счет">
      <ATypographyParagraph
        :ellipsis="{ tooltip: detail?.bank_account_from?.name }"
        :content="detail?.bank_account_from?.name"
        :style="{ fontSize: '16px', marginBottom: '0' }"
      />
    </ADescriptionsItem>
    <ADescriptionsItem :span="24">
      <ADivider style="background-color: #d5d5d5; height: auto" />
    </ADescriptionsItem>
    <ADescriptionsItem :span="12" label="Тип операции">{{
      detail?.action ? OperationActionDict[detail.action] : viewValueOrDefault(undefined)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="12" label="Вид операции">{{
      viewValueOrDefault(OperationTypeDict[detail?.type!])
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Сумма" class="split-description-item">
      <OperationSplitsTable :amount="detail?.amount" :splits="transformDetailData?.splits" />
    </ADescriptionsItem>
    <ADescriptionsItem v-if="operationDoesntHaveMultipleTransactions" :span="12" label="Статья">
      <ATypographyParagraph
        :ellipsis="{ tooltip: transformDetailData?.categories.nameList.join(', ') }"
        :content="viewValueOrDefault(transformDetailData?.categories.nameList.join(', '))"
        :style="{ fontSize: '16px', marginBottom: '0' }"
      />
    </ADescriptionsItem>
    <ADescriptionsItem v-if="operationDoesntHaveMultipleTransactions" :span="12" label="Проект">
      <ATypographyParagraph
        :ellipsis="{ tooltip: transformDetailData?.projects.nameList.join(', ') }"
        :content="viewValueOrDefault(transformDetailData?.projects.nameList.join(', '))"
        :style="{ fontSize: '16px', marginBottom: '0' }"
      />
    </ADescriptionsItem>
    <ADescriptionsItem :span="24">
      <ADivider style="background-color: #d5d5d5; height: auto" />
    </ADescriptionsItem>
    <ADescriptionsItem v-if="operationDoesntHaveMultipleTransactions" :span="24" label="Контрагент">
      <ATypographyParagraph
        :ellipsis="{ tooltip: transformDetailData?.counterparties.nameList.join(', ') }"
        :content="viewValueOrDefault(transformDetailData?.counterparties.nameList.join(', '))"
        :style="{ fontSize: '16px', marginBottom: '0' }"
      />
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Теги" :content-style="{ whiteSpace: 'normal' }">
      <ExpandableText :content="detail?.tags?.join(', ')" />
    </ADescriptionsItem>
    <ADescriptionsItem
      :span="24"
      label="Назначение платежа"
      :content-style="{ whiteSpace: 'normal' }"
    >
      <ExpandableText :content="detail?.purpose" :rows="3" />
    </ADescriptionsItem>
    <template v-if="detail?.is_repeat">
      <ADescriptionsItem :span="12" label="Переодичность повтора">{{
        viewValueOrDefault(RepeatPeriodCodeMap[detail?.repeat_period?.slug!])
      }}</ADescriptionsItem>
      <ADescriptionsItem :span="12" label="Повторять операцию до">{{
        viewValueOrDefault(detail?.repeat_finish_at)
      }}</ADescriptionsItem>
    </template>
    <ADescriptionsItem :span="24" label="Файлы">
      <p v-if="detail?.files.length === 0">—</p>
    </ADescriptionsItem>
  </ADescriptions>

  <div style="display: flex; flex-direction: column; gap: 4px">
    <ASpace v-for="file in detail?.files" :key="file.id">
      <PaperClipOutlined />
      <AButton
        type="link"
        style="max-width: 520px"
        @click="() => executeDownload(file.id, file.file_name)"
      >
        <ATypographyParagraph
          :ellipsis="{ tooltip: file.file_name }"
          :content="file.file_name"
          :style="{ fontSize: '16px', marginBottom: '0', color: 'inherit' }"
      /></AButton>
    </ASpace>
  </div>
</template>
