import type { CurrentSubscriptionData } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useGetCurrentSubscription = () => {
  const { execute, data, isLoading, error } = useAxios<CurrentSubscriptionData>(
    "/subscription/current",
    { method: "GET" },
    client,
    {
      immediate: false
    }
  )

  const getCurrentSubscription = async () => {
    return await execute("/subscription/current")
  }

  return {
    subscriptionCurrentData: data,
    getCurrentSubscription,
    SubscriptionCurrentPending: isLoading,
    SubscriptionCurrentError: useApiError(error)
  }
}
