import type { RegisterForm } from "../interfaces"

import { computed, reactive } from "vue"

import { parseNumber } from "@/package/util"
import type { RegisterPhysicalEntity } from "@/modules/physical-entity"
import type { RegisterLegalEntity } from "@/modules/legal-entity"

export interface LegalEntityData extends Omit<RegisterForm, "accept" | "phone"> {
  phone: number
}

export const useFormState = () => {
  const formState = reactive<RegisterForm>({
    //register data
    password_confirmation: "",
    accept: false,
    promocode: undefined,

    //physical entity
    email: "",
    phone: "",
    password: "",
    first_name: "",
    last_name: "",
    middle_name: "",

    //legal entity
    itn: "",
    organization_name: "",
    legal_address: "",
    okved_code: "",
    okved_id: undefined,
    head_fullname: "",
    monthly_turnover_rate: undefined,

    //bank account
    bic: "",
    bank_name: "",
    account_number: "",
    correspondent_account_number: ""
  })

  const physicalEntity = computed<RegisterPhysicalEntity>(() => {
    const {
      email,
      phone,
      password,
      first_name,
      last_name,
      middle_name,
      password_confirmation,
      promocode
    } = formState

    return {
      promocode,
      email,
      first_name,
      last_name,
      middle_name,
      password,
      password_confirmation,
      phone: parseInt(parseNumber(phone))
    }
  })
  const legalEntity = computed<RegisterLegalEntity>(() => {
    const physicalEntityDto = physicalEntity.value
    const {
      itn,
      organization_name,
      legal_address,
      okved_code,
      okved_id,
      head_fullname,
      monthly_turnover_rate,

      bic,
      bank_name,
      account_number,
      correspondent_account_number
    } = formState

    return {
      ...physicalEntityDto,

      itn,
      organization_name,
      legal_address,
      okved_id: okved_id ? okved_id : 0,
      okved_code,
      head_fullname,
      monthly_turnover_rate: monthly_turnover_rate ? monthly_turnover_rate : 0,

      bic,
      bank_name,
      account_number,
      correspondent_account_number
    }
  })

  return { formState, physicalEntity, legalEntity }
}
