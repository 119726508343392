<script setup lang="ts">
import { ref, watch, defineProps, onMounted } from "vue"

import ASwitch from "../../../../../../package/ui-kit/lib/ASwitch.vue"

const props = defineProps({
  onCheckedChange: {
    type: Function,
    required: true
  },

  id: {
    type: String,
    required: true
  },

  state: {
    type: Boolean
  }
})

const checked = ref<boolean>(false)

onMounted(() => {
  checked.value = props.state
})

watch(checked, (value) => {
  if (props.onCheckedChange) {
    props.onCheckedChange(props.id, value)
  }
})
</script>

<template>
  <ASwitch v-model:checked="checked" />
</template>

<style scoped></style>
