<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import { useToggle } from "@vueuse/core"
import { Form, theme } from "ant-design-vue"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import { push } from "notivue"

import { FinanceOperationsRouteName } from "@/router"
import { AButton } from "@/package/ui-kit"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import OperationImportBankForm from "../../../modules/operation/components/OperationBanksForm/ui/OperationImportBankForm/OperationImportBankForm.vue"
import { useBankAuthByCode } from "../../../modules/operation/hooks/use-bank-auth-by-code"
import { useBankIntegration } from "../../../modules/operation/hooks/use-bank-integration"

const router = useRouter()
const route = useRoute()
const openListingAction = () => router.push({ name: FinanceOperationsRouteName })

const [isOpened, toggleOpened] = useToggle(true)

const closeForm = () => {
  toggleOpened(false)
}

const { token } = theme.useToken()

const currentBank = sessionStorage.getItem("currentBank")
const currentBankType = sessionStorage.getItem("currentBankType")

const modalTitle = computed(() => `Подключение ${currentBank}`)

const code = route.query.code as string
const state = route.query.state as string

if (!code || !state) {
  openListingAction()
}

const formRef = ref<typeof Form | null>(null)
const integrationId = ref(0)
const connectBankAccount = () => {
  formRef.value?.submitAction()
}

const { bankAuthResponse, bankAuthPending, bankAuth, bankAuthError } = useBankAuthByCode()
const { bankIntegrationData, getBankIntegration, BankIntegrationPending } = useBankIntegration()

watch(bankAuthError, (err) => {
  if (err?.message) {
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
    closeForm()
  }
})

const bankAuthentication = async (currentBankType: string, state: string, code: string) => {
  await bankAuth(currentBankType, state, code)
  await getBankIntegration(bankAuthResponse.value?.data?.user_bank_integration_id ?? 0)
  integrationId.value = bankAuthResponse.value?.data?.user_bank_integration_id as number
}

onMounted(() => {
  bankAuthentication(currentBankType as string, state, code)
})

const successHandle = () => {
  closeForm()
}
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    :title="modalTitle"
    custom-scroll-body
    :body-style="{
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '--body-color': token.colorBgBase
    }"
    :footer-style="{
      minHeight: '57px'
    }"
    @onClose="openListingAction"
  >
    <OverlayScrollbarsComponent
      :style="{ maxHeight: '100%', padding: '24px', backgroundColor: token.colorBgBase }"
    >
      <ASkeleton v-if="BankIntegrationPending || bankAuthPending" active />
      <template v-else>
        <OperationImportBankForm
          v-if="bankIntegrationData"
          ref="formRef"
          :data="bankIntegrationData?.data?.available_bank_accounts as any"
          :integration-id="integrationId"
          @close="successHandle"
        />
      </template>
    </OverlayScrollbarsComponent>

    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton size="large" block @click="closeForm">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton size="large" block type="primary" @click="connectBankAccount"
            >Подключить</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped></style>
