import type { CreateIncomeOperationForm, FormTransaction } from "../interface"

import { computed, inject, reactive } from "vue"
import { get } from "@vueuse/core"

import { SamoDecimal } from "@/package/util"

import {
  OperationAction,
  type OperationCreatRequest,
  type OperationTransaction
} from "../../../interface"
import { DEFAULT_TRANSACTION_STATE, INCOMING_FORM_DEFAULT_STATE } from "../constants"
import { createDateValidator } from "../validator"

import { DefaultTransactionToken, FormStateToken } from "./use-form-state-provider"
import { createTransactionsAmountValidator } from "./use-operation-split"

export const useIncomingRuleset = (
  formState: Partial<Pick<CreateIncomeOperationForm, "amount" | "is_repeat" | "repeat_period">>
) =>
  computed(() => {
    const defaultRuleset = {
      amount: [{ required: true, message: "Пожалуйста, введите сумму", trigger: "submit" }],
      date: [{ required: true, message: "Пожалуйста, выберите дату", trigger: "submit" }],
      bank_account_from_id: [
        { required: true, message: "Пожалуйста, выберите  расчетный счет", trigger: "submit" }
      ],
      type: [{ required: true, message: "Пожалуйста, выберите вид операции", trigger: "submit" }],
      transactions: [
        {
          asyncValidator: createTransactionsAmountValidator(formState.amount)
        }
      ],
      purpose: [
        {
          type: "string",
          max: 4096,
          message: "Превышено допустимое количество символов"
        }
      ]
    }

    const repetPreiodRuleset = {
      repeat_period: [{ required: true, message: "Пожалуйста, выберите переодичность повтора" }],
      repeat_finish_at: [
        { required: true, message: "Пожалуйста, выберите дату завершения повтора" },
        {
          asyncValidator: createDateValidator(formState)
        }
      ]
    }

    return formState.is_repeat ? Object.assign(defaultRuleset, repetPreiodRuleset) : defaultRuleset
  })

export const useIncomingFormStateInject = (props: { cleanFilesOnChange: boolean }) => {
  const formState = inject<Partial<CreateIncomeOperationForm>>(
    FormStateToken,
    reactive(Object.assign({}, INCOMING_FORM_DEFAULT_STATE))
  )

  const defaultTransaction = inject<Partial<FormTransaction>>(
    DefaultTransactionToken,
    reactive(Object.assign({}, DEFAULT_TRANSACTION_STATE))
  )

  const isFileUploadingDisabled = computed(() => props.cleanFilesOnChange && formState.is_repeat)

  const formData = computed((): OperationCreatRequest => {
    const {
      amount: amountSrc,
      purpose,
      bank_account_from_id,
      type,
      tags,
      transactions,
      date,
      repeat_period,
      is_repeat,
      repeat_finish_at,
      files
    } = formState as CreateIncomeOperationForm
    const amount = SamoDecimal(amountSrc).value
    const defaultTransactions =
      transactions.length > 1
        ? []
        : new Array<OperationTransaction>({
            id: defaultTransaction.id,
            amount,
            counterparty_id: defaultTransaction.counterparty_id,
            operation_category_id: defaultTransaction.income_operation_category_id,
            project_id: defaultTransaction.project_id
          })

    return {
      action: OperationAction.incoming,
      purpose,
      bank_account_from_id,
      type,
      tags,
      transactions: defaultTransactions.concat(
        transactions?.map(
          // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
          ({ amount, outcome_operation_category_id, income_operation_category_id, ...dto }) => ({
            ...dto,
            operation_category_id: income_operation_category_id,
            amount: SamoDecimal(amount).value
          })
        )
      ),
      date: date?.add(date?.utcOffset(), "minutes"),
      repeat_period,
      is_repeat,
      repeat_finish_at,
      amount,
      files: get(isFileUploadingDisabled) ? [] : get(files)?.map((file) => file.id)
    }
  })
  return {
    formData,
    formState,
    defaultTransaction,
    isFileUploadingDisabled
  }
}
