import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const UseCounterpartiesDelete = () => {
  const { execute, isLoading, error } = useAxios<{ success: string }, { id: number }>(
    "/counterparties/delete",
    { method: "POST" },
    client,
    {
      immediate: false
    }
  )

  const executeDelete = async (data: { id: number }) => await execute({ data })

  return {
    executeDelete,
    isDeleting: isLoading,
    counterpartyDeleteError: useApiError(error)
  }
}
