import type { FormValidator } from "./types"

import PWValidatorBuilder from "password-validator"

import { isSomeCyrillic } from "./cyrillic"

const schema = new PWValidatorBuilder()
  .is()
  .min(8, "Пароль должен содержать не менее 8 символов")
  .is()
  .max(24, "Пароль должен содержать не более 24 символов")
  .has()
  .uppercase(undefined, "Пароль должен содержать буквы в обоих регистрах и по одной цифре")
  .has()
  .lowercase(undefined, "Пароль должен содержать буквы в обоих регистрах и по одной цифре")
  .has()
  .digits(undefined, "Пароль должен содержать буквы в обоих регистрах и по одной цифре")
  .usingPlugin(
    (value: string) => !isSomeCyrillic(value),
    "Пароль должен содержать буквы в обоих регистрах и по одной цифре"
  )
export const passwordValidator: FormValidator = (_, value: string) =>
  new Promise((resolve, reject) => {
    const errors = schema.validate(value, { details: true }) as Array<{ message: string }>

    if (errors.length === 0) {
      resolve(undefined)
    } else {
      reject(errors[0]["message"])
    }
  })
