export type BudgetListingElement = Record<string, number | string | BudgetListingElement[] | null>

export interface BudgetDetailEntry {
  category: {
    name: string
    id: number
  }
  childrens: Array<BudgetDetailEntry>
  periods: Array<{
    column_slug: string
    summary: {
      target: string
      factual: string
      difference: {
        absolut_value: string
        percents_value: string
      }
    }
  }>
}

export interface Budget {
  budget: {
    id: number
    name: string
    from: string
    to: string
    project: {
      id: number | null
      name: string | null
    }
    bankAccount: {
      id: number | null
      name: string | null
    }
  }
  columns: Array<{
    slug: string
    year: string
    month: string
  }>
  details: Array<BudgetDetailEntry>
}

export interface BudgetListingColumn {
  title: string
  key?: string
  dataIndex?: string
  width?: number
  fixed?: boolean
  children?: Array<BudgetListingColumn>
}

export const idAndNameTypeguard = (
  value: { id: number; name: string } | { id: number | null; name: string | null }
): value is { id: number; name: string } => {
  return !!value?.id
}
