import type { TemplateOption } from "../interfaces"

import { useAxios } from "@vueuse/integrations/useAxios"

import { client, useApiError } from "@/package/api-client"

export const useOperationCategoryTemplateOptions = () => {
  const { execute, data, isLoading, error, isFinished } = useAxios<{
    success: boolean
    data: TemplateOption[]
  }>("/operation-category-templates", { method: "GET" }, client, { immediate: false })

  const getTemplateOptions = async () => {
    try {
      await execute()
    } catch (err) {
      console.error("Error fetching template options:", err)
    }
  }

  return {
    optionsResponse: data,
    getTemplateOptions,
    isGettingTemplates: isLoading,
    templateGetError: useApiError(error),
    isGettingTemplatesFinished: isFinished
  }
}
