import type { FormValidator } from "./types"

export function validateInn(inn?: string | number): [
  boolean,
  {
    code: number
    message: string
  } | null
] {
  let result = false
  let error = null
  if (typeof inn === "number") {
    inn = inn.toString()
  } else if (typeof inn !== "string") {
    inn = ""
  }
  if (!inn.length) {
    error = {
      code: 1,
      message: "ИНН пуст"
    }
  } else if (/[^0-9]/.test(inn)) {
    error = {
      code: 2,
      message: "ИНН может состоять только из цифр"
    }
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error = {
      code: 3,
      message: "ИНН может состоять только из 10 или 12 цифр"
    }
  } else {
    const checkDigit = function (inn: string, coefficients: Array<number>) {
      let n = 0
      for (const i in coefficients) {
        n += coefficients[i] * +inn[i]
      }
      return Math.trunc((n % 11) % 10)
    }
    switch (inn.length) {
      case 10: {
        const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
        if (n10 === parseInt(inn[9])) {
          result = true
        }
        break
      }
      case 12: {
        const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
          result = true
        }
        break
      }
    }
    if (!result) {
      error = { code: 4, message: "Неправильное контрольное число" }
    }
  }
  return [result, error]
}

export const innValidator: FormValidator = (_, value: string) =>
  new Promise((resolve, reject) => {
    const [result, error] = validateInn(value)
    if (!result) {
      reject(error?.message)
    } else {
      resolve(undefined)
    }
  })
