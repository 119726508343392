import type { UserRolesOptions } from "../interface"

import { useRequest } from "vue-request"

import { useApiError } from "@/package/api-client"

import { userRoleGet } from "../api/user-roles.get"

export const useUserRoles = () => {
  const { data, error, loading, runAsync } = useRequest<UserRolesOptions | undefined>(
    () => userRoleGet(),
    {
      cacheKey: "/admin/roles",
      staleTime: 60 * 60 * 1000
    }
  )

  return {
    userRolesResponse: data,
    requestUserRoles: runAsync,
    isUserRolesLoading: loading,
    userRolesError: useApiError(error)
  }
}
