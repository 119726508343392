import { useRoute } from "vue-router"
import { computed } from "vue"
import { createSharedComposable } from "@vueuse/core"

const useLayoutVar = () => {
  const route = useRoute()
  const isContentLayout = computed(() => route.meta.contentLayout)
  return {
    isContentLayout
  }
}
export const useLayoutVariant = createSharedComposable(useLayoutVar)
