import currency, { type Options } from "currency.js"
const defaultOpts: Options = {
  symbol: "",
  separator: " ",
  decimal: ".",
  precision: 2
}
export const SamoDecimal = (value: currency.Any, fromCents = false, skipDecimal = false) =>
  currency(value, {
    ...defaultOpts,
    fromCents,
    format: (value, opts) => {
      let result = value
        ? currency(value, {
            ...defaultOpts,
            fromCents
          }).format(opts)
        : ""
      if (skipDecimal && result) {
        result = result.replace(/\.00$/, "")
      }
      return result ?? ""
    }
  })
