import type { RouteRecordRaw } from "vue-router"

import {
  FinanceOperationsCreateRouteName,
  FinanceOperationsDetailRouteName,
  FinanceOperationsEditListRouteName,
  FinanceOperationsEditRouteName,
  FinanceOperationsFilterRouteName,
  FinanceOperationsImportRouteName,
  FinanceOperationsBanksRouteName,
  FinanceOperationsBankConnectionRouteName,
  FinanceOperationsRestoreRouteName,
  FinanceOperationsRouteName,
  FinancePlanningCreateRouteName,
  FinancePlanningDetailRouteName,
  FinancePlanningEditRouteName,
  FinancePlanningRouteName,
  FinanceReportsPage,
  FinanceRouteName,
  FinanceStatistics
} from "@/router"
import OperationCreatePage from "@/views/finance/operations/OperationCreatePage.vue"
import OperationsPage from "@/views/finance/operations/OperationsPage.vue"
import OperationsFilterPage from "@/views/finance/operations/OperationsFilterPage.vue"
import OperationEditPage from "@/views/finance/operations/OperationEditPage.vue"
import OperationDetailPage from "@/views/finance/operations/OperationDetailPage.vue"
import OperationListEdit from "@/views/finance/operations/OperationListEdit.vue"
import OperationsImportPage from "@/views/finance/operations/OperationsImportPage.vue"
import OperationsBanksPage from "@/views/finance/operations/OperationsBanksPage.vue"
import OperationsBankConnection from "@/views/finance/operations/OperationsBankConnection.vue"
import PlanningPage from "@/views/finance/planning/BudgetsPage.vue"
import PlanningEditPage from "@/views/finance/planning/BudgetEditPage.vue"
import PlanningCreatePage from "@/views/finance/planning/BudgetCreatePage.vue"
import PlanningDetailPage from "@/views/finance/planning/BudgetDetailPage.vue"
import StatisticsPage from "@/views/finance/StatisticsPage.vue"
import ReportsPage from "@/views/finance/ReportsPage.vue"
import OperationsRestorePage from "@/views/finance/operations/OperationsRestorePage.vue"

export const FinanceRoute: RouteRecordRaw[] = [
  {
    path: "/finance",
    name: FinanceRouteName,
    redirect: () => "/profile/phys",
    meta: {
      breadcrumb: {
        disabled: true,
        name: "Управление финансами"
      }
    },
    children: [
      {
        path: "operations",
        name: FinanceOperationsRouteName,
        component: OperationsPage,
        meta: {
          breadcrumb: {
            name: "Операции"
          }
        },
        children: [
          {
            path: "create",
            name: FinanceOperationsCreateRouteName,
            component: OperationCreatePage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "filter",
            name: FinanceOperationsFilterRouteName,
            component: OperationsFilterPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id/edit",
            name: FinanceOperationsEditRouteName,
            component: OperationEditPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: ":id",
            name: FinanceOperationsDetailRouteName,
            component: OperationDetailPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "edit",
            name: FinanceOperationsEditListRouteName,
            component: OperationListEdit,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "import",
            name: FinanceOperationsImportRouteName,
            component: OperationsImportPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "banks",
            name: FinanceOperationsBanksRouteName,
            component: OperationsBanksPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "connect-bank",
            name: FinanceOperationsBankConnectionRouteName,
            component: OperationsBankConnection,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      },
      {
        path: "restore-operations",
        name: FinanceOperationsRestoreRouteName,
        component: OperationsRestorePage,
        meta: {
          breadcrumbBefore: [
            {
              to: { name: FinanceOperationsRouteName },
              name: "Операции"
            }
          ],
          breadcrumb: { name: "Корзина" }
        }
      },
      {
        path: "planning/:id",
        name: FinancePlanningDetailRouteName,
        component: PlanningDetailPage,
        meta: {
          breadcrumbBefore: [
            {
              to: { name: FinancePlanningRouteName },
              name: "Планирование бюджета"
            }
          ],
          breadcrumb: {
            name: "Бюджет"
          }
        }
      },
      {
        path: "planning",
        name: FinancePlanningRouteName,
        component: PlanningPage,
        meta: {
          breadcrumb: {
            name: "Планирование бюджета"
          }
        },
        children: [
          {
            path: ":id/edit",
            name: FinancePlanningEditRouteName,
            component: PlanningEditPage,
            meta: {
              breadcrumb: undefined
            }
          },
          {
            path: "create",
            name: FinancePlanningCreateRouteName,
            component: PlanningCreatePage,
            meta: {
              breadcrumb: undefined
            }
          }
        ]
      },
      {
        path: "statistics",
        name: FinanceStatistics,
        component: StatisticsPage,
        meta: {
          breadcrumb: {
            name: "Статистика"
          }
        }
      },
      {
        path: "reports",
        name: FinanceReportsPage,
        component: ReportsPage,
        meta: {
          breadcrumb: {
            name: "Отчеты"
          }
        }
      }
    ]
  }
]
