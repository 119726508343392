import type { EventBusKey } from "@vueuse/core"
import type { OperationsCreateDto } from "../interface"

import { useEventBus } from "@vueuse/core"

export type OperationsCreateEvents = OperationsCreateDto["event"]
export const operationsCreateBus: EventBusKey<OperationsCreateEvents> =
  Symbol("OperationsCreateBus")
export const useOperationsCreateBus = () =>
  useEventBus<OperationsCreateEvents, { message: string }>(operationsCreateBus)
