<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, onMounted, onUnmounted, type PropType, ref, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import { useToggle } from "@vueuse/core"
import { h } from "vue"
import { RetweetOutlined } from "@ant-design/icons-vue"

import { useOperationCategoriesStore } from "@/modules/operation-category"
import { AButton } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import OperationImportBankForm from "../OperationImportBankForm/OperationImportBankForm.vue"

import { useFormState } from "./hooks/use-form-state"
import { useRuleset } from "./hooks/use-ruleset"

const props = defineProps({
  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  formTitle: {
    type: String,
    default: ""
  }
})

const emit = defineEmits(["success"])

const { formState } = useFormState()
const ruleset = useRuleset()
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const operationsStore = useOperationCategoriesStore()
const { createError, isCreating, isCreateFinished } = storeToRefs(operationsStore)

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const confirmFormRef = ref<typeof Form | null>(null)
const importFormRef = ref<typeof Form | null>(null)
const countdown = ref<number>(30)

const submitAction = () =>
  validate()
    // .then(() => operationsStore.createOperationCategories(requestData.value))
    .then((id: number) => {
      openForm()
      push.success({
        message: "Статья создана"
      })
      emit("success", id)
      operationsStore.updateBufferAfterCreate(id)
    })
    .catch((err) => {
      if (isValidateError(err)) {
        confirmFormRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isCreateFinished.value || isValidating.value)
})

onUnmounted(() => {
  isCreateFinished.value = false
})

const [isFormOpen, toggleFormOpen] = useToggle(false)
const [codeIsSended, toggleCodeIsSended] = useToggle(true)

const openForm = () => {
  toggleFormOpen(true)
}

const closeForm = () => {
  toggleFormOpen(false)
}

const resendCode = () => {
  toggleCodeIsSended(true)
}

let timer: number | undefined
let interval: number | undefined

watch(codeIsSended, (codeStatus) => {
  if (codeStatus) {
    countdown.value = 30
    interval = window.setInterval(() => {
      countdown.value--
      if (countdown.value === 0) {
        toggleCodeIsSended(false)
      }
    }, 1000)
  } else {
    if (interval) {
      window.clearInterval(interval)
      interval = undefined
    }
  }
})

onMounted(() => {
  interval = window.setInterval(() => {
    countdown.value--
    if (countdown.value === 0) {
      toggleCodeIsSended(false)
    }
  }, 1000)
})

onUnmounted(() => {
  if (timer) {
    clearTimeout(timer)
  }
  if (interval) {
    clearInterval(interval)
  }
})

const formattedCountdown = computed(() => {
  const minutes = Math.floor(countdown.value / 60)
    .toString()
    .padStart(2, "0")
  const seconds = (countdown.value % 60).toString().padStart(2, "0")
  return `${minutes}:${seconds}`
})
</script>

<template>
  <AForm
    ref="confirmFormRef"
    name="create-operation-categories-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem label="Код из СМС" name="code" v-bind="validateInfos?.code">
      <AInput v-model="formState.code" size="large" placeholder="Введите код" />
    </AFormItem>
    <AFlex v-if="codeIsSended" justify="center" align="center">
      <ATypographyParagraph
        :style="{
          textAlign: 'center',
          fontSize: '16px',
          marginTop: '16px',
          marginBottom: 0,
          height: '40px'
        }"
      >
        Отправить повторно через
        <ATypographyText
          strong
          :style="{
            fontSize: '16px'
          }"
          >{{ formattedCountdown }}</ATypographyText
        >
      </ATypographyParagraph>
    </AFlex>
    <AButton
      v-else
      :style="{ marginTop: '16px' }"
      block
      size="large"
      :icon="h(RetweetOutlined)"
      @click="resendCode"
      >Выслать СМС повторно</AButton
    >
    <APageDrawer
      v-if="isFormOpen"
      v-model:open="isFormOpen"
      :title="props.formTitle"
      class-name="bank-account-drawer"
    >
      <OverlayScrollbarsComponent :style="{ maxHeight: '100%' }">
        <OperationImportBankForm ref="importFormRef" />
      </OverlayScrollbarsComponent>

      <template #footer>
        <ARow :gutter="[8, 8]">
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block @click="closeForm">Отмена</AButton>
          </ACol>
          <ACol :sm="24" :md="12" flex="1 1 50%">
            <AButton size="large" block type="primary" @click="openForm">Подключить 333</AButton>
          </ACol>
        </ARow>
      </template>
    </APageDrawer>
  </AForm>
</template>
